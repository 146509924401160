import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CamperStatus } from '@enums';

@Component({
  selector: 'cah-table-switch',
  templateUrl: './table-switch.component.html',
  styleUrls: ['./table-switch.component.scss']
})
export class TableSwitchComponent implements OnInit {
  @Input() state: any = '';
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  camperStatus = CamperStatus;

  constructor() { }

  ngOnInit() {
  }

  onSwitch() {
    let payload;
    if (this.state === CamperStatus.ACTIVE) {
      payload = CamperStatus.SUSPENDED;
    } 
    else if (this.state === true) {
      payload = CamperStatus.SUSPENDED;
    }
    else if (this.state === false) {
      payload = CamperStatus.ACTIVE;
    }
    else {
      payload = CamperStatus.ACTIVE;
    }
    this.state = payload;
    this.onChange.emit(payload);
  }

}
