import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cah-archive-status',
  templateUrl: './archive-status.component.html',
  styleUrls: ['./archive-status.component.scss']
})
export class ArchiveStatusComponent implements OnInit {
  @Input() status: boolean = false;
  @Input() row_id: string;
  @Output() getArchiveOption: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onArchiveId(row_id) {
    this.getArchiveOption.emit({ row_id: this.row_id, archive: true });
  }

  onUnarchiveId(row_id) {
    this.getArchiveOption.emit({ row_id: this.row_id, archive: false });
  }
}
