import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'cah-tab-header-button',
  templateUrl: './tab-header-button.component.html',
  styleUrls: ['./tab-header-button.component.scss']
})
export class TabHeaderButtonComponent implements OnInit {
  @Input() type: string;
  @Input() disabled: boolean = false;
  @Input() customLabel: string = '';
  @Input() bindName: string;
  @Input() dropDownOptions: string[] = [];
  @Output() outOnClick: EventEmitter<any> = new EventEmitter();
  dropDownToggle: boolean = false;
  language: string;

  constructor(public languageService: LanguageService) { 
    languageService.language$
      .subscribe(lang => {
        this.language = lang;
      })
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.type === 'dropdown') {
      this.dropDownToggle = false;
    }
  }

  ngOnInit() {
  }

  onToggleDropDown(evt: Event): void {
    evt.stopPropagation();
    this.dropDownToggle = !this.dropDownToggle;
  }

  onClick(event: any): void {
    if (this.disabled) { return; }
    let payload: any = {
      action: this.bindName,
      value: true
    };

    if (this.type === 'dropdown') {
      payload.action = event;
    }

    if (this.type === 'custom') {
      payload.action = this.customLabel;
    }

    this.outOnClick.emit(payload);
  }

}
