import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({ name: 'indicatorBox', pure: true })
export class IndicatorBoxPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(percentage: number): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--value: ${percentage}%;'}`);
  }
} 