import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProgramVisibleEnum } from 'app/enums/EProgramVisibleType.enum';
import { ICustomDescription } from 'app/interfaces/custom-description.interface';
import { IProgSessForm } from 'app/interfaces/prog-sess-form.interface';

@Component({
  selector: 'cah-prog-wpage-settings',
  templateUrl: './prog-wpage-settings.component.html',
  styleUrls: ['./prog-wpage-settings.component.scss']
})
export class ProgWpageSettingsComponent implements OnInit {

  @Input() program ;
  @Output() onChange = new EventEmitter<any>();
  
  payload = {}
  
  programList: IProgSessForm[] = [ //*//   FOR Programs  //*//
    {
      icon: 'clickto-earth-link',
      title: 'visible_based_on_sessions',
      subtitle: 'auto_turns_visble',
      clicked: false,
      type: 'none',
      name: ProgramVisibleEnum.VISIBLEBYSESSION,
      value: null
    },
    {
      icon: 'clickto-earth-secure',
      title: 'always_visible',
      subtitle: 'program_always_visible',
      clicked: false,
      type: 'none',
      value: null,
      name: ProgramVisibleEnum.VISIBLE,
    },
    {
      icon: 'clickto-earth-delete',
      title: 'hidden',
      subtitle: 'program_be_hidden',
      clicked: false,
      type: 'none',
      value: null,
      name: ProgramVisibleEnum.HIDDEN
    }
  ]

  constructor() { }

  ngOnInit() {

    this.programList.map(el => {
      if (el.name == this.program.visibility) {
        el.clicked = true;
      }
    })
  }

  onInputChange(e, name){ 
    this.onChange.emit({...this.payload, [name]: e.target.value})
  }

  onAddDescription(e){
    this.program.descriptions.push({ title:'', description:'', action: 'create' });
  }

  onDeleteDescription(index){
    if(this.program.descriptions[index].id){
      this.program.descriptions[index]['action'] = 'delete';
    }else{
      this.program.descriptions.splice(index, 1);
    }
  }

  onCustomDescChange(option: string, index: number, value: string): void {
    this.program.descriptions[index][option] = value;
    this.program.descriptions[index][`${option}Error`] = '';
    this.program.descriptions[index]['action'] = this.program.descriptions[index].id ? 'update' : 'create';
  }
  
  onClick(itm): void {
    this.programList.map(el => el.clicked = false);
    itm.clicked = !itm.clicked;
    this.onChange.emit({...this.payload, 'visibility': itm.name})
  }

}
