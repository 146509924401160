import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
@Pipe({
    name: 'safeStyle',
    pure: true
})
export class SafeStylePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}
    transform(value: string, varName: string): SafeStyle {
        if (!value) return '';
        
        return this.sanitizer.bypassSecurityTrustStyle(`--${varName}: ${value};'}`);
    }
}