import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CampService } from '@services/camp/camp.service';
import { Weekdays } from '@util/weekdays/weekdays';
import { IWeekday } from '@interfaces';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'cah-date-time-listing',
  templateUrl: './date-time-listing.component.html',
  styleUrls: ['./date-time-listing.component.scss']
})
export class DateTimeListingComponent implements OnInit, OnChanges {
  @Input() listDates: any[] = [];
  @Input() dropdownOptions: any[] = [];
  @Input() type: 'week' | 'date' = 'date';
  @Input() delete: boolean = false;
  @Input() addButton: boolean = false;
  @Input() stickyButton: boolean = false;
  @Input() height: number = 319;
  @Output() outValues = new EventEmitter<any[]>();
  minimumDate: Date = new Date();
  calendarModal: boolean = false;
  dateOptions: any[] = [];
  calendarEditPayload: any = {
    action: 'edit',
  };
  weekdays: IWeekday[] = Weekdays;
  moment = moment;

  constructor(private campService: CampService) { }

  ngOnInit() {
    if (this.type === 'week') {
      this.weekdays[6].sort = this.campService.getCampInfo().week_start === 'su' ? 1 : 8; 
      const sortedWeekdays: string[] = this.weekdays.sort((a, b) => a.sort - b.sort).map(w => w.fulltTitle);
      this.dropdownOptions.sort((a, b) =>{
        return sortedWeekdays.indexOf(a.week) - sortedWeekdays.indexOf(b.week);
    });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.listDates) {
      this.dateOptions = [];
      this.listDates.forEach(timeslot => {
        this.dateOptions.push({ 
          id: timeslot.id || null,
          date: moment(timeslot.date).format('ll'), 
          week: moment(timeslot.date).format('dddd'), 
          start_time: timeslot.start_time,
          end_time: timeslot.end_time,
          error: timeslot.error,
          action: timeslot.action
         })
      });
    }
  }

  onChangeValue(index: number, prop: string, value: string): void {
    this.dateOptions[index][prop] = value;
    this.dateOptions[index]['action'] = this.dateOptions[index]['action'] === 'create' ? 'create' : 'update';
    this.outValues.emit(this.dateOptions);
  }

  onAddNewDate(evt: any): void {
    const selectedDay: any = moment(evt);
    if (this.calendarEditPayload.action === 'edit') {
      this.dateOptions[this.calendarEditPayload.index]['date'] = (selectedDay).format('YYYY-MM-DD');
      this.dateOptions[this.calendarEditPayload.index]['action'] = this.dateOptions[this.calendarEditPayload.index]['action'] === 'create' ? 'create' : 'update';
    } else {
      this.dateOptions.push({
        date: selectedDay.format('ll'), 
        week: selectedDay.format('dddd'), 
        start_time: '',
        end_time: '',
        action: 'create'
      });
    }
    this.calendarModal = false;
    this.outValues.emit(this.dateOptions);
  }

  onDelete(index: number): void {
    this.dateOptions[index].action = 'delete';
    this.outValues.emit(this.dateOptions);
  }

  onEditDate(index: number): void {
    if (this.type === 'week') return;
    this.calendarModal = true;
    this.calendarEditPayload = {
      action: 'edit',
      index
    };
  }

}
