import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { HelperService } from '@services/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class TimeslotService {

  constructor(private http: HttpClient) { }


  addTimeslot(payload): Observable<any> {
    return this.http
      .post(environment.endpoint + 'timeslots/timeslot', payload)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  getTimeslots(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http
      .get(environment.endpoint + 'timeslots?' + queryString)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  getTimeslot(id: string): Observable<any> {
    return this.http.get<any>(environment.endpoint + 'timeslots?session=' + id + '&all=1')
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  getSpaceOrGroupWithActivity(timeslotId: string, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get<any>(environment.endpoint + `timeslots/timeslot/${timeslotId}/check?${queryString}`)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  addCopiedDayTimeslot(payload, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http
      .post(environment.endpoint + `timeslots/day/copy?${queryString}`, payload)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }
  checkTimeslotDay(params: any): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http
      .get(environment.endpoint + `timeslots/date/check?${queryString}`)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }
  addDayTimeslotInactive(payload): Observable<any> {
    return this.http
      .post(environment.endpoint + `timeslots/date/session/inactive`, payload)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }
  addDayTimeslotActive(payload, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http
      .post(environment.endpoint + `timeslots/date/session/active?${queryString}`, payload)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }
  editDayTimeslot(payload): Observable<any> {
    return this.http
      .patch(environment.endpoint + 'timeslots/date', payload)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }
  editTimeTimeslot(id, payload): Observable<any> {
    return this.http
      .patch(environment.endpoint + 'timeslots/timeslot/' + id, payload)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  deleteDayTimeslot(payload): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ...payload },
    };


    return this.http
      .delete(environment.endpoint + 'timeslots/day', options)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        }),
      );
  }

  deleteTimeTimeslot(id): Observable<any> {
    return this.http
      .delete(environment.endpoint + 'timeslots/timeslot/' + id)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        }),
      );
  }

  getTimeslotLabels(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http
      .get(environment.endpoint + 'timeslots/timeslot/label?' + queryString)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  addTimeslotLabel(payload): Observable<any> {
    return this.http
      .post(environment.endpoint + 'timeslots/timeslot/label', payload)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  pasteQuickAssigment(id: number, payload: any, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http
      .post(environment.endpoint + `timeslots/assignment/copy/${id}?${queryString}`, payload)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  ediTimeslotLabel(id, payload): Observable<any> {
    return this.http
      .patch(environment.endpoint + 'timeslots/timeslot/label/' + id, payload)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  deleteTimeslotLabel(id): Observable<any> {
    return this.http
      .delete(environment.endpoint + 'timeslots/timeslot/label/' + id)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        }),
      );
  }

  onDeleteStaffTimeslot(id: any): Observable<any> {
    return this.http
      .delete(environment.endpoint + 'timeslots/assignment/timeslot/' + id)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  pasteStaffTimeslot(payload: any, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http
      .post(environment.endpoint + `timeslots/assignment/timeslot/paste?${queryString}`, payload)
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }
}
