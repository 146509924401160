import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ModelTypeEnum, TooltipPlacementEnum } from "app/enums";
import { IButton, IInputValue } from "app/interfaces";

@Component({
  selector: "cah-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() campId: number;
  //'primary','secondary','icon','text'
  @Input()  model = ModelTypeEnum.BtnPRIMARY;

  @Input() showActionTooltip: boolean = false;
  @Input() tooltipVal: number = null;
  @Input() actionTooltip: string = "";
  @Input() onClickActionTooltip: string ='';
  @Input() dynamic: boolean = false;
  @Input() backgroundColor: string;
  @Input() borderRadius: string;
  
  //button text
  @Input() text: string = "";

  @Input() hoverType: string = "background";

  //'button', 'submit', 'reset'
  @Input() type: string = "button";
  @Input() background: string = "";

  //'clickto-*' any class from './assets/icons/clickto-icons.css'
  @Input() icon: string = "";
  @Input() iconPosition: string = "left";

  //makes button disabled
  @Input() disabled: boolean = false;

  @Input() active: boolean = false;

  //'lg'; 'sm' , by default nothing
  @Input() size: string = "";

  @Input() width: string = "";
  @Input() height: string = "";

  //passed name
  @Input() bindName: string = "";

  // ITooltipInterface
  @Input() tooltip: any = {
    placement: TooltipPlacementEnum.NONE,
    text: "",
    text2: ""
  };
  @Input() tooltipTranslated: boolean = true;

  @Output() outClick: EventEmitter<IInputValue> = new EventEmitter();

  onClickTooltipStatus: boolean = false;

  constructor() {}

  ngOnInit() {
    if (
      !this.icon &&
      (this.model === ModelTypeEnum.BtnICON ||
        this.model === ModelTypeEnum.BtnICONTRANSPARENT)
    ) {
      this.icon = "cog";
    }
  }

  onClick(event: MouseEvent) {
    if (!this.disabled) {
      const payload: IInputValue = {
        name: this.bindName,
        value: event,
      };
      this.outClick.emit(payload);
    }

      
    if(this.onClickActionTooltip){
      this.onClickTooltipStatus = true;
      setTimeout(() => {
            this.onClickTooltipStatus = false;
          },750);  
    }
  }
}
