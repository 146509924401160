import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';
import { DateFormatService } from '@services/date-format/date-format.service';
import * as moment from 'moment';

@Component({
  selector: 'cah-form-time',
  templateUrl: './form-time.component.html',
  styleUrls: ['./form-time.component.scss']
})
export class FormTimeComponent implements OnInit, OnChanges {

  @Input() bindName: string;
  @Input() type: string;
  @Input() label: string;
  @Input() placeHolder: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isPrefixIcon: boolean = false;
  @Input() value: any;
  @Input() error: any;
  @Input() errorWithoutMessage: boolean = false;
  @Input() iconSize: string = "tp-icon-2";
  @Input() isFilter: boolean = false;
  @Input() clearBtn: boolean = false;
  @Input() isModalGenerator: boolean = true;
  @Input() state: string = 'gray' || 'white' || 'generator';
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();

  focus: boolean = false;

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService, 
              public dateFormatter: DateFormatService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.value && this.value && this.value.split(':').length == 3) {
      this.value = this.value.slice(0, -3);
    }
  }


  onInputChange(event){
    this.value = event
    const payload: IInputValue = {
      name: this.bindName,
      value: this.value ? moment(this.value,'HHmm').format("HH:mm") : null
    }
    this.onChange.emit(payload);
  }

  modelChange(){
    const payload: IInputValue = {
      name: this.bindName,
      value:this.value ? moment(this.value,'HHmm').format("HH:mm") : null
    }
    this.onChange.emit(payload);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
