import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cah-info-dropdown',
  templateUrl: './info-dropdown.component.html',
  styleUrls: ['./info-dropdown.component.scss']
})
export class InfoDropdownComponent implements OnInit {
  @Input() label: string = '';
  @Input() helper: string = '';
  showDropdown: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
