import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ScheduleTypeEnum } from '@enums';

@Component({
  selector: 'cah-live-board',
  templateUrl: './live-board.component.html',
  styleUrls: ['./live-board.component.scss']
})

export class LiveBoardComponent implements OnInit{
  @Input() groups: any = {};
  @Input() camp: any = {};
  @Input() timeslots: any[] = [];
  @Input() campers: any[] = [];
  @Input() timeslotStart: number;
  @Input() timeslotLimit: number;
  @Input() currentTimeslotId: number = 0;
  @Input() columnSize: string;
  @Input() scheduleFilter: any;
  @Input() isHebrew: boolean = false;
  @ViewChild('scrollTwo', { static: true }) scroll: any;
  @Input() set scrollBody(left) {
    this.scroll.nativeElement.scrollLeft = left;
  }
  @Output() onOutputAction = new EventEmitter<any>();
  @Output() outEditElective: EventEmitter<number> = new EventEmitter();
  @Output() outShowCampers: EventEmitter<any> = new EventEmitter();
  @Output() onHover: EventEmitter<any> = new EventEmitter();
  @Output() onScroll: EventEmitter<any> = new EventEmitter();

  scheduleTypeEnum = ScheduleTypeEnum;

  constructor() { }

  ngOnInit() {
  }

  filtered(schedule: any): any {
    switch (this.scheduleFilter.key) {
      case 'group':
        return !!this.groups[schedule.timeslot_id + '-' + schedule.space_id + '-' + schedule.activity_id + '-' + schedule.elective_id + '-'  + schedule.board_id].find(gr => gr.id === this.scheduleFilter.value)
      
      case 'space':
        if (schedule.type === ScheduleTypeEnum.BYGROUP) {
          return schedule.space_id === this.scheduleFilter.value;
        } else if (schedule.type === ScheduleTypeEnum.ELECTIVE){
          schedule.elective.options = schedule.elective.options.filter(opt => opt.space_id === this.scheduleFilter.value);
          return !!schedule.elective.options.length;
        } else {
          return false;
        }
      
      default:
        return true;
    }
  }

  onScrollBox(event) {
    this.onScroll.emit(event.target.scrollLeft);

  }
}
