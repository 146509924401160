import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormElementModule } from '@shared/form-elements';
import { FormsModule } from '@angular/forms';
import { SharedPipesModule } from 'app/shared-pipes';
import { FormDropdownInputComponent } from './form-dropwon-input.component';



@NgModule({
  declarations: [FormDropdownInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedPipesModule,
    FormElementModule
  ],
  exports: [
    FormDropdownInputComponent
  ],
})
export class FormDropdownInputModule { }
