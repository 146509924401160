import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionAgeTypeEnum } from '@enums';
import { IInputValue } from '@interfaces';

@Component({
  selector: 'cah-form-age-limit',
  templateUrl: './form-age-limit.component.html',
  styleUrls: ['./form-age-limit.component.scss']
})
export class FormAgeLimitComponent implements OnInit {
  @Input() value: any;
  @Input() value1: number;
  @Input() value2: number;
  @Input() error: any;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  selectedAgeType = SessionAgeTypeEnum.AGEGROUP;
  tmpValue1: number;
  tmpValue2: number;
  constructor() { }

  ngOnInit() {
    this.selectedAgeType = this.value ? this.value : this.selectedAgeType;
  }

  radioCheck(event){
      if(this.selectedAgeType == SessionAgeTypeEnum.AGEGROUP && event != SessionAgeTypeEnum.AGEGROUP){
        this.tmpValue1 = this.value1;
        this.tmpValue2 = this.value2;
      }
      if(event == SessionAgeTypeEnum.AGEGROUP){
        this.value1 = this.tmpValue1 ? this.tmpValue1 : null;
        this.value2 = this.tmpValue2 ? this.tmpValue2 : null;
        this.createRangePayloadAndEmit('min_age', this.value1);
        this.createRangePayloadAndEmit('max_age', this.value2);
      }
      if(event == SessionAgeTypeEnum.ADULTS){
        this.createRangePayloadAndEmit('min_age', 18);
        this.createRangePayloadAndEmit('max_age', 120);
      }
      if(event == SessionAgeTypeEnum.NOAGE){
        this.createRangePayloadAndEmit('min_age', 1);
        this.createRangePayloadAndEmit('max_age', 120);
      }
      this.selectedAgeType = event;
      const payload = {
        name: 'age_type',
        value: this.selectedAgeType
      }
      this.onChange.emit(payload);
  }

  createRangePayloadAndEmit(bindname, value){
    const payload: IInputValue = {
      name: bindname,
      value: value
    }
    this.onChange.emit(payload);
  }

}
