import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormElementModule } from '@shared/form-elements';
import { FormRadioComponent } from './form-radio.component';



@NgModule({
  declarations: [FormRadioComponent],
  imports: [
    CommonModule,
    FormElementModule,

  ],
  exports: [
    FormRadioComponent
  ],
})
export class FormRadioModule { }
