import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'cah-groups-indicator',
  templateUrl: './groups-indicator.component.html',
  styleUrls: ['./groups-indicator.component.scss']
})
export class GroupsIndicatorComponent implements OnInit {
  @Input() groups: any = [];
  @Input() showTooltip: boolean = true;
  dropdown: boolean = false;
  position: any = {};

  constructor() { }

  ngOnInit() {
  }

  onDropdownOpen(event): void {
    this.position = HelperService.calculatePopupPosition(event.pageX,
      event.pageY,
      400, 300,
      window.innerHeight,
      window.innerWidth,
      window.scrollY,
      window.scrollX);
      
    this.dropdown = true;
  }

}
