import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GroupService } from '@services/group/group.service';
import { SpaceService } from '@services/space/space.service';
import { TimeslotService } from '@services/timeslots/timeslot.service';
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';
import { CampersService } from '@services/campers/campers.service';

@Component({
  selector: 'cah-reassign-enrollment-modal',
  templateUrl: './reassign-enrollment-modal.component.html',
  styleUrls: ['./reassign-enrollment-modal.component.scss']
})
export class ReassignEnrollmentModalComponent implements OnInit {
  @Input() enrollment: any;
  @Input() camperName: string;
  @Output() onCancel: EventEmitter<boolean> = new EventEmitter();
  enrollmentTypeOptions: any = [
    {
      name: 'Full Session',
      id: 'full'
    },
    {
      name: 'Drop In',
      id: 'dropin'
    },
    {
      name: 'Date Range',
      id: 'range'
    }
  ];
  meetingOptions: any[] = [];
  groupOptions: any[] = [];
  timeSlotOptions: any[];
  model: any = {
    reassign_payments: true,
    reassign_tags: true
  };
  session: any;
  availableDates: Set<any> = new Set();
  datePickerOptions: any = {
    disabledDates: [],
    minDate: null,
    maxDate: null
  };

  validations: any = {
      full: {
        course: [],
        program: ['group_id']
      },
      dropin: {
        course: ['meeting_id'],
        program: ['utc_start', 'group_id']
      },
      range: {
        course: ['utc_start', 'utc_end'],
        program: ['utc_start', 'utc_end', 'group_id']
      }
  }

  constructor(private spaceService: SpaceService, 
              private groupService: GroupService,
              private timeslotService: TimeslotService,
              private dateFormatterService: DateFormatterService,
              private campersService: CampersService
              ) { }

  ngOnInit() {
    console.log(this.camperName);
  }

  getMeetings(){
    this.spaceService.getMeetings({ session_id: this.session.id })
        .subscribe(res => this.meetingOptions = res.data);
  }

  getGroups(){
    this.groupService.getGroups({session: this.session.id})
        .subscribe(res => this.groupOptions = res.data)
  }

  changeEnrollmentType(event){
    switch(event.value){
      case('dropin'): {
        if(this.session && this.session.program.type === 'course' && !this.meetingOptions.length){
          this.getMeetings();
        }
        if(this.session && this.session.program.type === 'program' && !this.availableDates.size){
          this.getDatesFromTimeslots();
        }
        break;
      }
    }
    this.model['type'] = event.value;
  }

  changeSession(event){
    if(!this.session || this.session.id != event.id){
      this.session = event;
      this.model = {
        type: this.model['type'],
        note: this.model['note'],
        reassign_payments: this.model['reassign_payments'],
        reassign_tags: this.model['reassign_tags']
      };
      this.meetingOptions = [];
      this.groupOptions = [];
      if(this.session.program.type === 'program'){
        this.getGroups();
      }
      if(this.model['type'] === 'dropin' && this.session.program.type === 'course'){
        this.getMeetings();
      }
      if(this.model['type'] === 'dropin' && this.session.program.type === 'program'){
        this.getDatesFromTimeslots();
      }
      if(this.model['type'] === 'range'){
        this.datePickerOptions.minDate = null;
        this.datePickerOptions.maxDate = null;
      }
    }
  }

  onInputChange(event){
    this.model[event.name] = event.value;
    if(event.name === 'utc_start' && this.model['type'] === 'range') this.datePickerOptions.minDate = new Date(event.value);
    if(event.name === 'utc_end' && this.model['type'] === 'range') this.datePickerOptions.maxDate = new Date(event.value);
  }

  onSubmit(){
    if(!this.disablebutton()){
      const payload = {
        enroll_id: this.enrollment.id,
        session_id: this.session.id,
        ...this.model
      }
      this.campersService.swapEnrollment(payload)
          .subscribe(res => {
            this.onCancel.emit(true);
          })
    }
  }

  onMonthChange(e){    
    if(!e['month']) e = {
      month: (new Date()).getMonth() + 1,
      year: (new Date()).getFullYear()
    }
    this.datePickerOptions.disabledDates = [];
    this.dateFormatterService.getDaysInMonth(e.month-1, e.year).map(date =>{
      let dateString = this.dateFormatterService.formatDateToYmD(date);
      if(!this.availableDates.has(dateString)){
          this.datePickerOptions.disabledDates.push(new Date(dateString));
      }
    }) 
  }

  getMarkedDays(date) {
    const dateString = `${date.year.toString()}-${('00' + (date.month + 1).toString()).substr(-2)}-${('00' + date.day.toString()).substr(-2)}`;
    return this.availableDates.has(dateString);
  }

  getDatesFromTimeslots() {
    this.availableDates = new Set();
    this.timeslotService.getTimeslots({session: this.session.id, all: 1})
        .subscribe(res => {
          res.data.forEach(timeslot => {
            if(!this.availableDates.has(timeslot.date)) this.availableDates.add(timeslot.date);
          });
        })
  }

  disablebutton(){
    if(this.session && this.model['type']){
      console.log(this.validations[this.model.type][this.session.program.type], this.model)
      return !this.validations[this.model.type][this.session.program.type].every(item => this.model[item]);
    }
    return true;
  }

}
