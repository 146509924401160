import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IInputValue } from "@interfaces";
import { HelperService } from "@services/helper/helper.service";
import { LanguageService } from "@services/language/language.service";

@Component({
  selector: "cah-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"],
})
export class ImageUploadComponent implements OnInit {
  @Input() bindName: string = "";
  @Input() type: string = "";
  @Input() label: string = "";
  @Input() placeHolder: string = "";
  @Input() helper: string = "";
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() value: string = "";
  @Input() imageProperties: any = {};
  @Input() error: any;
  @Input() tabindex: string = "";
  // @Input() preview: any;

  preview: any;
  @Input('preview') set getPreview(value: string) {
    this.preview = value;
    if(typeof value == 'string' && value.split('.')[value.split('.').length - 1] == 'mp4') {
      this.format = 'video'
    }else {
      this.format = 'image';
    }
  }
  @Input() loading: boolean = false;
  @Input() width: string = "100%";
  @Input() height: string;
  @Input() bottomButtonVisible: boolean = true ;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();
  @Input() no_padding: boolean = false;
  fileDrag: boolean = false;
  allowImageTypes: string[] = ['.jpg', '.jpeg', '.png', '.mp4'];
  isMouseOver: boolean = false;
  format:any = null;


  constructor(public languageService: LanguageService) {}

  ngOnInit() {
    
  }

  onSubmitValue(files: any): any {
    this.loading = true;
    this.error = '';
    if (files[0] && files[0].name && !HelperService.hasExtension(files[0], this.allowImageTypes)) {
      this.loading = false;
      // this.detectFile(files);
      return this.error = 'this_file_type_is_not_supported';
    }else{
      this.loading = false;
    }
    
    var reader = new FileReader();
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => {
      this.detectFile(files);
      this.preview = reader.result; 
      this.loading = false;
      this.fileDrag = false;
    }
    
    const payload: IInputValue = {
      name: this.bindName,
      value: files[0],
    };
    this.onChange.emit(payload);
  }

  detectFile(files: any) {
    if(files[0].type.indexOf('video')> -1) {
      this.format = 'video';
    }else {
      this.format = 'image';
    }
  }
  
}


