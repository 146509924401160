import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { UserRole } from '@enums';

@Component({
  selector: 'cah-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() size: string = 'lg';
  @Input() visibleItems: number = 3;
  @Input() items: any = [];
  @Input() bindImg: string = 'image';
  @Input() bindFullname: string = 'first_name,last_name';
  @Input() bindRole: string = 'label';
  @Input() showTooltip: boolean = true;
  @Input() noHover: boolean = false;
  @Input() defaultImage: boolean = false;
  dropdown: boolean = false;
  position: any = {};

  constructor() { }

  ngOnChanges(){
  }

  ngOnInit() {
  }

  onDropdownOpen(event): void {
    this.position = HelperService.calculatePopupPosition(event.pageX,
      event.pageY,
      400, 300,
      window.innerHeight,
      window.innerWidth,
      window.scrollY,
      window.scrollX);
      
    this.dropdown = !this.dropdown;
  }

}
