import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, delay, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import { HelperService } from '@services/helper/helper.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SpaceService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  getSpaces(params: any): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + 'spaces?' + queryString).pipe(take(1));
  }

  getSpace(spaceId: number): Observable<any> {
    return this.http.get(environment.endpoint + `spaces/space/${spaceId}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getMeetings(params: any): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + 'spaces/meetings?' + queryString).pipe(take(1));
  }

  getMeetingsCount(sessionId: any): Observable<any> {
    return this.http.get(environment.endpoint + 'spaces/meetings/count/' + sessionId).pipe(take(1));
  }


  addSpace(data) {
    return this.http.post(environment.endpoint + 'spaces/space', data)
      .pipe(catchError((e) => throwError(e.error || 'server error')));
  }

  addMeeting(data){
    return this.http.post(environment.endpoint + 'meetings/meeting', data)
      .pipe(catchError((e) => throwError(e.error || 'server error')));
  }


  convertToBackFormat(data){
   const payload = {
     session_id: 1,
     dates: [],
     space_id: data.space_id,
     calendar_event: data.calendar_event
   }
   const startTime = data.start_time ? data.start_time + ':00' : '00:00:00';
   const endTime = data.end_time ? data.end_time + ':00' : '00:00:00';
   if(data.date_list){
    data.date_list.forEach(element => {
      let date = element.split('T')[0];
      let dateItem = {
        start: date + 'T' + startTime,
        end: date + 'T' + endTime
      }
      payload.dates.push(dateItem);
    });
   }else{
    let date = data.date.split('T')[0];
    let dateItem = {
      start: date + 'T' + startTime,
      end: date + 'T' + endTime
    }
    payload.dates.push(dateItem);
   }

   return payload;
  }


  addSpaceMultiple(data) {
    return this.http.post(environment.endpoint + 'spaces/space/import', data)
      .pipe(catchError((e) => throwError(e.error || 'server error')));
  }

  updateSpace(id, data) {
    return this.http.patch(environment.endpoint + 'spaces/space/' + id, data)
      .pipe(catchError((e) => throwError(e.error || 'server error')));
  }

  deleteSpace(id: number): Observable<any> {
    return this.http
      .delete(`${environment.endpoint}spaces/space/${id}`)
      .pipe(
        switchMap(r => {
          return of({
            status: true,
            data: r
          });
        }),
        catchError(e => {
          return of({ status: false, message: 'error on deleting' });
        }),
      );
  }

  deleteSpaceList(payload: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ...payload },
    };
    return this.http.delete(environment.endpoint + "spaces/multiple", options)
      .pipe(
        map( res => res),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  deleteMeetingList(payload: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ...payload },
    };
    return this.http.delete(environment.endpoint + "spaces/meetings", options)
      .pipe(
        map( res => res),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  goToKaltura(params: any = {}, space_title: string, logo: string, isInstructor?: number): any {
    const queryString = HelperService.generateQueryParams(params);
    const url = this.router.createUrlTree(['/room'], { queryParams: { link: `${environment.endpoint}newrow/launch/staff?${queryString}`, space_title, logo, isInstructor } });
    return HelperService.goToLink(url.toString());
  }

  goToZoom(params: any = {}): any {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(`${environment.endpoint}zoom/launch/staff?${queryString}`)
          .pipe(
            take(1),
            shareReplay(1),
            map( res => res ),
            catchError( error => {
              return throwError(error.error || "server error")
            })
          )
  }

  getGuestSpaceData(code: string): Observable<any> {
    return this.http.get<any>(environment.endpoint + 'spaces/guest/' + code)
      .pipe(take(1), catchError( err => throwError(err)));
  }

  getGuestMeetingLink(code: string, name: string): Observable<any> {
    return this.http.get<any>(environment.endpoint + 'spaces/guest/' + code + '/url?name=' + name)
      .pipe(take(1), catchError( err => throwError(err)));
  }

  goToGuestKalturaMeeting(code: string, name: string, camp_id: number, space_title: string, isGuest: number = 1, logo: string, roomType: string): any {
    if(roomType === 'zoom'){
      return this.http.get(`${environment.endpoint}spaces/guest/${code}/url?name=${name}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
    }else{
      return this.router.navigate(['/room'], { queryParams: { link: `${environment.endpoint}spaces/guest/${code}/url?name=${name}`, camp_id, space_title, isGuest, logo } })
    }
  }

  getSpaceByGuestCode(code: string): Observable<any> {
    return this.http.get<any>(environment.endpoint + 'spaces/space/get-camp/' + code)
      .pipe(take(1), catchError( err => throwError(err)));
  }

  resetSpaceGuestLink(id: number): Observable<any> {
    return this.http.patch(environment.endpoint + `spaces/space/${id}/reset-code`, {})
      .pipe(
        take(1),
        catchError( err => throwError(err))
      );
  }

  attachFile(data: any, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.post(environment.endpoint + `presentations/upload/file?${queryString}`, data)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  removeFileFromSpace(spaceId: number, resourceId: number): Observable<any> {
    return this.http.delete(environment.endpoint + `spaces/space/${spaceId}/file/${resourceId}`)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  getLibrary(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `presentations/content/library?${queryString}`)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  deleteLibraryItem(id: number): Observable<any> {
    return this.http.delete(environment.endpoint + `presentations/file/${id}`)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  deleteLibraryItems(data: any = {}): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ...data },
    };

    return this.http.delete(environment.endpoint + `presentations/files`, options)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  addItemsToSpace(spaceId: number, data): Observable<any> {
    return this.http.patch(environment.endpoint + `spaces/space/${spaceId}/files`, data)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  attachFileToSpaces(data: any): Observable<any> {
    return this.http.patch(environment.endpoint + `spaces/file`, data)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  removeFileFromSpaces(data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ...data },
    };

    return this.http.delete(environment.endpoint + `spaces/file`, options)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  getAttendeesReport(spaceId: number) {
    return this.http.get(environment.endpoint + `spaces/space/${spaceId}/attendees`, { responseType: 'blob' })
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  getNewrowTestLink() {
    const url = this.router.createUrlTree(['/room'], { queryParams: { link: `${environment.endpoint}newrow/demo`, camp_id: 123 } });
    return HelperService.goToLink(url.toString(), '_self');
  }


}
