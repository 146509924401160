import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { ElectiveTypeEnum, RoomTypeEnum } from 'app/enums';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { CampersService } from '@services/campers/campers.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-live-board-box-elective',
  templateUrl: './live-board-box-elective.component.html',
  styleUrls: ['./live-board-box-elective.component.scss']
})
export class LiveBoardBoxComponentElective implements OnInit {
  @Input() schedule: any = {};
  @Input() groups: any = {};
  @Input() camp: any = {};
  @Input() campers: any[] = [];
  @Output() onOutputAction = new EventEmitter<any>();
  @Output() outEditElective: EventEmitter<number> = new EventEmitter();
  @Output() outShowCampers: EventEmitter<any> = new EventEmitter();

  showDropDown: boolean = false;
  showOptionDropDown: any = {};
  groupDetails: any = {};
  groupDetailsPosition: any = {};
  assignedCampersPosition: any = {};
  staffAssgnmentHoverPosition: any = {};
  electiveTypeEnum = ElectiveTypeEnum;
  roomTypeEnum = RoomTypeEnum;

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;


  constructor(public languageService: LanguageService,
    private campersService: CampersService,
    private eRef: ElementRef
    ) {
      languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == 'he';
    });
   }

  ngOnInit() {
    if (!this.schedule.elective) return;
    this.schedule.elective.options.forEach(element => { 
      element['showStaff'] = false 
      element['showCamper'] = false 
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeAllDropDown()
    }
  }

  onEditElective(id: number): void {
    this.outEditElective.emit(id);
  }

  onShowOptionDropDown(index: number): any {
    if (this.showOptionDropDown[index]) {
      return this.showOptionDropDown[index] = false;
    }
    this.closeAllDropDown();
    this.showOptionDropDown[index] = true;
  }

  closeAllDropDown(): void {
    Object.keys(this.showOptionDropDown).forEach(key => {
      this.showDropDown = false;
      this.showOptionDropDown[key] = false;
    });
  }

  onClickDropDownMenu(option: string, index: number): void {
    this.onOutputAction.emit({ action: option, space: this.schedule.elective.options[index].space });
    this.closeAllDropDown();
  }

  onShowGroupDetails(event: any, i: number, option: boolean): void {
    this.groupDetails[i] = option;
    this.groupDetailsPosition = HelperService.calculatePopupPosition(event.pageX,
      event.pageY,
      400, 300,
      window.innerHeight,
      window.innerWidth,
      window.scrollY,
      window.scrollX);
  }

  onAssignedCampersShow(event: any, index: number, showCampers: boolean) {
    this.schedule.elective.options[index].showCamper = showCampers;
    this.assignedCampersPosition = HelperService.calculatePopupPosition(event.pageX,
      event.pageY,
      400, 300,
      window.innerHeight,
      window.innerWidth,
      window.scrollY,
      window.scrollX);
    if (showCampers) {
      this.outShowCampers.emit({ option_id: this.schedule.elective.options[index].id })
    }
    else {
      this.outShowCampers.emit({ option_id: -1 })
    }
  }


  onShowstaffAssgnment(event: any, index: number, showStaff: boolean) {
    this.schedule.elective.options[index].showStaff = showStaff;
    this.staffAssgnmentHoverPosition = HelperService.calculatePopupPosition(event.pageX,
      event.pageY,
      400, 300,
      window.innerHeight,
      window.innerWidth,
      window.scrollY,
      window.scrollX);
      
  }
  

  hexToRgbNew(hex: any, opacity: any): string {
    if (!hex) { return; }
    let h = hex.replace('#', '');
    h = h.match(new RegExp('(.{' + h.length / 3 + '})', 'g'));

    for (let i = 0; i < h.length; i++) {
      h[i] = parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16);
    }

    if (typeof opacity !== 'undefined') { h.push(opacity); }

    return 'rgba(' + h.join(',') + ')';
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
