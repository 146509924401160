import { Injectable } from '@angular/core';
import { IFormField } from '@interfaces';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { CampService } from '@services/camp/camp.service';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {
  errors: any = {};
  dependantDates: any = {};
  camp: any = {};

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  translateValue: string = '';

  constructor(private languageService: LanguageService, private campService: CampService, public translate: TranslateService) {

    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == "he";
      });

      this.campService.campInfo$.subscribe(
        (res: any) => {
          if (!res) { return; }
          this.camp = res;
        }
      )
  }

  validateRecursiveLayout(fields: IFormField[]) {
    this.errors = {};
    this.dependantDates = {};
    this.validate(fields);
    this.validateDate();
    return this.errors;
  }

  validate(fields: IFormField[]) {
    for (let field of fields) {
      if (field.hidden || field.disabled || !field.validators) { continue; }
      if (field.type == 'layout') {
        this.validate(field.items);
      } else {
        for (let validator of field.validators) {
          if (validator == "required") {
            let error = this.validateRequired(field.value);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          } else if (validator == "email") {
            let error = this.validateEmail(field.value);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          } else if (validator == "zipcode") {
            let error = this.validateZipCode(field.value);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          } else if (validator.includes("maxLength")) {
            const maxLengthOption: string[] = validator.split(':');
            
            const error = this.validateMaxLength(field.value, +maxLengthOption[1]);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          }
          else if (validator.includes("minLength")) {
            const options: string[] = validator.split(':');
            const error = this.validateMinLength(field.value, + options[1], field.name);
            if (!error) {
              this.errors[field.name] = error;
            }
          }
          else if (validator.includes("image-size")) {
            const options: string[] = validator.split(':');
            const error = this.validateImageSize(field.value, + options[1]);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          }
          else if (validator == "number") {
            let error = this.validateNumber(field.value);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          }
          else if (validator == "cardExpired") {
            let error = this.validateCardExpired(field.value);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          }
          else if (validator.includes("date")) {
            let dateValidatorProperties = validator.split(':');
            this.dependantDates[field.name] = {
              value: field.value,
              label: field.label,
              operation: dateValidatorProperties[1],
              dependsOn: dateValidatorProperties[2]
            }
          }
          else if (validator.includes('minMaxNumber')) {
            const minMaxOption: string[] = validator.split(':');
            let error = this.validateMinMax(+field.value, +minMaxOption[1], +minMaxOption[2]);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          }
          else if (validator.includes('minNumber')) {
            if (!field.value) return;
            const minOption: string[] = validator.split(':');
            let error = this.validateMin(+field.value, +minOption[1]);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          }
          else if (validator.includes('maxNumber')) {
            if (!field.value) return;
            const maxOption: string[] = validator.split(':');
            let error = this.validateMax(+field.value, +maxOption[1]);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          }
          else if (validator === 'range-minmax') {
            let error = this.validateRange(field);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          }
          else if (validator === 'agegroup') {
            let error = this.validateAgegroup(field);
            if (error !== true) {
              this.errors[field.name] = error;
            }
          }
        }
      }
    }
  }

  validateImageSize(fieldValue, limit){
    if(!fieldValue){
      return true;
    }
    if(fieldValue.size > (limit * 1048576)){
      let error = {
        name: "image-size",
        message: "image_size_limit"
      }
      return error;
    }
    return true;
  }

  validateAgegroup(field): any {
    if (field.value['age_type'] === 'agegroup' && (!field.value[field.bindName1] || !field.value[field.bindName2])) {
      let error = {
        name: "agegroup",
        message: "this_field_is_required"
      }
      return error;
    } else {
      return true;
    }
  }

  validateRange(field): any {
    if (field.value[field.bindName1] > field.value[field.bindName2] && field.value[field.bindName1] != null && field.value[field.bindName2] != null) {
      let error = {
        name: "range-minmax",
        message: "max_must_be_greater_to_min"
      }
      return error;
    } else {
      return true;
    }
  }

  validateTime(fieldValue){
    if(moment().tz(this.camp.timezone.slug).isBefore(moment(fieldValue, 'hh:mm').tz(this.camp.timezone.slug))){
      return true;
    }else{
      let error = {
        name: "time",
        message: "choose_valid_time"
      }
      return error;
    }
  }

  validateEmail(fieldValue) {
    const regex = new RegExp('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}');
    if (fieldValue !== null && fieldValue !== '' && !regex.test(fieldValue)) {
      let error = {
        name: "email",
        message: "enter_valid_email"
      }
      return error;
    } else {
      return true;
    }
  }

  validateZipCode(fieldValue) {
    const regex = new RegExp('^[A-Za-z0-9]+$');
    if (fieldValue !== null && fieldValue !== '' && !regex.test(fieldValue)) {
      let error = {
        name: "zipcode",
        message: "enter_valid_zipcode"
      }
      return error;
    } else {
      return true;
    }
  }

  validateRequired(fieldValue) {
    if (typeof fieldValue === 'string') {
      if (fieldValue.trim().length > 0) {
        return true;
      }
    } else if (typeof fieldValue === 'number') {
      if (fieldValue === 0 || fieldValue) {
        return true
      }
    }

    let error = {
      name: "required",
      message: "this_field_is_required"
    }
    return error;
  }

  validateNumber(fieldValue) {
    let regex = /^(0|[1-9]\d*)(\.\d+)?$/gm;

    if (fieldValue !== "" && !regex.exec(fieldValue)) {
      let error = {
        name: "required",
        message: "this_field_must_number"
      }
      return error;
    }
    return true;
  }

  validateCardExpired(fieldValue) {
    let regex = /^(0\d|1[0-2])\/\d{2}$/gm;
    
    const {0: month, 1: year} = fieldValue.split("/");
    const expiry = new Date(+('20'+year), month);
    
    const current = new Date();
    if (!regex.test(fieldValue) || (expiry.getTime() < current.getTime())) {
      let error = {
        name: "cardExpired",
        message: "format_is_incorrect"
      }
      return error;
    }
    return true;
  }

  validateMinMax(fieldValue: number, min: number, max: number): boolean | any {
    if (fieldValue <= max && fieldValue >= min) {
      return true;
    }

    const error = {
      name: 'minMaxNumber',
      message: `between_min_max`,
      translateDynamicallValues: { max, min }
    };
    return error;
  }

  validateMin(fieldValue: number, min: number): boolean | any {
    if (fieldValue >= min) {
      return true;
    }

    const error = {
      name: 'minNumber',
      message: `greater_than_min`,
      translateDynamicallValues: { min }
    };
    return error;
  }

  validateMax(fieldValue: number, max: number): boolean | any {
    if (fieldValue <= max) {
      return true;
    }

    const error = {
      name: 'maxNumber',
      message: `less_than_max`,
      translateDynamicallValues: { max }
    };
    return error;
  }

  validateMaxLength(fieldValue: string, maxLength: number): boolean | any {
    if (fieldValue === null || (typeof fieldValue === 'string' && fieldValue.length <= maxLength)) {
      return true;
    }

    const error = {
      name: 'maxLength',
      message: `maximum_letters`,
      translateDynamicallValues: { maxLength }
    };
    return error;
  }

  async validateMinLength(fieldValue: string, minLength: number, fieldName: string) {
    if (typeof fieldValue === 'string' && fieldValue.length >= minLength) {
      return true;
    }

    this.translateValue = await this.translate.get(fieldName).toPromise();
      
    const error = {
      name: 'minLength',
      message: `minimum_letters`,
      translateDynamicallValues: { fieldName: this.translateValue, minLength: minLength }
    };
    return error;
  }

  validateDate() {
    for (let dateKey of Object.keys(this.dependantDates)) {
      let dependingDate = this.dependantDates[dateKey];
      const today = {
        dependsOn: dateKey,
        label: "today",
        operation: ">",
        // value: moment().tz(this.camp.timezone.slug).toISOString().split('T')[0] + "T" + "00:00:00.000"
        value: moment().tz(this.camp.timezone.slug).format("YYYY-MM-DD[T]HH:MM:SS").split('T')[0] + "T" + "00:00:00.000"
      }
      let dependantDate = dependingDate.dependsOn == 'now' ? today : this.dependantDates[dependingDate.dependsOn];
      
      switch (dependingDate.operation) {

        case "timeMore":
          let end_time = moment().tz(this.camp.timezone.slug).toISOString().split('T')[0] + "T" + dependingDate.value;
          let start_time = moment().tz(this.camp.timezone.slug).toISOString().split('T')[0] + "T" + dependantDate.value;
          if(moment(end_time).isBefore(moment(start_time))){
            let error = {
              name: "time",
              message: "less_than_start_time"
            }
            this.errors[dateKey] = error;
          }
          break;

        case "time":
          let isoDate = dependantDate.value.split('T')[0] + 'T' + dependingDate.value;
          if((moment().tz(this.camp.timezone.slug).format().valueOf() > moment(isoDate).format().valueOf()) && dependingDate.value){
            let error = {
              name: "time",
              message: "choose_valid_time"
            }
            this.errors[dateKey] = error;
          }
          break;
          
        case "===":
          if (new Date(dependingDate.value) !== new Date(dependantDate.value)) {
            const error = {
              name: "date",
              message: `dependingDate_must_equal_dependantDate`,
              translateDynamicallValues: { dependingDate: dependingDate.label, dependantDate: dependantDate.label }
            };
            this.errors[dateKey] = error;
          }
          break;

        case ">=":
          if (new Date(dependingDate.value) < new Date(dependantDate.value)) {
            const error = {
              name: "date",
              message: `dependingDate_must_greater_equal_dependantDate`,
              translateDynamicallValues: { dependingDate: dependingDate.label, dependantDate: dependantDate.label }
            };
            this.errors[dateKey] = error;
          }
          break;

        case ">":
          if (new Date(dependingDate.value) <= new Date(dependantDate.value)) {
            const error = {
              name: "date",
              message: `dependingDate_must_greater_dependantDate`,
              translateDynamicallValues: { dependingDate: dependingDate.label, dependantDate: dependantDate.label }
            };
            this.errors[dateKey] = error;
          }
          break;

        case "<=":
          if (new Date(dependingDate.value) > new Date(dependantDate.value)) {
            const error = {
              name: "date",
              message: `dependingDate_must_less_equal_dependantDate`,
              translateDynamicallValues: { dependingDate: dependingDate.label, dependantDate: dependantDate.label }
            };
            this.errors[dateKey] = error;
          }
          break;

        case "<":
          if (new Date(dependingDate.value) >= new Date(dependantDate.value)) {
            const error = {
              name: "date",
              message: `dependingDate_must_less_dependantDate`,
              translateDynamicallValues: { dependingDate: dependingDate.label, dependantDate: dependantDate.label }
            };
            this.errors[dateKey] = error;
          }
          break;
      }
    }
  }

  handleServerErrors(errors) {
    const formErrors = {};
    if (!Array.isArray(errors)) {
      formErrors['email'] = { name: 'email', message: errors };
      return formErrors;
    }
    for (let error of errors) {
      let payload = {
        name: error.property,
        message:  Object.values(error.constraints)[0]
      }
      formErrors[error.property] = payload;
    }
    return formErrors;
  }
}
