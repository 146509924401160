import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CampersService } from '@services/campers/campers.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'cah-group-hover-box',
  templateUrl: './group-hover-box.component.html',
  styleUrls: ['./group-hover-box.component.scss']
})
export class GroupHoverBoxComponent implements OnInit, OnDestroy {
  @Input() group: any = {};
  @Input() groupDetailsPosition: any = {
    vertical: 'bottom',
    horizontal: 'right'
  };
  selectedSession: any = JSON.parse(localStorage.getItem('selectedSession'));

  campers: any[] = [];

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;


  constructor(public languageService: LanguageService,
    private campersService: CampersService
  ) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });
  }
  ngOnInit(): void {
    this.campersService.groupCampers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(groupCampers => {
        if (groupCampers.hasOwnProperty(this.group.id)) {
          this.campers = groupCampers[this.group.id];
        } else if (this.selectedSession) {
          this.campersService.getCampers({ group_id: this.group.id, session_id: this.selectedSession.id, all: 1 })
            .subscribe(res => {
              this.campersService.setGroupCampers({ ...groupCampers, [this.group.id]: res.data });
            });
        }
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
