import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {KeyValue} from '@angular/common';
import {CampersService} from '@services/campers/campers.service';
import {takeUntil} from 'rxjs/operators';
import {SessionService} from '@services/session/session.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'cah-display-button-filter',
  templateUrl: './display-button-filter.component.html',
  styleUrls: ['./display-button-filter.component.scss']
})
export class DisplayButtonFilterComponent implements OnInit, OnDestroy {

  @Input() bindName: string = 'display';
  @Input() serviceName: string;
  @Output() onFilterAction: EventEmitter<any> = new EventEmitter();
  @Output() optionsTitle: EventEmitter<any> = new EventEmitter();
  @Output() checkedByDefaultOut: EventEmitter<any> = new EventEmitter();

  private unsubscribe$ = new Subject<void>();
  session: any;
  options: any = {};

  constructor(private campService: CampersService, private sessionService: SessionService) {
  }

  ngOnInit() {
    if (this.serviceName === 'enrollments_screen') {
      this.sessionService.selectedSession$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(selectedSession => {
          if (!selectedSession.isLoaded) {
            return;
          }
          this.session = selectedSession.session;
          this.getButtonData(this.session.id);
        });
    } else {
      this.getButtonData();
    }
  }

  getButtonData(sessionId?) {
    this.campService.displayButtonFilter({page: this.serviceName, session_id: sessionId}).subscribe((data: any) => {
      if (data) {
        const mainCol = data.columns;
        Object.keys(mainCol).forEach(key => this.options[key] =
          {
            ...mainCol[key],
            disabled: data.permanent.includes(key),
            display: data.visible_default.includes(key),
            sort: data.sort[key]._sort
          }
        );
        this.optionsTitle.emit(this.options);
        this.checkedByDefaultOut.emit(data.visible_default);
      }
    });
  }

  identify(index: number, item: any): string {
    return item.key;
  }

  valueAscOrder = (
    a: KeyValue<number, any>,
    b: KeyValue<number, any>
  ): any => {
    return a.value.sort - b.value.sort;
  };

  selectColumn(option) {
    if (option.value.disabled) {
      return;
    }
    option.value.display = !option.value.display;
    const payload: any = {
      title: option.key,
      name: 'display',
      status: option.value.display
    };

    this.onFilterAction.emit(payload);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }
}
