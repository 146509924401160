import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'cah-shared-dropdown',
  templateUrl: './shared-dropdown.component.html',
  styleUrls: ['./shared-dropdown.component.scss']
})
export class SharedDropdownComponent implements OnInit {
  @Input() bindImg: string = 'image';
  @Input() bindFullname: string = 'first_name,last_name';
  @Input() bindRole: string = 'role.name';  
  @Input() bindName: string;
  @Input() isModalGenerator: boolean = true;
  @Input() isMulti: boolean = false;
  @Input() type: string;
  @Input() label: string;
  @Input() options: any = [
    {
      name: "William"
    },
    {
      name: "Gurami"
    }
  ];
  @Input() value: any;
  @Input() multiValue: any[] = [];
  @Input() displayProperty: string = "name";
  @Input() returnProperty: string = "name";
  @Input() prefixIcon: string = "clickto-check";
  @Input() showBottomButton: boolean = false;
  @Input() showTopButton: boolean = true;
  @Input() showImage: boolean = false;
  @Input() showSearch: boolean = true;
  @Input() showRole: boolean = false;
  @Input() showDotIcon: boolean = false;
  @Input() showChip: boolean = false;
  @Input() showSelectedAvatar = false;
  @Input() showSelectedIcon = false;

  @Input() selectedIcon: string = "clickto-filter";

  @Input() placeHolder: string;
  @Input() helper: string;
  @Input() startPrefix: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() error: any;
  @Input() tabindex: string;
  @Input() isBillingForm: string;

  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();
  @Output() onTopButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onBottomButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSelectDropdownToggle: EventEmitter<any> = new EventEmitter();
  @Output() onRowAction: EventEmitter<any> = new EventEmitter();

  listState: boolean = true;
  selectedItem: any = this.options[0];
  selectedItems: any[] = [];

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options || changes.value || changes.multiValue) {
      this.checkDefaultValue();
    }
  }

  checkDefaultValue(){
    if(this.isMulti){
      let tmpValues = []
      this.multiValue.forEach(item => {
        let option = this.options.find( x => x[this.returnProperty] === item);
        if(option){
          tmpValues.push(option)
        }
      });
      this.selectedItems = this.selectedItems.length < 1 ? tmpValues : this.selectedItems;
    }else{
      let defaultValue = this.options.find( x => x[this.returnProperty] === this.value);
      
      if(!this.selectedItem && defaultValue){
        this.selectedItem = defaultValue;
      }else if(this.selectedItem && !defaultValue){
        this.selectedItem = null;
      }
    }
  }

  toggleList(state, event?){
    this.listState = state;

    const payload: IInputValue = {
      name: this.bindName,
      value: state
    }

    this.onSelectDropdownToggle.emit(payload);
  }

  selectItem(item){
    console.log(item);
    if(this.isMulti){
      if(this.multiValue.includes(item[this.returnProperty])){
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        this.multiValue.splice(this.multiValue.indexOf(item[this.returnProperty]), 1);
      }else{
        this.selectedItems.push(item);
        this.multiValue.push(item[this.returnProperty])
      }
  
      const payload: IInputValue = {
        name: this.bindName,
        value: this.multiValue
      }
      this.onChange.emit(payload);
    }else{
      if(this.value == item[this.returnProperty]){
        this.selectedItem = null;
        this.value = null;
      }else{
        this.selectedItem = item;
        this.value = this.selectedItem[this.returnProperty];
      }
      const payload: IInputValue = {
        name: this.bindName,
        value: this.value
      }

      this.onChange.emit(payload);
      this.toggleList(false);
    }
  }

  clearAll(){
    this.selectedItems = [];
    this.multiValue = [];

    const payload:IInputValue = {
      name: this.bindName,
      value: this.multiValue
    }

    this.onChange.emit(payload);
  }

  search(event){
    const payload = {
      name: this.bindName,
      value: event.value
    }
    this.onSearch.emit(payload);
  }

  buttonClick(type){
    if(type == 'top'){
      const payload = {
        name: this.bindName,
        value: 'top'
      }
      this.onTopButtonClick.emit(payload)
    }else{
      const payload = {
        name: this.bindName,
        value: 'bottom'
      }
      this.onBottomButtonClick.emit(payload)
    }
  }

  rowActionClick(event){

  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
    this.selectedItems = [];
    this.multiValue = [];
  }
}
