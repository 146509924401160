import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural',
  pure: true
})
export class PluralPipe implements PipeTransform {

  transform(value: string, quantity: number): any {
    return quantity > 1 ? value + 's' : value;
  }

}
