import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cah-form-radio',
  templateUrl: './form-radio.component.html',
  styleUrls: ['./form-radio.component.scss']
})
export class FormRadioComponent implements OnInit {

  @Input() bindName: string;
  @Input() checked: boolean = false;
  @Output() onCheck: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  check(){
    //this.checked = !this.checked;
    const payload = {
      bindName: this.bindName,
      value: this.checked
    }
    this.onCheck.emit(payload)
  }

}
