import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { IDisplayColumn } from 'app/interfaces';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-column-select',
  templateUrl: './column-select.component.html',
  styleUrls: ['./column-select.component.scss']
})
export class ColumnSelectComponent implements OnInit {
  @Input() label: string;
  @Input() columns: IDisplayColumn[];
  @Input() isActive: boolean = true;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  boxState: boolean = false;
  clickInside: boolean = false;
  isLastChecked: boolean = false;
    
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false

  constructor(public languageService: LanguageService,) {         
      languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });
  }

  ngOnInit() {
  }

  toggleBox() {
    if (this.isActive) {
      this.boxState = !this.boxState;
    }
  }

  onColumnSelect(event) {
    const selectedColumn = this.columns.find(x => x.column_id === event.name);
    selectedColumn.display = event.value;
    this.isLastChecked = this.columns.filter(x => x.display).length === 1;
    this.onSelect.emit(this.columns);
  }

  @HostListener('click')
  onComponentClick() {
    this.clickInside = true;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (!this.clickInside && this.boxState) {
      this.boxState = false;
    }
    this.clickInside = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
