import { Pipe, PipeTransform } from "@angular/core";
import { DateFormatterService } from "@util/date-formatter/date-formatter.service";

@Pipe({
  name: "dateFormat",
  pure: true
})
export class DateFormatPipe  implements PipeTransform {
    constructor(private dateFormatterService: DateFormatterService) {}

    transform(date: any, option: string, lang: string, timezone?: string, timeFormat?: number, format: any = 'long', dateFormat: any = 'dddd, MMMM DD, '): string {
        switch (option) {
            case 'date': 
                return this.dateFormatterService.formatDate(date, lang);
            
            case 'dateWithTime' : 
                return this.dateFormatterService.getFormattedDateWithTime(date, timezone, timeFormat, lang, dateFormat);

            case 'time' : 
                return this.dateFormatterService.getFormattedTime(date, timezone, timeFormat);

            case 'day' :
                return this.dateFormatterService.getWeeklyDay(date);

            case 'dateWeekView' :
                return this.dateFormatterService.formatDateWeekView(date, lang, format);
        }
    }
}