import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cah-circle-countdown',
  templateUrl: './circle-countdown.component.html',
  styleUrls: ['./circle-countdown.component.scss']
})
export class CircleCountdownComponent implements OnInit {

  @Input() time: number;
  @Input() color: string = '';
  @Input() size: number;
  @Input() animationSpeed: string = "10";

  @Input() hours: string;
  @Input() minutes: string;
  @Input() seconds: string;


  constructor() { }

  ngOnInit() {    
  }

}
