import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cah-timeslot-status',
  templateUrl: './timeslot-status.component.html',
  styleUrls: ['./timeslot-status.component.scss']
})
export class TimeslotStatusComponent implements OnInit {
  @Input() status: string;
  statuses: any = {
    completed: {
      color: '#5DD052',
      bgColor: '#F2FBF1',
      icon: 'circle-done'
    },
    live: {
      color: '#FF3E00',
      bgColor: '#FFF0EB',
      icon: 'play'
    },
    upcoming: {
      color: '#FF9200',
      bgColor: '#FFF6EB',
      icon: 'reset'
    }
  } 

  constructor() { }

  ngOnInit() {
  }

}
