import { Injectable } from '@angular/core';
import { CampEditModel } from '@models';

@Injectable({
  providedIn: 'root'
})
export class FormFillerService {

  constructor() { }

  editModel = {};

  fillRecursiveForm(fields, model){
    this.fillForm(fields,model);
    let payload = {
      fields: fields,
      model: this.editModel
    }
    return payload;
  }

  fillForm(fields, model){
    for(let field of fields){
      if(field.type == 'layout'){
        this.fillForm(field.items, model);
      }else{
        field.value = model[field.name] || model[field.name] === 0 ? model[field.name] : null;
        this.editModel[field.name] = model[field.name] ? model[field.name] : null;
      }
    }
  }
}
