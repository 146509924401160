import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { CampService } from "@services/camp/camp.service";
import { ConfigService } from "@services/config/config.service";
import { PaymentService } from "@services/payment/payment.service";
import { ScriptService } from "@services/script/script.service";
import {ThemeService} from '@services/theme/theme.service';

@Component({
  selector: "cah-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"],
})
export class MainLayoutComponent implements OnInit {
  camp: any = {};
  class: string = "with-padding";
  disabled: boolean = false;
  @ViewChild("mainScreen", { read: ElementRef, static: false })
  elementView: ElementRef;
  loaded: boolean = false;

  constructor(
    private configService: ConfigService,
    private campService: CampService
  ) {
    campService.getCampConfigs().then(() => this.loaded = true)
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getHeight();
  }

  onScroll(): void {
    this.configService._scrollWindow.next(true);
  }

  getHeight() {
    this.disabled = false;
    if (
      this.elementView.nativeElement.scrollHeight -
      this.elementView.nativeElement.clientHeight === 0
    ) {
      this.disabled = true;
    }

  }
}
