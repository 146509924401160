import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedPipesModule } from 'app/shared-pipes';
import { FormElementModule } from '@shared/form-elements/form-element.module';
import { TableEnrollmentsDetailsComponent } from './table-enrollments-details.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    TableEnrollmentsDetailsComponent
  ],
  imports: [
    CommonModule,
    SharedPipesModule,
    FormElementModule,
    NgbModule
  ],
  exports: [
    TableEnrollmentsDetailsComponent
  ],
})
export class TableEnrollmentsDetailsModule { }
