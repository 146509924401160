import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';
import { CurrencyEnum, ElementState } from 'app/enums';
import { HelperService } from '@services/helper/helper.service';
import { DecimalPipe } from '@angular/common';
import { CampService } from '@services/camp/camp.service';
import { LocalizationService } from '@services/localization/localization.service';

@Component({
  selector: 'cah-form-decimal',
  templateUrl: './form-decimal.component.html',
  styleUrls: ['./form-decimal.component.scss']
})
export class FormDecimalComponent implements OnInit {

  @Input() validators: string[] = [];
  @Input() showIcon: boolean = false;
  @Input() iconText: string;
  @Input() showClear: boolean = false;
  @Input() bindName: string;
  @Input() type: string;
  @Input() onlyNumber: string;
  @Input() label: string;
  @Input() onlyLabel: boolean = false;
  @Input() placeHolder: string = '';
  @Input() helper: string;
  @Input() startPrefix: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() state: string = ElementState.GENERATOR;
  @Input() value: any;
  @Input() error: any;
  @Input() tabindex: string;
  @Input() isBillingForm: string;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  currencyIcon: string = "$";

  constructor(private languageService: LanguageService, private decimalPipe: DecimalPipe, private localizationService: LocalizationService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });

    this.localizationService.getCurrency().pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((currency: any) => {
           if (!currency) { return; }     
            this.currencyIcon = currency.currencyFull;
           })
  }

  ngOnInit() {
    this.value = this.value ?  this.decimalPipe.transform(this.value, '1.2-2').replace(/,/g, '') : "0.00";
  }

  onInputChange(event){
    if (!this.value && this.value !== 0) {
      this.value = null;
    } else {
      if(this.value < 0) this.value *= -1;
      this.value = this.decimalPipe.transform(this.value, '1.2-2').replace(/,/g, '');
    }
    const payload: IInputValue = {
      name: this.bindName,
      value: this.value 
    }
    this.onChange.emit(payload);
  }
  
  clearInput(){
    this.value = '';
    const payload: IInputValue = {
      name: this.bindName,
      value: this.value 
    };
    this.onChange.emit(payload);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
