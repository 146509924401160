import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GroupsIndicatorComponent } from './groups-indicator.component';
import { SharedPipesModule } from '@pipes';
import { SharedDirectivesModule } from '@directives';
import { GroupDetailModule } from '@shared/group-detail/group-detail.module';

@NgModule({
  declarations: [
    GroupsIndicatorComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    SharedPipesModule,
    SharedDirectivesModule,
    GroupDetailModule
  ],
  exports: [
    GroupsIndicatorComponent
  ]
})
export class GroupsIndicatorModule { }