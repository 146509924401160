import { HostListener } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-board-modal',
  templateUrl: './board-modal.component.html',
  styleUrls: ['./board-modal.component.scss']
})
export class BoardModalComponent implements OnInit {

  @Input() label: string;
  @Input() staff: any[];
  @Input() selectedStaff: any[];
  @Input() showCloseButton: boolean = false;
  @Input() width: string = "100%";
  @Input() language: string = 'en';
  @Input() edit: boolean = false;
  
  @Input() nameErrorMsg: string = '';
  @Input() boardName: string = '';
  @Input('boardStatus') selectedStatus: string = 'public';

  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() outSearchStaff: EventEmitter<string> = new EventEmitter();
  private searchStaffSubject: Subject<string> = new Subject();
  statusDropdown: boolean = false;


  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == "he";
      });
  }

  ngOnInit() {    
    
    this.searchStaffSubject
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
      ).subscribe(staff => {
        this.outSearchStaff.emit(staff);
      });
  }

  onSearchStaff(e: any): void {
    this.searchStaffSubject.next(e.term);
  }

  onCloseModal() {
    this.onClose.emit(false);
  }

  selectStatus(status: string = ''){
    this.selectedStatus = status != '' ? status : this.selectedStatus;
    this.statusDropdown = !this.statusDropdown;
  }

  onAdd() {
    let payload = {
      selectedStaff: this.selectedStaff ? this.selectedStaff : [],
      boardName: this.boardName,
      selectedStatus: this.selectedStatus == 'public' ? true : false,
      edit: this.edit
    }
    
    this.onSubmit.emit(payload);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.getAttribute('class') &&
      !event.target.getAttribute('class').includes('status')) {
         this.statusDropdown = false;
      } 
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
