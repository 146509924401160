import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  @Input() isError: boolean = false;
  @Input() message: string = '';
  @Input() info: string = '';
  @Input() icon: string = '/assets/images/success-icon.svg';
  @Input() time: number = 3500;
  @Input() translateDynamicallValues: any = {};
  
  @Output() onCloseDialog = new EventEmitter<boolean>();
  
  closeModal: boolean = false;
  isHebrew: boolean = false;
  private unsubscribe$ = new Subject<any>();
  
  constructor(public languageService: LanguageService
    ) {
      languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == 'he';
    });
   }


  ngOnInit(): void {
    if (this.time) {
      setTimeout(() => {
        this.closeModal = true;
        setTimeout(() => {
          this.onCloseDialog.emit(false)
        }, 500)
      }, this.time);
    }
  }

  onClick(isOpen: boolean): void {
    this.onCloseDialog.emit(isOpen);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
