import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { TimeFormat } from '@enums';
import * as moment from 'moment';


@Component({
  selector: 'cah-day-box',
  templateUrl: './day-box.component.html',
  styleUrls: ['./day-box.component.scss']
})
export class DayBoxComponent implements OnInit {
  monthNames = ['january', 'february', 'march', 'april', 'may', 'june',
  'july', 'august', 'september', 'october', 'november', 'december'
  ];
  @Input() timeFormat: TimeFormat = TimeFormat.HOUR12;
  @Input() background: string;
  @Input() campId: number;
  @Input() activityDate: string;
  @Input() activityData: any;
  @Input() session_start_time: string;
  @Input() session_end_time: string;
  @Input() dayOfWeek: any;
  
  timeFormatEnum = TimeFormat;
  
  isHebrew: boolean = false;
  moment = moment;

  constructor(){
    this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;
   }

  ngOnInit() {
  }

}
