import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';
import { CurrencyEnum, ElementState } from 'app/enums';
import { HelperService } from '@services/helper/helper.service';
import { CampService } from '@services/camp/camp.service';

@Component({
  selector: 'cah-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss']
})
export class FormTextareaComponent implements OnInit {

  @Input() bindName: string;
  @Input() onlyNumber: string;
  @Input() label: string;
  @Input() placeHolder: string = '';
  @Input() helper: string;
  @Input() startPrefix: string;
  @Input() isRequired: boolean = false;
  @Input() onlyLabel: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() state: string = ElementState.GENERATOR;
  @Input() maxLength: number = 500;
  @Input() value: any;
  @Input() error: any;
  @Input() tabindex: string;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService, private campService: CampService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  ngOnInit() {
  }

  onInputChange(event){
    const payload: IInputValue = {
      name: this.bindName,
      value: this.value 
    }

    this.onChange.emit(payload);
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
