import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IInputValue } from "@interfaces";
import { LanguageService } from "@services/language/language.service";

@Component({
  selector: "cah-inline-image-upload",
  templateUrl: "./inline-image-upload.component.html",
  styleUrls: ["./inline-image-upload.component.scss"],
})
export class InlineImageUploadComponent implements OnInit {
  @Input() bindName: string = "";
  @Input() type: string = "";
  @Input() label: string = "";
  @Input() placeHolder: string = "";
  @Input() helper: string = "";
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isModal: boolean = false;
  @Input() value: string = "";
  @Input() imageProperties: any = {};
  @Input() error: any;
  @Input() tabindex: string = "";
  @Output() onOutPutValue: EventEmitter<IInputValue> = new EventEmitter();
  @Output() onModalClick: EventEmitter<any> = new EventEmitter();

  constructor(public languageService: LanguageService) {}

  ngOnInit() {}

  onSubmitValue(evt: any): void {
    const payload: IInputValue = {
      name: this.bindName,
      value: evt,
    };
    this.onOutPutValue.emit(payload);
    this.resetImage(evt);
  }

  click(evt: any): void {
    const payload: IInputValue = {
      name: this.bindName,
      value: evt,
    };
    if (!this.isDisabled) {
      this.onModalClick.emit(payload);
    }
  }
  resetImage(evt: any): void {
    evt.target.value = "";
  }
}
