import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, catchError, shareReplay, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { HelperService } from '@services/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  constructor(private http: HttpClient) { }

  getPrograms(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `programs?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getProgramsCategories(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `programs/program/categories?${queryString}`)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getProgram(programId: number, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `programs/program/${programId}?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  addProgram(data: any): Observable<any> {
    return this.http.post(environment.endpoint + `programs/program`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  updateProgram(programId: number, data: any): Observable<any> {
    return this.http.patch(environment.endpoint + `programs/program/${programId}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  archiveProgram(programId: number, data: any = {}): Observable<any> {
    return this.http.patch(environment.endpoint + `programs/program/archive/${programId}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  unarchiveProgram(programId: number, data: any = {}): Observable<any> {
    return this.http.patch(environment.endpoint + `programs/program/unarchive/${programId}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  deleteProgram(programId: number): Observable<any> {
    return this.http.delete(environment.endpoint + `programs/program/${programId}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  uploadProgramImage(data: any, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.post(environment.endpoint + `programs/image?${queryString}`, data)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  getProgramCategories(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `programs/program/categories${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getProgramPublishedCounter(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `programs/published${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getArchivedProgramsCount(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `programs/archived/stats${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getProgramSessionsCount(programId: number, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `programs/${programId}/sessions/count${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
}
