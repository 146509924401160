import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '@services/user/user.service';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-board-box',
  templateUrl: './board-box.component.html',
  styleUrls: ['./board-box.component.scss']
})
export class BoardBoxComponent implements OnInit {
  @Input() position: any = {
    vertical: 'bottom',
    horizontal: 'right'
  };

  @Input() activityData: any[] = [];
  @Input() activityValue: any;
  @Input() spaceData: any[] = [];
  @Input() spaceValue: any;
  @Input() selectedSpaceId: string;
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onActivityAction: EventEmitter<any> = new EventEmitter();
  @Output() onSpaceAction: EventEmitter<any> = new EventEmitter();
  @Output() onSpaceTabChange: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  suggestionState: string;
  spaceTabs: any = [{
    name: 'Sessions Rooms '
  },
  {
    name: 'Public Rooms '
  }
]

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;


  constructor(private userService: UserService,
    public languageService: LanguageService
    ) {
      languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == 'he';
    });
  }

  ngOnInit() {
    const div: any = document.getElementById('main-content');
    div.style.overflow = 'hidden';
  }

  onActionActivity(e: Event, activity: any, optionName: string): void {
    let payload = {
      name: optionName,
      value: activity
    }
    this.onActivityAction.emit(payload);
  }

  onSpaceActivity(e: Event, activity: any, optionName: string): void {
    console.log(e);
    let payload = {
      name: optionName,
      value: activity
    }
    this.onSpaceAction.emit(payload);
  }

  onTextChange(event, inputName){
    let payload = {
      name: inputName,
      value: event.value
    }
    this.onSearch.emit(payload)
  }

  onItemSelect(event, inputName){
    let payload = {
      name: inputName,
      value: event
    }
    payload.name == 'activity' ? this.activityValue = payload.value : this.spaceValue = payload.value;
    
    // if( this.activityValue && this.spaceValue && Object.values(this.activityValue).length && Object.values(this.spaceValue).length){
    //   this.onBoxClose();
    // }
    this.onSelect.emit(payload);
  }

  onBoxClose(){
    this.onClose.emit();
  }

  onFocus(input){
    this.suggestionState = input;
  }

    
  ngOnDestroy(): void {
    const div: any = document.getElementById('main-content');
    div.style.overflow = 'auto';
    this.unsubscribe$.complete();
  }

}
