import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { HelperService } from '@services/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulingService {
  selectedScheduleFilter: any = null;
  public selectedScheduleFilter$ = new BehaviorSubject<any>(this.selectedScheduleFilter);

  constructor(
    private http: HttpClient
  ) {
  }

  updateScheduleFilter(filter: any): void {
    this.selectedScheduleFilter = { ...this.selectedScheduleFilter, ...filter };
    this.selectedScheduleFilter$.next(this.selectedScheduleFilter);
  }

  getSchedules(params: any): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + 'schedules?' + queryString)
                    .pipe(
                      take(1),
                      shareReplay(1),
                      map( res => res ),
                      catchError( error => {
                        return throwError(error.error || "server error")
                      })
                    )
  }

  getScheduleRecordings(id: number, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `schedules/schedule/${id}/recordings?${queryString}`)
                    .pipe(
                      take(1),
                      shareReplay(1),
                      map( res => res ),
                      catchError( error => {
                        return throwError(error.error || "server error")
                      })
                    )
  }

  addSchedule(payload): Observable<any> {
    return this.http
      .post(environment.endpoint + 'schedules/schedule', payload)
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

  deleteNullSchedules(sessionId): Observable<any> {
    return this.http
      .post(environment.endpoint + `schedules/session/${sessionId}/schedules/check`, {})
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

  copyTimeslot(payload: any, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http
      .post(environment.endpoint + `schedules/timeslot/copy?${queryString}`, payload)
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

  pasteTimeslot(payload: any, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http
      .post(environment.endpoint + `schedules/timeslot/paste?${queryString}`, payload)
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

  onDeleteTimeslot(id: any): Observable<any> {
    return this.http
      .delete(environment.endpoint + 'schedules/timeslot/' + id)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  addAssignment(payload: any): Observable<any> {
    return this.http
      .post(environment.endpoint + 'timeslots/assignment', payload)
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

  deleteAssignment(id: any): Observable<any> {
    return this.http
      .delete(environment.endpoint + 'timeslots/assignment/' + id)
      .pipe(
        catchError(e => of(e.error)),
        switchMap(resp => {
          if (resp.error && resp.statusCode) {
            return of({status: false, ...resp});
          } else {
            return of({status: true, data: resp});
          }
        })
      );
  }

  deleteSchedule(id: any): Observable<any> {
    return this.http
      .delete(environment.endpoint + 'schedules/schedule/' + id)
      .pipe(
        catchError(e => of(e.error)),
        switchMap(resp => {
          if (resp.error && resp.statusCode) {
            return of({status: false, ...resp});
          } else {
            return of({status: true, data: resp});
          }
        })
      );
  }

  updateSchedule(id, payload): Observable<any>{
    return this.http.patch(environment.endpoint + "schedules/schedule/" + id, payload)
    .pipe(
      catchError( error => {
        return throwError(error.error || "server error")
      })
    );
  }

  updateAssignment(id, payload): Observable<any>{
    return this.http.patch(environment.endpoint + "timeslots/assignment/" + id, payload)
    .pipe(
      catchError( error => {
        return throwError(error.error || "server error")
      })
    );
  }

  getCamperRecordings(code: string, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `schedules/recordings/list/${code}?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
}
