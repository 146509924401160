import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';
import { ElementState } from 'app/enums';

@Component({
  selector: 'cah-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  @Input() bindName: string;
  @Input() type: string;
  @Input() onlyNumber: string;
  @Input() label: string;
  @Input() placeHolder: string;
  @Input() helper: string;
  @Input() startPrefix: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() state: string = ElementState.GENERATOR;
  @Input() value: any;
  @Input() error: any;
  @Input() tabindex: string;
  @Input() isBillingForm: string;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();
  
  

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  ngOnInit() {
  }

  onInputChange(event){
    if (this.onlyNumber) {
      this.value = this.value.replace(/\D/g, '');
      if (this.value.charAt(0) === '0') {
        this.value = this.value.slice(1);
      }
      event.target.value = this.value
    }
   
    const payload: IInputValue = {
      name: this.bindName,
      value: this.type == 'number' ? parseInt(this.value) : this.value
    }
    if(this.type == "date"){
      let date =  new Date(payload.value);
      payload.value = date.toISOString();
    }
    this.onChange.emit(payload);
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
  
}
