import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberToArray } from './number-to-array/number-to-array.pipe';
import { SafePipe } from './iframe-safe/iframe-safe.pipe';
import { ArraySortPipe } from './array-sort/array-sort.pipe';
import { TimeFormatter } from './time-formatter/time-formatter.pipe';
import { Translation } from './translation/translation.pipe';
import { NumberWithCommas } from './number-with-commas/number-with-commas.pipe';
import { SafeHTMLPipe } from './html-safe/html-safe.pipe';
import { IndicatorBoxPipe } from './indicator-box/indicator-box.pipe';
import { NestedValuePipe } from './nested-value/nested-value.pipe';
import { SafeStylePipe } from './safe-style/safe-style.pipe';
import { DateFormatPipe } from './date-formatter/date-formatter.pipe';
import { FilterPipe } from './array-filter/array-filter.pipe';
import { MinutesToHmsPipe } from './minutes-to-hms/minutes-to-hms.pipe';
import { CurrencyPipe } from './currency/currency.pipe';
import { TimeFormat } from './time-formatter/time-format.pipe';
import { DateRangePipe } from './date-range/date-range.pipe';
import { ReduceArrayPipe } from './reduce-array/reduce-array.pipe';
import { PluralPipe } from './plural/plural.pipe';
import { SecondToHmsPipe } from './second-to-hms/second-to-hms.pipe';
import { TextHighlightPipe } from './text-highlight/text-highlight.pipe';
import { UrlPrettyPipe } from './url-pretty/url-pretty.pipe';

const commonPipes: any[] = [NumberToArray, SafePipe, 
  ArraySortPipe, TimeFormatter, 
  Translation, NumberWithCommas, 
  SafeHTMLPipe, IndicatorBoxPipe, 
  NestedValuePipe, SafeStylePipe,
  DateFormatPipe, FilterPipe, 
  MinutesToHmsPipe, CurrencyPipe,
  TimeFormat, DateRangePipe,
  ReduceArrayPipe, SecondToHmsPipe,
  PluralPipe, UrlPrettyPipe,
  TextHighlightPipe]

@NgModule({
  declarations: [...commonPipes],
  exports: [...commonPipes],
  imports: [
    CommonModule
  ]
})
export class SharedPipesModule { }
