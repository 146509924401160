import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '@models';

@Component({
  selector: 'cah-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss']
})
export class NavigationItemComponent implements OnInit {
  @Input() menuItem: MenuItem;
  @Input() expandMenuItem: MenuItem;
  @Input() activeUrl: string = '';
  @Input() language: string = 'en';
  @Input() title: string;
  @Output() outClickMenuItem: EventEmitter<string> = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit(): void {
  }

  onClickItem(url: string): void {
    this.outClickMenuItem.emit(url);
  }

}
