import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cah-activity-box-splash',
  templateUrl: './activity-box-splash.component.html',
  styleUrls: ['./activity-box-splash.component.scss']
})
export class ActivityBoxSplashComponent implements OnInit {

  @Input() selected = true;
  @Input() optionList;
  @Input() selectedId : any ;
  @Output() selectAct = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onActSelect(id: number): void {
    this.selectAct.emit(id);
  }
}
