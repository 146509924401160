import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, map, take} from 'rxjs/operators';
import {Observable, throwError, Subject, BehaviorSubject} from 'rxjs';
import { HelperService } from '@services/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private _rate = new BehaviorSubject<number>(1.99);
  rate$ = this._rate.asObservable();

  private _paymentType = new BehaviorSubject<string>('payment_successful');
  paymentType$ = this._paymentType.asObservable();

  constructor(
    private http: HttpClient
  ) {
  }

  changePaymentType(type: string): void {
    this._paymentType.next(type);
  }

  changeRate(rate: number): void {
    this._rate.next(rate);
  }

  makeOrder(payload: any): Observable<any> {
    return this.http.post(environment.endpoint + 'orders/order', payload)
        .pipe(
            take(1),
            catchError((e) => throwError(e.error || 'server error')));
  }

  addBillingInformation(payload: any): Observable<any> {
    return this.http.post(environment.endpoint + 'orders/order/info', payload)
        .pipe(
            take(1),
            catchError((e) => throwError(e.error || 'server error')));
  }

  getRate(): any {
    return this.http.get(environment.endpoint + 'orders/rate')
        .pipe(take(1))
        .subscribe((res: any) => {
          this._rate.next(res.rate);
        }, err => {
          console.log(err);
        });
  }

  getCreditPrice(filter: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(filter);
    return this.http.get(environment.endpoint + 'orders/package-price?' + queryString)
        .pipe(
          take(1),
          map(res => res),
          catchError(err => throwError(err.error || 'server error'))
        )
  }

  getCoupon(code: string): Observable<any> {
    return this.http.get(environment.endpoint + 'coupons/coupon/' + code)
        .pipe(
          take(1),
          map(res => res),
          catchError(err => throwError(err.error || 'server error'))
        )
  }

  getPayments(filter: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(filter);
    return this.http.get(environment.endpoint + 'payments?' + queryString)
        .pipe(
          take(1),
          map(res => res),
          catchError(err => throwError(err.error || 'server error'))
        )
  }

  addPaymentStripe(payload: any): Observable<any> {
    return this.http.post(environment.endpoint + 'payments/stripe', payload)
        .pipe(
            take(1),
            catchError((e) => throwError(e.error || 'server error')));
  }

}
