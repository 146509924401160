import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormValidatorService } from '@util/form-validator/form-validator.service';
import { FormFillerService } from '@util/form-filler.service';
import { IFormField } from 'app/interfaces';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { TooltipPlacementEnum } from 'app/enums';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'cah-form-generator',
  templateUrl: './form-generator.component.html',
  styleUrls: ['./form-generator.component.scss']
})
export class FormGeneratorComponent implements OnInit, OnChanges {
  @Input() newFields: boolean = false;
  @Input() formFields:IFormField[] = [];
  @Input() formModel:any = {};
  @Input() formErrors:any;
  @Input() serverError:any;
  @Input() listServerError:any;
  @Input() dataForFields: any = {};
  @Input() edit: boolean = false;
  @Input() loading: boolean = false;
  @Input() onlySubmit: boolean = false;
  @Input() primaryButtonText: string = "add";
  @Input() language: string = 'en';
  @Input() buttonSize: string = 'lg';
  @Input() bottomLine: boolean = true;
  @Input() hideSteps: boolean = false;
  @Input() hiddenButtons: boolean = false;
  @Input() elementsState: string = 'generator';
  @Input() steps: number[];
  @Input() additionalButtons: any[] = [
    {
      text: "add_and_enroll",
      type: "primary",
      action: "addAndEnroll"
    }
  ];
  @Input() previousStepData: any[] = [];
  @Input() scrollToTopOnInit: boolean = true;
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onFormDiscard: EventEmitter<any> = new EventEmitter();
  @Output() onClickButton: EventEmitter<any> = new EventEmitter();
  @Output() onClickCheckbox: EventEmitter<any> = new EventEmitter();
  @Output() onSelectChange: EventEmitter<any> = new EventEmitter();
  @Output() onAddGroup: EventEmitter<any> = new EventEmitter();
  @Output() onSelectSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSelectButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onBtnClick: EventEmitter<any> = new EventEmitter();
  @Output() onSelectDropdownToggle: EventEmitter<any> = new EventEmitter();
  @Output() onTabChange: EventEmitter<any> = new EventEmitter();
  @Input() disableButtons : boolean = false;
  collapsedDefault = {
    isCollapsed: false,
    collapsedText: "Collapsed Text",
    collapsedButtonText: "Open"
  };
  tooltipPlacementEnum = TooltipPlacementEnum;
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private formValidatorService: FormValidatorService,
               private formFillerService: FormFillerService,
               private languageService: LanguageService,
               ) {
    
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == 'he';
    });
  }

  ngOnInit() {    
    // if(this.scrollToTopOnInit){
    //   this.scrollToTop();
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.edit && changes.formModel) {
      const filledForm = this.formFillerService.fillRecursiveForm(this.formFields, this.formModel);
      this.formFields = filledForm.fields;
      console.log('temo ',this.formFields);
      
    }
  }

  onClearRef(item) {
    item = item && Array.isArray(item) ? item : [];
    return item = [...item];
  }

  onGroupAdd(event){
    this.onAddGroup.emit(this.formModel);
  }

  onInputChange(event, field){
    this.disableButtons = false;
    if (field.type === 'dropdown-input' ) {
      field.value = event.value;
      return this.formModel[event.name] = event.value;
    }

    if(field.type == 'text'){
      if(event.value.trim().length < 1){
        event.value = null
      }
    }

    if(field.name == 'date' || field.name == 'start_date'){
      if(this.formErrors.start_time){
        delete this.formErrors.start_time;
      }
    }
    
    if(field.type == 'range' || field.type == 'ageLimit'){
      this.formModel[event.name] = event.value;
      field.value = { ...field.value, [event.name] : event.value };
    }else{
      this.formModel[field.name] = event.value;
      field.value = event.value;
    }
    
    
    if(field.type == "checkbox") {
      this.onClickCheckbox.emit(field);
    }
    if(field.type == "ngselect" || field.type == "multiselect" || field.type === 'toggle') {
      this.onSelectChange.emit({field, value: event.value});
    }
  }

  onSubmit(action, element?: HTMLElement){
    this.formErrors = {};
    let errors: any = this.formValidatorService.validateRecursiveLayout(this.formFields);
    if(Object.keys(errors).length > 0) {
      this.formErrors = errors;
      this.disableButtons = false;
      setTimeout(() => {
        HelperService.scrollOnElement('cah__error');
      }, 100);
    }else{
      let payload = {
        action: action,
        data: this.formModel
      }
      this.onFormSubmit.emit(payload);
    }
  }

  scrollToTop() {
    (function smoothscroll() {
        var currentScroll = document.getElementById('main-content').scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            document.getElementById('main-content').scrollTo(0, currentScroll - (currentScroll / 8));
        }
    })();
  }

  onSelectToggle(evt: any) {
    this.onSelectDropdownToggle.emit(evt);
  }

  onDiscard(){
    this.onFormDiscard.emit('discard');
  }

  onClick(event){
    this.onClickButton.emit(event)
  }

  selectSearch(event){
    this.onSelectSearch.emit(event);
  }

  buttonClick(type){
    this.onSelectButtonClick.emit(type);
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
