import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'minutesToHms', pure: true })
export class MinutesToHmsPipe implements PipeTransform {

  transform(d: number): string {
    if (!d) return '';
    d = Number(d);
    const h: string | number = Math.floor(d / 60);
    let m: string | number = Math.floor(d % 60);
    
    const hDisplay = h > 0 ? h + (m ? ':' + m : '') + ' Hour' : m + ' Min';
    return hDisplay; 
}

}
