import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '@services/user/user.service';
import { RolesService } from '@services/roles/roles.service';
import { UserRole } from '@enums';

@Component({
  selector: 'cah-staff-assignment-hover-box',
  templateUrl: './staff-assignment-hover-box.component.html',
  styleUrls: ['./staff-assignment-hover-box.component.scss']
})
export class StaffAssignmentHoverBoxComponent implements OnInit {

  @Input() staffAssignment: any[] = [];
  @Input() isHebrew: boolean = false;
  @Input() staffAssgnmentHoverPosition: any = {
    vertical: 'bottom',
    horizontal: 'right'
  };

  roleList: any = [
    { id: UserRole.SUPERADMIN, name: "superadmin_role" },
    { id: UserRole.ADMIN, name: "admin_role" },
    { id: UserRole.SUPERVISOR, name: "supervisor_role" },
    { id: UserRole.STAFF, name: "staff_role" },
  ];
  constructor(private roleService: RolesService) { }

  ngOnInit() {
  }

  getRoleById(id) {
    return this.roleList.find(x => x.id === id).name;
  }

}
