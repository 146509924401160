import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormElementModule } from '@shared/form-elements';
import { FormsModule } from '@angular/forms';
import { SharedPipesModule } from 'app/shared-pipes';
import { FormDatepickerComponent } from './form-datepicker.component';
import { CalendarModule } from 'primeng/calendar';


@NgModule({
  declarations: [FormDatepickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedPipesModule ,
    FormElementModule,
    CalendarModule
  ],
  exports: [
    FormDatepickerComponent
  ],
})
export class FormDatePickerModule { }
