import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IInputValue, ITablePopupOptions } from 'app/interfaces';

@Component({
  selector: 'cah-table-popup',
  templateUrl: './table-popup.component.html',
  styleUrls: ['./table-popup.component.scss']
})
export class TablePopupComponent implements OnInit {

  @Input() count: number;
  @Input() title: string;
  @Output() onSelectOption: EventEmitter<IInputValue> = new EventEmitter();
  @Output() onTablePopupClose: EventEmitter<boolean> = new EventEmitter();


  @Input() options: ITablePopupOptions[] = [];

  constructor() { }

  ngOnInit() {
  }

  outsideClick() {
    this.options.map(el => el.state = false);    
  }
  onSelect(opt) {
    if (opt.disable) return;
    if (opt.returnProp){
      const payload: IInputValue = {
          name: opt.returnProp,
          value: opt.title
      };
      this.outsideClick();
      this.onSelectOption.emit(payload)
    } else {
      opt.state = !opt.state;
    }
 
  }

  onClose(){
    this.onTablePopupClose.emit(true);
  }
}

