import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IInputValue, ResponseList } from '@interfaces';
import * as moment from 'moment';

@Component({
  selector: 'cah-upcoming-meetings-section',
  templateUrl: './upcoming-meetings-section.component.html',
  styleUrls: ['./upcoming-meetings-section.component.scss']
})
export class UpcomingMeetingsSectionComponent implements OnInit {
  @Input() items: ResponseList;
  @Input() loading: boolean = false;
  @Input() camp: any = {};
  @Output() outAction: EventEmitter<IInputValue> = new EventEmitter<IInputValue>();
  isHebrew: boolean = false;

  moment = moment;

  constructor() {
    this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;
  }

  ngOnInit() {
  }

  onAction(name: string, value: any): void {
    const payload: IInputValue = { name, value };
    this.outAction.emit(payload);
  }

}
