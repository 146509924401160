import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { AssignmentTypeEnum, QuickAssignmentType } from '@enums';

@Component({
  selector: 'cah-quick-staff-assignments-modal',
  templateUrl: './quick-staff-assignments-modal.component.html',
  styleUrls: ['./quick-staff-assignments-modal.component.scss']
})
export class QuickStaffAssignmentsModalComponent implements OnInit {
  @Input() label: string;
  @Input() type: AssignmentTypeEnum = AssignmentTypeEnum.GROUP;
  @Input() space: any;
  @Input() group: any;
  @Input() throughout: string = QuickAssignmentType.SESSION;
  @Input() staff: any[] = [];
  @Input() timeslotLabels: any[] = [];
  @Input() selectedLabels: any[] = [];
  @Input() selectedStaff: any[] = [];
  @Input() showCloseButton: boolean = false;
  @Input() width: string = "100%";
  @Input() language: string = 'en';
  @Input() errors: any = {};
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() outSearch: EventEmitter<string> = new EventEmitter();
  assignmentTypeEnum = AssignmentTypeEnum;
  quickAssignmentType = QuickAssignmentType;

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  private searchSubject: Subject<string> = new Subject();

  constructor(private languageService: LanguageService) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == "he";
      });
  }

  ngOnInit() {
    this.searchSubject
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
      ).subscribe(value => {
        this.outSearch.emit(value);
      });
  }

  onSearch(e: any): void {
    console.log(this.selectedLabels, this.timeslotLabels);
    this.searchSubject.next(e.value);
  }

  onRadioChange(e): void {
    this.throughout = e.target.value
  }

  onCloseModal() {
    this.onClose.emit(false);
  }

  onAdd() {
    this.errors = {};
    if (!this.selectedStaff || !this.selectedStaff.length) {
      return this.errors.staff = true;
    } else if (!this.selectedLabels || !this.selectedLabels.length) {
      return this.errors.label = true;
    }
    const payload: any = {
      staffs: this.selectedStaff,
      labels: this.selectedLabels,
      type: this.throughout
    }
    this.onSubmit.emit(payload);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
