import { Component, OnInit, Input, HostListener, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { CampersService } from '@services/campers/campers.service';
import { NgSelectComponent } from '@shared/form-group/ngselect/ngselect.component';
import { async } from '@angular/core/testing';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'cah-elective-option-box',
  templateUrl: './elective-option-box.component.html',
  styleUrls: ['./elective-option-box.component.scss']
})
export class ElectiveOptionBoxComponent implements OnInit {

  // @Input() selectedAssignedCampersList: any[] = []
  @Input() activityData: any[] = [];
  @Input() activityValue: string;
  @Input() spaceData: any[] = [];
  @Input() sessionID: number = 0;
  @Input() spaceValue: string;
  @Input('selectedAssignedCampersListIds') usedCampersListIds: any[] = [];
  //@Input() tempAssignedCampersList: any[] = [];
  @Input() set tempAssignedCampersList ( obj ){

    //this.campersToDraw = [];


    obj.forEach(campProps => { if(campProps && campProps.camper){ this.campersToDraw = [...this.campersToDraw, { id: campProps.camper.id, name: `${campProps.camper.first_name} ${campProps.camper.last_name}`, group: campProps.camper.group_name, email: campProps.camper.email }] } })
      if( obj && typeof obj[0] == 'number'){

         this.selectedAssignedCamperIDs = obj;  
         this.selectedAssignedCamperIDs.filter(el => !this.usedCampersListIds.includes(el))
                      .map(campId => this.usedCampersListIds.push(campId) )

                
          this.campersService.getCampers(this.filter).subscribe(res => {
            this.campersToDraw = res.data.filter(camper => camper.status == "active"  && this.selectedAssignedCamperIDs.includes(camper.id))
              .map(camper => ({ id: camper.id, name: `${camper.first_name} ${camper.last_name}`, group: camper.group && camper.group.title ? camper.group.title : null , email: camper.email }));
          
            });

            if(this.campersToDraw.length == 0 && this.selectedAssignedCamperIDs.length > 0){
              if(this.campersList && this.campersList.length > 0 ){
                this.selectedAssignedCamperIDs.map(op => {
                      this.campersList.map(camper =>{
                        if(camper.id == op){
                          this.campersToDraw = [...this.campersToDraw, ({ id: camper.id, name: `${camper.first_name} ${camper.last_name}`, group: camper.group && camper.group.title ? camper.group.title : null, email: camper.email })] 
                        }
                     })
                  });                  
              }else{

                //after adding room or activity was loosing campersTodraw
                this.campersService.getCampers({'session_id':this.sessionID,'all': 1}).subscribe(res => {
                     this.campersList = res.data;
                     this.selectedAssignedCamperIDs =  this.selectedAssignedCamperIDs.filter(id => this.campersList.find(camp => camp.id == id))

                     this.selectedAssignedCamperIDs.map(op => {                       
                      this.campersList.map(camper =>{
                        if(camper.id == op){
                          this.campersToDraw = [...this.campersToDraw, ({ id: camper.id, name: `${camper.first_name} ${camper.last_name}`, group: camper.group && camper.group.title ? camper.group.title : null, email: camper.email })]                           
                        }
                     })
                  });
                })               
              }
             
            } 


      }else{
        //this.selectedAssignedCamperIDs = this.campersToDraw.filter(x => this.usedCampersListIds.includes(x.id)).map(x => x.id);
        //console.log( "else ",this.selectedAssignedCamperIDs);

      }      
  }
  @Input() showAssignedCampers: boolean = false;
  @Input() title: string = '';
  @Input() index: number;
  @Input() option_id: number;
  @Input() isEmptyTitle: boolean = false;
  @Input() isHebrew: boolean = false;
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSearchCamper: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onNameChange: EventEmitter<string> = new EventEmitter();
  @Output() onDelete: EventEmitter<boolean> = new EventEmitter();
  @Output() onAssignedCamperChange: EventEmitter<any> = new EventEmitter();
  @Output() onActivityAction: EventEmitter<any> = new EventEmitter();
  @Output() onAlertError: EventEmitter<any> = new EventEmitter();
  @Output() onSpaceTabChange: EventEmitter<any> = new EventEmitter();

  isOpen: boolean = false;

  @ViewChild('select',  {read: ElementRef,static: false}) ngselect: ElementRef;
  //@ViewChild('select',  {static: false}) ngselect: NgSelectComponent; 
  alertOption: any = { isError: false, isOpen: false, message: '' };

  existsStrMsg = '';

  titleEditMode: boolean = false;
  suggestionState: string;

  selectedAssignedCamperIDs: any[] = [];
  campersToDraw: any[] = [];
  @Input() campersList: any;


  filter: any = {
    search: "",
    session_id: null,
    all: 1
  }
  spaceTabs: any = [{
    name: 'Sessions Rooms '
  },
  {
    name: 'Public Rooms '
  }
]
  campersLoading: boolean = false;
  private searchSubject: Subject<string> = new Subject();

  constructor(
    private campersService: CampersService,
  ) { 
   }

  ngOnInit() {

    console.log(this.activityValue);
    


    this.searchSubject
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
      ).subscribe(resp => {

        this.getCampers(this.filter);
      });

    this.filter.session_id = this.sessionID;
    //this.tempAssignedCampersList.forEach(campProps => { if(campProps.camper){ this.campersToDraw = [...this.campersToDraw, { id: campProps.camper.id, name: `${campProps.camper.first_name} ${campProps.camper.last_name}`, group: campProps.camper.group_name, email: campProps.camper.email }] } })

    //this.campersToDraw = this.campersToDraw.map(camper => ({ id: camper.id, name: `${camper.first_name} ${camper.last_name}`, group: camper.group_name, email: camper.email }));
    // this.selectedAssignedCamperIDs = this.campersToDraw.map(camper => camper.id)
    let campersToDrawIds = this.campersToDraw.filter(x => this.usedCampersListIds.includes(x.id)).map(x => x.id)


    this.campersService.getCampers(this.filter).subscribe(res => {
      this.campersToDraw = res.data.filter(camper => camper.status == "active" && !this.usedCampersListIds.includes(camper.id))
        .map(camper => ({ id: camper.id, name: `${camper.first_name} ${camper.last_name}`, email: camper.email }));
    });

    if (campersToDrawIds.length > 0) {
      this.selectedAssignedCamperIDs = campersToDrawIds;
    }

  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Enter') {
      this.titleEditMode = false;
      this.onNameChange.emit(this.title);
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.getAttribute('class') &&
      !event.target.getAttribute('class').includes('elective-option-box__header__title--input') && 
      !event.target.getAttribute('class').includes('elective-option-box__header__title--edit')) {
      this.titleEditMode = false;
      this.onNameChange.emit(this.title);
    }
  }

  onTextChange(event, inputName) {
    let payload = {
      name: inputName,
      value: event.value
    }    
    this.onSearch.emit(payload)
  }

  onItemSelect(event, inputName) {
    let payload = {
      name: inputName,
      value: event
    }
    this.onSelect.emit(payload);
  }

  onFocus(input) {
    this.suggestionState = input;
  }

  onActionActivity(e: Event, activity: any, optionAction: string, optionName: string): void {
    let payload = {
      name: optionAction,
      value: activity,
      optionName: optionName
    }    
    this.onActivityAction.emit(payload);
  }

  onAssignedCampersClear(){
    this.onAssignedCampersRemove(null);
  }
  onAssignedCampersRemove(camper: any): void {
    let payload = {
      selectedAssignedCampers: this.selectedAssignedCamperIDs,
      action: 'delete',
    }
    this.onAssignedCamperChange.emit(payload);
  }

  onAssignedCampersAdd(camper = {}): void {
    let payload = {
      selectedAssignedCampers: this.selectedAssignedCamperIDs,
      action: 'add',
    }    
    this.onAssignedCamperChange.emit(payload);
  }


  onSearchAssignedCampers(e: any): void {
    this.campersLoading = true;
    this.campersToDraw = [];
    delete this.filter.page;

    if (!e) {
      delete this.filter.search;
    } else {
      this.filter.search = e.term;
    }

    this.searchSubject.next(new Date().getTime().toString());
  }


  getCampers(filter) {
    this.campersService.getCampers(filter).subscribe(res => {
      this.campersToDraw = res.data.filter(camper => camper.status == "active" && !this.usedCampersListIds.includes(camper.id))
        .map(camper => ({ id: camper.id, name: `${camper.first_name} ${camper.last_name}`, email: camper.email }));
      this.campersLoading = false;
    });
  }


  changeCampersColor(pastedData){


    setTimeout(() => {
      const dataArray = pastedData.map(Function.prototype.call, String.prototype.trim);
      const dom: HTMLElement = this.ngselect.nativeElement;
      const data = dom.querySelectorAll('div.ng-value');
      for (let i = 0; i < data.length; i++) {
        let el = data[i].querySelector('.ng-value-label');
        // @ts-ignore
        if (dataArray.includes(el.innerText.toString())) {
          // @ts-ignore
          data[i].classList.add('ng-select-err');
        }
        // @ts-ignore

      }
    }, 1000)

  }

  onOptionAdd(e: Event, option ,optionName: string, index: number): void {

    let payload = {
      name: optionName,
      value: option,
      index: index
    }

    this.onActivityAction.emit(payload);
  }



  onPaste(e){
    e.stopPropagation();
    e.preventDefault();

    this.existsStrMsg = '';

    // let pastedData = e.clipboardData.getData('Text').split('\n')
    //   .map(el =>el.replace(/\s\s+/g, ' ').trim())
    //   .filter((elem, index, self) => index === self.indexOf(elem) && elem != '' && elem.includes(' '))
    //   .join(',')

      let pastedData = e.clipboardData.getData('Text').split('\n')
      .filter((elem, index, self) => index === self.indexOf(elem) && elem != '')
      .map(el =>el.replace(/\s/g, ' ')
        .trim())
      .join(',')

    this.filter.search = pastedData+","; //for backend if camper is 1

    pastedData = pastedData.split(',').filter((elem, index, self) => index === self.indexOf(elem) && elem != '')
      .map(Function.prototype.call, String.prototype.trim);



    this.campersService.getCampers(this.filter).subscribe(res => {

      this.campersToDraw = [...this.campersToDraw];

      // if( this.campersToDraw.includes )

      this.selectedAssignedCamperIDs.map(oldCamper => {
        if(!this.campersToDraw.find(camper => camper.id == oldCamper)){
          let oldCamperToInsert = this.campersList.find(camper => camper.id == oldCamper);
          this.campersToDraw = oldCamperToInsert ?
            [...this.campersToDraw, {
              id: oldCamperToInsert.id,
              name: `${oldCamperToInsert.first_name} ${oldCamperToInsert.last_name}`,
              group: oldCamperToInsert.group.title,
              email: oldCamperToInsert.email } ]
            : this.campersToDraw;
        }
      })


      res.data.filter(camper => camper.status == "active")
        .map(camper => {
          if(camper && !this.selectedAssignedCamperIDs.includes(camper.id) && !this.usedCampersListIds.includes(camper.id)){
            this.selectedAssignedCamperIDs = [...this.selectedAssignedCamperIDs, camper.id]
            this.usedCampersListIds = [...this.usedCampersListIds, camper.id];
            this.campersToDraw =  [...this.campersToDraw, { id: camper.id, name: `${camper.first_name} ${camper.last_name}`, group: camper.group && camper.group.title ? camper.group.title : null, email: camper.email }]
            


          }else{
            //  this.alertOption = [...this.alertOption, {isOpen: true, message: }]
            this.existsStrMsg  += `${camper.first_name} ${camper.last_name}, `;
            console.log("used  ------ ",this.existsStrMsg)
          }


          //* to check 1 or 2 columns was copied *//
          pastedData = pastedData.filter(n => n && ( n.includes(' ') && n != camper.first_name +' '+ camper.last_name)
                                                || ( !n.includes(' ') && n != camper.first_name )  );

        })

      if(this.existsStrMsg) {
        this.alertOption = { isError: true, isOpen: true, message: 'the_following_campers_assigned_another', translateDynamicallValues: {campers: this.existsStrMsg.slice(0, -2)}};
        this.onAlertError.emit(this.alertOption)
      }

      let  i = 9999999999; // some fake id for nonExist camper
      pastedData.map(nonExistCamper =>{

        if(!this.usedCampersListIds.includes(nonExistCamper)){

          this.campersToDraw = [...this.campersToDraw,{id: i, name: nonExistCamper, group: 'empty', email: 'empty', fake: true}];
          this.selectedAssignedCamperIDs = [...this.selectedAssignedCamperIDs, i]
          this.usedCampersListIds = [...this.usedCampersListIds, i];
          ++i;
        }
      })

      //for unique only, just cleaning
      // this.campersToDraw = this.campersToDraw.filter((elem, index, self) => index === self.indexOf(elem) && elem != '')
      this.selectedAssignedCamperIDs = this.selectedAssignedCamperIDs.filter((elem, index, self) => index === self.indexOf(elem) && elem != '')
      this.usedCampersListIds = this.usedCampersListIds.filter((elem, index, self) => index === self.indexOf(elem) && elem != '')

      //  
      this.getCampers(this.filter);
      this.changeCampersColor(pastedData);

      this.onAssignedCampersAdd();
    });
  }


}
