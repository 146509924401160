import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { IInputValue } from '@interfaces';
import { NestedValuePipe } from 'app/shared-pipes/nested-value/nested-value.pipe';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'cah-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent implements OnInit {
  @Input() helper: string = 'helper';
  @Input() placeHolder: string = 'select';
  @Input() bindImg: string = 'image';
  @Input() bindFullname: string = 'first_name,last_name';
  @Input() bindRole: string = 'role.name';
  @Input() bindName: string;
  @Input() isMulti: boolean = true;
  @Input() translated: boolean = true;
  @Input() label: string = "label";
  @Input() options: any = [];
  @Input() value: any;
  @Input() multiValue: any[] = [];
  @Input() displayProperty: string = "label";
  @Input() returnProperty: string = "id";
  @Input() showTopButton: boolean = true;
  @Input() showSearch: boolean = true;
  @Input() showRole: boolean = false;
  @Input() showImage: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() dataType: string = 'text';
  @Input() selectedIcon: string;
  @Input() showSelectedIcon = false;
  @Input() error: any;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();
  @Output() onTopButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSelectDropdownToggle: EventEmitter<any> = new EventEmitter();
  @Output() onRowAction: EventEmitter<any> = new EventEmitter();
  listState: boolean = false;
  selectedItem: any;
  selectedItems: any[] = [];
  position: any = {
    vertical: 'bottom'
  };
  searchValue: string = '';
  isHebrew: boolean = false;

  constructor(private nestedValuePipe: NestedValuePipe) {
    this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options || changes.value || changes.multiValue) {
      if(this.options.length || this.options.length && (this.multiValue.length || this.value)){
        this.checkDefaultValue();
      }
    }
  }

  checkDefaultValue(){
    if(this.isMulti){
      let tmpValues = []
      this.multiValue.forEach(item => {
        let option = this.options.find( x => {
          return this.nestedValuePipe.transform(x, this.returnProperty) === item
        });

        if(option){
          tmpValues.push(option)
        }
      });
      this.selectedItems = tmpValues;
    }else{
      let defaultValue = this.options.find( x => this.nestedValuePipe.transform(x, this.returnProperty) === this.value);
      this.selectedItem = defaultValue || null;
    }

  }

  toggleList(state, event?){
    if(this.isDisabled){
      return;
    }
    if(event){
      this.position = HelperService.calculatePopupPosition(event.pageX, event.pageY, 360, 442, window.innerHeight, window.innerWidth, window.scrollY, window.scrollX);
    }
    this.listState = state;
    const payload: IInputValue = {
      name: this.bindName,
      value: state
    }
    this.onSelectDropdownToggle.emit(payload);
    if(!state && this.searchValue){
      this.search({value:''});
    }
  }

  search(event){
    this.searchValue = event.value;
    const payload = {
      name: this.bindName,
      value: event.value
    }

    this.onSearch.emit(payload);
  }

  buttonClick(){
      const payload = {
        name: this.bindName,
        value: 'top'
      }

      this.onTopButtonClick.emit(payload);
  }

  selectItem(item){
    if(!item.disabled){
      if(this.isMulti){
        if(this.multiValue.includes(item[this.returnProperty])){
          this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
          this.multiValue.splice(this.multiValue.indexOf(item[this.returnProperty]), 1);
        }else{
          this.selectedItems.push(item);
          this.multiValue.push(item[this.returnProperty])
        }

        const payload: IInputValue = {
          name: this.bindName,
          value: this.multiValue
        }
        this.onChange.emit(payload);
      }else{
        this.selectedItem = this.nestedValuePipe.transform(this.selectedItem, this.returnProperty) === this.nestedValuePipe.transform(item, this.returnProperty) ? null : item;
        this.value = this.nestedValuePipe.transform(this.selectedItem, this.returnProperty) || null;
        const payload: IInputValue = {
          name: this.bindName,
          value: this.value,
          item: this.selectedItem
        };
        this.onChange.emit(payload);
        this.toggleList(false);
      }
    }
  }

}
