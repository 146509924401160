import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "nested",
  pure: true
})
export class NestedValuePipe  implements PipeTransform {
  transform(item: any, val: any): any {
    if (!item || !val) return;
    
    if (val.includes(',') || val.includes('/')) {
      const sepValues: string[] = val.split(/[\,\/]/)  
      let newValue: any = '';
      for (const i in sepValues) {
        const sepValue: string = sepValues[i];
        const nestedObj: string[] = sepValue.split('.');
        let nestedVal: string = {...item};
        for (const objProrperty of nestedObj) {
          if (!nestedVal || !nestedVal[objProrperty]) return;
          nestedVal = nestedVal[objProrperty];
        }
        newValue += nestedVal + (val.includes('/') && (+i !== sepValues.length - 1) ? ' - ' : ' ');
      }
      
      return newValue;
    }

    const nestedObj: string[] = val.split('.');
    for (const objProrperty of nestedObj) {
      if (!item) return;
      item = item[objProrperty];
    }
    return item;
  }
}