import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private http: HttpClient) { }

  public static changeColorTheme(color: string){
    color = color ? color : '#5C4BFF';
    document.documentElement.style.setProperty('--primary-dynamic', this.hexToRgb(color));
  }
  public static changeFontFamily(font: string): void {
    font = font === 'he' ? 'sans-serif' : 'Roboto';
    document.documentElement.style.setProperty('--font', font);
  }

  public static hexToRgb(hex: string){
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return r + ", " + g + ", " + b;
  }

}
