import { ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { LanguageService } from '@services/language/language.service';
import { ModelTypeEnum } from 'app/enums';
import { IButton } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-import-file-dnd-modal',
  templateUrl: './import-file-dnd-modal.component.html',
  styleUrls: ['./import-file-dnd-modal.component.scss']
})
export class ImportFileDndModalComponent implements OnInit, OnChanges {

  @Input() errors;
  @Input() cancelText: string = "discard";
  @Input() confirmText: string = "add";
 
  @Input() modalTitle: string;
  @Input() contentTitle: string = "instructions";
  @Input() toogleTitle: string = '';
  @Input() exampleFile: any;
  @Input() contentBody: [] = [];
  @Input() toogleValue: boolean = false;
  @Input() afterCheck: boolean = false;
  @Input() loading: boolean = false;
  @Input() fileOver: boolean = false;
  @Input() showButtons: boolean = true;
  @Input() showAdd: boolean = true;
  @Input() onSubmitDisab: boolean = true;
  @Input() hasSteps: boolean = false;
  @Input() stepData: any;
  @Input() checkedUsers;
  @Input() excelCampers: any;
  @Input() uploadedMembers: string = "staff_members_are_uploaded";
  @Input() foundSomeErrors: string = "we_found_some_errors";
  @Input() additionalButtons: any[] = [
    {
      text: "add_and_enroll",
      type: "primary",
      action: "addAndEnroll",
      disabled: false
    }
  ];

  primarySettings: IButton = {
    model: ModelTypeEnum.BtnPRIMARY,
    text: "primary_b",
    icon: "",
    size: "",
    type: "",
    disabled: false,
  };

  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onUpload: EventEmitter<any> = new EventEmitter();
  @Output() onSubmitBtn: EventEmitter<any> = new EventEmitter();

  @ViewChild('dashedBox' , {static: false})  dashedBox: ElementRef;
  height: string;
  value: any;
  edit: boolean = false;

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  formData: FormData = new FormData();

  constructor(public languageService: LanguageService
  ) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
   
    if(this.afterCheck && !this.fileOver){
      setTimeout(()=>{
        this.height =  this.dashedBox.nativeElement.offsetHeight;              
      },500);
    }

  }
  ngOnInit() {
  }

  closeModal() {
    this.onClose.emit(false);
  }

  onFileDropped(event) {
    this.formData = new FormData();
    if (event[0]) {
      this.formData.append('file', event[0]);
      this.formData.append('send_invitation', this.toogleValue ? '1' : '');
      HelperService.createFormData(event)
      this.onUpload.emit(this.formData);
     // event.target.value = ''
    }
    this.fileOver = false;
  }

  onChangeToogle(st){
    this.toogleValue = st.value;
    if(this.formData.has('send_invitation')){
      this.formData.set('send_invitation', this.toogleValue ? '1' : '')
    }
  }
  onSubmit(e){
    this.onSubmitBtn.emit({action: e});
  }
  fileOverChange(e){
   this.fileOver = e;
   if(this.afterCheck && this.fileOver){
     this.dashedBox.nativeElement.setAttribute('style', 'height:'+ this.height+'px !important ')
  }
    //console.log(e);
    
  }

}
