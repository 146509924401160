import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Output } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cah-show-hide-button',
  templateUrl: './showhide-button.component.html',
  styleUrls: ['./showhide-button.component.scss']
})
export class ShowHideButtonComponent implements OnInit {

  @Input() type: boolean;
  @Input() tooltipTitle: string;
  //@Input() iconPath: string = "/assets/images/archive.svg";

  @Output() onChange = new EventEmitter<boolean>();


  constructor() { }

  ngOnInit() {
  }

  onClickEyeButton(){
    this.onChange.emit(!this.type)
  }

}
