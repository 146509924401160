import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cah-select-dropdown-day',
  templateUrl: './select-dropdown-day.component.html',
  styleUrls: ['./select-dropdown-day.component.scss']
})
export class SelectDropdownDayComponent implements OnInit {

  
  boxState: boolean = false;
  
  @Input() selectedLabel: string;
  @Input() labelsToSelect: [];
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  toggleBox() {
    this.boxState = !this.boxState;
  }
  selectLabel(selectedLabel){
    this.selectedLabel = selectedLabel;
    this.boxState = false;
    this.onSelect.emit(this.selectedLabel);
  }
}
