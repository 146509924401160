import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';
import { ElementState } from 'app/enums';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'cah-form-dropwon-input',
  templateUrl: './form-dropwon-input.component.html',
  styleUrls: ['./form-dropwon-input.component.scss']
})
export class FormDropdownInputComponent implements OnInit {

  @Input() validators: string[] = [];
  @Input() bindName: string;
  @Input() type: string;
  @Input() onlyNumber: boolean;
  @Input() label: string;
  @Input() placeHolder: string = '';
  @Input() helper: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() state: string = ElementState.GENERATOR;
  @Input() value: any;
  @Input() error: any;
  @Input() tabindex: string;
  @Input() isBillingForm: string;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();

  
  @Input() dropdownOpions: any;
  @Input() dropdownValue: any;
  @Input() dropdownName: string = 'name';
  @Input() dropdownBindName: string = 'name';
  @Input() dropdownBindValue: string = 'id';
  dropdown: boolean = false;
  dropdownSelectedValue: string = '';
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  ngOnInit() {
    if(this.dropdownOpions.length && this.dropdownValue){
      this.dropdownSelectedValue = this.dropdownOpions.find(item => item[this.dropdownBindValue] === this.dropdownValue)[this.dropdownBindName]
    }
  }

  onInputChange(event){
    if (this.onlyNumber) {
      this.value = this.value.replace(/\D/g, '');
      if (parseFloat(this.value) < 0) {
        this.value = 0.00;
      }
      event.target.value = this.value
    }
    if (this.onlyNumber || this.validators.length) {
      this.value = !isNaN(parseInt(this.value)) && this.onlyNumber ? parseInt(this.value) : HelperService.limitTextInput(this.validators, this.value, event);
    }
    const payload: IInputValue = {
      name: this.bindName,
      value: this.value 
    }
    if(this.type == "date"){
      let date =  new Date(payload.value);
      payload.value = date.toISOString();
    }
    this.onChange.emit(payload);
  }

  onChangeDropdown(option: any): void {
    this.dropdownValue = option[this.dropdownBindValue];
    this.dropdownSelectedValue = option[this.dropdownBindName];
    const payload: IInputValue = {
      name: this.dropdownName,
      value: this.dropdownValue 
    };
    
    this.onChange.emit(payload);
    this.dropdown = false;
  }
  
  clearInput(){
    this.value = '';
    const payload: IInputValue = {
      name: this.bindName,
      value: this.value 
    };
    this.onChange.emit(payload);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
