import { Component, OnInit, Output, EventEmitter, Input, HostListener, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { ModelTypeEnum, TooltipPlacementEnum } from "app/enums";
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';
import { HelperService } from '@services/helper/helper.service';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { DateFormatService } from '@services/date-format/date-format.service';

@Component({
  selector: 'cah-timeslot-day',
  templateUrl: './timeslot-day.component.html',
  styleUrls: ['./timeslot-day.component.scss']
})
export class TimeslotDayComponent implements OnInit, OnChanges {
  @ViewChild('timeslotsContent', { static: false, read: ElementRef }) public timeslotsContent: ElementRef<any>;

  @Output() modal: EventEmitter<any> = new EventEmitter();
  @Input() timeslotsData: any = {};
  @Input() session_selected: boolean;
  @Input() language: string = 'en';
  showDayDropdown: boolean = false;
  showTimeDropdown: boolean = false;
  selectedItemKey: any;
  selectedTimeItemKey: any;
  scrollOption: string = 'left';
  isScroll: boolean = false;
  dateFormat24: boolean;

      
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false

  constructor(public languageService: LanguageService,
    public dateFormatterService: DateFormatterService,
    public dateFormatter: DateFormatService) {         
      languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });

      dateFormatter.timeFormat$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(dateFormat =>{
        this.dateFormat24 = dateFormat == 24;
      })
  }

  ngOnInit() {
  }

  ngOnChanges() {
    setTimeout(() => {
      this.checkScroll();
    });
  }

  @HostListener('window:resize', [])
  checkScroll(){
    if (!this.timeslotsContent) { return; }
    this.isScroll = this.timeslotsContent.nativeElement.scrollWidth > this.timeslotsContent.nativeElement.offsetWidth;
  }
  

  onToggle(status, name, label_name, timeslot_date = '', timeslot_id = '', start_time = '', end_time = '', label_id = null) {
    this.modal.emit({ status, name, label_name, timeslot_date, timeslot_id, start_time, end_time, label_id  })
  }

  getMonthAndDay(date: any) {
    return this.dateFormatterService.getMonthAndDay(date, this.language);
  }

  changeTimeToAmPm(time) {
    return HelperService.timeToAmPm(time);
  }

  optionDayDropdown(status, itemKey) {
    this.showDayDropdown = !this.showDayDropdown;
    this.selectedItemKey = itemKey;
  }

  optionTimeDropdown(timeItemKey) {
    this.showTimeDropdown = !this.showTimeDropdown;
    this.selectedTimeItemKey = timeItemKey;
  }
  
  // Close the dropdown if the user clicks outside of it
  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (event.target.id != "dropdownDay") {
      this.showDayDropdown = false;
      this.showTimeDropdown = false;
    }
  }

  onScrollWithButton(side: string): void {
    const scrollElement: any = this.timeslotsContent.nativeElement;

    if (side === 'left') {
      scrollElement.scrollTo({
        left: (scrollElement.scrollLeft - 50), behavior: 'smooth'
      });
    } else {
      scrollElement.scrollTo({
        left: (scrollElement.scrollLeft + 50), behavior: 'smooth'
      });
    }
    this.scrollOption = scrollElement.scrollLeft - 50 <= 0 ? 'left' :
      ((scrollElement.scrollLeft + 50 + scrollElement.clientWidth) >= scrollElement.scrollWidth) ? 'right' : '';
  }

  onScroll(event: any): void {
    const scrollElement = event.target;
    this.scrollOption = scrollElement.scrollLeft <= 0 ? 'left' :
      ((scrollElement.scrollLeft + scrollElement.clientWidth) >= scrollElement.scrollWidth) ? 'right' : '';
  }

  // checkScroll() {
  //   if (!this.timeslotsContent) { return; }
  //   this.isScroll = this.timeslotsContent.nativeElement.scrollWidth > this.timeslotsContent.nativeElement.offsetWidth;
  // }
  
  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
