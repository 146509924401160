import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';
import { NestedValuePipe } from 'app/shared-pipes/nested-value/nested-value.pipe';
import { HelperService } from '@services/helper/helper.service';
import { ElementState } from 'app/enums';
import { CampService } from '@services/camp/camp.service';
import * as moment from 'moment';

@Component({
  selector: 'cah-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit, OnChanges {
  @Input() bindImg: string = 'image';
  @Input() bindFullname: string = 'first_name,last_name';
  @Input() bindRole: string = 'role.name';  
  @Input() bindName: string;
  @Input() isMulti: boolean = false;
  @Input() type: string;
  @Input() dataType: any;
  @Input() label: string;
  @Input() options: any = [];
  @Input() value: any;
  @Input() multiValue: any[] = [];
  @Input() displayProperty: string = "label";
  @Input() returnProperty: string = "id";
  @Input() prefixIcon: string = "clickto-check";
  @Input() showBottomButton: boolean = false;
  @Input() showTopButton: boolean = true;
  @Input() showImage: boolean = false;
  @Input() showSearch: boolean = true;
  @Input() showRole: boolean = false;
  @Input() showDotIcon: boolean = false;
  @Input() showChip: boolean = false;
  @Input() showSelectedAvatar = false;
  @Input() showSelectedIcon = false;
  @Input() showActions = false;
  @Input() showTabs = false;
  @Input() tabs = [];
  selectedTabIndex = 0;
  @Input() selectedIcon: string = "clickto-filter";
  @Input() state: string = ElementState.GENERATOR;
  @Input() placeHolder: string;
  @Input() helper: string;
  @Input() startPrefix: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() error: any;
  @Input() errorWithoutMessage: boolean = false;
  @Input() tabindex: string;
  @Input() isBillingForm: string;
  @Input() selectionPlaceholder: string = 'item_selected';

  @Input() iconArrowSize: string = 'sm';
  @Input() clearBtn: boolean = false;
  @Input() isFilter: boolean = false;

  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();
  @Output() onTopButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onBottomButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSelectDropdownToggle: EventEmitter<any> = new EventEmitter();
  @Output() onRowAction: EventEmitter<any> = new EventEmitter();
  @Output() onTabChange: EventEmitter<any> = new EventEmitter();

  listState: boolean = false;
  selectedItem: any;
  selectedItems: any[] = [];
  position: any = {
    vertical: 'bottom'
  };
  checkedAllTabs: boolean = false;
  moment = moment;

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService, 
              private nestedValuePipe: NestedValuePipe,
              public campService: CampService
              ) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options || changes.value || changes.multiValue) {
      if(this.options.length || this.options.length && (this.multiValue.length || this.value)){
        this.checkDefaultValue();
      }
    }
  }

  checkDefaultValue(){
    if(this.isMulti){
      if (!this.multiValue) return this.multiValue = [];
      let tmpValues = []
      this.multiValue.forEach(item => {
        let option = this.options.find( x => this.nestedValuePipe.transform(x, this.returnProperty) === item);
        if(option){
          tmpValues.push(option)
        }
      });
      this.selectedItems = this.selectedItems.length < 1 ? tmpValues : this.selectedItems;
    }else{
      let defaultValue = this.options.find( x => this.nestedValuePipe.transform(x, this.returnProperty) === this.value);
      
      if(defaultValue){
        this.selectedItem = defaultValue;
        this.checkedAllTabs = true;
      } else if(!this.showTabs) {
        this.selectedItem = null;
      }
    }

    if(this.showTabs && !this.checkedAllTabs && this.selectedItem == null && this.selectedItem == undefined && !this.selectedItems.length){
      if(this.selectedTabIndex < this.tabs.length - 1){        
        this.changeTab(this.selectedTabIndex+1);
      }else{
        this.checkedAllTabs = true;
      }
    }
  }

  toggleList(state, event?){
    if(event){
      this.position = HelperService.calculatePopupPosition(event.pageX, event.pageY, 250, 300, window.innerHeight, window.innerWidth, window.scrollY, window.scrollX);
    }
    this.listState = state;

    this.options.forEach(element => {
      //console.log(this.nestedValuePipe.transform(this.selectedItem, this.returnProperty),this.nestedValuePipe.transform(element, this.returnProperty))
    });

    const payload: IInputValue = {
      name: this.bindName,
      value: state
    }

    this.onSelectDropdownToggle.emit(payload);
  }

  selectItem(item){
    if(this.isMulti){
      if(this.multiValue.includes(item[this.returnProperty])){
        // this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        this.multiValue.splice(this.multiValue.indexOf(item[this.returnProperty]), 1);
      }else{
        // this.selectedItems.push(item);
        this.multiValue.push(item[this.returnProperty])
      }
    }else{      
      if(this.value == this.nestedValuePipe.transform(item, this.returnProperty) || item == this.selectedItem){
        this.selectedItem = null;
        this.value = null;
      }else{
        this.selectedItem = item;
        this.value = this.nestedValuePipe.transform(this.selectedItem, this.returnProperty);
      }
      const payload: IInputValue = {
        name: this.bindName,
        value: this.value,
        item: this.selectedItem
      }

      this.onChange.emit(payload);
      this.toggleList(false);
    }
  }

  selectAllToggle(): void {
    this.multiValue.length === this.options.length ? this.multiValue = [] : this.multiValue = this.options.map(opt => opt[this.returnProperty]);
    // this.selectedItems = [...this.multiValue];
  }

  onApplyMultiValues(): void {
    this.selectedItems = this.multiValue.length === 1 ? [this.options.find(opt => opt[this.returnProperty] === this.multiValue[0])] :  [...this.multiValue];
    const payload: IInputValue = {
      name: this.bindName,
      value: this.multiValue
    };
    this.onChange.emit(payload);
    this.toggleList(false);
  }

  changeTab(index){
    this.selectedTabIndex = index;
    const payload = {
      name: this.tabs[index],
      index: index
    }
    this.onTabChange.emit(payload)
  }

  clearAll(){
    if(this.isMulti){
      this.selectedItems = [];
      this.multiValue = [];
  
      const payload:IInputValue = {
        name: this.bindName,
        value: this.multiValue
      }
      this.onChange.emit(payload);
    }else{
      this.selectedItem = null;
      this.value = null;
  
      const payload:IInputValue = {
        name: this.bindName,
        value: this.value,
        item: this.selectedItem
      }
      this.onChange.emit(payload);
    }    
    

  }

  search(event){
    const payload = {
      name: this.bindName,
      value: event.value
    }


    this.onSearch.emit(payload);
  }

  buttonClick(type){
    if(type == 'top'){
      const payload = {
        name: this.bindName,
        value: 'top'
      }
      this.onTopButtonClick.emit(payload)
    }else{
      const payload = {
        name: this.bindName,
        value: 'bottom'
      }
      this.onBottomButtonClick.emit(payload)
    }
  }

  actionClick(action, item){
    const payload = { action, item };
    this.onRowAction.emit(payload);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
    this.selectedItems = [];
    this.multiValue = [];
  }
}
