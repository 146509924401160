import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CampersService } from '@services/campers/campers.service';
import { Splash, SplashError, SessionStatus, CamperStatus, ScheduleTypeEnum, ElectiveTypeEnum, TimeFormat, RoomTypeEnum } from '@enums';

import * as moment from 'moment';
import 'moment-timezone';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'cah-activity-splash',
  templateUrl: './activity-splash.component.html',
  styleUrls: ['./activity-splash.component.scss']
})
export class ActivitySplashComponent implements OnInit {
  logo: string = '/assets/images/clicktocamplogo.png';
  splashBackground: string;
  tomorrowBackground: string;
  transferBackground: string;
  endBackground: string;
  loading: boolean = true;
  error: number = 0;
  warningSplash: boolean = false;
  countdownTimer: any;
  hash: string = '';
  seconds: number = 0;
  timerDate: any = {
    days: '00',
    hours: '00',
    minutes: '00',
    remainingSeconds: '00'
  };
  selectedElectiveOptions: any = null;
  activityData: any = {};
  activityDate: string = '';

  splashOption = Splash;
  activeSplash: number = this.splashOption.DAY;
  scheduleTypeElective: boolean;
  electiveType: ElectiveTypeEnum;
  session_start_time: string;
  session_end_time: string;
  dayOfWeek: string;
  showCheckBanner: boolean = true;
  camperError: any;
  roomTypeEnum = RoomTypeEnum;
  timeFormat: TimeFormat = TimeFormat.HOUR12;
  isHebrew: boolean = false;
  campId: number = 0;
  campInfo: any = null;
  program: any = null;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private campersService: CampersService) {
    this.timeFormat = localStorage.getItem('time-format') ? JSON.parse(localStorage.getItem('time-format'))
      : TimeFormat.HOUR12;
    this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;

  }

  ngOnInit() {    
    // check hash
    this.hash = this.route.snapshot.params.hash;
    if (!this.hash) {
      this.loading = false;
      return this.error = SplashError.NOTWORK;
    }

    this.campersService.getCallTime(this.hash)
      .subscribe(res => {
        this.activityData = res;
        // console.log(res);

        let now = moment(new Date());
        let diff = now.diff(this.activityData.schedule.timeslot.date, 'days');
        this.dayOfWeek = (diff * -1) <= 7 ? moment(this.activityData.schedule.timeslot.utc_start).format('dddd') : 
                moment(this.activityData.schedule.timeslot.utc_start).format('MMM DD (dddd)');

        // get left seconds and start timer
        this.seconds = res.timeLeft;
        this.countdownTimer = setInterval(() => this.timer(), 1000);

        // get activity date time based on timezone
        this.activityDate = res.schedule.timeslot.utc_start;

        let sessionTimeFormat = this.timeFormat === TimeFormat.HOUR24 ?  'HH:mm' : 'hh:mm A';
        this.session_start_time = moment(res.schedule.timeslot.utc_start).format(sessionTimeFormat);
        this.session_end_time = moment(res.schedule.timeslot.utc_end).format(sessionTimeFormat)

        this.scheduleTypeElective = res.schedule.type == ScheduleTypeEnum.ELECTIVE ? true : false;
        this.campId = res.schedule.session.camp_id;
        this.campInfo = res.schedule.session.camp;
        this.program = res.schedule.session.program;

        this.electiveType = this.scheduleTypeElective ? res.schedule.elective.type : null;

        this.logo = res.campLogo && res.campLogo.length ? res.campLogo[0].full_path : '/assets/images/clicktocamplogo.png';
        const splashBackground = this.program.hours_splash_image || this.program.shop_image || null;
        const transferBackground = this.program.seconds_splash_image || this.program.shop_image || null;
        const endBackground = this.program.days_splash_image || this.program.shop_image || null;
        const tomorrowBackground = this.program.days_splash_image || this.program.shop_image || null;
        
        this.splashBackground = splashBackground ? splashBackground.full_path : "assets/images/splash-activity/entrance.png";
        this.transferBackground = transferBackground ? transferBackground.full_path : "assets/images/splash-activity/transfer.png";
        this.endBackground = endBackground ? endBackground.full_path : "assets/images/splash-activity/exit.png";
        this.tomorrowBackground =  tomorrowBackground ? tomorrowBackground.full_path : "assets/images/splash-activity/exit.png";

        // check if session is suspended
        if (res.schedule.session.status === SessionStatus.SUSPENDED) {
          this.error = SplashError.TRYAGAIN;
          return this.loading = false;
        }

        // check if camper is inactive
        if (res.camper.status === CamperStatus.SUSPENDED) {
          this.error = SplashError.DENIED;
          return this.loading = false;
        }

        // set hast to localstorage for backurl
        localStorage.setItem('userUnikoHash', this.hash);

        if (this.scheduleTypeElective) {
          if (this.electiveType == ElectiveTypeEnum.REGISTRATIONBASED) {
            res.schedule.elective.options.filter(obj => {
              obj.campers.filter(camperObj => {
                if (camperObj.camper.code == this.hash) {
                  this.selectedElectiveOptions = obj;
                }
              })
            })
          } else {
            this.selectedElectiveOptions = this.checkLocal(res.schedule.elective.options);
          }
        }

        // check if meeting is ongoing now
        if (res.timeLeft === 0) {
          this.loading = false;
          return this.activeSplash = this.splashOption.SECONDS;
        }
      }, e => {
        console.log(e);
        this.loading = false;
        this.campId = e.error.campId || 123;
        this.camperError = e.error.participant;
        if ((e.status === 410 || e.status === 404) && 
          (e.error.error === "slot_not_found" || e.error.error === 'schedule_not_found_for_camper' 
          || e.error.message === "groups_not_found")) {          
          this.logo = e.error.campLogo && e.error.campLogo.length ? e.error.campLogo[0].full_path : '/assets/images/clicktocamplogo.png';
          setTimeout(() => {
            this.warningSplash = false;
            this.loading = true;
            this.ngOnInit();
          }, 60000)
          return this.warningSplash = true;
        } else if (e.status === 409 && e.error.message === 'session_is_not_active') {
          return this.error = SplashError.TRYAGAIN;
        } else if (e.error.message === 'access_denied') {
          return this.error = SplashError.DENIED;
        }
        this.error = SplashError.NOTWORK;
      });

  }

  checkLocal(optionList: any[]): any {
    if (localStorage.getItem('splashAct')) {
      const key = this.activityData.schedule.id + '-' +
        this.activityData.camper.id + '-' +
        this.activityData.schedule.elective_id;

      const options = JSON.parse(localStorage.getItem('splashAct'));
      const checkOption = optionList.find(op => op.id === options[key])
      return checkOption;
    }
    return false;
  }

  checkSplash(timerDate: any): void {
    // check if left time date is tomorrow
    const endOfDay = new Date().setHours(23, 59, 59);
    const nowTime = new Date();
    const secondsBeforeNextDay = ((new Date(endOfDay).getTime() - nowTime.getTime()) / 1000);
    const dif = this.seconds - secondsBeforeNextDay;
    // check left time and show suitable splash
    const days: number = +timerDate.days;
    if (days === 0 && timerDate.hours === 0 &&
      timerDate.minutes === 0 && timerDate.remainingSeconds <= 5) {
      this.activeSplash = this.splashOption.SECONDS;
    } else if (secondsBeforeNextDay > this.seconds) {
      this.activeSplash = this.splashOption.HOUR;
    } else if (Math.floor(dif / 3600) < 24) {
      this.activeSplash = this.splashOption.TOMMOROW;
    } else {
      this.activeSplash = this.splashOption.DAY;
    }
    this.loading = false;
  }

  timer(): any {
    const days = Math.floor(this.seconds / 24 / 60 / 60);
    const hoursLeft = Math.floor((this.seconds) - (days * 86400));
    const hours = Math.floor(hoursLeft / 3600);
    const minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
    const minutes = Math.floor(minutesLeft / 60);
    const remainingSeconds = this.seconds % 60;
    if (this.seconds !== 0 && (remainingSeconds === 0 || this.seconds === 9)) {
      clearInterval(this.countdownTimer);
      return this.ngOnInit();
    }
    function pad(n) {
      return (n < 10 ? '0' + n : n);
    }
    this.timerDate = { days, hours, minutes, remainingSeconds };
    this.checkSplash(this.timerDate);

    if (this.seconds === 0) {
      clearInterval(this.countdownTimer);
    } else {
      this.seconds--;
    }
  }

  onRedirectMeeting(event: any): void {
    if (this.scheduleTypeElective) {
      this.router.navigate(["/elective-timer", this.hash]);
    } else if (this.activityData.schedule.type == ScheduleTypeEnum.CONTENT) {
      this.router.navigate(["/content-board-view"], 
                            {  queryParams: 
                                { 
                                  schedule_id: this.activityData.schedule.id, 
                                  guest: this.hash, 
                                  board_id:  this.activityData.schedule.group_id,
                                }
                            });
    } else {
      this.goToMeeting(this.hash, this.activityData.schedule.id);
    }
  }

  goToMeeting(hash: string, scheduleId: number): any {
    this.loading = true;
    this.campersService.goToKalturaMeeting(hash, scheduleId, {}, 
                          this.selectedElectiveOptions ? this.selectedElectiveOptions.space.title : 
                                                        this.activityData.schedule.space.title, this.campId, this.logo, this.campInfo.room_type);
  }

  calcTime(offset: any, date: string): any {
    const currentDay = new Date(date);
    const utc = currentDay.getTime();
    const nd = new Date(utc + (3600000 * offset));
    return nd;
  }

  goToLink(link: string, target: string = '_self'): void {
    HelperService.goToLink(link, target);
  }

  checkDaylightsavingtime(timeZone: string): boolean {
    if (!timeZone) { return false; }
    const m = moment.tz(timeZone);
    return m.isDST();
  }

}
