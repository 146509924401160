import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { ScheduleTypeEnum } from 'app/enums';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { CampersService } from '@services/campers/campers.service';
import { PickerColors } from '@colors';

@Component({
  selector: 'cah-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {

  boxType: string = ScheduleTypeEnum.BYGROUP;
  scheduleType = ScheduleTypeEnum;
  @Input() hovered: any = {
    row: null,
    col: null
  }
  @Input() days: any[] = [];
  @Input() selectedDayIndex: number;
  @Input() groups: any[];
  @Input() timeslots: any[];
  @Input() activities: any[];
  @Input() spaces: any[];
  @Input() electives: any[];
  @Input() boards: any[];
  @Input() selectedElective: string;
  @Input() selectedActivity: string;
  @Input() selectedSpace: string;
  @Input() selectedSchedule: any = {};
  @Input() canTimeslotCopied: boolean = false;
  @Input() timeslotStart: number;
  @Input() timeslotLimit: number;
  @Input() isCopied: any = false;
  @ViewChild('scroll', { static: true }) scroll: any;
  @ViewChild('board', { static: true }) board: any;
  @Input() set scrollBody(left) {
    this.scroll.nativeElement.scrollLeft = left;
    this.openModal = {
      row: null,
      col: null
    }
  };
  @Output() onScheduleSelect: EventEmitter<any> = new EventEmitter();
  @Output() onScheduleDelete: EventEmitter<any> = new EventEmitter();
  @Output() onGroupDelete: EventEmitter<any> = new EventEmitter();
  @Output() onSchedulePaste: EventEmitter<any> = new EventEmitter();
  @Output() onEditElective: EventEmitter<number> = new EventEmitter();
  @Output() onEditContent: EventEmitter<number> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onCopied: EventEmitter<any> = new EventEmitter();
  @Output() onHover: EventEmitter<any> = new EventEmitter();
  @Output() onActivityAction: EventEmitter<any> = new EventEmitter();
  @Output() onElectiveAction: EventEmitter<any> = new EventEmitter();
  @Output() onContentAction: EventEmitter<any> = new EventEmitter();
  @Output() onTimeslotAction: EventEmitter<any> = new EventEmitter();
  @Output() onGroupAction: EventEmitter<any> = new EventEmitter();
  @Output() onSpaceAction: EventEmitter<any> = new EventEmitter();
  @Output() onScroll: EventEmitter<any> = new EventEmitter();
  @Output() onCloseBoxModal: EventEmitter<any> = new EventEmitter();
  @Output() onAddGroup: EventEmitter<boolean> = new EventEmitter();
  @Output() onTabChange: EventEmitter<any> = new EventEmitter();
  
  selectedDay: any;
  groupDetails: any = {};
  groupDetailsPosition: any = {};
  campersList: any;
  translateDynamicallValues: any = {};
  timeslotDropdown: any = { col: false };

  coordinate = { i: -1, j: -1 }

  deletePayload = {};

  
  @Input() openModal = {
    row: null,
    col: null
  }

  showDropDown: any = {
    row: null,
    col: null
  }

  position = {
    vertical: 'bottom',
    horizontal: 'right'
  }

  alertOption = {
    isError: false,
    message: 'Copied!',
    isOpen: false
  };

  confirmModal: any = {
    question: 'are_you_sure_delete',
    show: false
  };

  question: string;
  showCopy: boolean;
  selectedGroup: any;
  rowSchedule: any = {};
  emptyBoxesQty: number = 0;
  hasHorizontalScrollbar: boolean = false;
  pickerColors = PickerColors;

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(public languageService: LanguageService,
              private campersService: CampersService,

  ) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });
  }

  ngOnInit() {
    this.emptyBoxesQty = (this.board.nativeElement.offsetHeight) / 70;
    console.log("WTF")
  }

  ngAfterViewInit(): void {
    this.onCheckVerticalScroll();
    console.log(this.groups)

  }

  ngOnChanges() {
    setTimeout(() => {
      this.onCheckVerticalScroll();
    })
    this.selectedDay = this.selectedDayIndex != null && this.days ? this.days[this.selectedDayIndex] : null;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.getAttribute('class') && 
        (!event.target.getAttribute('class').includes('board-right-list-item-box-full-icons-icon-dots') &&
      !event.target.getAttribute('class').includes('icon-svg-dots'))) {
      this.showDropDown = {
        row: null,
        col: null
      }
    }

    if (event.target.getAttribute('class') !== 'board-right-list-item-header-right-icon' &&
      event.target.getAttribute('class') !== 'icon-svg-dots') {
      this.timeslotDropdown = {
        col: null
      }
    }
  }

  identify(index, item): any {
    return item.id; 
  }

  onCheckVerticalScroll(): void {
    const div = document.getElementById('board-right-list');
    this.hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
  }

  onActionTimeslot(name: string, option: any): void {
    this.onTimeslotAction.emit({ name, option });
  }

  onShowTimeslotDropDown(i: number): any {
    if (this.timeslotDropdown.col === i) {
      return this.timeslotDropdown.col = false;
    }
    this.timeslotDropdown.col = i;
  }

  onShowGroupDetails(event: any, i: number, option: boolean): void {
    this.hovered = { row: i, col: null };
    this.groupDetails[i] = option;
    this.groupDetailsPosition = HelperService.calculatePopupPosition(event.pageX,
      event.pageY,
      400, 300,
      window.innerHeight,
      window.innerWidth,
      window.scrollY,
      window.scrollX);
  }


  findSchedule(schedules: any[], groupId: number, timeslotId: number): any {
    // if (this.rowSchedule[timeslotId + '-'+groupId]) { return this.rowSchedule[timeslotId + '-'+groupId]; }
    const findSchedule: any = schedules.find(sched => sched.group_id === groupId);
    if (findSchedule) {
      this.rowSchedule[timeslotId + '-'+groupId] = findSchedule;
    }
    return findSchedule;
  }

  checkSchedule(rowSchedule, j){
    if(rowSchedule.type == ScheduleTypeEnum.BYGROUP && !rowSchedule.activity && !rowSchedule.space || 
      rowSchedule.type == ScheduleTypeEnum.ELECTIVE && !rowSchedule.elective ){
      this.deleteSchedule( null, rowSchedule, j, 0);
      return false;
    }
    return   true;
  }

  mouseOver(row, col) {
    this.hovered = {
      row,
      col
    }
    this.onHover.emit(this.hovered)
  }

  closePopup() {
    this.onCloseBoxModal.emit(true);
  }

  showPopup(event, row, col, scheduleIndex: any = 0, scheduleBlock?: any) {
    if (this.openModal.row == row && this.openModal.col == col) {
      this.closePopup();
    } else {
      this.openModal = {
        row,
        col
      }
    }
    this.boxType = scheduleBlock ? scheduleBlock.type : ScheduleTypeEnum.BYGROUP;
    this.position = HelperService.calculatePopupPosition(event.pageX, event.pageY, 400, 300, window.innerHeight + 50, window.innerWidth, window.scrollY, window.scrollX);
    let schedule = {
      payload: scheduleBlock ? scheduleBlock : {
        timeslot_id: this.timeslots[col].id,
        group_id: this.groups[row].id,
        activity_id: null,
        space_id: null,
        activity: null,
        space: null,
        elective_id: null,
        elective: null,
        board_id: null,
        board: null
      },
      timeslotIndex: col,
      scheduleIndex: scheduleIndex
    }

    this.onScheduleSelect.emit(schedule);
  }

  onCopySchedule(e: Event, schedule: any, option: string, i = -1, j = -1): void {
    e.stopPropagation();
    const payload: any = {
      schedule,
      option
    }
    this.onCopied.emit(payload);
    this.showDropDown.row = null;
    this.showDropDown.col = null;

    if (i >= 0 && j >= 0) {
      this.coordinate.i = i;
      this.coordinate.j = j;
      setTimeout(() => {
        this.coordinate.i = -1;
        this.coordinate.j = -1;
      },
        350)
    }

  }

  onPasteSchedule(row, col): void {
    if (!this.isCopied) { return; }
    let schedule = {
      payload: {
        timeslot_id: this.timeslots[col].id,
        group_id: this.groups[row].id,
        activity_id: this.isCopied.activity_id,
        space_id: this.isCopied.space_id,
        elective_id: this.isCopied.elective_id,
        board_id: this.isCopied.board_id,
        activity: null,
        space: null,
        elective: null,
        board: null
      },
      timeslotIndex: col,
    }

    this.onSchedulePaste.emit(schedule);
  }

  onShowDropDown(e: Event, i: number, j: number): void {
    e.stopPropagation();
    this.showDropDown.row = i;
    this.showDropDown.col = j;
  }

  onBoxSearch(event) {
    this.onSearch.emit(event)
  }

  onBoxSelect(event) {
    this.onSelect.emit(event)
  }

  onGroupActions(e: Event, group: any, optionName: string) {
    let payload = {
      name: optionName,
      value: group
    }
    this.onGroupAction.emit(payload);
  }

  deleteSchedule(e: Event, schedule, timeslotIndex, scheduleIndex) {
    if(e){
      e.stopPropagation();
    }
    this.deletePayload = {
      scheduleId : schedule.id,
      timeslotIndex,
      scheduleIndex
    }

    this.rowSchedule = {};

    // this.confirmModal.show = true;
    // this.confirmModal.name = 'schedule';
   
    //this.confirmModal.question = 'are_you_sure_delete';
    this.onScheduleDelete.emit(this.deletePayload);
  }

  onConfirm(event) {
    if (event.confirm) {
      if (event.modalName === 'schedule') {
        this.onScheduleDelete.emit(this.deletePayload);
      } else if (event.modalName === 'group') {
        this.onGroupDelete.emit(this.selectedGroup);
      }
    }
    this.confirmModal.show = false;
  }
  onScrollBox(event) {
    this.onScroll.emit(event.target.scrollLeft);

  }
  onDeleteGroup(group: any): void {
    this.campersService.getCampers({group_id: group.id})
      .subscribe(
        res => {
          this.campersList = '';
          res.data.map(camp => this.campersList = this.campersList + camp.first_name + " " + camp.last_name + ", ")
          this.campersList = this.campersList.slice(0, -2)
  
          this.translateDynamicallValues['campers'] = this.campersList;
          this.confirmModal.question = 'this_will_delete_the_following_campers';
          this.selectedGroup = group;
          this.confirmModal.show = true;
          this.confirmModal.name = 'group';
        }
      )
    


  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
