import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '@services/user/user.service';
import { LanguageService } from '@services/language/language.service';
import { takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { AssignmentTypeEnum } from '@enums';
import { KeyValue } from '@angular/common';
import { HelperService } from '@services/helper/helper.service';
@Component({
  selector: 'cah-board-staff-box',
  templateUrl: './board-staff-box.component.html',
  styleUrls: ['./board-staff-box.component.scss']
})
export class BoardStaffBoxComponent implements OnInit {
  @Input() position: any = {
    vertical: 'bottom',
    horizontal: 'right'
  };

  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onEdit: EventEmitter<number> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onSpaceTabChange: EventEmitter<any> = new EventEmitter();

  @Input() data: any = {};
  @Input() type: AssignmentTypeEnum = AssignmentTypeEnum.GROUP;
  @Input() value: any;
  @Input() selectedValue: any;
  spaceTabs: any = [{
    name: 'Sessions Rooms '
  },
  {
    name: 'Public Rooms '
  }];
  suggestionState: any ;
  notValid: boolean = false;
  assignmentTypeEnum = AssignmentTypeEnum;

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  private searchSubject: Subject<any> = new Subject();

  constructor(private userService: UserService,
    public languageService: LanguageService
    ) {
      languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == 'he';
    });
   }


  ngOnInit() {
    const div: any = document.getElementById('main-content');
    div.style.overflow = 'hidden';
    
    this.searchSubject
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
      ).subscribe(res => {
        let payload = {
          name: res.inputName,
          value: res.event
        }
        this.onSearch.emit(payload)
      });
  }

  ngOnChanges() {
    console.log(this.selectedValue)
    this.data = Object.values(this.data);
  }

  onBoxClose(): void {
    this.onClose.emit();
  }

  onItemSelect(event: any): any {
    console.log(event);
    if(this.type === this.assignmentTypeEnum.GROUP){
      event = event.item.data;
      this.selectedValue = event;
      this.value = event.title;
      let payload = {
        name: this.type,
        value: event
      }
      this.onSelect.emit(payload);
    }else{
      event = event.item;
      this.selectedValue = event;
      this.value = event.title;
      let payload = {
        name: this.type,
        value: event
      }
      this.onSelect.emit(payload);
    }
    
  }

  onTextChange(event: any, inputName: string): void {
    this.searchSubject.next({ event: event.value, inputName: inputName });
  }

  orderWithActivityAsc = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
    const a = akv.value.activity;
    const b = bkv.value.activity;
    return HelperService.isObjectEmpty(a) ? 1 : (HelperService.isObjectEmpty(b) ? -1 : 0);
};
  
  ngOnDestroy(): void {
    const div: any = document.getElementById('main-content');
    div.style.overflow = 'auto';
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
