import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';
import { ElementState } from 'app/enums';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'cah-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() bindName: string;
  @Input() type: string;
  @Input() onlyNumber: string;
  @Input() maxValue: string;
  @Input() label: string;
  @Input() placeHolder: string = '';
  @Input() helper: string;
  @Input() startPrefix: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() state: string = ElementState.GENERATOR;
  @Input() value: any;
  @Input() error: any;
  @Input() tabindex: string;
  @Input() isBillingForm: string;
  @Input() validators: string[];
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  ngOnInit() {
  }

  onInputChange(event){
    if (this.onlyNumber) {
      this.value = this.value.replace(/\D/g, '');
            
      if (this.value.charAt(0) === '0' && this.value.length > 1) {
        this.value = this.value.slice(1);
      }
      if (this.maxValue && this.value > this.maxValue) {
        this.value = this.value.slice(0, this.maxValue.toString().length);
        this.value = this.value > this.maxValue ? this.maxValue : this.value;
      }
      event.target.value = this.value;
    }

    if (this.validators) {
      this.value = HelperService.limitTextInput(this.validators, this.value, event);
    }
   
    this.value = !isNaN(parseInt(this.value)) && this.onlyNumber ? parseInt(this.value) : this.value
   
    const payload: IInputValue = {
      name: this.bindName,
      value: this.value 
    }
    if(this.type == "date"){
      let date =  new Date(payload.value);
      payload.value = date.toISOString();
    }
    this.onChange.emit(payload);
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
