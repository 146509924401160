import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { RoomTypeEnum } from '@enums';

@Component({
  selector: 'cah-live-board-box',
  templateUrl: './live-board-box.component.html',
  styleUrls: ['./live-board-box.component.scss']
})
export class LiveBoardBoxComponent implements OnInit {
  @Input() schedule: any = {};
  @Input() groups: any = {};
  @Input() camp: any = {};
  @Input() staffAssignment: any = {};
  @Output() onOutputAction = new EventEmitter<any>();

  showDropDown: boolean = false;
  groupDetails: any = {};
  groupDetailsPosition: any = {};

  staffAssgnmentHover: any = false;
  staffAssgnmentHoverPosition: any = {};


  staffAssignmentList: any[] = [];

  roomTypeEnum = RoomTypeEnum;

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;


  constructor(public languageService: LanguageService,
    private eRef: ElementRef
  ) {

    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });

  }

  ngOnInit() {
    
    for (let i = 0; i < this.staffAssignment.length; i++) {
      const element = this.staffAssignment[i];

      for (const group of this.groups[this.schedule.timeslot_id + '-' + this.schedule.space_id + '-' + this.schedule.activity_id + '-' + this.schedule.elective_id + '-' + this.schedule.board_id]) {
        if (element.group_id && group.id && element.group_id === group.id) {
          this.staffAssignmentList.unshift(element)
        }
      }

      if (element.space_id && this.schedule.space_id && element.space_id === this.schedule.space_id) {
        this.staffAssignmentList.unshift(element)
      }
    }

  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showDropDown = false;
    }
  }

  onClickDropDownMenu(option: string): void {
    this.onOutputAction.emit({ action: option, space: this.schedule.space });
    this.showDropDown = false;
  }

  onShowGroupDetails(event: any, i: number, option: boolean): void {
    this.groupDetails[i] = option;
    this.groupDetailsPosition = HelperService.calculatePopupPosition(event.pageX,
      event.pageY,
      400, 300,
      window.innerHeight,
      window.innerWidth,
      window.scrollY,
      window.scrollX);
  }

  onShowstaffAssgnment(event: any, option: boolean): void {
    this.staffAssgnmentHover = option;
    this.staffAssgnmentHoverPosition = HelperService.calculatePopupPosition(event.pageX,
      event.pageY,
      400, 300,
      window.innerHeight,
      window.innerWidth,
      window.scrollY,
      window.scrollX);
  }

  lightenDarkenColor(col: string, amt: number): string {
    amt = 256 - Math.floor(amt * 5.12);
    const usePound = col[0] === '#';
    if (usePound) {
      col = col.slice(1);
    }

    const num = parseInt(col, 16);

    const rr = (num >> 16) + amt;
    const bb = ((num >> 8) & 0x00FF) + amt;
    const gg = (num & 0x0000FF) + amt;

    const r = rr > 255 ? 255 : rr < 0 ? 0 : rr;
    const b = bb > 255 ? 255 : bb < 0 ? 0 : bb;
    const g = gg > 255 ? 255 : gg < 0 ? 0 : gg;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }

  hexToRgbNew(hex: any, opacity: any): string {
    if (!hex) { return; }
    let h = hex.replace('#', '');
    h = h.match(new RegExp('(.{' + h.length / 3 + '})', 'g'));

    for (let i = 0; i < h.length; i++) {
      h[i] = parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16);
    }

    if (typeof opacity !== 'undefined') { h.push(opacity); }

    return 'rgba(' + h.join(',') + ')';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
