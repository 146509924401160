export enum DropdownButtonPlacementTypeEnum {
  NONE = "",
  AUTO = "auto",
  TOP = "top",
  TOPLEFT = "top-left",
  TOPRIGHT = "top-right",
  RIGHT = "right",
  RIGHTTOP = "right-top",
  RIGHTBOTTOM = "right-bottom",
  BOTTOM = "bottom",
  BOTTOMLEFT = "bottom-left",
  BOTTOMRIGHT = "bottom-right",
  LEFT = "left",
  LEFTTOP = "left-top",
  LEFTBOTTOM = "left-bottom",
}
