import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasRoleDirective } from './has-role/has-role.directive';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { LongPressDirective } from './long-press/long-press.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { DndDirective } from './dnd/dnd.directive';
import { Autosize } from './auto-size/auto-size.directive';
import { LightBoxDirective } from './image-lightbox/image-lightbox.directive';
import { ClickStopPropagation } from './stop-propagation/stopPropagation.directive';

@NgModule({
  declarations: [
    HasRoleDirective, TooltipDirective, 
    LongPressDirective, Autosize,
    ClickOutsideDirective, DndDirective,
    LightBoxDirective, ClickStopPropagation
    ],
  exports: [
    HasRoleDirective,
    TooltipDirective,
    LongPressDirective,
    ClickOutsideDirective,
    DndDirective,
    Autosize,
    LightBoxDirective,
    ClickStopPropagation
  ],
  imports: [
    CommonModule
  ]
})
export class SharedDirectivesModule { }
