import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatService } from '@services/date-format/date-format.service';
import { TimeFormat } from '@enums';
import { CampService } from '@services/camp/camp.service'
import * as moment from 'moment';

@Pipe({ name: 'timeformatter', pure: true })
export class TimeFormatter implements PipeTransform {
    timeFormat: TimeFormat = TimeFormat.HOUR12;
    camp: any;

    constructor(private dateFormatService: DateFormatService, private campService: CampService) {
        this.dateFormatService.timeFormat$.subscribe(timeFormat => {
            this.timeFormat = timeFormat;
        });

        this.campService.campInfo$.subscribe( camp => this.camp = camp);
    }
    
    transform(time: any, isDate: boolean = false, byTimezone: boolean = false): string {
        if(byTimezone){
            time = isDate ? moment.utc(time, 'YYYY/MM/DD HH:mm').tz(this.camp.timezone.slug).format("HH:mm") : time;
        }else{
            time = isDate ? moment(time, 'YYYY/MM/DD HH:mm').format("HH:mm") : time;
        }
        if (!time || this.timeFormat === TimeFormat.HOUR24) { 
            return moment(time, 'HHmm').format("HH:mm"); 
        }
        var H = +time.substr(0, 2);
        var h = (H % 12) || 12;
        var ampm = H < 12 ? " AM" : " PM";
        return time = h + time.substr(2, 3) + ampm;
        
    }
}
