import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from '@interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'cah-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() options: any[] = [];
  @Input() displayProperty: string;
  @Input() returnProperty: string;
  @Input() bindName: string;
  @Input() type: string;
  @Input() label: string;
  @Input() placeHolder: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() value: any;
  @Input() defaultValue: any;
  @Input() error: any;
  @Input() tabindex: string;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();
  @Output() onAddGroup: EventEmitter<any> = new EventEmitter();
  
  
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  suggestionState: any;

  constructor(private languageService: LanguageService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  checkDefaultValue(){
    let defaultValue = this.options.find( x => x[this.returnProperty] === this.defaultValue);
    this.value = defaultValue;
  }

  ngOnInit() {
    if(this.defaultValue){
      this.checkDefaultValue();
    }
    console.log(this.value);
    
  }

  selectChange(event){    
    const payload:IInputValue = {
      name: this.bindName,
      value: event.id
    }
    this.value = event.title ? event.title : event.name;
    
    this.onChange.emit(payload)
    this.suggestionState = '';
  }

  onOptionAdd(event,name, action){    
    const payload = {
      name: name,
      action: action
    }
    this.onAddGroup.emit(payload);    
  }
  

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

  onFocus(input) {
    this.suggestionState = input;
  }

  isObject(val){
    return (typeof val === 'object');
  }
}
