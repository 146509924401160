import { Component, Input, OnInit } from '@angular/core';
import { CamperEnrollStatusEnum } from 'app/enums';

@Component({
  selector: 'cah-table-enrollments-details',
  templateUrl: './table-enrollments-details.component.html',
  styleUrls: ['./table-enrollments-details.component.scss']
})
export class TableEnrollmentsDetailsComponent implements OnInit {
  @Input() item: any = {};
  enrollStatusEnum = CamperEnrollStatusEnum;
  enrollType: any = {
    dropin: 'Dropin',
    range: 'Date Range',
    full: 'Full Session'
  };

  constructor() { }

  ngOnInit() {
  }

}
