import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { IInputValue, ResponseList } from "@interfaces";
import { Subject } from "rxjs";
import { LanguageService } from "@services/language/language.service";
import { takeUntil, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { TimeslotService } from "@services/timeslots/timeslot.service";

@Component({
  selector: "cah-autocomplete",
  templateUrl: "./autocomplete.component.html",
  styleUrls: ["./autocomplete.component.scss"],
})
export class AutocompleteComponent implements OnInit {
  @Input() bindName: string;
  @Input() type: string;
  @Input() label: string;
  @Input() placeHolder: string;
  @Input() helper: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() value: any;
  @Input() error: any;
  @Input() tabindex: string;
  @Input() helperText: string = "example_timeslot_label";
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();
  @ViewChild('searchInput', { static: false, read: ElementRef }) public searchInput: ElementRef<any>;
  
  
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  
  searchLabelUpdate = new Subject<string>();
  labelItemUpdate = new Subject<string>();
  newLabelValue: string = "";
  labelAlreadyExists: boolean = false;
  titleEditMode: any = {};
  isLabelSelected: any = {};
  selectedSession: any = JSON.parse(localStorage.getItem("selectedSession"));
  labelError: string = "";
  isDropDownOpen: boolean = false;
  editedLabel: string;
  defaultValueTitle: string = 'Period 1';

  public labelList: ResponseList = {
    total: 0,
    maxPages: 0,
    perPage: 0,
    currentPage: 0,
    data: [],
    meta: [],
  };

  filter: any = {
    session: null,
  };

  editedLabelValue: any = "";
  editLabelId: any = null;
  selectedOpt: any;
  error2: string;

  constructor(
    private languageService: LanguageService,
    private timeslotService: TimeslotService
  ) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isHebrew) => {
        this.isHebrew = isHebrew == "he";
      });

    this.searchLabelUpdate
      .pipe(debounceTime(400), takeUntil(this.unsubscribe$))
      .subscribe((inputText) => {
        this.filter.s = inputText.length > 1 ? inputText : "";
        delete this.filter.page;
        this.getLabels(this.filter);
        this.labelError = "";
      });

    this.labelItemUpdate
      .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe((labelNewValue) => {
        if (labelNewValue.length > 20) {
          this.labelError = "maximum_letters";
        } else {
          this.labelError = "";
          this.editedLabelValue = labelNewValue;
          this.updateLabel();
        }
      });
  }

  ngOnInit() {
    this.getLabels(this.filter);
    this.onItemSelect(this.value);
  }

  onLabelEdit(event: Event,label: string) {
    event.stopPropagation();
    Object.keys(this.titleEditMode).forEach(
      (x) => (this.titleEditMode[x] = false)
    );
    if(label){
      this.labelItemUpdate.next(label);
    }
  }
  onInputClick(){
    this.isDropDownOpen = !this.isDropDownOpen;
    if(this.isDropDownOpen){
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      });
    }else{
      this.closeDropdown();
    }
  }

  clearSearch(ev: Event){
    ev.stopPropagation();
    this.newLabelValue = '';
    this.error2 = '';
    this.onChangeLabelTitle('');
  }

  getLabels(filter) {
    this.filter.session = this.selectedSession.id;
    this.timeslotService.getTimeslotLabels(this.filter).subscribe((res) => {
      this.labelList.data = res.data;  
      if(!filter.s){
        this.selectedOpt =  this.labelList.data.filter(x => x.id == this.value)[0];   
      }  
      if (this.labelList.data.some((x) => x.title.toLowerCase() === filter.s)) {
        this.labelAlreadyExists = true;
      } else {
        this.labelAlreadyExists = false;
      }
    });
  }


  onChangeLabelTitle(val: string): void {
    this.error2 = null;
    this.searchLabelUpdate.next(val);
  }

  onItemAdd(inputValue) {
    if (inputValue.length > 20) {
      return (this.error2 = "maximum_letters");
    }
    let payload = {
      session_id: this.filter.session,
      title: inputValue,
    };
    this.timeslotService.addTimeslotLabel(payload).subscribe(
      (res) => {
        this.onChangeLabelTitle('');
        this.onItemSelect(res.id);
        this.newLabelValue = '';
        this.isDropDownOpen = false;
      },
      (err) => {
        this.error = err.message;
        console.log(err);
      }
    );
  }

  closeDropdown(){
    this.isDropDownOpen = false;
    this.titleEditMode = [];
    this.newLabelValue = '';
    this.onChangeLabelTitle('');
  }

  onActionEdit(key) {
    this.editLabelId = key;
    Object.keys(this.titleEditMode).forEach(
      (x) => (this.titleEditMode[x] = false)
    );
    this.titleEditMode[key] = true;
  }

  onActionDelete(id) {
    this.timeslotService.deleteTimeslotLabel(id).subscribe(
      (res) => {
        this.getLabels(this.filter);
        this.onItemSelect(res.id);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onItemSelect(key) {
    let payload: IInputValue = {
      name: this.bindName,
      value: key,
    };
    this.titleEditMode = [];
    let keys = Object.keys(this.isLabelSelected);
    if (this.isLabelSelected[key] == true) {
      this.isLabelSelected[key] = false;
      payload.value = null;
      this.selectedOpt = '';
    } else {
      keys.forEach((x) => (this.isLabelSelected[x] = false));
      this.isLabelSelected[key] = true;
      this.isDropDownOpen = false;
      this.selectedOpt =  this.labelList.data.filter(x => x.id == key)[0]; 
    }

    this.onChange.emit(payload);
  }

  // @HostListener("document:keydown", ["$event"])
  // onKeydownHandler(event: KeyboardEvent) {
  //   if (event.key === "Escape" || event.key === "Enter") {
  //     Object.keys(this.titleEditMode).forEach(
  //       (x) => (this.titleEditMode[x] = false)
  //     );
  //   }
  // }

  // @HostListener("document:click", ["$event"])
  // clickout(event) {
  //   if (
  //     event.target.getAttribute("class") &&
  //     !event.target.getAttribute("class").includes("title--input") &&
  //     event.target.getAttribute("class") !== "title--edit"
  //   ) {
  //     Object.keys(this.titleEditMode).forEach(
  //       (x) => (this.titleEditMode[x] = false)
  //     );
  //   }
  // }

  updateLabel() {
    let payload = {
      session_id: this.filter.session,
      title: this.editedLabelValue,
    };
    this.timeslotService.ediTimeslotLabel(this.editLabelId, payload).subscribe(
      (res) => {
        this.getLabels(this.filter);
      },
      (err) => {
        console.log(err);
        this.error = err.message;
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
