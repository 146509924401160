import { Pipe, PipeTransform } from '@angular/core';
import { CampService } from '@services/camp/camp.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'timeformat', pure: true })
export class TimeFormat implements PipeTransform {
    
    constructor(private campService: CampService) { }
    
    transform(time: any): Observable<any> {
        
        return this.campService.campInfo$.pipe(map(camp => {
            if (!time) return of('');
            return moment(time).format(camp.time_format === 24 ? 'HH:mm' : 'hh:mm A');
        })); 
    }
}
