import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import * as moment from 'moment-timezone';
import {IInputValue} from '@interfaces';
import { CampService } from '@services/camp/camp.service';
import { CALENDER_CONFIG } from '@util/calendar-config/calendar-config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';

@Component({
  selector: 'cah-schedule-calendar',
  templateUrl: './schedule-calendar.component.html',
  styleUrls: ['./schedule-calendar.component.scss']
})
export class ScheduleCalendarComponent implements OnInit, OnDestroy, OnChanges {

  @Input() days;
  @Output() outAction: EventEmitter<IInputValue> = new EventEmitter<IInputValue>();
  calendIsOpen: boolean = false;
  calendarSelectedDay: any;
  calendarConfig: any = CALENDER_CONFIG['en'];
  moment = moment;
  disabledDates: any[] = [];

  private unsubscribe$ = new Subject<any>();
  
  constructor(public campService: CampService, private dateFormatterService: DateFormatterService) { 
    campService.campInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(camp => {
        if (!camp) return;
        this.calendarConfig = CALENDER_CONFIG[camp.language.code]
        this.calendarConfig.firstDayOfWeek = camp.week_start === 'su' ? 0 : 1;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.days){
      this.onMonthChange(
        {
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear()
        }
      )
    }
  }

  ngOnInit() { }

  onDaySelect(date: any) {
    this.onAction('select-day', date);
  }
  onAction(name: string, value: any): void {
    const payload: IInputValue = {
      name,
      value
    };

    this.outAction.emit(payload);
  }
  
  getDayClasses(date) {
    let classesToReturn = ''
    const dateString = `${date.year.toString()}-${('00' + (date.month + 1).toString()).substr(-2)}-${('00' + date.day.toString()).substr(-2)}`;
    const diffDays: number = moment(0,"HH").diff(moment(date), "days");
    classesToReturn += this.days.indexOf(dateString) > -1 ? ' calendar-meetings' : '';
    classesToReturn += diffDays === 0 ? ' calendar-today' : '';
    return classesToReturn;
  }

  onMonthChange(e){
    this.disabledDates = this.dateFormatterService.getDisabledDays(e.month-1, e.year, this.days);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
