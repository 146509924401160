import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from 'app/models/menu.item';
import {MainMenu} from '../nav';

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {
  public mainMenu = new BehaviorSubject<MenuItem[]>(MainMenu);

  constructor(
  ) {

  }

  setProfileImage(img: string) {
    let menu = this.mainMenu.getValue();
    menu.forEach(item => {
      if (item.title === 'profile') {
        item.image.url = img;
      }
    });
    this.mainMenu.next(menu);
  }

  // addBadge(key, badge){
  //   let menu = this.mainMenu.getValue();
  //   menu.forEach(item => {
  //     if (item.title === key) {
  //       item.badge = badge;
  //     }
  //   });
  //   this.mainMenu.next(menu);
  // }
}
