import { Injectable } from '@angular/core';
import { Scripts } from '@interfaces';

declare var document: any;

@Injectable()
export class ScriptService {
    ScriptStore: Scripts[] = [
        // { name: 'reportBug', src: 'https://widget.usersnap.com/load/1a9f87d3-15c1-4ced-ac8f-f27aa1ba8fd7' },
        { name: 'freshworks', src: 'https://widget.freshworks.com/widgets/64000000549.js' },
        { name: 'chatBot', src: 'https://cdn.chatbot.com/widget/plugin.js' },
        { name: 'ckeEditor', src: 'https://cdn.ckeditor.com/4.9.2/full-all/ckeditor.js' },
        { name: 'userback', src: 'https://static.userback.io/widget/v1.js' },
    ];

    private scripts: any = {};

    constructor() {
        this.ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
    }

    load(...scripts: string[]) {
        const promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    
    loadScript(name: string) {
        return new Promise((resolve, reject) => {
            // resolve if already loaded
            if (this.scripts[name].loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            } else {
                // load script
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                script.async = true;
                if (script.readyState) {  // IE
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {  // Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }

    remove(...scripts: string[]) {
        const promises: any[] = [];
        scripts.forEach((script) => { promises.push(this.removeScript(script)) });
        return Promise.all(promises);
    }

    removeScript(name: string) {
        return new Promise((resolve, reject) => {
            // resolve if already loaded
            if (this.scripts[name].loaded) {
                this.scripts[name].loaded = false;
                var head = document.getElementsByTagName('head')[0];
                var headElemArr = Array.from(head.childNodes);

                for (let index = 0; index < headElemArr.length; index++) {
                    const element: any = headElemArr[index];
                    if (element.src == this.scripts[name].src) {
                        element.parentNode.removeChild(element);
                    }
                }

                var chatBotElem = document.getElementById('chatbot-chat');
                chatBotElem.parentNode.removeChild(chatBotElem);


                resolve({ script: name, loaded: false, status: 'Removed Succsessfully' });
            } else {
                resolve({ script: name, loaded: false, status: 'Script not exists' });
            }
        });
    }


}