import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-listing-add-button',
  templateUrl: './listing-add-button.component.html',
  styleUrls: ['./listing-add-button.component.scss']
})
export class ListingAddButtonComponent implements OnInit {
  @Input() label: string = "Add";
  @Input() disabled: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter;
  
  
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false

  constructor(public languageService: LanguageService,) {         
      languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });
  }

  ngOnInit() {
  }

  click(event){
    if(!this.disabled){
      this.onClick.emit("clicked");
    }
  }

  
  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
