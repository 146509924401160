import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from '@interfaces';
@Component({
  selector: 'cah-form-range',
  templateUrl: './form-range.component.html',
  styleUrls: ['./form-range.component.scss']
})
export class FormRangeComponent implements OnInit {
  @Input() maxValue: number;
  @Input() minValue: number;
  @Input() showCheckbox: boolean = false;
  @Input() showSlider: boolean = false;
  @Input() name: string;
  @Input() name1: string;
  @Input() disabled: boolean;
  @Input() disabled1: boolean;
  @Input() bindName1: string;
  @Input() bindName2: string;
  @Input() error: any;
  @Input() value1: any = 0;
  @Input() value2: any = 0;
  @Input() label: string;
  @Input() label1: string;
  @Input() label2: string;
  @Input() isRequired: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  range: number[] = [
    0,
    5
  ]

  constructor() { }

  ngOnInit() {
    this.range[0] = this.value1 || this.minValue;
    this.range[1] = this.value2 || this.maxValue;
  }

  change(event, input){
    if(input === 0){
      this.value1 = event.value || null;
      this.range = [this.value1 ? this.value1 : this.minValue, this.range[1]];
      this.createPayloadAndEmit(event.name, this.value1);
    }else if(input === 1){
      this.value2 = event.value || null;
      this.range = [this.range[0], this.value2 ? this.value2 : this.maxValue];
      this.createPayloadAndEmit(event.name, this.value2);
    }else{
      this.value1 = event.values[0];
      this.value2 = event.values[1];
      this.createPayloadAndEmit(this.bindName1, this.value1);
      this.createPayloadAndEmit(this.bindName2, this.value2);
    }
  }

  onCheck(evt: any, checkbox: number): void {
    checkbox === 1 ? this.disabled1 = evt.value : this.disabled = evt.value;
    this.createPayloadAndEmit(evt.name, evt.value);
  }

  createPayloadAndEmit(bindname, value){
    const payload: IInputValue = {
      name: bindname,
      value: value
    }
    this.onChange.emit(payload);
  }
}
