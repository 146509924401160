import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {UserService} from '@services/user/user.service';
import {Router} from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    public authService: UserService
  ) {}


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem('token');

    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token)
      });
    }

    // todo_'refresh a token st'
    return next.handle(request).pipe(
      catchError(err => {
        if (err && err.status === 401) {
          this.authService.logout();
        } else if (err && err.status === 403) {
          // forbidden
        }
        console.log(err);
        return throwError(err);
      }),
      map((event: any) => {
        return event;
      })
    );
  }
}
