import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, SimpleChanges } from '@angular/core';
import { HelpQuestionMarkType } from '@enums';

@Component({
  selector: 'cah-elective-radio',
  templateUrl: './elective-radio.component.html',
  styleUrls: ['./elective-radio.component.scss']
})
export class ElectiveRadioComponent implements OnInit {
  @ViewChild('radioElement', { static: true }) radioElement: ElementRef;

  // set attributes
  @Input() set options(att) {
    Object.keys(att).forEach(key => {
      this.radioElement.nativeElement.setAttribute(key, att[key]);
    });
  }
  
  @Input() radioValue = '';
  @Input() checkBoxLabel = '';
  @Input() checkBoxName = '';
  @Input() checkBoxChecked: boolean = false;
  @Input() radioChecked: boolean = false;
  @Input() disabled: boolean = false;

  @Input() helperType: number = HelpQuestionMarkType.INFO;
  @Input() helperRadioLableText: string = '';
  @Input() helperCheckboxLableText: string = '';
  @Input() helperleftDirection: boolean = false;
  @Input() isHebrew: boolean = false;

  @Output() onRadioOutPutValue = new EventEmitter();
  @Output() onCheckBoxOutPutValue = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onRadioChange(inputValue: any): void {
    const options = {
      value: this.radioElement.nativeElement.value,
      name: this.radioElement.nativeElement.name
    }
    this.onRadioOutPutValue.emit(options);
  }

  onCheckBoxChange(value: any): void {
    this.onCheckBoxOutPutValue.emit(value);
  }
}
