import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-import-enrollment-modal',
  templateUrl: './import-enrollment-modal.component.html',
  styleUrls: ['./import-enrollment-modal.component.scss']
})
export class ImportEnrollmentModalComponent implements OnInit {
  @Input() campers: any[] = [];
  @Input() group: any;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  @Output() onConfirm: EventEmitter<boolean> = new EventEmitter();

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(public languageService: LanguageService
  ) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });
  }
  
  ngOnInit() {
  }
}
