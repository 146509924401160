import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cah-option-campers-hover-box',
  templateUrl: './option-campers-hover-box.component.html',
  styleUrls: ['./option-campers-hover-box.component.scss']
})
export class OptionCampersHoverBoxComponent implements OnInit {
  @Input() campers: any[] = [];
  @Input() isHebrew: boolean = false;
  @Input() assignedCampersPosition: any = {
    vertical: 'bottom',
    horizontal: 'right'
  };

  constructor() { }

  ngOnInit() {
  }

}
