import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { IPlanBtns } from 'app/interfaces/plan-btns.interface';

@Component({
  selector: 'cah-modal-plan',
  templateUrl: './modal-plan.component.html',
  styleUrls: ['./modal-plan.component.scss']
})
export class ModalPlanComponent implements OnInit {
 
  counter: number = 0;

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfirmCheckbox: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPlanBtnClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSubmitModal: EventEmitter<any> = new EventEmitter<any>();

  @Input() hostsCounter: string = ""; //add || remove
  @Input() hostsCounterPrice: string = "$0.00/month";

  @Input() width: string = "764px";

  @Input() flag: string = ""; //blue-flag || red-flag
  @Input() mainTitle: string = "";
  @Input() mainSubTitle: string = ""; // Pro 15 - $125.99/month
  @Input() preBtnText: any = {
                  // text1: "total_of",
                  // text2: "$57.61"
                }
  @Input() submitBtn: any = {
                  disabled: false,
                  text: "upgrade_now"
                }

  @Input() buttons: any = [
    // { 
    //   name : 'upgrade_now',
    //   active: true
    // },
    // { 
    //   name : 'upgrade_on_next_renewal',
    //   active: false
    // }
  ];

  @Input() packeges: any = [
    // {
    //   size: "",
    //   price: "",
    //   current: false,
    //   active: false,
    // },
    // {
    //   size: '500GB',
    //   price: "$40 / month",
    //   current: true,
    //   active: false,
    // },
    // {
    //   size: '3TB',
    //   price: "$40 / month",
    //   current: false,
    //   active: true,
    // },

]

  @Input() contentBoxes: IPlanBtns = 
    { 
      contentMainTitle : 'changes_that_require',
      boxes: [
        {
          title: 'host_licenses',
          text: 'currently_have_hosts',
          box: false,
          icon: false
        },
        {
          text: 'by_proceeding',
          box: true,
          icon: true
        },
        {
          text: 'currently_have_hosts',
          title: "changes_that_require",
          box: true,
          icon: false,
          total: "$55"
        },
        {
          confirmText: 'have_read_info',
          status: false
        },
    ]
    };
    confirm: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  closeModal() {
    this.onClose.emit(false);
  }

  onCheck(){
    this.confirm = !this.confirm;
    let payload = {
      name: 'check',
      value: this.confirm
    }
    this.onConfirmCheckbox.emit(payload);
  }

  onChoosePack(pack){
    this.packeges.map(pck => JSON.stringify(pck) === JSON.stringify(pack) ? pck.active = true :  pck.active = false);
    this.onPlanBtnClick.emit({name: "pack", value: pack});
  }

  onSubmit(e){
   this.onSubmitModal.emit(true);
  }

  onBtnClick(name: string){
    this.buttons.map(btn => btn.name == name ? btn.active = true :  btn.active = false);
    this.onPlanBtnClick.emit({name: name, value: true});
  }

}
