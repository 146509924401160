import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IBreadCrumb } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  breadcrumbs: IBreadCrumb[] | null = null;

  private _breadcrumbs = new BehaviorSubject<IBreadCrumb[]>(this.breadcrumbs);
  breadcrumbs$ = this._breadcrumbs.asObservable();

  setBreadcrumb(breadcrumbs: IBreadCrumb[]) {
      this.breadcrumbs = breadcrumbs;
      this._breadcrumbs.next(this.breadcrumbs);
  }

}