export const PickerColors: any =
    {
        '#ff3e00': {
            name: "vermilion",
            key: "vermilion",
            f10: '#ffe6de',
            f50: "#ff9f7f",
            f100: "#ff3e00"
        },
        '#ff6700' : {
            name: "blaze orange",
            key: "blaze_orange",
            f10: '#fff3eb',
            f50: "#ffb37f",
            f100: "#ff6700"
        },
        '#f09f3f' : {
            name: "jaffa",
            key: "jaffa",
            f10: '#fef5ec',
            f50: "#f8cf9f",
            f100: "#f09f3f"
        },
        '#ffc653' : {
            name: "golden tainoi",
            key: "golden_tainoi",
            f10: '#fff8e9',
            f50: "#ffe3a9",
            f100: "#ffc653"
        },
        '#dfea73' : {
            name: "manz",
            key: "manz",
            f10: '#fbfced',
            f50: "#eff5b9",
            f100: "#dfea73"
        },
        '#a4db63' : {
            name: "conifer",
            key: "conifer",
            f10: '#f3faeb',
            f50: "#d2edb1",
            f100: "#a4db63"
        },
        '#5dd052' : {
            name: "emerald",
            key: "emerald",
            f10: '#eaf9e9',
            f50: "#aee8a9",
            f100: "#5dd052"
        },
        '#79deb9' : {
            name: "bermuda",
            key: "bermuda",
            f10: '#f5fdfa',
            f50: "#bcefdc",
            f100: "#79deb9"
        },
        '#00edd8' : {
            name: "bright turquoise",
            key: "bright_turquoise",
            f10: '#ebfefc',
            f50: "#7ff6ec",
            f100: "#00edd8"
        },
        '#1e84f0' : {
            name: "dodger blue",
            key: "dodger_blue",
            f10: '#edf6fe',
            f50: "#8fc2f8",
            f100: "#1e84f0"
        },
        '#5348c7' : {
            name: "indigo",
            key: "indigo",
            f10: '#f2f1fb',
            f50: "#a9a4e3",
            f100: "#5348c7"
        },
        '#a08eff' : {
            name: "melrose",
            key: "melrose",
            f10: '#f8f6ff',
            f50: "#d0c7ff",
            f100: "#a08eff"
        },
        '#9c54ff' : {
            name: "heliotrope",
            key: "heliotrope",
            f10: '#f8f3ff',
            f50: "#ceaaff",
            f100: "#9c54ff"
        },
        '#ef81ff' : {
            name: "heliotrope light",
            key: "heliotrope_light",
            f10: '#fef6ff',
            f50: "#f7c0ff",
            f100:"#ef81ff"
        },
        '#ff7bbe' : {
            name: "light pink",
            key: "light_pink",
            f10: '#fff6fa',
            f50: "#ffbddf",
            f100:"#ff7bbe"
        },
        '#b82c6f' : {
            name: "hibiscus",
            key: "hibiscus",
            f10: '#faf0f5',
            f50: "#dc96b7",
            f100:"#b82c6f"
        }
    };