import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { HelperService } from '@services/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(
    private http: HttpClient
  ) {
  }

  getAllStaff(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + 'users?' + queryString)
      .pipe(take(1),
        catchError(e => {
          return of({status: false, message: 'error on fetching'});
        })
      );
  }

  getStaff(id: number, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `users/user/${id}?${queryString}`)
      .pipe(take(1),
        catchError(e => {
          return of({status: false, message: 'error on fetching'});
        })
      );
  }

  addStaff(payload): Observable<any> {
    return this.http
      .post(environment.endpoint + 'users/staff', payload)
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

  resendActivationStaff(payload: any): Observable<any> {
    return this.http
      .post(environment.endpoint + 'users/user/activation/resend', payload)
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

  reactivateStaff(id: number): Observable<any> {
    return this.http
      .patch(environment.endpoint + 'users/staff/'+id+'/reactivate','')
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

  suspendStaff(id: number): Observable<any> {
    return this.http
      .patch(environment.endpoint + 'users/staff/'+id+'/suspend','')
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

  deleteStaffMember(id: any): Observable<any> {
    return this.http
      .delete(environment.endpoint + 'users/staff/' + id)
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

  updateStaff(id, payload): Observable<any>{
    return this.http.patch(environment.endpoint + "users/staff/" + id, payload)
    .pipe(
      catchError( error => {
        return throwError(error.error || "server error")
      })
    );
  }

  importStaff(payload: any, params: any = {}) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');

    const queryString = HelperService.generateQueryParams(params);

    return this.http.post(environment.endpoint + `users/staff/import/excel?${queryString}`, payload)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error || "server error")
          })
        )
  }

  getStaffBySession(id: number, filter: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(filter);
    return this.http.get(environment.endpoint + `sessions/session/${id}/staff?` + queryString)
      .pipe(take(1),
        catchError(e => {
          return of({status: false, message: 'error on fetching'});
        })
      );
  }

  addStaffToSession(id: number, payload: any): Observable<any> {
    return this.http.patch(environment.endpoint + `sessions/session/${id}/staff`, payload )
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

  deleteStaffFromSession(id: number, staffId: number): Observable<any> {
    return this.http
      .delete(environment.endpoint + `sessions/session/${id}/${staffId}`)
      .pipe(
        catchError( error => {
          return throwError(error.error || "server error")
        })
      );
  }

}
