import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from './form-group/input/input.component';
import { NgSelectComponent } from './form-group/ngselect/ngselect.component';
import { SelectComponent } from './form-group/select/select.component';
import { ButtonFormComponent } from './form-group/button/button.component';
import { TextareaComponent } from './form-group/textarea/textarea.component';
import { TabsComponent } from './tabs/tabs.component';
import { ModalComponent } from './modal/modal.component';
import { FormGeneratorComponent } from './form-generator/form-generator.component';
import { FormValidatorService } from '@util/form-validator/form-validator.service';
import { LoaderComponent } from './loader/loader.component';
import { RouterModule } from '@angular/router';
import { TableComponent } from './table/table.component';
import { ListingAddButtonComponent } from './listing-add-button/listing-add-button.component';
import { CheckboxFormComponent } from './form-group/checkbox-form/checkbox-form.component';
import { ColumnSelectComponent } from './column-select/column-select.component';
import { ListingSearchInputComponent } from './listing-search-input/listing-search-input.component';
import { ListingActionButtonComponent } from './listing-action-button/listing-action-button.component';
import { TimeslotDayComponent } from './timeslot-day/timeslot-day.component';
import { SimpleboxComponent } from './simplebox/index';
import { RowStatusComponent } from './row-status/row-status.component';
import { DatepickerComponent } from './form-group/datepicker/datepicker.component';
import { CalendarModule } from 'primeng/calendar';
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';
import { BoxListsComponent } from '@shared/box-lists/box-lists.component';
import { DatepickerRangeComponent } from './datepicker-range/datepicker-range.component';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import {
  MatButtonModule, MatDatepickerModule,
  MatFormFieldModule, MatInputModule, MatNativeDateModule
} from '@angular/material';
import { SelectDropdownSessionComponent } from './select-dropdown-session/select-dropdown-session.component';
import { ColorPickerComponent } from './form-group/color-picker/color-picker.component';
import { ColorCircleComponent } from './color-circle/color-circle.component';
import { SelectDropdownDayComponent } from './select-dropdown-day/select-dropdown-day.component';
import { TimeComponent } from './form-group/time/time.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/index';
import { ImportFileModalComponent } from './import-file-modal/import-file-modal.component';
import { AlertDialogComponent } from './alert-dialog/index';
import { SharedPipesModule } from '@pipes';
import { TableSwitchComponent } from './table-switch/table-switch.component';
import { EmptyListComponent } from './empty-list/empty-list.component';
import { BoardHeaderComponent } from './scheduling-board/board-header/board-header.component';
import { BoardComponent } from './scheduling-board/board/board.component';
import { BoardBoxComponent } from './scheduling-board/board-box/board-box.component';
import { WarningSplashComponent } from './splash-screens/warning-splash/warning-splash.component';
import { ErrorSplashComponent } from './splash-screens/error-splash/error-splash.component';
import { ActivitySplashComponent } from './splash-screens/activity-splash/activity-splash.component';
import { SecondsBoxComponent } from './splash-screens/activity-splash/seconds-box/seconds-box.component';
import { HourBoxComponent } from './splash-screens/activity-splash/hour-box/hour-box.component';
import { DayBoxComponent } from './splash-screens/activity-splash/day-box/day-box.component';
import { TomorrowBoxComponent } from './splash-screens/activity-splash/tomorrow-box/tomorrow-box.component';
import { SharedDirectivesModule } from '@directives';
import { LiveBoardComponent } from './scheduling-board/live-board/live-board.component';
import { LiveBoardBoxComponent } from './scheduling-board/live-board-box/live-board-box.component';
import { GroupHoverBoxComponent } from './scheduling-board/group-hover-box/group-hover-box.component';
import { GuestSplashComponent } from './splash-screens/guest-splash/guest-splash.component';
import { ModalCreditsComponent } from './modal-credits/modal-credits.component';
import { ToggleComponent } from './form-group/toggle/toggle.component';
import { HelpQuestionMarkComponent } from './help-question-mark/help-question-mark.component';
import { ModalPurchaseDetailsComponent } from './modal-purchase-details/modal-purchase-details.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ArchiveStatusComponent } from './archive-status/archive-status.component';
import { AddEectiveModalComponent } from './add-elective-modal/add-elective-modal.component';
import { ElectiveRadioComponent } from './elective-radio/elective-radio.component';
import { BoardElectiveBoxComponent } from './scheduling-board/board-elective-box/board-elective-box.component';
import { ElectiveOptionBoxComponent } from './elective-option-box/elective-option-box.component';
import { ElectiveSplashComponent } from './splash-screens/elective-splash/elective-splash/elective-splash.component';
import { ActivityBoxSplashComponent } from './activity-box-splash/activity-box-splash.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LiveBoardBoxComponentElective } from './scheduling-board/live-board-box-elective/live-board-box-elective.component';
import { OptionCampersHoverBoxComponent } from './scheduling-board/option-campers-hover-box/option-campers-hover-box.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AutocompleteComponent } from './form-group/autocomplete/autocomplete.component';
import { StaffBoardComponent } from './scheduling-board/staff-board/staff-board.component';
import { BoardStaffBoxComponent } from './scheduling-board/board-staff-box/board-staff-box.component';
import { AddStaffModalComponent } from './scheduling-board/add-staff-modal/add-staff-modal.component';
import { QuickStaffAssignmentsModalComponent } from './scheduling-board/quick-staff-assignments-modal/quick-staff-assignments-modal.component';
import { StaffAssignmentHoverBoxComponent } from './scheduling-board/staff-assignment-hover-box/staff-assignment-hover-box.component';
import { TabHeaderButtonComponent } from './tab-header-button/tab-header-button.component';
import { ImportFileDndModalComponent } from './import-file-dnd-modal/import-file-dnd-modal.component';
import { InlineImageUploadComponent, InlineInputComponent, InlineSelectComponent } from './inline-form-group/index';
import { LabelComponent } from './form-group/label/label.component';
import { InstructionsComponent } from './form-group/instructions/instructions.component';
import { ListComponent } from './form-group/list/list.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { ListingAddMultipleButtonComponent } from './listing-add-multiple-button/listing-add-multiple-button.component';
import { BoardContentBoardBoxComponent } from './scheduling-board/board-content-board-box/board-content-board-box.component';
import { ShowHideButtonComponent } from './show-hide-button/showhide-button.component';
import { BoardModalComponent } from './board-modal/board-modal.component';
import { LiveContentBoardBoxComponent } from './scheduling-board/live-board-content-box/live-board-content-box.component';
import { UploadCroppedImageComponent } from './upload-cropped-image/upload-cropped-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PasswordComponent } from './password/password.component';
import { CheckboxModule } from './form-group';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormElementModule } from './form-elements/form-element.module';
import { MultiSelectComponent } from '@shared/multi-select/multi-select.component';
import { ChipModule } from './chip';
import { LoadingComponent } from './loading/loading.component';
import { DateListComponent } from './date-list/date-list.component';
import { SessionPickerComponent } from './session-picker/session-picker.component';
import { AvatarModule } from 'ngx-avatar';
import { AvatarModule as Avatar } from './avatar';
import { SharedDropdownComponent } from './shared-dropdown/shared-dropdown.component';
import { GroupsIndicatorModule } from '@shared/groups-indicator';
import { GroupDetailModule } from './group-detail/group-detail.module';
import { NestedValuePipe } from 'app/shared-pipes/nested-value/nested-value.pipe';
import { CollapsedInputComponent } from './collapsed-input/collapsed-input.component';
import { FormStepsComponent } from './form-steps/form-steps.component';
import { DropdownBoxComponent } from './dropdown-box/dropdown-box.component';
import { CircleCountdownComponent } from './circle-countdown/circle-countdown.component';
import { ModalPlanComponent } from './modal-plan/modal-plan.component';
import { CounterComponent } from './counter/counter.component';
import { NgxEventHandlerModule } from 'ngx-event-handler';
import { BadgeComponent } from './badge/badge.component';
import { TimeslotStatusComponent } from './timeslot-status/timeslot-status.component';
import { ImportEnrollmentModalComponent } from './import-enrollment-modal/import-enrollment-modal.component';
import { FormInputModule } from './form-components/form-input/form-input.module';
import { FormSelectComponent } from './form-components/form-select/form-select.component'
import { FormCheckboxComponent } from './form-components/form-checkbox/form-checkbox.component';
import { FormRadioModule } from './form-components/form-radio/form-radio-module';
import { FormRangeComponent } from './form-components/form-range/form-range.component';
import { FormDecimalModule } from './form-components/form-decimal/form-decimal.module';
import { SliderModule } from 'primeng/slider';
import { FormAgeLimitComponent } from './form-components/form-age-limit/form-age-limit.component';
import { DateTimeListingComponent } from './date-time-listing/date-time-listing.component';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { TablePopupComponent } from './table-popup/table-popup.component';
import { ProgramSettingsModalComponent } from './program-settings-modal/program-settings-modal.component';
import { ProgBasicDetailsComponent } from './program-settings-modal/prog-basic-details/prog-basic-details.component';
import { ProgParticipantExpComponent } from './program-settings-modal/prog-participant-exp/prog-participant-exp.component';
import { ProgWpageSettingsComponent } from './program-settings-modal/prog-wpage-settings/prog-wpage-settings.component';
import { FormDatePickerModule } from './form-components/form-datepicker/form-datepicker-module';
import { FormCheckboxFieldComponent } from './form-components/form-checkbox-field/form-checkbox-field.component';
import { FormWarningComponent } from './form-components/form-warning/form-warning.component';
import { FormDropdownInputModule } from './form-components/form-dropwon-input/form-dropwon-input-module';
import { TableEnrollmentsDetailsModule } from './table-enrollments-details/table-enrollments-details.module';
import { UpcomingMeetingsComponent } from './splash-screens/activity-splash/upcoming-meetings/upcoming-meetings.component';
import { UpcomingMeetingsSectionComponent } from './splash-screens/activity-splash/upcoming-meetings/upcoming-meetings-section/upcoming-meetings-section.component';
import { RecordingSectionComponent } from './splash-screens/activity-splash/upcoming-meetings/recording-section/recording-section.component';
import { TagsManagerComponent } from './tags-manager/tags-manager.component';
import { ReassignEnrollmentModalComponent } from './reassign-enrollment-modal/reassign-enrollment-modal.component';
import { SelectSessionDropdownComponent } from './reassign-enrollment-modal/select-session-dropdown/select-session-dropdown.component';
import { FormTextareaComponent } from './form-components/form-textarea/form-textarea.component';
import { FormTimeComponent } from './form-components/form-time/form-time.component';
import {DisplayButtonComponent} from '@shared/display-button/display-button.component';
import {DisplayButtonFilterComponent} from '@shared/display-button-filter/display-button-filter.component';
import { ScheduleCalendarComponent } from './schedule-calendar/schedule-calendar.component';
import { InlineColorPickerComponent } from './inline-form-group/inline-color-picker/inline-color-picker.component';
import { CatalogSettingsComponent } from './program-settings-modal/catalog-settings/catalog-settings.component';
@NgModule({
  declarations: [
    FormRangeComponent,
    MultiSelectComponent,
    InputComponent,
    SelectComponent,
    NgSelectComponent,
    ButtonFormComponent,
    TextareaComponent,
    TabsComponent,
    ModalComponent,
    FormGeneratorComponent,
    LoaderComponent,
    TableComponent,
    ListingAddButtonComponent,
    CheckboxFormComponent,
    ColumnSelectComponent,
    ListingSearchInputComponent,
    TimeslotDayComponent,
    ListingActionButtonComponent,
    SimpleboxComponent,
    RowStatusComponent,
    DatepickerComponent,
    BoxListsComponent,
    SelectDropdownSessionComponent,
    ColorPickerComponent,
    ColorCircleComponent,
    SelectDropdownDayComponent,
    DatepickerRangeComponent,
    TimeComponent,
    PaginationComponent,
    ConfirmationDialogComponent,
    ImportFileModalComponent,
    AlertDialogComponent,
    TableSwitchComponent,
    EmptyListComponent,
    BoardHeaderComponent,
    BoardComponent,
    BoardBoxComponent,
    WarningSplashComponent,
    ErrorSplashComponent,
    ActivitySplashComponent,
    SecondsBoxComponent,
    HourBoxComponent,
    DayBoxComponent,
    TomorrowBoxComponent,
    LiveBoardComponent,
    LiveBoardBoxComponent,
    GroupHoverBoxComponent,
    GuestSplashComponent,
    ToggleComponent,
    HelpQuestionMarkComponent,
    ModalCreditsComponent,
    ToggleComponent,
    ModalPurchaseDetailsComponent,
    TableFilterComponent,
    ArchiveStatusComponent,
    AddEectiveModalComponent,
    ElectiveRadioComponent,
    BoardElectiveBoxComponent,
    ElectiveOptionBoxComponent,
    ElectiveSplashComponent,
    ActivityBoxSplashComponent,
    LiveBoardBoxComponentElective,
    OptionCampersHoverBoxComponent,
    AutocompleteComponent,
    StaffBoardComponent,
    BoardStaffBoxComponent,
    AddStaffModalComponent,
    QuickStaffAssignmentsModalComponent,
    StaffAssignmentHoverBoxComponent,
    TabHeaderButtonComponent,
    InlineImageUploadComponent,
    InlineInputComponent,
    InlineSelectComponent, LabelComponent, InstructionsComponent, ListComponent,
    ImportFileDndModalComponent,
    ListingAddMultipleButtonComponent,
    InlineSelectComponent,
    InlineSelectComponent,
    ImportFileDndModalComponent,
    BoardContentBoardBoxComponent,
    ImportFileDndModalComponent,
    ShowHideButtonComponent,
    LiveContentBoardBoxComponent,
    BoardModalComponent,
    UploadCroppedImageComponent,
    PasswordComponent,
    LoadingComponent,
    DateListComponent,
    SessionPickerComponent,
    SharedDropdownComponent,
    CollapsedInputComponent,
    FormStepsComponent,
    DropdownBoxComponent,
    CircleCountdownComponent,
    ModalPlanComponent,
    CounterComponent,
    BadgeComponent,
    TimeslotStatusComponent,
    ImportEnrollmentModalComponent,
    FormSelectComponent, FormCheckboxComponent, FormAgeLimitComponent, DateTimeListingComponent, TablePopupComponent,
    ProgramSettingsModalComponent, ProgBasicDetailsComponent, ProgParticipantExpComponent,
    ProgWpageSettingsComponent, FormCheckboxFieldComponent, FormWarningComponent, UpcomingMeetingsComponent, UpcomingMeetingsSectionComponent, RecordingSectionComponent,
    TagsManagerComponent,
    ReassignEnrollmentModalComponent,
    SelectSessionDropdownComponent,
    FormTextareaComponent,
    FormTimeComponent, DisplayButtonComponent, DisplayButtonComponent, DisplayButtonFilterComponent, ScheduleCalendarComponent, InlineColorPickerComponent, CatalogSettingsComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    CalendarModule, DragDropModule,
    MatDatepickerModule, SharedPipesModule, SharedDirectivesModule,
    MatNativeDateModule, MatFormFieldModule, MatInputModule,
    CKEditorModule,
    MatButtonModule, SatDatepickerModule, SatNativeDateModule, NgSelectModule,
    ReactiveFormsModule, NgxMaterialTimepickerModule,
    ImageCropperModule, CheckboxModule,
    FormElementModule,
    NgbModule,
    ChipModule,
    AvatarModule,
    Avatar,
    GroupsIndicatorModule,
    GroupDetailModule,
    NgxEventHandlerModule,
    SliderModule,
    ColorPickerModule,
    FormRadioModule,
    FormInputModule,
    FormDecimalModule,
    FormDatePickerModule,
    FormDropdownInputModule,
    TableEnrollmentsDetailsModule
  ],
    exports: [
        PasswordComponent,
        UploadCroppedImageComponent,
        InputComponent,
        SelectComponent,
        NgSelectComponent,
        ButtonFormComponent,
        TextareaComponent,
        TabsComponent,
        ModalComponent,
        FormGeneratorComponent,
        TabsComponent,
        TableComponent,
        ListingAddButtonComponent,
        ListingAddMultipleButtonComponent,
        ColumnSelectComponent,
        CheckboxFormComponent,
        ToggleComponent,
        DatepickerComponent,
        ListingSearchInputComponent,
        ListingActionButtonComponent,
        TimeslotDayComponent,
        SimpleboxComponent,
        RowStatusComponent,
        BoxListsComponent,
        SelectDropdownSessionComponent,
        ColorPickerComponent,
        ColorCircleComponent,
        DatepickerRangeComponent,
        SelectDropdownDayComponent,
        ConfirmationDialogComponent,
        AlertDialogComponent,
        PaginationComponent,
        ImportFileModalComponent,
        AlertDialogComponent,
        TableSwitchComponent,
        LoaderComponent,
        EmptyListComponent,
        BoardHeaderComponent,
        BoardComponent,
        BoardBoxComponent,
        WarningSplashComponent,
        ErrorSplashComponent,
        ActivitySplashComponent,
        LiveBoardComponent,
        GuestSplashComponent,
        HelpQuestionMarkComponent,
        ModalCreditsComponent,
        ModalPurchaseDetailsComponent,
        TableFilterComponent,
        NgSelectModule,
        AddEectiveModalComponent,
        ElectiveRadioComponent,
        ElectiveOptionBoxComponent,
        ElectiveSplashComponent,
        LiveBoardBoxComponentElective,
        StaffBoardComponent,
        BoardStaffBoxComponent,
        AddStaffModalComponent,
        QuickStaffAssignmentsModalComponent,
        TabHeaderButtonComponent,
        InlineImageUploadComponent,
        InlineInputComponent,
        InlineSelectComponent,
        ImportFileDndModalComponent,
        BoardContentBoardBoxComponent,
        LiveContentBoardBoxComponent,
        ShowHideButtonComponent,
        BoardModalComponent,
        MultiSelectComponent,
        LoadingComponent,
        DateListComponent,
        SessionPickerComponent,
        TimeComponent,
        SharedDropdownComponent,
        FormStepsComponent,
        ModalPlanComponent,
        DropdownBoxComponent,
        CounterComponent,
        BadgeComponent,
        TimeslotStatusComponent,
        ImportEnrollmentModalComponent,
        ListComponent,
        FormRangeComponent,
        FormSelectComponent,
        FormAgeLimitComponent,
        DateTimeListingComponent,
        TablePopupComponent,
        ProgramSettingsModalComponent,
        ProgBasicDetailsComponent,
        ProgParticipantExpComponent,
        ProgWpageSettingsComponent,
        FormCheckboxComponent,
        TagsManagerComponent,
        ReassignEnrollmentModalComponent,
        SelectSessionDropdownComponent,
        FormTextareaComponent,
        FormTimeComponent,
        DisplayButtonComponent,
        DisplayButtonComponent,
        DisplayButtonFilterComponent,
        ScheduleCalendarComponent,
        InlineColorPickerComponent,
        CatalogSettingsComponent
    ],
  providers: [
    FormValidatorService,
    DateFormatterService,
    NestedValuePipe
  ]
})
export class SharedModule { }
