import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cah-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input() showImage: boolean = true;
  @Input() itemIndex: number = -1;
  @Input() item: any;
  @Input() showTooltip: boolean = true;
  @Input() bindText: string = 'first_name,last_name';
  @Output() onRemove: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  removeItem(event){
    this.onRemove.emit(this.item);
  }

}
