import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, catchError, shareReplay, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { HelperService } from '@services/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(private http: HttpClient) { }

  getPlans(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `plans?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getStorages(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `plans/storage?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getSubscription(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `plans/subscription?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getBilling(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `plans/billing?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getCard(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `plans/card?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getPaymentUrl(data: any, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.post(environment.endpoint + `plans/card/iframe/url?${queryString}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  calculatePlanPrice(id: number, data: any = {}, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.post(environment.endpoint + `plans/subscribe/${id}/calculate?${queryString}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  makeOrder(id: number, data: any = {}, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.post(environment.endpoint + `plans/subscribe/${id}?${queryString}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  updateBilling(data: any = {}, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.put(environment.endpoint + `plans/billing?${queryString}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  updateAddons(id: number, data: any = {}, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.patch(environment.endpoint + `plans/subscribe/${id}?${queryString}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  updateCalculator(id: number, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `plans/subscribe/${id}/calculate/update?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  updateCard(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `plans/card/iframe/url/token?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  cancelCalceledPlan(): Observable<any> {
    return this.http.delete(environment.endpoint + `plans/cancel/remove`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  cancelPlan(data: any = {}): Observable<any>{
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ...data },
    };

    return this.http.delete(environment.endpoint + `plans/cancel`, options)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  updateCustomPlan(campId: number, planId: number, data: any, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.patch(environment.endpoint + `plans/subscribe/${campId}/${planId}?${queryString}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
}
