import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarComponent } from './avatar.component';
import { SharedPipesModule } from '@pipes';
import { SharedDirectivesModule } from '@directives';
import * as Gravatar from 'ngx-avatar';

@NgModule({
  declarations: [
    AvatarComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    SharedPipesModule,
    SharedDirectivesModule,
    Gravatar.AvatarModule
  ],
  exports: [
    AvatarComponent
  ]
})
export class AvatarModule { }