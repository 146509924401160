import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProgramService } from '@services/program/program.service';
import { HelperService } from '@services/helper/helper.service';
import { IInputValue } from 'app/interfaces';

@Component({
  selector: 'cah-program-settings-modal',
  templateUrl: './program-settings-modal.component.html',
  styleUrls: ['./program-settings-modal.component.scss']
})
export class ProgramSettingsModalComponent implements OnInit {

  @Output() outClose = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<IInputValue>();

  @Input() program: any = {};
  loading: boolean = false;

  programActiveTab: string = "basic_details";
  defaultPreview: any;

  constructor(private programService: ProgramService) { }

  ngOnInit() {
    this.defaultPreview = this.program.shop_image ? this.program.shop_image.full_path : null;
  }

  onChangeProgramsTab(activeTab: string): void {
    this.programActiveTab = activeTab;
  }

  onModalChange(e): void {
    this.program = { ...this.program, ...e }

    console.log('-onModalChange- ',this.program)
    console.log('...e ', e)
  }

  onFeatureImageChange(e): void {
    this.program = { ...this.program, ...e }
    if(this.program.shop_image instanceof Blob){
      HelperService.blobToDataUri(this.program.shop_image).then(res => this.defaultPreview = res)
    }else{
      this.defaultPreview = this.program.shop_image.full_path;
    }
  }

  async uploadProgramImages(file: any, source_type: string): Promise<any> {
    const formData = new FormData();
    formData.append('image', file);
    const result = await this.programService.uploadProgramImage(formData, { source_type }).toPromise();
    return result;
  }

  async onSubmitBtn(e): Promise<any> {
    let result: any;
    this.loading = true;

    for (const [index, desc] of this.program.descriptions.entries()) {
      if (this.program.descriptions[index]['action'] != 'delete' && (!desc.title || !desc.description)) {
        this.program.descriptions[index][!desc.title ? 'titleError' : 'descriptionError'] = 'Required!';
        return this.loading = false;
      }
    }

    if (this.program.days_splash_image instanceof Blob) {
      result = await this.uploadProgramImages(this.program.days_splash_image, 'program_splash_days_image');
      this.program.days_splash_image_id = result.id;
    }

    if (this.program.hours_splash_image instanceof Blob) {
      result = await this.uploadProgramImages(this.program.hours_splash_image, 'program_splash_hours_image');
      this.program.hours_splash_image_id = result.id;
    }

    if (this.program.seconds_splash_image instanceof Blob) {
      result = await this.uploadProgramImages(this.program.seconds_splash_image, 'program_splash_seconds_image');
      this.program.seconds_splash_image_id = result.id;
    }

    if (this.program.shop_image instanceof Blob) {
      result = await this.uploadProgramImages(this.program.shop_image, 'program_shop_image');
      this.program.shop_image_id = result.id;
    }

    this.program.managers = this.program.manager_id ? [this.program.manager_id] : [];
    delete this.program.sessions; 
    this.programService.updateProgram(this.program.id, this.program)
      .subscribe(res => {
        this.onSubmit.emit({ name: 'success', value: this.program });
        this.loading = false;
      }, err => {
        this.loading = false;
        this.onSubmit.emit({ name: 'error', value: this.program });
      });
  }
}
