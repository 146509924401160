import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { IInputValue } from "@interfaces";
import { HelperService } from "@services/helper/helper.service";
import { LanguageService } from "@services/language/language.service";
import { FormValidatorService } from "@util/form-validator/form-validator.service";

@Component({
  selector: "cah-inline-input",
  templateUrl: "./inline-input.component.html",
  styleUrls: ["./inline-input.component.scss"],
})
export class InlineInputComponent implements OnInit {
  firstValue: any;
  editValue: any;
  editMode: boolean = false;
  wasInside: boolean = false;

  @Input() bindName: string;
  @Input() discardBtn: string;
  @Input() saveBtn: string;
  @Input() type: string;
  @Input() label: string;
  @Input() placeHolder: string;
  @Input() translationSection: string = 'form';
  @Input() helper: string;
  @Input() validators: string[];
  @Input() isDisabled: boolean = false;
  @Input() isModal: boolean = false;
  @Input() set value(val: any) {
    this.firstValue = val;
    this.editValue = val;
  }
  @Input() error: any;
  @Input() maxLength: number;
  @Input() minLength: number;
  @Input() tabindex: string;
  @Output() onOutPutValue: EventEmitter<IInputValue> = new EventEmitter();
  @Output() onModalClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private formValidatorService: FormValidatorService,
    public languageService: LanguageService
  ) {}

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (
      (event.target.getAttribute("class") && 
      !event.target.getAttribute("class").includes("inline-input-element-input") &&
      !event.target.getAttribute("class").includes("inline-input-element-input") &&
      !event.target.getAttribute("class").includes("inline-input-value-span") &&
      !event.target.getAttribute("class").includes("inline-input-value-img") &&
      !event.target.getAttribute("class").includes("inline-input-value")) || !this.wasInside
    ) {
      this.onDiscard();
    }

    this.wasInside = false;
  }

  @HostListener("click")
  clickInside() {
    this.wasInside = true;
  }

  ngOnInit() {}

  click(): void {
    const payload: IInputValue = {
      name: this.bindName,
      value: true,
    };
    if (!this.isDisabled) {
      this.onModalClick.emit(payload);
    }
  }

  onDiscard(): void {
    this.editValue = this.firstValue;
    this.error = null;
    this.editMode = false;
  }

  onInputChange(evt: any): void {
    if (this.type === "number") {
      this.editValue = this.editValue.replace(/\D/g, "");
      if (this.editValue.charAt(0) === "0") {
        this.editValue = this.editValue.slice(1);
      }
      evt.target.value = this.editValue;
    }
  }

  onSubmitValue(): void {
    this.error = this.formValidatorService.validateRecursiveLayout([
      {
        name: this.bindName,
        label: this.label,
        type: this.type,
        validators: this.validators,
        value: this.editValue,
      },
    ]);
    if (!HelperService.isObjectEmpty(this.error)) {
      console.log(this.error, this.bindName);
      
      return;
    }

    this.error = null;
    const payload: IInputValue = {
      name: this.bindName,
      value: this.type == "number" ? parseInt(this.editValue) : this.editValue,
    };
    this.editMode = false;
    this.onOutPutValue.emit(payload);
  }
}
