import { Component, OnInit, Input } from '@angular/core';
import { HelpQuestionMarkType } from '@enums';

@Component({
  selector: 'cah-help-question-mark',
  templateUrl: './help-question-mark.component.html',
  styleUrls: ['./help-question-mark.component.scss']
})
export class HelpQuestionMarkComponent implements OnInit {
  @Input() type: number = HelpQuestionMarkType.QUESTION;
  @Input() text: string;
  @Input() leftDirection: false;
  @Input() class: string = '';
  @Input() iconColor: string = '#ffffff';
  @Input() bgColor: string = '#ced2e0';
  @Input() isHebrew: false;
  @Input() hasButterflyIcon: true;
  @Input() helperIcon: string = '/assets/images/question.svg';
  @Input() infoIcon: string = '/assets/images/info.svg';
  @Input() fontSize: string = '16px';

  help: any = {};

  helpQuestionMarkType = HelpQuestionMarkType;

  constructor() {
   }

  ngOnInit(): void {
  }
}
