import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cah-modal-purchase-details',
  templateUrl: './modal-purchase-details.component.html',
  styleUrls: ['./modal-purchase-details.component.scss']
})
export class ModalPurchaseDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  closeModal(): void {
    // close
  }

}
