import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError, shareReplay } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { HelperService } from '@services/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
    translations: any = {};
    language: string = 'en';
    private _language = new BehaviorSubject<string>(this.language);
    language$ = this._language.asObservable();

    constructor(private http: HttpClient) { }

    changeLanguage(language: string): void {
        localStorage.setItem('language', JSON.stringify(language));
        this.language = language;
        this._language.next(this.language);
    }
}
