import {
  NgModule
} from '@angular/core';
import {
  Routes,
  RouterModule
} from '@angular/router';
import {
  AuthGuard
} from '@guards/auth.guard';
import {
  MainLayoutComponent
} from '@containers/main-layout/main-layout.component';
import { PermissionGuard } from '@guards/permission.guard';
import { SecureInnerPagesGuard } from '@guards/secure-inner-pages.guard';

const routes: Routes = [{
  path: '',
  component: MainLayoutComponent,
  canActivate: [AuthGuard],
  canActivateChild: [AuthGuard, PermissionGuard],
  children: [
      {
        path: '',
        loadChildren: () => import('./pages/main-dashboard/main-dashboard.module').then(m => m.MainDashboardModule),
        data: { breadcrumb: 'home' }
      },
      {
        path: 'settings/balance',
        loadChildren: () => import('./pages/balance/balance.module').then(m => m.BalanceModule),
        data: { breadcrumb: 'credits' }
      },
      {
        path: 'settings/camps',
        loadChildren: () => import('./pages/camps/camps.module').then(m => m.CampsModule),
        data: { breadcrumb: 'camps' }
      },
      {
        path: 'staff',
        loadChildren: () => import('./pages/staff/staff.module').then(m => m.StaffModule),
        data: { breadcrumb: 'staff' }
      },
      {
        path: 'staff-scheduling',
        loadChildren: () => import('./pages/staff-scheduling/staff-scheduling.module').then(m => m.StaffSchedulingModule),
        data: { breadcrumb: 'staff_scheduling_board' }
      },
      {
        path: 'superadmins',
        loadChildren: () => import('./pages/super-admins/super-admins.module').then(m => m.SuperAdminsModule),
        data: { breadcrumb: 'super_admins' }
      },
      {
        path: 'activities',
        loadChildren: () => import('./pages/activities/activities.module').then(m => m.ActivitiesModule),
        data: { breadcrumb: 'activities' }
      },
      {
        path: 'timeslots',
        loadChildren: () => import('./pages/timeslots/timeslots.module').then(m => m.TimeslotsModule),
        data: { breadcrumb: 'timeslots' }
      },
      {
        path: 'campers',
        loadChildren: () => import('./pages/campers/campers.module').then(m => m.CampersModule),
        data: { breadcrumb: 'campers' }
      },
      {
        path: 'test-modal',
        loadChildren: () => import('./pages/test/test.module').then(m => m.TestModule),
        data: { breadcrumb: 'test' }
      },
      {
        path: 'sessions',
        loadChildren: () => import('./pages/program-session/program-session.module').then(m => m.ProgramSessionModule),
        data: { breadcrumb: 'sessions' }
      },
      {
        path: 'spaces',
        loadChildren: () => import('./pages/spaces/spaces.module').then(m => m.SpacesModule),
        data: { breadcrumb: 'spaces' }
      },
      {
        path: 'groups',
        loadChildren: () => import('./pages/groups/groups.module').then(m => m.GroupsModule),
        data: { breadcrumb: 'groups' }
      },
      {
        path: 'scheduling',
        loadChildren: () => import('./pages/scheduling/scheduling.module').then(m => m.SchedulingModule),
        data: { breadcrumb: 'schedule' }
      },
      {
        path: 'live-schedule',
        loadChildren: () => import('./pages/live-schedule/live-schedule.module').then(m => m.LiveScheduleModule),
        data: { breadcrumb: 'live_schedule' }
      },
      {
        path: 'purchase',
        loadChildren: () => import('./pages/purchase/purchase.module').then(m => m.PurchaseModule),
        data: { breadcrumb: 'purchase' }
      },
      {
        path: 'purchase-history',
        loadChildren: () => import('./pages/purchase-history/purchase-history.module').then(m => m.PurchaseHistoryModule),
        data: { breadcrumb: 'purchase_history' }
      },
      {
        path: 'translations',
        loadChildren: () => import('./pages/translations/translations.module').then(m => m.TranslationsModule),
        data: { breadcrumb: 'translations' }
      },
      {
        path: 'settings/recordings',
        loadChildren: () => import('./pages/recordings/recordings.module').then(m => m.RecordingsModule),
        data: { breadcrumb: 'recordings' }
      },
      {
        path: 'electives',
        loadChildren: () => import('./pages/electives/electives.module').then(m => m.ElectivesModule),
        data: { breadcrumb: 'electives' }
      },
      {
        path: 'programs',
        loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsModule),
        data: { breadcrumb: 'programs' }
      },
      {
        path: 'members',
        loadChildren: () => import('./pages/members/members.module').then(m => m.MembersModule),
        data: { breadcrumb: 'users' }
      },
      {
        path: 'coupons',
        loadChildren: () => import('./pages/coupons/coupons.module').then(m => m.CouponsModule),
        data: { breadcrumb: 'coupons' }
      },
      { 
        path: 'content-board', 
        loadChildren: () => import('./pages/content-board/content-board.module').then(m => m.ContentBoardModule),
        data: { breadcrumb: 'Content boards' } 
      },
      {
        path: 'boards',
        loadChildren: () => import('./pages/boards/boards.module').then(m => m.BoardsModule),
        data: { breadcrumb: 'boards' }
      },
      { 
        path: 'licenses', 
        loadChildren: () => import('./pages/licenses/licenses.module').then(m => m.LicensesModule) 
      },
      { 
        path: 'reports', 
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) 
      },
      { 
        path: 'store', 
        loadChildren: () => import('./pages/store/store.module').then(m => m.StoreModule) 
      },
      { 
        path: 'icons', 
        loadChildren: () => import('./pages/custom-icons/custom-icons.module').then(m => m.CustomIconsModule) 
      },
      { 
        path: 'templates', 
        loadChildren: () => import('./pages/templates/templates.module').then(m => m.TemplatesModule) 
      },
      {
        path: 'stripe-success',
        redirectTo: 'store'
      },
      {
        path: 'stripe-failed',
        redirectTo: 'store'
      },
    ]
  },
  {
    canActivate: [SecureInnerPagesGuard],
    path: 'user',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)
  },
  { 
    path: 'content-board-view', 
    loadChildren: () => import('./pages/content-board-view/content-board-view.module').then(m => m.ContentBoardViewModule) 
  },
  {
    path: 'end-meeting',
    loadChildren: () => import('./pages/end-meeting/end-meeting.module').then(m => m.EndMeetingModule)
  },
  {
    path: 'timer/:hash',
    loadChildren: () => import('./pages/timer/timer.module').then(m => m.TimerModule)
  },
  {
    path: 'elective-timer/:hash',
    loadChildren: () => import('./pages/elective-timer/elective-timer.module').then(m => m.ElectiveTimerModule)
  },
  {
    path: 'meeting-for-guest/:hash',
    loadChildren: () => import('./pages/meeting-for-guest/meeting-for-guest.module').then(m => m.MeetingForGuestModule)
  },
  {
    path: 'troubleshooting',
    loadChildren: () => import('./pages/troubleshooting/troubleshooting.module').then(m => m.TroubleshootingModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'payment-success',
    loadChildren: () => import('./pages/payment-status/payment-status.module').then(m => m.PaymentStatusModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'payment-failed',
    loadChildren: () => import('./pages/payment-status/payment-status.module').then(m => m.PaymentStatusModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'password-expired',
    loadChildren: () => import('./pages/user-password-expired/user-password-expired.module').then(m => m.UserPasswordExpiredModule)
  },
  { 
    path: 'room', 
    loadChildren: () => import('./pages/room/room.module').then(m => m.RoomModule) 
  },
  { 
    path: 'online-player', 
    loadChildren: () => import('./pages/online-player/online-player.module').then(m => m.OnlinePlayerModule) 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
