import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipComponent } from './chip.component';
import { FormElementModule } from '@shared/form-elements';
import { AvatarModule } from '@shared/avatar';
import { SharedPipesModule } from '@pipes';
import * as Gravatar from 'ngx-avatar';


@NgModule({
  declarations: [ChipComponent],
  imports: [
    CommonModule,
    FormElementModule,
    AvatarModule,
    SharedPipesModule,
    Gravatar.AvatarModule
  ],
  exports: [
    ChipComponent
  ]
})
export class ChipModule { }
