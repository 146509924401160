import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from '@interfaces';

@Component({
  selector: 'cah-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss']
})
export class FormCheckboxComponent implements OnInit {
  @Input() bindName: string;
  @Input() helper: string;
  @Input() checked: boolean = false;
  @Input() returnNumber: boolean = false;
  @Input() disabled: boolean = false;
  @Output() onCheck: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
    if (this.returnNumber) this.checked = Boolean(Number(this.checked));
  }

  check(){
    if(!this.disabled){
      this.checked = !this.checked;
      let payload: IInputValue = {
        name: this.bindName,
        value: this.returnNumber ? Number(this.checked) : this.checked
      };
      this.onCheck.emit(payload)
    }
  }

}
