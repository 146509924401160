import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { IFormField, IInputValue } from "@interfaces";
import { LanguageService } from "@services/language/language.service";
import { ThemeService } from "@services/theme/theme.service";
import { Router } from "@angular/router";

@Component({
  selector: "cah-simplebox",
  templateUrl: "./simplebox.component.html",
  styleUrls: ["./simplebox.component.scss"],
})
export class SimpleboxComponent implements OnInit {
  @Input() language: string = "string";
  @Input() formFields: IFormField[] = [];
  @Input() formModel: any = {};
  @Input() formErrors: any;
  @Input() dataForFields: any = {};
  @Output() onFormSubmit = new EventEmitter<any>();
  @Output() onModalOpen = new EventEmitter<any>();

  constructor(
    public themeService: ThemeService,
    public languageService: LanguageService,
    private router: Router
  ) {}

  ngOnInit() {}

  onSubmit(payload: IInputValue): void {
    this.onFormSubmit.emit(payload);
  }

  onOpen(event: IInputValue): void {
    this.onModalOpen.emit(event);
  }

  routerlinkClicked(element : HTMLElement) {
    this.router.navigate([element.getAttribute('routerlink')]);
  }
}
