import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IPagenation } from 'app/interfaces';

@Component({
  selector: 'cah-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() options: IPagenation;
  @Input() loading: boolean = false;
  @Input() withCounter: boolean = true;
  @Input() onlyCounter: boolean = false;
  @Input() title: string = '';
  @Output() getPage = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  onPageSwitch(page: any): void {
    this.options.currentPage = this.options.currentPage + +page;
    if (this.options.currentPage < 1 || 
        this.options.currentPage > this.options.maxPages) return;
    this.getPage.emit(this.options.currentPage);
  }

  onGetPage(page: number): void {
    if (page !== this.options.currentPage) {
      this.options.currentPage = page;
      this.getPage.emit(page);
    }
  }

}
