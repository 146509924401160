import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CampersService } from '@services/campers/campers.service';
import { IInputValue, ResponseList } from '@interfaces';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { HelperService } from '@services/helper/helper.service';
import { SchedulingService } from '@services/scheduling/scheduling.service';

@Component({
  selector: 'cah-upcoming-meetings',
  templateUrl: './upcoming-meetings.component.html',
  styleUrls: ['./upcoming-meetings.component.scss']
})
export class UpcomingMeetingsComponent implements OnInit, OnDestroy {
  @Input() hash: string;
  @Input() camp: any;
  @Input() activityData: any;
  dropdown: boolean = false;
  recordingingsList: ResponseList;
  recordingFilter: any = { page: 1 };
  meetingsList: ResponseList;
  meetingFilter: any = { page: 1 };
  sessions: any[] = [];
  selectedSession: any;
  selectedContent: string = 'upms';
  loading: boolean = false;

  moment = moment;
  helperService = HelperService;
  
  private unsubscribe$ = new Subject<any>();
  
  constructor(private campersService: CampersService, 
              private schedulingService: SchedulingService) { }

  ngOnInit() {
    this.getMeeting(this.meetingFilter);
    this.getRecordings(this.recordingFilter);
    this.getSessions();
  }

  getMeeting(filter: any = {}, nextPage: boolean = false): void {
    this.loading = true;
    this.campersService.getCamperUpcomingMeetings(this.hash, filter)
      .subscribe(res => {
        const data: any[] = res.data.map(meeting => meeting.schedules[0]);
        this.meetingsList = { ...res, data: nextPage ? [...this.meetingsList.data, ...data] : data };
        this.loading = false;
      }, err => {
        this.loading = false;
      })
  }

  getRecordings(filter: any = {}, nextPage: boolean = false): void {
    this.loading = true;
    this.schedulingService.getCamperRecordings(this.hash, filter)
      .subscribe(res => {
        const data: any[] = res.data.map(recording => recording);
        this.recordingingsList = { ...res, data: nextPage ? [...this.recordingingsList.data, ...data] : data };
        this.loading = false;
      }, err => {
        this.loading = false;
      })
  }

  getSessions(): void {
    this.campersService.getCamperUpcomingSessions(this.hash)
      .subscribe(res => {
        this.sessions = res;
      })
  }

  onSelectFilter(session: any): void {
    let payload: any = { page: 1 };
    if (this.selectedSession && session.id === this.selectedSession.id) {
      this.selectedSession = null;
    } else {
      this.selectedSession = session;
      payload.session_ids = [session.id];
    }
    
    this.meetingFilter = { ...payload };
    this.recordingFilter = { ...payload };
    this.getMeeting(this.meetingFilter);
    this.getRecordings(this.recordingFilter);
  }

  onScrollTo(className: string): void {
    this.selectedContent = className;
    HelperService.scrollOnElement(className, 'nearest');
  }

  onSectionAction(evt: IInputValue): void {
    switch (evt.name) {
      case 'show-more':
        if (evt.value === 'meeting')  {
          this.meetingFilter.page += 1;
          this.getMeeting(this.meetingFilter, true);
        } else if (evt.value === 'recording') {
          this.recordingFilter.page += 1;
          this.getRecordings(this.recordingFilter, true)
        }
        break;

      case 'show-less':
        if (evt.value === 'meeting')  {
          this.meetingFilter.page = 1;
          this.meetingsList.data.splice(3);
        } else if (evt.value === 'recording') {
          this.recordingFilter.page = 1;
          this.recordingingsList.data.splice(3);
        }
        break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
