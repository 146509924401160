import { Directive, Input, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  @Input('tooltip') tooltipTitle: string;
  @Input() placement: string;
  @Input() pagePlacement: string = '';
  @Input() hideOnLength: number;
  @Input() delay: string = '100';
  tooltip: HTMLElement;
  class: string = "ng-tooltip-show";
  offset = 10;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('click') onClick() {
    if (this.tooltip) { this.hide(); }
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip && this.placement != 'none' ) { this.show(); }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) { this.hide(); }
  }

  show() {
    // this.create();
    // this.setPosition();
    // this.class =  this.pagePlacement == "sideBar" ? 'ng-tooltip-sidebar' : 'ng-tooltip';
    
    // if( this.hideOnLength && this.tooltipTitle && this.tooltipTitle.length > this.hideOnLength){      
    //   this.renderer.addClass(this.tooltip, this.class+"-show");
    // }else if(this.tooltipTitle && !this.hideOnLength){
    //   this.renderer.addClass(this.tooltip, this.class+"-show");
    // }

    // if(this.pagePlacement != "sideBar"){
    //   window.setTimeout(() => {
    //     if (this.tooltip) {
    //       this.renderer.removeChild(document.body, this.tooltip);
    //     }
    //     this.tooltip = null;
    //   }, 3000);
    // }
  }

  hide() {
    this.renderer.removeClass(this.tooltip, this.class+'-show');
    window.setTimeout(() => {
      if (this.tooltip) {
        this.renderer.removeChild(document.body, this.tooltip);
      }
      this.tooltip = null;
    }, +this.delay);
  }

  create() {
    this.tooltip = this.renderer.createElement('span');
    this.class =  this.pagePlacement == "sideBar" ? 'ng-tooltip-sidebar' : 'ng-tooltip';

    this.renderer.appendChild(
      this.tooltip,
      this.renderer.createText(this.tooltipTitle)
    );

    this.renderer.appendChild(document.body, this.tooltip);

    this.renderer.addClass(this.tooltip, this.class);
    this.renderer.addClass(this.tooltip, `${this.class}-${this.placement}`);

    this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.delay}ms`);
  }

  setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();

    const tooltipPos = this.tooltip.getBoundingClientRect();

    const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    let top, left;

    if (this.placement === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    
    } else if (this.placement === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    
    } else if (this.placement === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
   
    } else if (this.placement === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset + 15;
    
    }

    if(this.pagePlacement == "sideBar" && this.placement != 'none'){
      left -= 10;
    }
    if(this.tooltipTitle === "Settings"){
      top -= 17;
    }
    if(this.placement != 'none'){
      this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPos}px`);
      this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
    }else{
      this.renderer.removeClass(this.tooltip, this.class+'-show');
    
      if (this.tooltip) {
        this.renderer.removeChild(document.body, this.tooltip);
      }
      this.tooltip = null;
    }
    
  }
}
