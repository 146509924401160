import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() label: string = '';
  @Input() bindName: string;
  @Input() placeHolder: string;
  @Input() value: any;
  @Input() isDisabled: boolean = false;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();


  private unsubscribe$ = new Subject<any>();
  language: string;
  constructor(private languageService: LanguageService) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(lang => {
        this.language = lang;
      });
  }

  ngOnInit() {
  }

  onInputChange(event){
    const payload: IInputValue = {
      name: this.bindName,
      value: this.value
    }
    this.onChange.emit(payload);
  }
  
  // numericOnly(event: any) {
  //   if (this.type == 'number'){
  //     return (event.keyCode > 45 && event.keyCode < 58 || event.keyCode > 96 && event.keyCode < 106 || (event.keyCode == 8 || event.keyCode == 46))
  //   }
  // }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
