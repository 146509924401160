import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-listing-add-multiple-button',
  templateUrl: './listing-add-multiple-button.component.html',
  styleUrls: ['./listing-add-multiple-button.component.scss']
})
export class ListingAddMultipleButtonComponent implements OnInit {



  @Input() options: any[] = [];
  @Input() displayProperty: string;
  @Input() returnProperty: string;
  @Input() bindName: string;
  @Input() type: string;
  @Input() placeHolder: string;
  @Input() value: any;
  @Input() defaultValue: any;
  @Input() error: any;
  @Input() tabindex: string;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();
  @Output() onAddGroup: EventEmitter<any> = new EventEmitter();


  @Input() label: string = "Add";
  @Input() disabled: boolean = false;
  @Output() outOnClick: EventEmitter<any> = new EventEmitter;


  private unsubscribe$ = new Subject<any>();
  language: string = 'en';
  suggestionState: any;
  dropDownToggle: boolean = false;


  constructor(public languageService: LanguageService,) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(lang => {
        this.language = lang;
      });
  }

  ngOnInit() {
  }
  
  onCloseUploadModal(event){
    if (this.type === 'addDropdownButton') {
      this.dropDownToggle = false;
    }
  }

  onToggleDropDown(evt: Event): void {
    evt.stopPropagation();
    this.dropDownToggle = !this.dropDownToggle;
  }

  onClick(event: any): void {
    let payload: any = {
      type : event
    };
    this.outOnClick.emit(payload);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
