import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'cah-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements OnInit {

  @Input() isOpen: boolean = false;
  @Input() search: boolean = false;
  @Input() searchText: string = '';
  @Input() options: [] = [];
  @Input() filters: [];
  @Output() onOutputSearch = new EventEmitter<string>();
  @Output() mainFilterSearch = new EventEmitter<Object>();
  @Output() onSearchChange = new EventEmitter<Object>();
  private searchSubject: Subject<string> = new Subject();
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  @Input() mainFilter = {
    'id': null,
    'space_id': null,
    'camps_id': null,
    'start_date': '',
    'end_date': '',
    'start_amount': '',
    'end_amount': '',
    'start_price': '',
    'end_price': '',
    'start_time': '',
    'end_time': '',
    'status': 'done'
  }
  statusOptions = [
    { id: 1, name: 'Pedding Only', value: 'pending' },
    { id: 2, name: 'Done Only', value: 'done' },
    { id: 3, name: 'All Transactions', value: 'all' },
  ]


  constructor(
    languageService: LanguageService
  ) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });
  }

  ngOnInit() {
    this.searchSubject
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
      ).subscribe(resp => {
        this.onOutputSearch.emit(resp)
      });
  }

  onSearchCamp(e) {
    console.log(e.term)
    this.searchSubject.next(e.term)
  }

  onTimeChange(event: any): void {
    console.log(event);

    this.mainFilter[event.name] = event.value;
    console.log(this.mainFilter);

  }

  onInputChange(event, field) {
    if (field == "start_date" || field == "end_date") {
      this.mainFilter[field] = event.value
    } else if (field == "id") {
      this.mainFilter[field] = event && event.id ? event.id : null
    } else {
      this.mainFilter[field] = event.target.value
    }
  }

  // numericOnly(event: any) {
  //   return (event.keyCode > 45 && event.keyCode < 58 || (event.keyCode == 8))
  // }

  clear() {
    this.mainFilter = {
      'id': null,
      'camps_id': null,
      'space_id': null,
      'start_date': '',
      'end_date': '',
      'start_amount': '',
      'end_amount': '',
      'start_price': '',
      'end_price': '',
      'end_time': '',
      'start_time': '',
      'status': null,
    }
  }

  onSubmit(e) {
    if (e == 'apply') {
      this.mainFilterSearch.emit(this.mainFilter);
    } else {
      this.clear();
    }
  }

  onSearch(event) {
    this.onSearchChange.emit(event);
  }


  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
