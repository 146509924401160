import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IInputValue, ResponseList } from '@interfaces';
import { HelperService } from '@services/helper/helper.service';
import * as moment from 'moment';

@Component({
  selector: 'cah-recording-section',
  templateUrl: './recording-section.component.html',
  styleUrls: ['./recording-section.component.scss']
})
export class RecordingSectionComponent implements OnInit {
  @Input() items: ResponseList;
  @Input() loading: boolean = false;
  @Output() outAction: EventEmitter<IInputValue> = new EventEmitter<IInputValue>();

  recordringQty: number = 3;
  isHebrew: boolean = false;

  moment = moment;

  constructor() {
    this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;
  }

  ngOnInit() {
  }

  onViewRecording(rec: any): void {
    // HelperService.goToLink(`/online-player?id=${rec.local_url ? rec.local_url : rec.download_url}`);
    HelperService.goToLink(`/online-player?id=${rec.id}`);
  }

  onAction(name: string, value: any): void {
    const payload: IInputValue = { name, value };
    this.outAction.emit(payload);
  }

}
