import { IFormField } from 'app/interfaces';
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  constructor() {}

  public static generateQueryParams(queryparams: any = {}) {
    const queryparamsArray = [];
    for (const key in queryparams) {
      if (
        (Array.isArray(queryparams[key]) && !queryparams[key].length) ||
        !queryparams[key]
      ) {
        continue;
      }
      if (Array.isArray(queryparams[key])) {
        for (const val of queryparams[key]) {
          queryparamsArray.push(key + "[]=" + encodeURIComponent(val));
        }
      } else {
        queryparamsArray.push(key + "=" + encodeURIComponent(queryparams[key]));
      }
    }

    const queryString = queryparamsArray.join("&");
    return queryString;
  }

  public static DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  public static async blobToDataUri(file: any){
    var previewUrl: any;
    var reader = new FileReader();
    reader.readAsDataURL(file); 
    previewUrl = new Promise( (resolve, reject) => {
            reader.onload = (_event) => { 
              resolve(reader.result);
            }
        }
      )

    return await previewUrl;
  }

  public static isObjectEmpty(object: any): boolean {
    if (!object) return true;
    return !!(Object.entries(object).length === 0 && object.constructor);
  }

  public static goToLink(link: string, target: string = "_blank"): void {
    window.open(link, target);
  }

  public static createFormData(files: FileList, name: string = 'image'): FormData {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append(name, fileToUpload);
    return formData;
  }

  public static hasExtension(item: any = null, exts: string[]): boolean {
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$', "i")).test(item.name);
  }

  public static timeToAmPm(timeString) {
    var H = +timeString.substr(0, 2);
    var h = H % 12 || 12;
    var ampm = H < 12 ? " AM" : " PM";
    return (timeString = h + timeString.substr(2, 3) + ampm);
  }

  public static formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    hours = hours === 12 && ampm === "AM" ? "00" : hours;
    const strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  public static getDateList(start, end) {
    let dateList = [];
    let currentDate = new Date(start);
    let endDate = new Date(end);

    while (currentDate <= endDate) {
      dateList.push(currentDate.toISOString());
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateList;
  }

  public static calculatePopupPosition(
    clickPositionX,
    clickPositionY,
    elementHeight,
    elementWidth,
    windowHeight,
    windowWidth,
    pageYOffset,
    pageXOffset
  ) {
    let position = {
      vertical: "bottom",
      horizontal: "right",
    };

    if (clickPositionY + elementHeight > windowHeight + pageYOffset) {
      position.vertical = "top";
    }

    if (clickPositionX + elementWidth + 260 > windowWidth + pageXOffset) {
      position.horizontal = "left";
    }

    return position;
  }

  public static getEnviroment() {
    return environment;
  }

  public static findObjectValueKey(object: any, value: any): any {
    return Object.keys(object)[Object.values(object).indexOf(value)];
  }

  public static checkImageURL(url: string): boolean {
    if (!url) { return false; }
    return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  public static validURL(url: string): boolean {
    if (!url) { return false; }
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(url);
  }

  public static numberWithCommas(x: any): any {
    if (!x) {
      return 0;
    }
    // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return x.toString().replace(",",".").replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return new Intl.NumberFormat("en-US").format(parseFloat(x));
  }

  public static setValueToFormField(fields: IFormField[], name, value) {
    for (let field of fields) {
      if (field.type == 'layout') {
        this.setValueToFormField(field.items, name, value);
      } else {
        for (let validator of field.validators) {
          if (field.name == name ) {
              field.value = value;
          }
        }
     }
  }
  return fields;
  }

  public static setPropToFormFieldItem(form, key, property, value){
    if(form && form.name && form.name == key){
      form[property] = value;
    }else{
      if(form.items && form.items.length > 0){
        form.items.map(el => this.setPropToFormFieldItem(el,key,property,value))
      }
    }
  }

  public static scrollOnElement(className: string, block: any = 'center'): void {
    const htmlElement: HTMLElement = document.querySelector(`.${className}`);
    if (htmlElement) {
      htmlElement.scrollIntoView({behavior: "smooth", block });
    }
  }

  public static downloadFileByRequest(data: any, fileName) {
    const a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}),
    url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a)
  }

  public static searchArray(string, property, array: any[]){
    const filteredArray = array.filter(element => element[property].toString().toLowerCase().includes(string.toString().toLowerCase()));
    return filteredArray;
  }

  public static checkNumber(evt) {
    evt.target.value = evt.target.value.replace(/\D/g, '');
    if (evt.target.value.charAt(0) === '0') {
      evt.target.value = evt.target.value.slice(1);
    }
  }

  public static excludeArray(array: any[] = [], arrayToExclude: any[] = [], firstArrayParam: string, secondArrayParam: string){
    let newArray = JSON.parse(JSON.stringify(array));
    
    if(arrayToExclude.length > 0){
      for (let i = newArray.length - 1; i >= 0; i--) {
        for (let j = 0; j < arrayToExclude.length; j++) {
          if(newArray[i][firstArrayParam] == arrayToExclude[j][secondArrayParam]){
            newArray.splice(i,1);
            break;
          }
        }
      }
    }
    return newArray;
  }

  public static formatMinutes(minutes: number){
    if(minutes < 60){
      return minutes + " min"
    }else if(minutes%60 === 0){
      return minutes/60 + " h"
    }else{
      return Math.floor(minutes/60) + " h " + minutes%60 + " min";
    }
  }

  public static isTextOverflow(elementId: string): boolean {
    const elem = document.getElementById(elementId);
    if (elem) {
        return (elem.offsetWidth < elem.scrollWidth);
    }
    else {
        return false;
    }
  }

  public static timeIsBefore(start, end){
    let end_time = moment().toISOString().split('T')[0] + "T" + end;
    let start_time = moment().toISOString().split('T')[0] + "T" + start;
    if(moment(end_time).isBefore(moment(start_time))){
      let error = {
        name: "class_end_time",
        message: "less_than_start_time"
      }

      return error;
    }

    return false;
  }

  public static dateIsEqual(start, end, timezone: string = moment.tz.guess()){
    let end_time = moment(end).tz(timezone);
    let start_time = moment(start).tz(timezone);
    
    if(end_time.isSame(start_time, 'day')){
      return true;
    }
    return false;
  }

  public static checkCardType(cc: string): string {
    let amex = new RegExp('^3[47][0-9]{13}$');
    let visa = new RegExp('^4[0-9]{0,}$');
    let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
    let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');
    
    let mastercard = new RegExp('^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$');
    let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
    
    let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
    let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
    let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');
    
    let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
    let jcb =  new RegExp('^35[0-9]{14}[0-9]*$');
    
    
    if (visa.test(cc)) {
      return 'visa';
    }
    if (amex.test(cc)) {
      return 'amex';
    }
    if (mastercard.test(cc) || mastercard2.test(cc)) {
      return 'mastercard';
    }
    if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
      return 'discover';
    }
    if (diners.test(cc)) {
      return 'DINERS';
    }
    if (jcb.test(cc)) {
      return 'JCB';
    }
    if (cup1.test(cc) || cup2.test(cc)) {
      return 'CHINA_UNION_PAY';
    }
    return undefined;
  }

  public static limitTextInput(validators: string[], value: string, event){
    let payload = '';
    validators.forEach(validator => {
      const options = validator.split(':');
      const maxValue = parseInt(options[1]);
      if(options[0] === 'maxLength'){
        if(value.length > maxValue){
          const slicedValue = value.substring(0, maxValue);
          value = slicedValue;
          event.target.value = slicedValue;
          payload = slicedValue;
        }else{
          payload = value;
        }
      } else {
        payload = value;
      }
    })
    return payload;
  }

  public static getWeekDaysPattern(dates: any){
    let i: number = 0;
    let firstDate: any;
    const payload: any[] = [];

    while(i >= 0){
      firstDate = firstDate ? firstDate : dates[i];
      let firstDay = moment(firstDate.date, 'YYYY-MM-DD').format('dddd');
      let currentDay = moment(dates[i].date, 'YYYY-MM-DD').format('dddd');
      if(firstDay === currentDay && i != 0 && firstDate.start_time === dates[i].start_time){
        i = -1
      }else{
        payload.push(dates[i]);
        if(dates[i+1]){
          i++
        }else{
          i = -1
        }
      }
    }
    return payload;
  }

  luminance(r, g, b) {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow( (v + 0.055) / 1.055, 2.4 );
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  contrast(rgb1, rgb2) {
    rgb1 = rgb1.split(',')
      var lum1 = this.luminance(rgb1[0], rgb1[1], rgb1[2]);
      var lum2 = this.luminance(rgb2[0], rgb2[1], rgb2[2]);
      var brightest = Math.max(lum1, lum2);
      var darkest = Math.min(lum1, lum2);
      return (brightest + 0.05)
          / (darkest + 0.05);
  }

  hexToRgb(hex) {
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return r + "," + g + "," + b;
  }

  public static setStyleVariables(props: any): void {
    Object.keys(props).forEach(key => document.documentElement.style.setProperty(`--${key}`, props[key]));
  }

  public static copyClickBoard(str: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = str;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public static stringToHtml(htmlString){
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, 'text/html');
    return html.body;
  }

  public static convertTime(length: number, fromMeasure: any, toMeasure: any){
    const duration = moment.duration(length, fromMeasure);
    return toMeasure === 'minutes' ? duration.asMinutes() : toMeasure === 'hours' ? duration.asHours() : duration.asDays();
  }

}


