import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserLogin } from "@models";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, tap, map, shareReplay, take } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public userInfo: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor(private http: HttpClient, private router: Router) {}

  updateUser(data: any): Observable<any> {
    return this.http.patch<any>(environment.endpoint + 'users/profile', data);
  }

  signIn(payload: UserLogin, hash: string = "") {
    let options = {};
    options = {};
    if (hash) {
      options = {
        headers: new HttpHeaders({
          recaptcha: hash,
        }),
      };
    }

    return this.http
      .post(environment.endpoint + "auth/login", payload, options)
      .pipe(
        map((res) => {
          localStorage.setItem("token", res["access_token"]);
          return res;
        }),
        catchError((err) => throwError(err))
      );
  }

  signUp(payload) {
    /* TO BE DONE ONCE ENDPOINT IS MADE
    this.http.post("signupurl", payload)
        .subscribe( res => {
          localStorage.setItem('token', res['token'])
        })
    */
  }

  getCaptchaInf() {
    return this.http.get(environment.endpoint + "auth/captcha").pipe(
      take(1),
      catchError((e) => {
        return of({ status: false, message: "error on fetching" });
      })
    );
  }

  logout() {
    localStorage.removeItem("token");
    this.router.navigate(["user"]);
    this.userInfo.next(null);
  }

  getUserInfo(): Observable<any> {
    return this.http.get(environment.endpoint + "users/me").pipe(
      tap((r) => {
        this.userInfo.next(r);
      }),
      catchError((e) => {
        this.userInfo.next(null);
        return of(false);
      })
    );
  }

  activate(payload) {
    return this.http
      .patch(environment.endpoint + "users/profile/activate", payload)
      .pipe(
        map((res) => res),
        catchError((err) => throwError(err))
      );
  }

  uploadProfilePhoto(data: any): Observable<any> {
    return this.http.post(environment.endpoint + "uploads/profile/image", data);
  }

  forgotPassword(payload: any) {
    return this.http
      .post(environment.endpoint + "users/user/forgot-password", payload)
      .pipe(
        shareReplay(1),
        map((res) => res),
        catchError((err) => throwError(err))
      );
  }

  resetPassword(payload: any) {
    return this.http
      .post(environment.endpoint + "users/user/reset-password", payload)
      .pipe(
        shareReplay(1),
        map((res) => res),
        catchError((err) => throwError(err))
      );
  }

  changePasswordUserArea(payload: any) {
    return this.http
      .patch(environment.endpoint + "users/password", payload)
      .pipe(
        shareReplay(1),
        map((res) => res),
        catchError((err) => throwError(err))
      );
  }

  loginAs(id: number): Observable<any> {
    return this.http
      .post(environment.endpoint + "users/login/as/" + id, {})
      .pipe(
        shareReplay(1),
        map((res) => res),
        catchError((err) => throwError(err))
      );
  }
  loginAsCamp(id: number): Observable<any> {
    return this.http
      .post(environment.endpoint + "users/login/as/" + id, {}) //waiting endpoint
      .pipe(
        shareReplay(1),
        map((res) => res),
        catchError((err) => throwError(err))
      );
  }

  updateExpiredPassword(data: any): Observable<any> {
    return this.http.patch(environment.endpoint + `users/password/renew`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
}
