import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlPretty',
  pure: true
})
export class UrlPrettyPipe implements PipeTransform {

  transform(url: string): unknown {
    return url.replace(/^https?:\/\//i, "");
  }

}
