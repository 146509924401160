import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-listing-action-button',
  templateUrl: './listing-action-button.component.html',
  styleUrls: ['./listing-action-button.component.scss']
})
export class ListingActionButtonComponent implements OnInit {
  @Input() action: string;
  @Input() label: string;
  @Input() active: boolean = true;
  @Input() icon: boolean = false;
  @Input() isChecked: boolean = false;
  @Input() iconName: string;
  @Output() onClick: EventEmitter<any> = new EventEmitter;
  
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false

  constructor(public languageService: LanguageService,) {         
      languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });
  }

  ngOnInit() {
  }

  onButtonClick(event){
    let payload = {
      action: this.action
    }

    this.onClick.emit(payload)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
