import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'cah-display-button',
  templateUrl: './display-button.component.html',
  styleUrls: ['./display-button.component.scss']
})
export class DisplayButtonComponent implements OnInit {

  @Input() bindName: string = 'filter';
  @Input() contentState: boolean = false;
  @Input() contentPosition: string = 'left';
  @Input() mobileVersion: boolean = false;
  @Input() value: string = '';
  @Input() placeHolder: string = 'placeHolder';
  @Input() valuePlaceHolder: string = 'placeHolder';
  @Input() icon: string;
  @Output() onFilterWrapperAction: EventEmitter<any> = new EventEmitter();

  @Input() set campLanguage(res) {
    this.isHebrew = res;
  }

  isHebrew = false;

  constructor() {
    this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;
  }

  ngOnInit() {
  }

  onContentToggle(state?){
    this.contentState = state != null ? state : !this.contentState;
    this.onAction('toggle');
  }

  onAction(action){
    const payload = {
      action: action,
      name: this.bindName,
      contentState: this.contentState
    };

    this.onFilterWrapperAction.emit(payload);
  }

}
