import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { CustomURLEncoder } from '@util/url-encoder';
import { environment } from 'environments/environment';
import { Observable, Subject, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError, take, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public _scrollWindow: Subject<boolean> = new Subject<boolean>();

  private _customMergeTags: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  customMergeTags$ = this._customMergeTags.asObservable();

  constructor(private http: HttpClient) { }

  getConfig(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get<any>(environment.endpoint + 'camps/custom-settings?' + queryString)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  extractMergeTags(configs){
    let mergeTags = [];

    Object.keys(configs.campers.sections).forEach(key => {
      let section = {
        type: 'heading',
        title: configs.campers.sections[key].label,
        tags: []
      }
      Object.keys(configs.campers.sections[key].fields).forEach(fieldKey => {
        if(configs.campers.data[fieldKey].is_active){
          let tag = {
            type: 'tag',
            title: fieldKey,
            label: configs.campers.data[fieldKey].label
          }

          if(key === 'section_5' && !fieldKey.includes('custom_field')){
            tag.title = `camper_${fieldKey != 'country_id' ? fieldKey : 'country'}`;
          }else if(fieldKey === 'first_name' || fieldKey === 'last_name' || fieldKey === 'age'){
            tag.title = `participant_${fieldKey}`;
          }else if(fieldKey === 'phone'){
            tag.title = `mobile`
          }else if(fieldKey === 'screen_title'){
            tag.title = `screen_name`
          }else if(fieldKey === 'parent_first_name'){
            tag.title = `client_first_name`
          }else if(fieldKey === 'parent_last_name'){
            tag.title = `client_last_name`
          }else{
            tag.title = fieldKey;
          }

          section.tags.push(tag);
        }
        
      })
      mergeTags.push(section);
    })

    mergeTags[0].tags.push(
      {
        type: 'tag',
        title: 'personal_access_link',
        label: 'Personal Access Link'
      }
    )

    this._customMergeTags.next(mergeTags);
  }

  updateConfig(section: string, field: string, data: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: new HttpParams().set(field, JSON.stringify(data))
    };
    return this.http.patch<any>(environment.endpoint + `camps/customize/${section}`, options.body, { ...options.headers} )
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  checkCamperField(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get<any>(environment.endpoint + 'campers/field?' + queryString)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  deleteCamperFieldData(params: any = {}): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
    };
    return this.http.delete<any>(environment.endpoint + 'campers/field', options)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  getTemplateConfig(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get<any>(environment.endpoint + 'camps/custom-settings?' + queryString)
      .pipe(
        map(res => {
          Object.keys(res.templates.data).forEach(key => {
            let template = res.templates.data[key];
            if(template.timer){
              template.timer = HelperService.convertTime(template.timer, 'minutes',  `${template.type}s`);
            }
          })

          return res;
        }),
        catchError(err => throwError(err))
      )
  }

  updateTemplateConfig(section, data: any, slug, timeType: string){
    if(data.hasOwnProperty('timer')) data.timer = HelperService.convertTime(data.timer, `${data.type || timeType}s`, 'minutes'); 
    const body = new HttpParams({encoder: new CustomURLEncoder() })
      .set(slug, JSON.stringify(data))

    return this.http.patch(environment.endpoint + 'camps/customize/' + section, body, {headers: new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')} )
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
  
  resetModules(module: string): Observable<any> {
    return this.http.patch<any>(environment.endpoint + 'camps/custom-settings/' + module ,{})
      .pipe(
        take(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }
}