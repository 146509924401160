import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { LanguageService } from "@services/language/language.service";
import { UserService } from "@services/user/user.service";
import { IInputValue } from "app/interfaces";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

@Component({
  selector: "cah-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"],
})
export class PasswordComponent implements OnInit {
  //input variables
  passwordValues: any = {
    formButtonText: "add",
    label: "change_password",
  };

  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onPasswordChange: EventEmitter<any> = new EventEmitter();

  //form values
  changePasswordForm: FormGroup;
  passwordErrorMsg: string = "";
  numberAndCharacter: any = false;
  minlength: any = false;
  currentPasswordErrorMsg = "";
  formError: string = "";

  get currentPassword(): any {
    return this.changePasswordForm.get("currentPassword");
  }
  get newPassword(): any {
    return this.changePasswordForm.get("newPassword");
  }
  get reEnterPassword(): any {
    return this.changePasswordForm.get("reEnterPassword");
  }

  //local variables
  // currentPassword: string = "";
  currentPasswordVisible: boolean = false;
  newPasswordVisible: boolean = false;
  reEnterPasswordVisible: boolean = false;
  incorrectCredentials: boolean = false;

  //servise variables
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(
    private languageService: LanguageService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isHebrew) => {
        this.isHebrew = isHebrew == "he";
      });
    this.createChangePasswordForm();
  }

  ngOnInit() {
    this.numberAndCharacter = false;
    this.minlength = false;
    this.currentPasswordErrorMsg = "";
  }

  onKeyUp(event: any): void {
    this.incorrectCredentials = false;
  }

  createChangePasswordForm(): void {
    this.changePasswordForm = this.formBuilder.group(
      {
        currentPassword: [null, [Validators.required]],
        newPassword: [null, [Validators.required]],
        reEnterPassword: [null, [Validators.required]],
      },
      {
        validator: [this.passwordMatchValidator, this.ValidateCharacters],
      }
    );
  }

  ValidateCharacters(control: AbstractControl): void {
    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{7,}$/;
    let newPassword: string = control.get("newPassword").value;
    let errors = { numberAndCharacter: false, minlength: false };

    if (newPassword && re.test(newPassword) === false) {
      errors.numberAndCharacter = true;
      control.get("newPassword").setErrors(errors);
    }
    if (newPassword && newPassword.length <= 5) {
      errors.minlength = true;
      control.get("newPassword").setErrors(errors);
    }
  }

  passwordMatchValidator(control: AbstractControl): void {
    let newPassword: string = control.get("newPassword").value; // get password from our password form control
    let reEnterPassword: string = control.get("reEnterPassword").value; // get password from our confirmPassword form control
    // compare is the password 
    if (newPassword !== reEnterPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get("reEnterPassword").setErrors({ NoPassswordMatch: true });
    } else {
      control.get("reEnterPassword").setErrors(null)
    }
  }

  onCloseModal() {
    this.onClose.emit("clicked");
  }

  onSave(): void {
    if (
      this.changePasswordForm.valid &&
      this.currentPassword.value &&
      this.newPassword.value &&
      this.newPassword.value === this.reEnterPassword.value
    ) {
      let payload = {
        current_password: this.currentPassword.value,
        new_password: this.newPassword.value,
      };
      this.userService.changePasswordUserArea(payload).subscribe(
        (res) => {
          this.onPasswordChange.emit(true);
        },
        (err) => {
          if (err.error.message === "user_not_found") {
            this.currentPasswordErrorMsg = err.error.message;
            this.formError = err.error.message;
            return;
          }else if(err.error.message === "same_password"){
            this.currentPasswordErrorMsg = err.error.message;
            this.formError = err.error.message;
          }
          else{
            this.formError = "went_wrong";
          }
        }
      );
    } else {
      this.formError = "went_wrong";
    }
  }

  toggleVisibility(passType: String) {
    switch (passType) {
      case "currentPassword":
        this.currentPasswordVisible = !this.currentPasswordVisible;
        break;
      case "newPassowrd":
        this.newPasswordVisible = !this.newPasswordVisible;
        break;
      case "reEnterPassowrd":
        this.reEnterPasswordVisible = !this.reEnterPasswordVisible;
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
