import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WhithoutTokenService {

  constructor(
    private http: HttpClient,
    private handler: HttpBackend
  ) {
    this.http = new HttpClient(this.handler)
  }

  public checkColorRatio(color): Observable<any> {

    return  this.http.get<any>("https://webaim.org/resources/contrastchecker/?fcolor="+color+"&bcolor=FFFFFF&api")
        .pipe(
            map(res => res),
            catchError(err => throwError(err))
        )
  }


}
