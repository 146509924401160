import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textHighlight'
})
export class TextHighlightPipe implements PipeTransform {

  transform(wholeText: string, searchQuery: string, highlightClass: string = 'cah-mark'): string {
    if (!searchQuery) {
      return wholeText;
    }
    const re = new RegExp(searchQuery.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&'), 'gi');
    return wholeText.replace(re, `<span class="${highlightClass}">$&</span>`);
  }

}
