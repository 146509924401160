import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cah-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
  @Input() value: number = 0;
  @Input() disabledPlus: boolean = false;
  @Input() disabledMinus: boolean = false;
  @Input() minValue: number = 0;
  @Input() maxValue: number = 0;

  @Output() outValue = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  onInput(evt: any): any {
    evt.target.value = evt.target.value.replace(/\D/g, '');
    if (+evt.target.value < this.minValue) {
      evt.target.value = this.minValue;
      return false
    }
    if (+evt.target.value > this.maxValue) {
      evt.target.value = this.maxValue;
      return false
    }
    this.value = +evt.target.value;
    this.outValue.emit(this.value);
  }

  onChange(step: number): void {
    this.value += +step;
    this.outValue.emit(this.value);
  }

}
