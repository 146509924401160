export enum PagesEnum {
    timeslots = 1,
    activity = 2,
    schedulingBoard = 3,
    sessions = 4,
    staff = 5,
    spaces = 6,
    campers = 7,
    groups = 8,
    elective = 9,
    recordings = 10,
    purchaseHistory = 11,
    translations = 12,
    coupons = 13,
    dailyAgenda = 14,
    meetings = 15,
    modalRecordings = 16,
    boards = 17,
    enrollments = 18,
    sessionEnrollments = 19,
    programs = 20,
    staffReports = 21,
    meetingModalReports = 22,
    staffModalReports = 23,
    liveSchedule = 24,
    weeklyAgenda = 25
}