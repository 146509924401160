import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cah-form-steps',
  templateUrl: './form-steps.component.html',
  styleUrls: ['./form-steps.component.scss']
})
export class FormStepsComponent implements OnInit {
  @Input() steps: number[] = [1,2];
  @Input() currentStep: number = 2;
  constructor() { }
  ngOnInit() {
  }

}
