import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'reduce', pure: true })
export class ReduceArrayPipe implements PipeTransform {

  constructor() { }

  transform(items: any[], prop: string): number | string {
    return items.reduce((a, b) => a + (b[prop] || 0), 0);
  }
} 

