import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { HelperService } from '@services/helper/helper.service';
import { ProgramService } from '@services/program/program.service';
import { forkJoin, throwError } from 'rxjs';
@Component({
  selector: 'cah-catalog-settings',
  templateUrl: './catalog-settings.component.html',
  styleUrls: ['./catalog-settings.component.scss']
})
export class CatalogSettingsComponent implements OnInit {

  @Input() program;
  @Output() onChange = new EventEmitter<any>();
  @Output() onImageChange = new EventEmitter<any>();
  
  payload: {};
  formValue: {};
  loading = false;
  alertOption: any = { isError: false, isOpen: false, message: "" };

  settingsForm!: FormGroup;
  constructor(private fb: FormBuilder, private programService: ProgramService, private http: HttpClient) { }

  keys = {
    catalogAboutProgram: 'about_program',
    catalogAlumniReviews: '',
    catalogCohortPeers: 'cohort_peers',
    catalogCohortSchedule: 'cohort_schedule',
    catalogCourseProvider: 'course_provider',
    catalogCustomersLogos: 'course_customers_logos',
    catalogLogoSlider: 'logo_slider',
    catalogFaq: 'faq',
    catalogProperties: 'course_properties',
    catalogHeader: 'course_header',
    catalogImagesGallery: 'image_gallery',
    catalogLargeQuote: 'large_quote',
    catalogSkills: 'skills',
    catalogSyllabus: 'syllabus',
    catalogTrainers: 'trainers',
    catalogAskQuestion: 'ask_question',
    catalogVideo: 'video',
    catalogLiveLearning: 'live_learning',
    catalogCertificate: 'certificate'
  }

  settings = {};
  ngOnInit() {
    this.buildForm();
    // console.log(this.settingsForm.controls)
    console.log(this.program)

    this.settingsForm.valueChanges.subscribe(value => {
      // Handle form value changes
      console.log(value);
      this.formValue = value;
      // this.payload = { ...this.payload,  ...value};    
      // this.onChange.emit(this.payload);
    });    

    this.getProgramSettings(this.program.id).pipe(
      // take(1)
    ).subscribe(res => {

      this.settings = this.setSettings(res);
      console.log(this.settings)

      console.log('RES ',res)
      this.changeProps(res);
      for(const key in res) {
        const formControlName = this.keys[key];
        console.log({formControlName})
        if(formControlName) {
          this.settingsForm.get(formControlName).patchValue(res[key]);
        }
      }

      const categories = res['catalogProperties'].catalogPropertyCategory.map(el => el.catalogCategory.slug);
      this.settingsForm.get('course_properties.categories').patchValue(categories);

    });
  }

  changeProps(formGroups: any) {
    if(!formGroups) return;
    formGroups['catalogLogoSlider'] = {
      ...formGroups['catalogLogoSlider'],
      slider_1: formGroups['catalogLogoSlider'].logo_1,
      slider_2: formGroups['catalogLogoSlider'].logo_2,
      slider_3: formGroups['catalogLogoSlider'].logo_3,
      slider_4: formGroups['catalogLogoSlider'].logo_4,
      slider_5: formGroups['catalogLogoSlider'].logo_5,
      slider_6: formGroups['catalogLogoSlider'].logo_6,
      slider_7: formGroups['catalogLogoSlider'].logo_7,
      slider_8: formGroups['catalogLogoSlider'].logo_8,
    }
  }

  setSettings(res) {
    let settings = {};
    for(const key in res) {
      const formControlName = this.keys[key];
      if(formControlName) {
        settings[formControlName] = res[key];
      }
    }
    return settings;
  }

  jsonToFormData(jsonData) {
    const formData = new FormData();
  
    const appendToFormData = (data, prefix = '') => {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          const name = prefix ? `${prefix}[${key}]` : key;
          const value = data[key];
          if (typeof value === 'object' && !(value instanceof File)) {
            appendToFormData(value, name);
          } else if (value instanceof File) {
            // formData.append(name, value, value.name);
          } else {
            formData.append(name, value);
          }
        }
      }
    };
  
    appendToFormData(jsonData);
  
    return formData;
  }

  uploadImages(jsonData: any) {
    enum ImagesEnum {
      COURSE_HEADER_IMAGE = 'course_header_image',
      COURSE_HEADER_PROVIDER_LOGO = 'course_header_provider_logo',
      LARGE_QUOTE_COMPANY_LOGO = 'large_quote_company_logo',
      ABOUT_PROGRAM_IMAGE = 'about_program_image',
      SKILLS_1_IMAGE = 'skill_1_image',
      SKILLS_2_IMAGE = 'skill_2_image',
      SKILLS_3_IMAGE = 'skill_3_image',
      TRAINERS_1_IMAGE = 'trainer_1_image',
      TRAINERS_2_IMAGE = 'trainer_2_image',
      COURSE_PROVIDER_LOGO = 'course_provider_logo',
      COURSE_CUSTOMERS_LOGO_1 = 'course_customers_logo_1',
      COURSE_CUSTOMERS_LOGO_2 = 'course_customers_logo_2',
      COURSE_CUSTOMERS_LOGO_3 = 'course_customers_logo_3',
      COURSE_CUSTOMERS_LOGO_4 = 'course_customers_logo_4',
      COURSE_CUSTOMERS_LOGO_5 = 'course_customers_logo_5',
      COURSE_CUSTOMERS_LOGO_6 = 'course_customers_logo_6',
      COURSE_CUSTOMERS_LOGO_7 = 'course_customers_logo_7',
      COURSE_CUSTOMERS_LOGO_8 = 'course_customers_logo_8',
      IMAGE_GALLERY_IMAGE_1 = 'image_gallery_image_1',
      IMAGE_GALLERY_IMAGE_2 = 'image_gallery_image_2',
      IMAGE_GALLERY_IMAGE_3 = 'image_gallery_image_3',
      VIDEO_FILE = 'video_file',
      SLIDER_LOGO_1 = 'slider_logo_1',
      SLIDER_LOGO_2 = 'slider_logo_2',
      SLIDER_LOGO_3 = 'slider_logo_3',
      SLIDER_LOGO_4 = 'slider_logo_4',
      SLIDER_LOGO_5 = 'slider_logo_5',
      SLIDER_LOGO_6 = 'slider_logo_6',
      SLIDER_LOGO_7 = 'slider_logo_7',
      SLIDER_LOGO_8 = 'slider_logo_8',
    }

    const imageFields = {
      [ImagesEnum.ABOUT_PROGRAM_IMAGE]: jsonData.about_program.image,
      [ImagesEnum.COURSE_CUSTOMERS_LOGO_1]: jsonData.course_customers_logos.logo_1,
      [ImagesEnum.COURSE_CUSTOMERS_LOGO_2]: jsonData.course_customers_logos.logo_2,
      [ImagesEnum.COURSE_CUSTOMERS_LOGO_3]: jsonData.course_customers_logos.logo_3,
      [ImagesEnum.COURSE_CUSTOMERS_LOGO_4]: jsonData.course_customers_logos.logo_4,
      [ImagesEnum.COURSE_CUSTOMERS_LOGO_5]: jsonData.course_customers_logos.logo_5,
      [ImagesEnum.COURSE_CUSTOMERS_LOGO_6]: jsonData.course_customers_logos.logo_6,
      [ImagesEnum.COURSE_CUSTOMERS_LOGO_7]: jsonData.course_customers_logos.logo_7,
      [ImagesEnum.COURSE_CUSTOMERS_LOGO_8]: jsonData.course_customers_logos.logo_8,
      [ImagesEnum.COURSE_HEADER_IMAGE]: jsonData.course_header.header_image,
      [ImagesEnum.COURSE_HEADER_PROVIDER_LOGO]: jsonData.course_header.provider_logo,
      [ImagesEnum.COURSE_PROVIDER_LOGO]: jsonData.course_provider.course_provider_logo,
      [ImagesEnum.IMAGE_GALLERY_IMAGE_1]: jsonData.image_gallery.image_1,
      [ImagesEnum.IMAGE_GALLERY_IMAGE_2]: jsonData.image_gallery.image_2,
      [ImagesEnum.IMAGE_GALLERY_IMAGE_3]: jsonData.image_gallery.image_3,
      [ImagesEnum.LARGE_QUOTE_COMPANY_LOGO]: jsonData.large_quote.company_logo,
      [ImagesEnum.SKILLS_1_IMAGE]: jsonData.skills.skill_1_image,
      [ImagesEnum.SKILLS_2_IMAGE]: jsonData.skills.skill_2_image,
      [ImagesEnum.SKILLS_3_IMAGE]: jsonData.skills.skill_3_image,
      [ImagesEnum.TRAINERS_1_IMAGE]: jsonData.trainers.trainer_1_image,
      [ImagesEnum.TRAINERS_2_IMAGE]: jsonData.trainers.trainer_2_image,
      [ImagesEnum.SLIDER_LOGO_1]: jsonData.logo_slider.slider_1,
      [ImagesEnum.SLIDER_LOGO_2]: jsonData.logo_slider.slider_2,
      [ImagesEnum.SLIDER_LOGO_3]: jsonData.logo_slider.slider_3,
      [ImagesEnum.SLIDER_LOGO_4]: jsonData.logo_slider.slider_4,
      [ImagesEnum.SLIDER_LOGO_5]: jsonData.logo_slider.slider_5,
      [ImagesEnum.SLIDER_LOGO_6]: jsonData.logo_slider.slider_6,
      [ImagesEnum.SLIDER_LOGO_7]: jsonData.logo_slider.slider_7,
      [ImagesEnum.SLIDER_LOGO_8]: jsonData.logo_slider.slider_8,
      [ImagesEnum.VIDEO_FILE]: jsonData.video.video_file,
    }
    
    const observables = [];
    for(const field in imageFields) {
      const value = imageFields[field];
      if(value instanceof File) {
        const formData = new FormData();
        formData.append('image', value, value.name);
        const http = this.http.post(`${environment.endpoint}programs/program/${this.program.id}/catalog?catalog_section=${field}`, formData);
        observables.push(http);
      }
    }
    return observables;
  }

  updateSettings() {
    this.loading = true;
    console.log(this.formValue);
    const imageObservables = this.uploadImages(this.settingsForm.value);
    const formData = this.jsonToFormData(this.settingsForm.value);  

    const observables = [...imageObservables];

    const settingsUpdate = this.http.patch(`${environment.endpoint}programs/program/${this.program.id}/catalog`, formData);
    observables.push(settingsUpdate);

    forkJoin(observables).subscribe(results => {
      console.log({results});
      this.alertOption = { isError: false, isOpen: true, message: 'successfully_saved' };
      this.loading = false;
    }, error => {
      // Handle errors
      this.alertOption = { isError: true, isOpen: true, message: '' };
      this.loading = false;
      console.error(error);
    });
    
    // return this.http.patch(`${environment.endpoint}programs/program/${this.program.id}/catalog`, formData).pipe(
    //   take(1),
    //   shareReplay(1),
    //   map( res => res ),
    //   catchError( error => {
    //     return throwError(error.error || "server error")
    //   })
    // ).subscribe(res => {
    //   console.log(res)
    //   // this.getProgramSettings(this.program.id);
    // });
  }

  getProgramSettings(program_id) {
    return this.http.get(`${environment.endpoint}programs/program/${program_id}/catalog`)
  }

  imageChange(e): void {
    this.payload = { ...this.payload,  [e.name]: e.value};    
    this.onImageChange.emit(this.payload);
  }

  getForm(formName: string): FormGroup {
    return this.settingsForm.get(formName) as FormGroup;
  }

  updateControl(subformGroup: string, controlName: string, value: any) {
    const subform = this.settingsForm.get(subformGroup) as FormGroup;
    console.log({subform});
    
    subform.patchValue({ [controlName]: value });
  }

  categoryList = [
    {
      id: 'lead_excel',
      title: 'Leadership Excellence',
      slug: 'lead_excel'
    },
    {
      id: 'org_power',
      title: 'Organizational Power Skills',
      slug: 'org_power'
    },
    {
      id: 'customer_relations',
      title: 'Customer Relationships',
      slug: 'customer_relations'
    },
    {
      id: 'other_skills',
      title: 'Other skills',
      slug: 'other_skills'
    }
  ];

  selectSearch(e){
    // e.name == "manager_id" ? this.getStaff({search: e.value}) : this.getCategories({search: e.value});
  }
  buildForm() {
    this.settingsForm = this.fb.group({
      course_properties: this.fb.group({
        search_tags: [null, Validators.required],
        categories: [[], Validators.required],
        is_featured: [true, []],
      }),
      course_header: this.fb.group({
        course_name: ['Coding Academy: From Novice To Professional', Validators.required],
        audience_for: ['Coding Academy: From Novice To Professional', Validators.required],
        short_description: [null, Validators.required],
        header_image: [null, Validators.required],
        feedback_visible: [true, []],
        feedback_score: [null, []],
        feedback_number_of_ratings: [null, []],
        provider_logo: [null, []]
      }),
      video: this.fb.group({
        is_visible: [true, []],
        order: [null, [Validators.required]],
        section_background_color: ['#5C4BFF', [Validators.required]],
        video_file: [null, [Validators.required]],
        size: [null, [Validators.required]],
        // role: [null, Validators.required],
      }),
      live_learning: this.fb.group({
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        is_visible: [true, []],
        order: [null, [Validators.required]],
        section_background_color: ['#5C4BFF', [Validators.required]],
        bullets_circle_color: ['#5C4BFF', [Validators.required]],
        bullets_icon_color: ['#5C4BFF', [Validators.required]],
        bullets_text_color: ['#5C4BFF', Validators.required],
      }),
      certificate: this.fb.group({
        is_visible: [true, []],
        order: [null, [Validators.required]],
        section_background_color: ['#5C4BFF', [Validators.required]],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        logo_color: ['#5C4BFF', [Validators.required]],
        bullets_color: ['#5C4BFF', [Validators.required]],
        bullets_text_color: ['#5C4BFF', Validators.required],
      }),
      large_quote: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['', Validators.required],
        quote_text: [null, [Validators.maxLength(80)]],
        stars_show_hide: [true, Validators.required],
        star: [null],
        alumni_details:  [null, [Validators.required]],
        quote_alumni_details_color:  ['#5C4BFF', []],
        company_logo: [null],
      }),
      cohort_peers: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['#5C4BFF', Validators.required],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['', Validators.required],
        // image: [null, Validators.required],
        peers_details_text_color: [''],
      }),
      about_program: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['#5C4BFF', Validators.required],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        image: [null, Validators.required],
        about:  [null, [Validators.required]],
        about_text_color:  ['#5C4BFF', []]
      }),
      skills: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['#5C4BFF', Validators.required],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        skill_component_bg_color: ['#5C4BFF', Validators.required],
        skill_title_text_color:  ['#5C4BFF', [Validators.required]],
        skill_1_title:  [null, []],
        skill_1_text: [null, []],
        skill_1_image: [null, []],
        skill_2_title: [null, []],
        skill_2_text: [null, []],
        skill_2_image: [null, []],
        skill_3_title:  [null, []],
        skill_3_text: [null, []],
        skill_3_image: [null, []],
      }),
      trainers: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['#5C4BFF', Validators.required],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        trainer_details_text_color: ['#5C4BFF', Validators.required],
        trainer_1_image:  [null, [Validators.required]],
        trainer_1_name:  [null, []],
        trainer_1_title: [null, []],
        trainer_1_linkedin_url: [null, []],
        trainer_1_text: [null, []],
        trainer_2: [true, []],
        trainer_2_image: [null, []],
        trainer_2_name: [null, []],
        trainer_2_title: [null, []],
        trainer_2_linkedin_url: [null, []],
        trainer_2_text: [null, []],
      }),
      course_provider: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['#5C4BFF', Validators.required],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        provider_details_color: ['#5C4BFF', Validators.required],
        course_provider_logo:  [null],
        provider_name:  [null, []],
        linkedin_url: [null, []],
        website_url: [null, []],
        provider_description: [null, []],
      }),
      course_customers_logos: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['#5C4BFF', Validators.required],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        logos_overley_color: ['#5C4BFF', []],
        logo_1: [null],
        logo_2: [null],
        logo_3: [null],
        logo_4: [null],
        logo_5: [null],
        logo_6: [null],
        logo_7: [null],
        logo_8: [null],
      }),
      logo_slider: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['#5C4BFF', Validators.required],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        logos_overley_color: ['#5C4BFF', []],
        slider_1: [null],
        slider_2: [null],
        slider_3: [null],
        slider_4: [null],
        slider_5: [null],
        slider_6: [null],
        slider_7: [null],
        slider_8: [null],
      }),
      // customers_logo: this.fb.group({
      //   is_visible: [true, []],
      //   section_sub_title: [null, []],
      //   sub_title_color: [null, Validators.required],
      //   logos_overley_color: [null, []],
      //   logo_1: [null],
      //   logo_2: [null],
      //   logo_3: [null],
      //   logo_4: [null],
      //   logo_5: [null],
      //   logo_6: [null],
      //   logo_7: [null],
      //   logo_8: [null],
      // }),
      // alumni_reviews: this.fb.group({
      //   is_visible: [true, []],
      //   order: [null, []],
      //   section_background_color: [null, Validators.required],
      //   section_title: [null, [Validators.maxLength(80)]],
      //   title_color: [true, Validators.required],
      //   quote_alumni_details_text_color: [null, []],
      //   alumni_1_review_visible: [true],
      //   alumni_1_review_text: [null],
      //   alumni_1_review_stars: [null],
      //   alumni_1_review_avatar_image: [null],
      //   alumni_1_name: [null],
      //   alumni_1_company: [null],
      //   alumni_1_role: [null],
      //   alumni_2_review_visible: [true],
      //   alumni_2_review_text: [null],
      //   alumni_2_review_stars: [null],
      //   alumni_2_review_avatar_image: [null],
      //   alumni_2_name: [null],
      //   alumni_2_company: [null],
      //   alumni_2_role: [null],
      //   alumni_3_review_visible: [true],
      //   alumni_3_review_text: [null],
      //   alumni_3_review_stars: [null],
      //   alumni_3_review_avatar_image: [null],
      //   alumni_3_name: [null],
      //   alumni_3_company: [null],
      //   alumni_3_role: [null],
      //   alumni_4_review_visible: [true],
      //   alumni_4_review_text: [null],
      //   alumni_4_review_stars: [null],
      //   alumni_4_review_avatar_image: [null],
      //   alumni_4_name: [null],
      //   alumni_4_company: [null],
      //   alumni_4_role: [null],
      //   alumni_5_review_visible: [true],
      //   alumni_5_review_text: [null],
      //   alumni_5_review_stars: [null],
      //   alumni_5_review_avatar_image: [null],
      //   alumni_5_name: [null],
      //   alumni_5_company: [null],
      //   alumni_5_role: [null],
      //   alumni_6_review_visible: [true],
      //   alumni_6_review_text: [null],
      //   alumni_6_review_stars: [null],
      //   alumni_6_review_avatar_image: [null],
      //   alumni_6_name: [null],
      //   alumni_6_company: [null],
      //   alumni_6_role: [null],
      // }),
      syllabus: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['#5C4BFF', Validators.required],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        bullet_1_visible: [true],
        bullet_1_title: [null],
        bullet_1_text: [null],
        bullet_2_visible: [true],
        bullet_2_title: [null],
        bullet_2_text: [null],
        bullet_3_visible: [true],
        bullet_3_title: [null],
        bullet_3_text: [null],
        bullet_4_visible: [true],
        bullet_4_title: [null],
        bullet_4_text: [null],
        bullet_5_visible: [true],
        bullet_5_title: [null],
        bullet_5_text: [null],
        bullet_6_visible: [true],
        bullet_6_title: [null],
        bullet_6_text: [null],
        bullet_7_visible: [true],
        bullet_7_title: [null],
        bullet_7_text: [null],
        bullet_8_visible: [true],
        bullet_8_title: [null],
        bullet_8_text: [null],
      }),
      image_gallery: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['#5C4BFF', Validators.required],
        image_1_visible: [null],
        image_1: [null],
        image_2_visible: [null],
        image_2: [null],
        image_3_visible: [null],
        image_3: [null],
      }),
      cohort_schedule: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['#5C4BFF', Validators.required],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        timezone_note_color: ['#5C4BFF'],
      }),
      faq: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_background_color: ['#5C4BFF', Validators.required],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        bullet_1_visible: [true],
        bullet_1_title: [null],
        bullet_1_text: [null],
        bullet_2_visible: [true],
        bullet_2_title: [null],
        bullet_2_text: [null],
        bullet_3_visible: [true],
        bullet_3_title: [null],
        bullet_3_text: [null],
        bullet_4_visible: [true],
        bullet_4_title: [null],
        bullet_4_text: [null],
        bullet_5_visible: [true],
        bullet_5_title: [null],
        bullet_5_text: [null],
        bullet_6_visible: [true],
        bullet_6_title: [null],
        bullet_6_text: [null],
        bullet_7_visible: [true],
        bullet_7_title: [null],
        bullet_7_text: [null],
        bullet_8_visible: [true],
        bullet_8_title: [null],
        bullet_8_text: [null],
      }),
      ask_question: this.fb.group({
        is_visible: [true, []],
        order: [null, []],
        section_title: [null, [Validators.maxLength(80)]],
        title_color: ['#5C4BFF', Validators.required],
        section_background_color: ['#5C4BFF', Validators.required],
        description_text_color: ['#5C4BFF', Validators.required]
      }),
    });
  }

  get f() { return this.settingsForm.controls; }

  toggleAccordian(event:any, index: any) {
    let element = event.target;
    if (element.tagName === 'SPAN') {
      element = element.closest('button');
    }
    element.classList.toggle("active");
    // if (this.data[index].isActive) {
    //   this.data[index].isActive = false;
    // } else {
    //   this.data[index].isActive = true;
    // }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
      panel.style.overflow = "hidden";
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
      panel.style.overflow = "visible";
    }
  }


  onValueChange(data: any, formGroup: string) {
    // console.log(data)
    // console.log(formGroup)
    this.updateControl(formGroup, data.name, data.value);
  }

  onFileSelected(event: any, formGroup: string) {
    const file = event.value;
    console.log(file)

    // Convert file to base64 string
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const base64String = e.target.result;
      // Set base64 string as value of FormControl
      // this.fileFormControl.setValue(base64String);
      this.updateControl(formGroup, event.name, base64String);
    };
    reader.readAsDataURL(file);
  }
  
  stopPropagation(e) {
    e.stopPropagation()
  }

  onValidityCheck(e) {
    // this.colorPickerInfo = e;
    // this.isValidColor = e.ratioValid;
    // console.log(e)
  }

  onInputChange(e, formGroup: string) {    
    this.updateControl(formGroup, e.name, e.value);

    // this.payload = { ...this.payload,  [e.name]: e.value};    
    // this.onChange.emit(this.payload);

    // HelperService.blobToDataUri(data.value).then(res => console.log(res))
  }

  async uploadProgramImages(file: any, source_type: string): Promise<any> {
    const formData = new FormData();
    formData.append('image', file);
    const result = await this.programService.uploadProgramImage(formData, { source_type }).toPromise();
    return result;
  }

}
