import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef, ViewChild, ContentChild } from '@angular/core';
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';
import { HelperService } from '@services/helper/helper.service';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { BoardHeaderType } from 'app/enums';
import { SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'cah-board-header',
  templateUrl: './board-header.component.html',
  styleUrls: ['./board-header.component.scss']
})
export class BoardHeaderComponent implements OnInit {
  scrollLeft: number = 0;
  @Input() days: any[] = [];
  @Input() selectedDayIndex: number;
  @Input() boardHeaderType: BoardHeaderType = BoardHeaderType.OTHER;
  @Input() isSession: boolean = true;
  @Input() timeslots: any[] = [];
  @Input() timeslotStart: number;
  @Input() timeslotLimit: number;
  @Input() columnMaxWidth: string = '260px';
  @Input() isLiveSchedule: boolean = false;
  @Input() language: string = 'en';
  @Input() hovered: any = {};
  @Input() groupsQty: number = 0;
  @Input() isEmpty: boolean = false;
  @Input() staffQty: number = 0;
  @Input() canCopy: boolean = false;
  @Input() canTimeslotCopied: boolean = false;
  @Input() hasActions: boolean = true;
  @ViewChild('scroll', { static: true  }) scroll: ElementRef;
  @Input() scrollHeader: number = 0;
  @Input() currentTimeslotId: number = 0;
  @Output() onAddStaff: EventEmitter<boolean> = new EventEmitter();
  @Output() onAddGroup: EventEmitter<boolean> = new EventEmitter();
  @Output() onDaySelect: EventEmitter<any> = new EventEmitter();
  @Output() onScroll: EventEmitter<number> = new EventEmitter();
  @Output() onTimeslotChange: EventEmitter<any> = new EventEmitter();
  @Output() onTimeslotAction: EventEmitter<any> = new EventEmitter();
  @Output() onHover: EventEmitter<any> = new EventEmitter();
  @ViewChild('noscroll', { static: true }) noscroll: ElementRef;
  @ViewChild('calendar', { static: false}) calendar: Calendar;


  selectedDay: any;
  boardHeaderTypeEnum = BoardHeaderType;
  timeslotDropdown: any = { col: false };
  hasHorizontalScrollbar: boolean = false;
  showCopyTooltip: any = {};
  calendIsOpen: boolean = false;
  disabledDates: any = [];
  moment = moment;
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false
  liveSchedScroll: boolean = false;

  constructor(public dateFormatter: DateFormatterService, private dateFormatterService: DateFormatterService,
    public languageService: LanguageService,) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.getAttribute('class') !== 'icon-dots-icon' &&
      event.target.getAttribute('class') !== 'icon-svg-dots') {
      this.timeslotDropdown = {
        col: null
      }
    }
  }

  ngOnInit() {
  }

  mouseOver(row, col) {
    this.hovered = {
      row,
      col
    }
    this.onHover.emit(this.hovered)
  }

  ngAfterViewInit(): void {
    this.onCheckVerticalScroll();
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      if (this.isLiveSchedule) {
        const div: any = document.getElementById('cah-board__timeslots');
        if(div && div.scrollLeft){
          div.scrollLeft = this.scrollHeader; 
        }
        if(div && div.scrollWidth && div.clientWidth){
          this.liveSchedScroll =  div.scrollWidth > div.clientWidth;
        }
        this.scrollLeft = this.scrollHeader;
      }
      if(!this.isLiveSchedule) {
        this.onCheckVerticalScroll();
      }
    })
    this.selectedDay = this.selectedDayIndex != null && this.days ? this.days[this.selectedDayIndex] : null;
  }

  selectDay(date: Date) {
    let tmpDate = moment(date).format('YYYY-MM-DD');
    if(this.days.includes(tmpDate)){
      this.selectedDayIndex = this.days.indexOf(tmpDate);
      this.selectedDay = this.selectedDayIndex != null && this.days ? this.days[this.selectedDayIndex] : null;
      this.onDaySelect.emit(this.selectedDayIndex)
    }
  }

  getMarkedDays(date) {
    const dateString = `${date.year.toString()}-${('00' + (date.month + 1).toString()).substr(-2)}-${('00' + date.day.toString()).substr(-2)}`;
    const returnedStatus = this.days.indexOf(dateString) > -1 ? true : false;
    return returnedStatus;
  }

  onOpenCalendar(){
    let p = {
      month: (new Date()).getMonth() + 1,
      year: (new Date()).getFullYear()
    }
    this.onMonthChange(p);
  }

  onMonthChange(e){    
    this.disabledDates = [];
    this.dateFormatterService.getDaysInMonth(e.month-1, e.year).map(date =>{
   
    let dateString = this.dateFormatterService.formatDateToYmD(date);
    const returnedStatus = this.days.indexOf(dateString) > -1 ? true : false;
      if(!returnedStatus){
           this.disabledDates.push(new Date(dateString));
      }
    }) 
  }

  onCheckVerticalScroll(): void {
    if (this.isLiveSchedule) { return; }
    const div = document.getElementById('board-right-list');
    this.hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
  }

  onShowDropDown(e: Event, i: number): any {
    e.stopPropagation();
    if (this.timeslotDropdown.col === i) {
      return this.timeslotDropdown.col = false;
    }
    this.timeslotDropdown.col = i;
  }

  nextDay() {
    if (this.selectedDayIndex < this.days.length - 1) {
      this.onDaySelect.emit(this.selectedDayIndex + 1)
    }
  }

  previousDay() {
    if (this.selectedDayIndex > 0) {
      this.onDaySelect.emit(this.selectedDayIndex - 1)
    }
  }

  changeTimeslot(action) {
    this.onTimeslotChange.emit(action)
  }

  changeTimeToAmPm(time) {
    return HelperService.timeToAmPm(time);
  }

  onActionTimeslot(e: Event, name: string, option: any, j: number = 0): void {
    e.stopPropagation();
    if (j >= 0) {
      this.showCopyTooltip[j] = true;
      setTimeout(() => {
        this.showCopyTooltip[j] = false;
      },
        350);
    }
    this.onTimeslotAction.emit({ name, option });
  }
  onScrollBox(event, option: string = 'scroll') {
    switch (option) {
      case 'previous':
        this.onScroll.emit(this.scrollLeft -= 30)
        break;
      case 'next':
        this.onScroll.emit(this.scrollLeft += 30)
        break;
      default:
        this.onScroll.emit(event.target.scrollLeft);
        break;
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
