import { Injectable } from '@angular/core';
import { 
  ActivatedRouteSnapshot, 
  CanActivate, 
  Router, 
  RouterStateSnapshot, 
  UrlTree 
} from '@angular/router';
import { UserService } from '@services/user/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SecureInnerPagesGuard implements CanActivate {
  isUserAuthenticated$: Observable<boolean>;
  
  constructor(
    private router: Router,
    private authService: UserService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (localStorage.getItem('token')) {
        const user = this.authService.userInfo.getValue();
  
        if (!user) {
          return this.authService.getUserInfo()
            .pipe(
              map(r => {
                if (!r) {
                  return true;
                }
  
                this.router.navigate(['/'], {replaceUrl: true});
                return false;
              })
            );
        } else {
          this.router.navigate(['/'], {replaceUrl: true});
          return false;
        }
  
      } else {
        return true;
      }
  }

}