import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserRolesModel } from '../../models/user-roles.model';
import { environment } from '../../../environments/environment';
import { UserRole } from 'app/enums';
import { UserService } from '@services/user/user.service';
import { FeatureAccess } from '@access';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private temporaryEndpoind: string = environment.endpoint;
  userInfo: any;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
    this.userService.userInfo.subscribe(user => this.userInfo = user)
  }

  public getRoles(): Observable<UserRolesModel[]> {
    return this.http.get<UserRolesModel[]>(this.temporaryEndpoind + 'users/roles')
      .pipe(
        tap(d => {
        })
      );
  }

  checkRole(feature){
    return FeatureAccess[feature].has(this.userInfo.role_id);
  }

}
