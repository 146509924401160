import { Component, OnInit, OnDestroy } from '@angular/core';
import { distinctUntilChanged, filter, takeWhile } from 'rxjs/operators';

import { MainMenuService } from '@services/main-menu.service';
import { UserService } from '@services/user/user.service';
import { CampService } from '@services/camp/camp.service';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { MenuItem } from 'app/models';
import { UserRole } from '@enums'; 
import { LanguageService } from '@services/language/language.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ScriptService } from '@services/script/script.service';

@Component({
  selector: 'cah-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        margin: '0 0 0 100%',
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        margin: '0 0 0 0',
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ]),
  ]
})
export class NavigationComponent implements OnInit, OnDestroy {
  mainMenu: MenuItem[] = [];
  expandMenuItem: MenuItem | null = null;
  componentActive = true;
  userRole: number;
  userRoleEnum: any = UserRole;
  activeUrl: string = '/';
  expandMenuType: string = 'menu';
  camp: any;
  defaultLogo = '/assets/images/clicktocamplogo.png';
  menuState: string = 'out';
  user: any;

  constructor(
    private mainMenuService: MainMenuService,
    private userService: UserService,
    public campService: CampService,
    public languageService: LanguageService,
    private router: Router,
    private scriptService: ScriptService
  ) {
    if(this.router.url.split('/')[2] == 'scheduling' || this.router.url.split('/')[2] == 'staff-scheduling'){
      this.activeUrl = this.router.url;
    } else {
      this.activeUrl = this.router.url;
    }
    
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe((val: any) => {      
        if(val.url.split('/')[2] == 'scheduling' || val.url.split('/')[2] == 'staff-scheduling'){
          this.activeUrl = val.url;
        } else {
          this.activeUrl = val.url;
        }
    });
  }

  ngOnInit(): void {
    this.userService.userInfo.subscribe(
      res => {
        if (!res) { return; }
        this.userRole = res.role.id;
        this.user = res;
        this.getCamp(res.camp_id);
      }
    );

    this.mainMenuService.setProfileImage('/assets/images/profile-example.jpg');

    this.mainMenuService.mainMenu.pipe(
      takeWhile(value => this.componentActive)
    ).subscribe(menu => {
      this.mainMenu = menu;
    });
  }

  getCamp(id: string) {
    this.campService.campInfo$.subscribe(
      res => {
        this.camp = res;
      }
    )
  }

  onClickMenuItem(url: string): void {
    if(url == '/help'){
      this.scriptService
      .load("freshworks")
      .then((data) => {

        setTimeout(() => {
          (window as any).FreshworksWidget('hide', 'launcher');
          (window as any).FreshworksWidget('open');
        }, 800)
        
      })
      .catch((error) => console.log(error));



      return;
    }
    const findMenuItem: MenuItem = this.mainMenu.find(item => item.url === url);
    
    if (findMenuItem && findMenuItem.childrens && findMenuItem.childrens.length) {
      this.expandMenuType = url === '/settings' ? 'settings' : 'menu';
      if (this.expandMenuItem && this.expandMenuItem.url === url || !url) {
        this.menuState = 'out';
        this.expandMenuItem = null;
      } else {
        this.activeUrl = url;
        this.menuState = 'in';
        this.expandMenuItem = findMenuItem;
      }
    } else {
      this.menuState = 'out';
      this.expandMenuItem = null;
      this.activeUrl = url;
      url || url === '/' ? this.router.navigate([url]) : this.activeUrl = url;
    }
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

}
