import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'cah-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() newFields: boolean = false;
  @Input() label: string;
  @Input() formFields: any[];
  @Input() formModel: any = {};
  @Input() formErrors: any;
  @Input() serverError: any;
  @Input() listServerError: any;
  @Input() staticDataForFields: any;
  @Input() loading: boolean = false;
  @Input() showCloseButton: boolean = true;
  @Input() edit: boolean = false;
  @Input() width: string = "560px";
  @Input() onlySubmit: boolean = false;
  @Input() header: boolean = true;
  @Input() language: string = 'en';
  @Input() primaryButtonText: string = "add";
  @Input() customClass: string = 'custom_box';
  @Input() steps: number[];
  @Input() previousStepData: any[] = [];
  @Input() additionalButtons: any[] = [];
  @Input() hideSteps: boolean = false;
  @Input() disableButtons : boolean;
  @Input() scrollToTopOnInit : boolean;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onCheckboxClick: EventEmitter<any> = new EventEmitter();
  @Output() onSelectChange: EventEmitter<any> = new EventEmitter();
  @Output() onAddGroup: EventEmitter<any> = new EventEmitter();
  @Output() onSelectSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSelectButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onBtnClick: EventEmitter<any> = new EventEmitter();
  @Output() onSelectDropdownToggle: EventEmitter<any> = new EventEmitter();
  @Output() onTabChange: EventEmitter<any> = new EventEmitter();

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == "he";
      });
  }

  ngOnInit() {
  }

  onGroupAdd(event) {
    this.onAddGroup.emit(event);
  }

  onFormSubmit(event) {
    setTimeout(() => this.onSubmit.emit(event))
  }

  closeModal() {
    this.onClose.emit(false);
  }

  onClickButton(event) {
    this.onButtonClick.emit(event);
  }

  onClickCheckbox(event) {
    this.onCheckboxClick.emit(event);
  }

  selectSearch(event){
    this.onSelectSearch.emit(event);
  }

  buttonClick(type){
    this.onSelectButtonClick.emit(type);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
