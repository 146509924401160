import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SpaceService } from '@services/space/space.service';
import { SplashError, RoomTypeEnum } from '@enums';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CampService } from '@services/camp/camp.service';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'cah-guest-splash',
  templateUrl: './guest-splash.component.html',
  styleUrls: ['./guest-splash.component.scss']
})
export class GuestSplashComponent implements OnInit {
  logo: string = '/assets/images/splash/logo.png';
  activityData: any = {};
  guestName: string = '';
  spaceTitle: string = '';
  hash: string = '';
  error: number = 0;
  loading: boolean = true;
  campId: number = 123;
  campInfo: any = null;
  public get roomTypeResult(): typeof RoomTypeEnum {
    return RoomTypeEnum; 
  }

  isHebrew: boolean = false;

  constructor(private route: ActivatedRoute,
              private spaceService: SpaceService,
              private campService: CampService
              ) {
                this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;
               }

  ngOnInit(): void | number {
    // check hash
    this.hash = this.route.snapshot.params.hash;
    if (!this.hash) {
      this.loading = false;
      return this.error = SplashError.EXPIRED;
    }

    this.spaceService.getSpaceByGuestCode(this.hash).subscribe(space => {
      this.campId = space.camp_id;
      this.campInfo = space.camp;
      this.spaceTitle = space.title;
    });

    this.spaceService.getGuestSpaceData(this.hash)
      .subscribe(res => {
        this.activityData = res;
        this.logo = res.campLogo && res.campLogo.length ? res.campLogo[0].full_path : '';
        this.loading = false;
       }, () => {
        this.loading = false;
        this.error = SplashError.EXPIRED;
      });

  }

  onRedirectMeeting(): any {
    this.loading = true;
    if (this.guestName.length < 2) { return this.loading = false; }
    this.spaceService.goToGuestKalturaMeeting(this.hash, this.guestName, this.campId, this.spaceTitle, 1, this.logo, this.campInfo.room_type)
      .subscribe((res: any) => {
        this.loading = false;
        HelperService.goToLink(res.url, '_self');
      }, err => {
        this.loading = false;
      });
  }

}
