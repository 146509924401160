export enum ModelTypeEnum {
  NONE = "",
  BtnPRIMARY = "primary",
  BtnSECONDARY = "secondary",
  DYNAMIC = "dynamic",
  BtnICON = "icon",
  BtnICONTRANSPARENT = "iconTransparent",
  BtnTEXT = "text",
  CbxTOGGLE = "switch",
  CbxCHECKBOX = "checkbox",
}
