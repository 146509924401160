import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cah-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss']
})
export class DatepickerRangeComponent implements OnInit {

  inlineRangeChange(newObj) {
    console.log(newObj.value)
  }
  
  constructor() { }

  ngOnInit() {
  }

}
