import {Injectable} from '@angular/core';
import {Router, CanLoad, CanActivate, CanActivateChild, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {UserService} from '@services/user/user.service';
import {catchError, map, switchMap, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authService: UserService
  ) {
  }

  canLoad(): Observable<boolean> | boolean {
    const user = this.authService.userInfo.getValue();

    if (localStorage.getItem('token')) {

      if (!user) {
        return this.authService.getUserInfo()
          .pipe(
            map(r => {
              if (r) {
                return true;
              } else {
                return false;
              }
            })
          );
      } else {
        return true;
      }

    } else {
      this.router.navigate(['user'], {replaceUrl: true});
      this.authService.userInfo.next(null);
      return false;
    }
  }

  canActivate(): Observable<boolean> | boolean {
    if (localStorage.getItem('token')) {
      const user = this.authService.userInfo.getValue();

      if (!user) {
        return this.authService.getUserInfo()
          .pipe(
            map(r => {
              if (!r) {
                this.router.navigate(['user'], {replaceUrl: true});
                localStorage.removeItem('token');
                return false;
              }

              return true;
            })
          );
      } else {
        return true;
      }

    } else {
      this.router.navigate(['user'], {replaceUrl: true});
      this.authService.userInfo.next(null);
      return false;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (localStorage.getItem('token')) {
      const user = this.authService.userInfo.getValue();

      if (!user) {
        return this.authService.getUserInfo()
          .pipe(
            map(r => {
              if (!r) {
                this.router.navigate(['user'], {replaceUrl: true});
                localStorage.removeItem('token');
                return false;
              }

              return true;
            })
          );
      } else {
        return true;
      }

    } else {
      this.router.navigate(['user'], {replaceUrl: true});
      this.authService.userInfo.next(null);
      return false;
    }
  }

}
