import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDecimalComponent } from './form-decimal.component';
import { FormElementModule } from '@shared/form-elements';
import { FormsModule } from '@angular/forms';
import { SharedPipesModule } from 'app/shared-pipes';
import { DecimalPipe } from '@angular/common';


@NgModule({
  declarations: [FormDecimalComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedPipesModule ,
    FormElementModule
  ],
  exports: [
    FormDecimalComponent
  ],
  providers: [
    DecimalPipe
  ]
})
export class FormDecimalModule { }
