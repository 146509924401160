import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-error-splash',
  templateUrl: './error-splash.component.html',
  styleUrls: ['./error-splash.component.scss']
})
export class ErrorSplashComponent implements OnInit {
  @Input() splashType: number = 2;
  @Input() campId: number = 123;
  @Input() logo: string = '/assets/images/splash/logo.png';
  
  isHebrew: boolean = false;

  errorOptions: any = {
    1: {
      text: 'we_are_still_preparing_for_camp',
      mainText: 'try_again_later',
      image: '/assets/images/splash/error-1.svg'
    },
    2: {
      text: 'please_contact_the_camp_administration',
      mainText: 'your_access_was_denied',
      image: '/assets/images/splash/error-2.svg'
    },
    3: {
      text: 'please_use_your_personal_access_link_again',
      mainText: 'Sorry_something_didnt_work_right',
      image: '/assets/images/splash/error-3.svg'
    },
    4: {
      text: 'please_contact_the_camp_administration',
      mainText: 'this_link_has_expired',
      image: '/assets/images/splash/error-2.svg'
    }
  };



  constructor() {
    this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;
                 }

  ngOnInit() {
  }


}
