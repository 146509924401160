import { Component, OnInit, EventEmitter, HostListener, OnDestroy, Input } from '@angular/core';
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SessionService } from '@services/session/session.service';

@Component({
  selector: 'cah-select-dropdown-session',
  templateUrl: './select-dropdown-session.component.html',
  styleUrls: ['./select-dropdown-session.component.scss']
})
export class SelectDropdownSessionComponent implements OnInit, OnDestroy {

  boxState: boolean = false;
  private clickInside: boolean = false;
  language: string = 'en';

  sessions: any[] = null;
  @Input() dummySessions: any[] = [];
  selectedSession: any = null;
  onSelect: EventEmitter<any> = new EventEmitter();
  isHebrew: boolean;
  private unsubscribe$ = new Subject<any>();

  constructor(private dateFormatterService: DateFormatterService,
    public languageService: LanguageService,
    public sessionService: SessionService
  ) {

    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(lang => {
        this.language = lang;
        this.isHebrew = lang == 'he';
      });

  }

  ngOnInit() {
    this.sessionService.sessions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(sessions => {
        if (!sessions) { return this.sessions = null; }
        this.sessions = sessions;
      });
    this.sessionService.selectedSession$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedSession => {
        if (!selectedSession.isLoaded) { return; }
        this.selectedSession = selectedSession.session;
      });
    
  }

  @HostListener('click')
  onComponentClick() {
    this.clickInside = true;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (!this.clickInside && this.boxState) {
      this.boxState = false;
    }
    this.clickInside = false;
  }

  toggleBox(): void {
    this.boxState = !this.boxState;
  }

  onSelectChange(session: any) {
    this.boxState = false;
    this.sessionService.changeSession(session);
  }

  getDateLabel(startDate, endDate): string {
    return this.dateFormatterService.formatDate(startDate, this.language) + " - "
      + this.dateFormatterService.formatDate(endDate, this.language);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.sessionService.clearSelectedSession();
    this.sessionService.clearSession();
  }

}
