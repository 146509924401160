import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SharedModule } from 'app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { HeaderContainer } from './header/header.container';
import { SharedDirectivesModule } from 'app/shared-directives';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ScriptService } from '@services/script/script.service';
import { SharedPipesModule } from 'app/shared-pipes';
import { MatButtonModule } from '@angular/material/button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationComponent } from './main-layout/navigation/navigation.component';
import { NavigationItemComponent } from './main-layout/navigation/navigation-item/navigation-item.component';
import { NavigationExpandComponent } from './main-layout/navigation/navigation-expand/navigation-expand.component';
import { FormElementModule } from '@shared/form-elements';
import { PlanBannerModule } from '@shared/plan-banner/plan-banner.module';
import { AvatarModule } from '@shared/avatar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    MainLayoutComponent, HeaderContainer, 
    BreadcrumbComponent, NavigationComponent, 
    NavigationItemComponent, NavigationExpandComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    SharedDirectivesModule,
    SharedPipesModule,
    MatButtonModule,
    NgbModule,
    FormElementModule,
    PlanBannerModule,
    AvatarModule,
    InfiniteScrollModule
  ],
  exports: [
    MainLayoutComponent,
    HeaderContainer, 
    BreadcrumbComponent
  ],
  providers: [ScriptService]
})
export class ContainersModule { }
