import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GroupDetailComponent } from './group-detail.component';
import { SharedPipesModule } from '@pipes';
import { SharedDirectivesModule } from '@directives';

@NgModule({
  declarations: [
    GroupDetailComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    SharedPipesModule,
    SharedDirectivesModule
  ],
  exports: [
    GroupDetailComponent
  ]
})
export class GroupDetailModule { }

