import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProgramService } from '@services/program/program.service';
import { SessionService } from '@services/session/session.service';
import { ProgramTypeEnum } from '@enums';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
@Component({
  selector: 'cah-select-session-dropdown',
  templateUrl: './select-session-dropdown.component.html',
  styleUrls: ['./select-session-dropdown.component.scss']
})
export class SelectSessionDropdownComponent implements OnInit {
  @Input() selection: any;
  @Input() excludedSessionId: string | number | undefined;
  @Input() selectedIcon: string;
  @Input() showSelectedIcon = false;
  @Input() set type(value: ProgramTypeEnum) {
    this.filter.type = value;
  };
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  listState: boolean = false;

  filter: any = {
    search: '',
    page: 0,
    sort: 'title,asc',
    count_all_sessions: 1,
    count_active_sessions: 1
  };
  programs: any[];
  sessions: any = {};
  openSessions: any = {};
  loading: boolean = false;
  showCanceled: boolean = false;
  public searchSubject: Subject<string> = new Subject();

  constructor(
    private programService: ProgramService,
    private sessionService: SessionService
    ) {
      
    }

  ngOnInit() {
    this.getPrograms(this.filter);
    this.searchSubject
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe((resp) => {
        this.filter.search = resp.length > 0 ? resp : "";
        this.filter.page = 0;
        this.openSessions = {};
        this.getPrograms(this.filter)
      });
  }

  getPrograms(filter: any = {}): void {
    this.loading = true;
    this.programService.getPrograms(filter)
      .subscribe(res => {
        this.programs = res.data;
        this.loading = false;
      }, err => {
        this.loading = false;
      })
  }

  openProgramSessions(programId): void {
    if(this.openSessions[programId]) {
      this.openSessions[programId] = false;
    }else{
      if(this.sessions[programId]){
        this.openSessions[programId] = true;
      }else{
        this.sessionService.getSessions({ program_id: programId, all: 1, load_staff: 1, sort: 'title,asc' })
        .subscribe(res => {
          this.sessions[programId] = res.data;
          this.loading = false;
          this.openSessions[programId] = true;
        });
      }
    }
  }

  toggleList(state?){
    this.listState = state !== undefined ? state : !this.listState;
    if(!this.listState){
      this.showCanceled = false;
      this.openSessions = {};
      if(this.filter.search.length > 0){
        this.filter.search = "";
        this.getPrograms(this.filter);
      }
    }
  }

  selectSession(session, program?){
    this.selection = session;
    if(program) this.selection['program'] = program;
    this.onChange.emit(this.selection);
    this.toggleList(false);
  }

}
