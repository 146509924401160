import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cah-collapsed-input',
  templateUrl: './collapsed-input.component.html',
  styleUrls: ['./collapsed-input.component.scss']
})
export class CollapsedInputComponent implements OnInit {
  @Input() collapsedOptions: any = {
    isCollapsed: false,
    collapsedText: "Collapsed Text",
    collapsedButtonText: "Open",
    sidebar: false,
    style: {}
  };
 collapsedState: boolean = true;

  constructor() { }
  
  ngOnInit() {
  }

  toggle(){
    this.collapsedState = !this.collapsedState;
  }

}
