import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-instructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.scss']
})
export class InstructionsComponent implements OnInit {

  @Input() instructions: any[];
  @Input() label: string = '';

  private unsubscribe$ = new Subject<any>();
  language: string;
  constructor(private languageService: LanguageService) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(lang => {
        this.language = lang;
      });
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
