import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cah-prog-participant-exp',
  templateUrl: './prog-participant-exp.component.html',
  styleUrls: ['./prog-participant-exp.component.scss']
})
export class ProgParticipantExpComponent implements OnInit {
  
  @Input() program ;
  @Input() defaultPreview: any;
  @Output() onChange = new EventEmitter<any>();

  payload: {};

  constructor() { }

  ngOnInit() {
  }


  onInputChange(e): void {    
    this.payload = { ...this.payload,  [e.name]: e.value };
    this.onChange.emit(this.payload);
    console.log(this.payload);
  }


}
