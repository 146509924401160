import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITab } from '@interfaces';
@Component({
  selector: 'cah-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() tabs: ITab[];
  @Input() activeTabIndex: number = 0;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onTabChange(tabIndex){
    console.log("clicked")
    this.onChange.emit(tabIndex);
  }

}
