import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { IInputValue } from '@interfaces';
import { HelperService } from '@services/helper/helper.service';
import { LanguageService } from '@services/language/language.service';
import { FormValidatorService } from '@util/form-validator/form-validator.service';

@Component({
  selector: 'cah-inline-select',
  templateUrl: './inline-select.component.html',
  styleUrls: ['./inline-select.component.scss']
})
export class InlineSelectComponent implements OnInit {
  firstValue: any;
  editValue: any;
  editMode: boolean = false;
  wasInside: boolean = false;

  @Input() options: any[] = [];
  @Input() displayProperty: string;
  @Input() returnProperty: string;
  @Input() bindName: string;
  @Input() discardBtn: string;
  @Input() saveBtn: string;
  @Input() type: string;
  @Input() label: string;
  @Input() translationSection: string;
  @Input() placeHolder: string;
  @Input() validators: string[];
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() set value(val: any) {
    this.firstValue = val;
    this.editValue = val;
  };
  @Input() error: any;
  @Input() tabindex: string;
  @Output() onOutPutValue: EventEmitter<IInputValue> = new EventEmitter();

  constructor(private formValidatorService: FormValidatorService,
              public languageService: LanguageService,
              private eRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target) && (event.target.getAttribute('class') &&
      !event.target.getAttribute('class').includes('inline-input-element') &&
      !event.target.getAttribute('class').includes('ng-option') &&
      !event.target.getAttribute('class').includes('ng-value-container') &&
      !event.target.getAttribute('class').includes('inline-input-value')) || !this.wasInside) {
      this.onDiscard();
    }

    this.wasInside = false;
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  ngOnInit() {
  }

  onFindValue(id: string): string {
    const findValue = this.options.find(opt => opt.id === id);
    return findValue ? findValue[this.displayProperty] : '';
  }

  onDiscard(): void {
    this.editValue = this.firstValue;
    this.editMode = false;
  }

  onSubmitValue(): void {
    this.error = this.formValidatorService.validateRecursiveLayout(
      [
        { 
          name: this.bindName, 
          label: this.label, 
          type: this.type, 
          validators: this.validators, 
          value: this.editValue
        }
      ]);

    if (!HelperService.isObjectEmpty(this.error)) { return; } 
    
    this.error = null; 
    const payload: IInputValue = {
      name: this.bindName,
      value: this.editValue
    }
    this.editMode = false;
    this.onOutPutValue.emit(payload);
  }

}
