import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateService } from '@services/translate/translate.service';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (translateService: TranslateService) => {
        return async () => {
          await translateService.setLanguage('en');
        }
      },
      deps: [TranslateService]
    }
  ]
})
export class InitializerModule { }