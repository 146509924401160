import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'cah-seconds-box',
  templateUrl: './seconds-box.component.html',
  styleUrls: ['./seconds-box.component.scss']
})
export class SecondsBoxComponent implements OnInit {
  @Input() background: string;
  @Input() campId: number;
  @Input() selectedElectiveOptions : any;
  @Input() activityData : any;
  @Input() scheduleTypeElective : boolean;
  @Output() outDated = new EventEmitter<boolean>();
 
  @Input() session_start_time: string;
  @Input() session_end_time: string;  
  @Input() dayOfWeek: any;

  timerDate: number = 5;
  timer: any;
  joinBtn: boolean = false;
  isHebrew: boolean = false;


  constructor() {
    this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;
   }

  ngOnInit() {
    if (this.activityData.timeLeft=== 0) return this.joinBtn = true; 
    this.timer = setInterval(() => {
      if (this.timerDate <= 1) {
        // this.outDated.emit(true);
        this.joinBtn = true;
        return clearInterval(this.timer);
      }
      this.timerDate -= 1;
    }, 1000);
  }

}
