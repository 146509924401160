import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[cahDnd]'
})
export class DndDirective {

  constructor() { }

  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();
  @Output() fileDrag = new EventEmitter<boolean>();
  @Input() filePreview: boolean = false;

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
    this.fileDrag.emit(true);

  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
      this.fileOver = false;
      this.fileDrag.emit(false);
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer.files;
    if (files.length > 0 && !this.filePreview) {
      this.fileDropped.emit(files);
    }else if(files.length > 0 && this.filePreview){
      this.handleInputChange(evt);
    }

  }

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : 'null';
    console.log('datatransfer',e.dataTransfer.files);
    var reader = new FileReader();

    reader.onload = this.handleReaderLoaded.bind(this, file);
    reader.readAsDataURL(file);
}
handleReaderLoaded(e, file) {
    var reader = e.target;

    this.fileDropped.emit({ 'file':e, 'event' :file});
}

}
