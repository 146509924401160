import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from '@interfaces';

@Component({
  selector: 'cah-form-checkbox-field',
  templateUrl: './form-checkbox-field.component.html',
  styleUrls: ['./form-checkbox-field.component.scss']
})
export class FormCheckboxFieldComponent implements OnInit {
  @Input() bindName: string;
  @Input() label: string;
  @Input() helper: string;
  @Input() value: boolean = false;
  @Input() returnNumber: boolean = false;
  @Input() disabled: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
    if (this.returnNumber) this.value = Boolean(Number(this.value));
  }

  check(){
    if(!this.disabled){
      this.value = !this.value;
      let payload: IInputValue = {
        name: this.bindName,
        value: this.returnNumber ? Number(this.value) : this.value
      };
      this.onChange.emit(payload)
    }
  }
}
