export enum RouteTypeEnum {
    campers = '/manage/campers',
    electives = '/manage/electives',
    groups = '/manage/groups',
    liveschedule = '/live-schedule',
    schedulingboard = '/scheduling',
    schedulingboard2 = '/manage/scheduling',
    staffscheduling ='/staff-scheduling',
    staffscheduling2 ='/manage/staff-scheduling',
    timeslots = '/manage/timeslots',
    manage = '/manage'
}