import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';
import { CurrencyEnum, ElementState } from 'app/enums';
import { HelperService } from '@services/helper/helper.service';
import { LocalizationService } from '@services/localization/localization.service';

@Component({
  selector: 'cah-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {

  @Input() validators: string[] = [];
  @Input() showIcon: boolean = false;
  @Input() iconText: string;
  @Input() showClear: boolean = false;
  @Input() bindName: string;
  @Input() type: string;
  @Input() onlyNumber: string;
  @Input() label: string;
  @Input() placeHolder: string = '';
  @Input() helper: string;
  @Input() startPrefix: string;
  @Input() isRequired: boolean = false;
  @Input() onlyLabel: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() state: string = ElementState.GENERATOR;
  @Input() maxLength: number;
  @Input() value: any;
  @Input() error: any;
  @Input() tabindex: string;
  @Input() isBillingForm: string;
  @Input() minNumber: number;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  currenctSymbol: string = "$";

  constructor(private languageService: LanguageService, private localizationService: LocalizationService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  ngOnInit() {
    this.localizationService.getCurrency()
        .pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe( (currency: any) => {
            if (!currency) { return; }
            this.currenctSymbol = currency.currencyFull;
          }
        )
  }

  onInputChange(event){
    if (this.onlyNumber) {
      this.value = this.value.replace(/\D/g, '');
      if (parseFloat(this.value) < 0) {
        this.value = 0.00;
      }
      event.target.value = this.value
    }
    if (this.onlyNumber || this.validators.length) {
      this.value = !isNaN(parseInt(this.value)) && this.onlyNumber ? parseInt(this.value) : HelperService.limitTextInput(this.validators, this.value, event);
    }

    if(this.minNumber && typeof this.value === 'number'){
      this.value = this.value < this.minNumber ? this.minNumber : this.value;
      event.target.value = this.value;
    }

    const payload: IInputValue = {
      name: this.bindName,
      value: this.value 
    }

    if(this.type == "date"){
      let date =  new Date(payload.value);
      payload.value = date.toISOString();
    }
    
    this.onChange.emit(payload);
  }
  
  clearInput(){
    this.value = '';
    const payload: IInputValue = {
      name: this.bindName,
      value: this.value 
    };
    this.onChange.emit(payload);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
