import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cah-form-warning',
  templateUrl: './form-warning.component.html',
  styleUrls: ['./form-warning.component.scss']
})
export class FormWarningComponent implements OnInit {
  @Input() icon: string = 'clickto-info_circle';
  @Input() label: string = 'warning_text';
  @Input() style: any;
  constructor() { }

  ngOnInit() {
  }

}
