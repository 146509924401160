import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'cah-tags-manager',
  templateUrl: './tags-manager.component.html',
  styleUrls: ['./tags-manager.component.scss']
})
export class TagsManagerComponent implements OnInit {
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  @Input() getList: (params: any) => Observable<any>;
  @Input() addItemToList: (payload: any) => Observable<any>;
  @Input() updateItemInList: (id, payload: any) => Observable<any>;
  @Input() deleteItemFromList: (id) => Observable<any>;
  @Input() bindName: string;
  @Input() selection: any[] = [];
  @Input() selectedItems: any[] = [];
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  public searchSubject: Subject<string> = new Subject();
  list: any[];
  filter: any = {
    search: ''
  }
  selectedItem: any;
  ogTitle: string;
  indexToDelete: number;
  listState: boolean = false;
  warning: any = {
    undone: false,
    severe: false,
    update: false
  }
  

  constructor() { }

  ngOnInit() {
    if(this.selection && this.selection.length && !this.selectedItems.length){
      this.getList({id: this.selection})
      .subscribe(res => this.selectedItems = res.data);
    }
    this.getListItems();
    this.searchSubject
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe((resp) => {
        this.filter.search = resp.length > 0 ? resp : "";
        this.getListItems();
      });
  }

  selectItem(item){
    if(this.selection.includes(item.id)){
      this.selection.splice(this.selection.indexOf(item.id), 1);
      let i = this.selectedItems.findIndex(x => x.id === item.id);
      this.selectedItems.splice(i, 1);
    }else{
      this.selection.push(item.id);
      this.selectedItems.push(item);
    }
    this.emitChanges();
  }

  emitChanges(){
    const payload = {
      name: this.bindName,
      value: this.selection,
      items: this.selectedItems
    }
    this.onChange.emit(payload);
  }

  toggleList(state?: boolean){
    this.listState = state != null || state != undefined ? state : !this.listState;
    if(this.listState){
      this.filter.search = '';
      setTimeout(() => this.searchInput.nativeElement.focus(),0);
      this.getListItems();
    }
  }

  getListItems(params?){
    this.getList(params || this.filter)
        .subscribe(res => this.list = res.data)
  }

  addListItem(){
    const payload = {
      title: this.filter.search
    }
    this.addItemToList(payload)
        .subscribe(res => {
          this.list.push(res);
          this.selectedItems.push(res);
          this.selection.push(res.id);
          this.emitChanges();
          this.toggleList();
        })
  }

  deleteListItem(showWarning: boolean = false){
    if(showWarning){
      if(this.checkIfTagAssigned(this.list[this.indexToDelete])){
        this.warning.severe = true;
      }else{
        this.warning.undone = true;
      }
    }else{
      this.deleteItemFromList(this.list[this.indexToDelete].id)
      .subscribe(res => {
        let selectionIndex = this.selection.findIndex(x => x === this.list[this.indexToDelete].id);
        let itemIndex = this.selectedItems.findIndex(x => x.id === this.list[this.indexToDelete].id);
        this.selection.splice(selectionIndex, 1);
        this.selectedItems.splice(itemIndex, 1);
        this.list.splice(this.indexToDelete, 1);
        this.indexToDelete = null;
      })
    }
  }

  updateListItem(showWarning: boolean = false){
    if(showWarning){
      if(this.checkIfTagAssigned(this.selectedItem)){
        this.warning.update = true;
      }else{
        this.updateListItem(false);
      }
    }else{
      const payload = {
        title: this.selectedItem.title
      }
      
      this.updateItemInList(this.selectedItem.id, payload)
          .subscribe(res => {
            if(this.selection.includes(this.selectedItem.id)){
              this.selectedItems.find(x => x.id === this.selectedItem.id).title = this.selectedItem.title;
            }
            this.selectedItem = null;
            this.ogTitle = null;
          });
    }
  }

  discardChanges(){
    this.selectedItem.title = this.ogTitle;
    this.selectedItem = null;
    this.ogTitle = null;
  }

  onConfirmModal(event){
    switch(event.modalName) {
      case('cant_undone'): {
        if(event.confirm){
          this.warning.undone = false;
          this.deleteListItem(false);
        }else{
          this.warning.undone = false;
          this.indexToDelete = null;
        }
        break;
      }
      case('tag_assigned'): {
        if(event.confirm){
          this.warning.undone = true;
          this.warning.severe = false;
        }else{
          this.warning.severe = false;
          this.indexToDelete = null;
        }
        break;
      }
      case('rename'): {
        if(event.confirm){
          this.warning.update = false;
          this.updateListItem(false);
        }else{
          this.warning.update = false;
          this.discardChanges()
        }
        break;
      }
    }
    console.log(event);
  }

  checkIfTagAssigned(itemToDelete){
    return Number(itemToDelete.used_cnt);
  }

}
