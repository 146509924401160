import { Injectable } from '@angular/core';
import { IDatePickerOption } from '@interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatePickerService {

  disabledOptions: IDatePickerOption = {
    minDate: null,
    maxDate: null,
    disabledDates: null
  }
    
  private _disabledOptions = new BehaviorSubject<any>(this.disabledOptions);
  disabledOptions$ = this._disabledOptions.asObservable();
  
  constructor() { }
  
  disabledDates(options: any) {
    this.disabledOptions = { ...options };
    this._disabledOptions.next(this.disabledOptions);
  }
 

}
