import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TimeFormat } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {
    timeFormat: number = TimeFormat.HOUR12;
    private _timeFormat = new BehaviorSubject<number>(this.timeFormat);
    timeFormat$ = this._timeFormat.asObservable();

    constructor() { }

    changeTimeFormat(timeFormat: number): void {
        this.timeFormat = timeFormat;
        localStorage.setItem('time-format', JSON.stringify(timeFormat));
        this._timeFormat.next(timeFormat);
    }
}
