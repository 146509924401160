import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '@services/user/user.service';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-hour-box',
  templateUrl: './hour-box.component.html',
  styleUrls: ['./hour-box.component.scss']
})
export class HourBoxComponent implements OnInit, OnChanges {
  @Input() timerDate: any = {};
  @Input() background: string;
  @Input() campId: number;

  @Input() activityData: any;
  @Input() session_start_time: string;
  @Input() session_end_time: string;
  @Input() dayOfWeek: any;

  isHebrew: boolean = false;
  time: any;


  constructor() {
      this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;
   }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.timerDate){
      this.time = ( this.timerDate.hours < 10) ? '0' + this.timerDate.hours : this.timerDate.hours;
      this.time += ";"+(this.timerDate.minutes < 10) ? '0' + this.timerDate.minutes : this.timerDate.minutes  ;
      this.time += " : "+(this.timerDate.remainingSeconds < 10) ? '0' + this.timerDate.remainingSeconds : this.timerDate.remainingSeconds ;
    }
  }

  ngOnInit() {
  }
}
