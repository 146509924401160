import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'cah-add-staff-modal',
  templateUrl: './add-staff-modal.component.html',
  styleUrls: ['./add-staff-modal.component.scss']
})
export class AddStaffModalComponent implements OnInit {
  @Input() label: string;
  @Input() staff: any[];
  @Input() selectedStaff: any[];
  @Input() showCloseButton: boolean = false;
  @Input() width: string = "100%";
  @Input() language: string = 'en';
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() outSearchStaff: EventEmitter<string> = new EventEmitter();
  private searchStaffSubject: Subject<string> = new Subject();

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == "he";
      });
  }

  ngOnInit() {
    this.searchStaffSubject
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
      ).subscribe(staff => {
        this.outSearchStaff.emit(staff);
      });
  }

  onSearchStaff(e: any): void {
    this.searchStaffSubject.next(e.value);
  }

  onCloseModal() {
    this.onClose.emit(false);
  }

  onAdd() {
    this.onSubmit.emit(this.selectedStaff);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
