import { Injectable } from '@angular/core';
import { CampService } from '@services/camp/camp.service';
import { map } from 'rxjs/operators';
import { getCurrencySymbol } from '@angular/common';
import { CurrencyEnum } from 'app/enums';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  currencyPrefixes = {
    [CurrencyEnum.AUD]: 'AU',
    [CurrencyEnum.USD]: '',
    [CurrencyEnum.ILS]: ''
  }

  defaultCurrency = {
    currencyCode: 'usd',
    currencySymbol: '$',
    currencyPrefix: '',
    currencyFull: '$'
  }

  constructor(private campService: CampService) { 
  
  }

  getCurrency(){
    return this.campService.campInfo$.pipe(
      map(res => {
        if(!res) return this.defaultCurrency;
        
        return {
          currencyCode: res.currency,
          currencySymbol: getCurrencySymbol(res.currency.toUpperCase(), 'narrow'),
          currencyPrefix: this.currencyPrefixes[res.currency],
          currencyFull: this.currencyPrefixes[res.currency] + getCurrencySymbol(res.currency.toUpperCase(), 'narrow')
        }
      })
    )
  }

}
