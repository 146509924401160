import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { HelperService } from '@services/helper/helper.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private http: HttpClient) { }

  getGroups(filter?: any): Observable<any> {
    const queryString = HelperService.generateQueryParams(filter);
    return this.http.get<any>(environment.endpoint + 'groups?' + queryString)
      .pipe(
        map( res => res),
        catchError( err => throwError(err))
      )
  }

  addGroup(sessionId: string, payload: any){
    return this.http.post(environment.endpoint + "groups/group/" + sessionId, payload)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
  addGroupMultiple(sessionId: string, payload: any){
    return this.http.post(environment.endpoint + "groups/multiple/" + sessionId, payload) // multiple upload groups
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  updateGroup(id: string, data: any) {
    return this.http.patch(environment.endpoint + 'groups/group/' + id, data)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  deleteGroup(id: string) {
    return this.http.delete(environment.endpoint + "groups/group/" + id)
      .pipe(
        map( res => res),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  deleteGroupList(payload: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ...payload },
    };
    console.log(options);
    return this.http.delete(environment.endpoint + "groups/multiple", options)
      .pipe(
        map( res => res),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }
}
