import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, catchError, take, shareReplay } from 'rxjs/operators';
import { throwError, BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { HelperService } from '@services/helper/helper.service';
import { CampService } from '@services/camp/camp.service';
import * as moment from 'moment';
import { ClassTimeType } from 'app/enums';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  camp: any;
  sessions: any = null;
  selectedSession: any = {
    session: null,
    isLoaded: false
  };

  private _selectedSession = new BehaviorSubject<any>(this.selectedSession);
  selectedSession$ = this._selectedSession.asObservable();

  private _sessions = new BehaviorSubject<any>(this.sessions);
  sessions$ = this._sessions.asObservable();

  constructor(private http: HttpClient, private campService: CampService) { 
    this.campService.campInfo$.subscribe(
      (res: any) => {
        if (!res) { return; }
        this.camp = res;
      }
    )
  }

  changeSession(session: any): void {
    localStorage.setItem('selectedSession', JSON.stringify(session));
    this.selectedSession = { session, isLoaded: true };
    this._selectedSession.next(this.selectedSession);
  }

  clearSelectedSession(): void {
    this.selectedSession = { session: null, isLoaded: false };
    this._selectedSession.next(this.selectedSession);
  }

  clearSession(): void {
    this.sessions = null;
    this._sessions.next(this.sessions);
  }

  getUnarchiveSessions(params: any = {}): void {
    params = { ...params, archive: '0', all: 1 };
    const queryString = HelperService.generateQueryParams(params);
    this.http.get<any>(`${environment.endpoint}sessions?${queryString}`)
      .pipe(take(1), shareReplay(1))
      .subscribe((res: any) => {
        const sessions: any[] = res.data;
        if (!sessions.length) { 
          localStorage.removeItem('selectedSession'); 
          this.sessions = null;
          this._sessions.next(this.sessions);
          return this.changeSession(null);
        }
        const savedSession: any = localStorage.getItem('selectedSession');
        this.sessions = sessions;
        this._sessions.next(this.sessions);

        if (!savedSession || !JSON.parse(savedSession) || !this.checkSession(sessions, JSON.parse(savedSession))) {
          return this.changeSession(sessions[0]);
        }

        if (!this.selectedSession.session || (this.selectedSession.session && this.selectedSession.session.id !== JSON.parse(savedSession).id)) {
          this.changeSession(JSON.parse(savedSession));
        }
      }, e => {
        this.sessions = null;
        this._sessions.next(this.sessions);
      })
  }

  checkSession(sessions: any[], session): boolean {
    return !!(sessions.find(sess => sess.id === session.id));
  }

  getSessions(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get<any>(environment.endpoint + 'sessions?' + queryString)
      .pipe(
        map(res => res),
        catchError(err => throwError(err))
      )
  }
  
  getSession(id): Observable<any> {
    return this.http.get<any>(environment.endpoint + 'sessions/session/' + id)
      .pipe(
        map(res => {
          res.before_session_start = HelperService.convertTime(res.before_session_start, 'minutes', `${res.before_session_start_type}s`)
          res.after_session_end = HelperService.convertTime(res.after_session_end, 'minutes', `${res.after_session_end_type}s`)
          res.before_meeting_start = HelperService.convertTime(res.before_meeting_start, 'minutes', `${res.before_meeting_start_type}s`)
          res.before_every_day_start = HelperService.convertTime(res.before_every_day_start, 'minutes', `${res.before_every_day_start_type}s`)
          return res;
        }),
        catchError(err => throwError(err))
      )
  }

  addSession(payload: any) {
    payload.utc_start = moment.tz(payload.utc_start, this.camp.timezone.slug).utc().format('YYYY-MM-DD[T]HH:mm:ss');
    payload.utc_end = moment.tz(payload.utc_end, this.camp.timezone.slug).utc().format('YYYY-MM-DD[T]HH:mm:ss');
    return this.http.post(environment.endpoint + "sessions/session", payload)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  updateSession(id: string | number, data: any) {
    if(typeof data.days == 'string'){
      data.days = JSON.parse(data.days);
    }
    data.register_during_period_start = moment(data.register_during_period_start).format('YYYY-MM-DDT00:00:00');
    data.register_during_period_end = moment(data.register_during_period_end).format('YYYY-MM-DDT23:59:59');
    if (data.class_start_time && data.class_end_time) {
      data.class_start_time = moment(data.class_start_time, 'HH:mm:ss').format('HH:mm');
      data.class_end_time = moment(data.class_end_time, 'HH:mm:ss').format('HH:mm');
    }
    if(data.before_session_start && data.after_session_end && data.before_meeting_start && data.before_every_day_start){
      data.before_session_start = HelperService.convertTime(data.before_session_start, `${data.before_session_start_type}s` ,'minutes');
      data.after_session_end = HelperService.convertTime(data.after_session_end, `${data.after_session_end_type}s`, 'minutes');
      data.before_meeting_start = HelperService.convertTime(data.before_meeting_start, `${data.before_meeting_start_type}s`, 'minutes');
      data.before_every_day_start = HelperService.convertTime(data.before_every_day_start, `${data.before_every_day_start_type}s`, 'minutes');
    }
    
    return this.http.patch(environment.endpoint + 'sessions/session/' + id, data)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  duplicateSession(data: any) {
    return this.http.post(environment.endpoint + 'sessions/session/duplicate', data)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  activateSession(id: string) {
    return this.http.patch(environment.endpoint + 'sessions/session/' + id + '/activate', '')
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  archiveSession(id: string) {
    return this.http.patch(environment.endpoint + 'sessions/session/' + id + '/archive', '')
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  unarchiveSession(id: string) {
    return this.http.patch(environment.endpoint + 'sessions/session/' + id + '/unarchive', '')
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  getCreditsForActivationSession(id: string) {
    return this.http.get(environment.endpoint + 'sessions/session/' + id + '/activate')
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  saveViewedSession(payload: any) {
    return this.http.post(environment.endpoint + "sessions/sessionview", payload)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      ).subscribe(res => res);
  }

  deleteSession(sessionIds: number[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { session_ids: sessionIds },
    };
    return this.http.delete(environment.endpoint + "sessions/multiple", options)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  moveSessions(params: any = {}) {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.patch(environment.endpoint + `sessions/move?${queryString}`, {})
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  changeSessionsStatus(sessiondIds: number[], status: string) {
    return this.http.patch(environment.endpoint + `sessions/multiple/status`, { session_ids: sessiondIds, status })
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }
}
