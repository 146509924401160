import { IWeekday } from "@interfaces";

export const Weekdays: IWeekday[] = [
    {
      id: '1',
      title: 'mon',
      shortTitle: 'day.shortTitle_m',
      fulltTitle: 'Monday',
      sort: 2
    },
    {
      id: '2',
      title: 'tue',
      shortTitle: 'day.shortTitle_t',
      fulltTitle: 'Tuesday',
      sort: 3
    },
    {
      id: '3',
      title: 'wed',
      shortTitle: 'day.shortTitle_w',
      fulltTitle: 'Wednesday',
      sort: 4
    },
    {
      id: '4',
      title: 'thu',
      shortTitle: 'day.shortTitle_t',
      fulltTitle: 'Thursday',
      sort: 5
    },
    {
      id: '5',
      title: 'fri',
      shortTitle: 'day.shortTitle_f',
      fulltTitle: 'Friday',
      sort: 6
    },
    {
      id: '6',
      title: 'sat',
      shortTitle: 'day.shortTitle_s',
      fulltTitle: 'Saturday',
      sort: 7
    },
    {
      id: '0',
      title: 'sun',
      shortTitle: 'day.shortTitle_su',
      fulltTitle: 'Sunday',
      sort: 8
    },
];
