import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'cah-listing-search-input',
  templateUrl: './listing-search-input.component.html',
  styleUrls: ['./listing-search-input.component.scss']
})
export class ListingSearchInputComponent implements OnInit {
  @Input() fullWidth: boolean = false;
  @Input() bindName: string;
  @Input() value: string;
  @Input() placeHolder: string;
  @ViewChild('listingInput', { static: false, read: ElementRef }) public listingInput: ElementRef<any>;

  @Input() set focus(focus){
    setTimeout(() => {
      this.listingInput.nativeElement.focus();
    });
  }
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  
  private unsubscribe$ = new Subject<any>();
  private searchSubject: Subject<string> = new Subject();

  constructor(public languageService: LanguageService) { }

  ngOnInit() {
    this.searchSubject
    .pipe(
      distinctUntilChanged(),
      debounceTime(500),
    ).subscribe(resp => {
      let payload = {
        name: this.bindName,
        value: resp
      };
  
      this.onChange.emit(payload);
    });
  }

  onTextChange(value: string): void {
    this.searchSubject.next(value);
  }

  onClear(){
    this.onTextChange('');
    this.value = ' ';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
