import { Component, OnInit } from '@angular/core';
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';
import { PlanService } from '@services/plan/plan.service';
import * as moment from 'moment';
import { CampService } from '@services/camp/camp.service';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'cah-plan-banner',
  templateUrl: './plan-banner.component.html',
  styleUrls: ['./plan-banner.component.scss']
})
export class PlanBannerComponent implements OnInit {

  bannerConf: any = { show: false };
  subscription: any;
  daysLeft: number = 0;
  camp_id: any;
  showExpiredBanner: boolean = false;
  private unsubscribe$ = new Subject<void>();

  constructor(private dateFormatterService: DateFormatterService, 
              private planService: PlanService, 
              private router: Router,
              private campService: CampService) {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(e => e instanceof NavigationEnd)
        ).subscribe((res: any) => {
          this.showExpiredBanner = res.url === '/settings/camps/camp-settings/payment_billing';
    });

    

    this.campService.campInfo$.subscribe(
      (res: any) => {
        if (!res) { return; }
        this.subscription = res._plan;
        this.daysLeft = moment(this.subscription.utc_end).diff(moment.utc(), 'days') + 1;

        const payload = {
          time: moment(new Date()).add(-30, 'm').toDate(),
          show: true,
          camp_id: res.id
        }  
        
        this.camp_id = res.id
        
        
        if(localStorage.getItem('plan-banner') && JSON.parse(localStorage.getItem('plan-banner')).camp_id == this.camp_id ){
          this.bannerConf = JSON.parse(localStorage.getItem('plan-banner'))
          
          if(this.dateFormatterService.getDurationMinutes(new Date(this.bannerConf.time).toISOString(), new Date().toISOString()) < 30){
            this.bannerConf.show = true;
            localStorage.setItem('plan-banner', JSON.stringify(this.bannerConf))
          }else{
            this.bannerConf.show = true;
            // this.bannerConf.time = new Date();
            localStorage.setItem('plan-banner', JSON.stringify(this.bannerConf))
          }
        }else{
          
          this.bannerConf = payload;
          this.bannerConf.show = true;
          localStorage.setItem('plan-banner', JSON.stringify(this.bannerConf))
        }
        
    })
    
  }

  ngOnInit() {
    
  }

  closeBanner(event){
    this.bannerConf.time = new Date();
    this.bannerConf.show = false;
    localStorage.setItem('plan-banner', JSON.stringify(this.bannerConf))
  }

}
