import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProgramService } from '@services/program/program.service';
import { StaffService } from '@services/staff/staff.service';
import { UserRole } from 'app/enums';

@Component({
  selector: 'cah-prog-basic-details',
  templateUrl: './prog-basic-details.component.html',
  styleUrls: ['./prog-basic-details.component.scss']
})
export class ProgBasicDetailsComponent implements OnInit {

  @Input() program ;
  @Output() onChange = new EventEmitter<any>();
  @Output() onImageChange = new EventEmitter<any>();

  supervisorsList: [] = [];
  categoryList: [] = [];
  payload: {};

  constructor(private staffService: StaffService, private programsService: ProgramService) {
    this.getStaff();
    this.getCategories();
  }


  ngOnInit() {
  }

  onInputChange(e): void {    
    this.payload = { ...this.payload,  [e.name]: e.value};    
    this.onChange.emit(this.payload);
  }

  imageChange(e): void {
    this.payload = { ...this.payload,  [e.name]: e.value};    
    this.onImageChange.emit(this.payload);
  }

  selectSearch(e){
    e.name == "manager_id" ? this.getStaff({search: e.value}) : this.getCategories({search: e.value});
  }

  getStaff(filter: any = {}): void {
    this.staffService.getAllStaff({ ...filter, role: [UserRole.ADMIN, UserRole.SUPERVISOR] })
      .subscribe(res => {
        this.supervisorsList = res.data;
      }, err => {
        console.log(err);
      });
  }


  getCategories(filter: any = {}): void {
    this.programsService.getProgramsCategories(filter)
      .subscribe(res => {
        this.categoryList = res.data;
      }, err => {
        console.log(err);
      });
  }

}
