import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDatePickerOption, IInputValue } from 'app/interfaces';
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';
import { TimeslotService } from '@services/timeslots/timeslot.service';
import { DatePickerService } from '@services/date-picker/date-picker.service';
import { SessionService } from '@services/session/session.service';
import { ElementState } from 'app/enums';

@Component({
  selector: 'cah-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {

  @Input() bindName: string;
  @Input() type: string;
  @Input() label: string;
  @Input() placeHolder: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isPrefixIcon: boolean = false;
  @Input() hasRestrictions: boolean = false;
  @Input() value: any;
  @Input() state: string = ElementState.GENERATOR;
  @Input() helper: string;
  @Input() error: any;
  @Input() onlyDate: boolean = false;
  @Input() iconSize: string = "tp-icon-2";
  @Input() isFilter: boolean = false;
  @Input() clearBtn: boolean = false;
  @Input() language: string = 'en';
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();

  disabledOptions: IDatePickerOption = {
    minDate: null,
    maxDate: null,
    disabledDates: null
  }
  timeslotsData: any = {};
  selectedSession: any = null;
  focus: boolean = false;

  private unsubscribe$ = new Subject<any>();
  private unsubscribeSession$ = new Subject<any>();
  isHebrew: boolean = false;
  moment = moment;
  constructor(
    private languageService: LanguageService,
    private dateFormatterService: DateFormatterService,
    public timeslotService: TimeslotService,
    private datePickerService: DatePickerService,
    private sessionService: SessionService
  ) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == "he";
      });

    this.sessionService.selectedSession$
      .pipe(takeUntil(this.unsubscribeSession$))
      .subscribe(session => {
        this.selectedSession = session;
      });
  }

  ngOnInit() {
    if (this.value) {
      this.value = moment(this.value, "YYYY MM DD").format('YYYY MM DD');
      this.value = new Date(this.value);
    }
    this.datePickerService.disabledOptions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.disabledOptions = { ...res };
      });
  }

  ngOnChanges() {
    if (this.value) {
      this.value = moment(this.value, "YYYY MM DD").format('YYYY MM DD');
      this.value = new Date(this.value);
    }
  }

  onBlur() {
    setTimeout(() => {
      this.focus = false;
    }, 100);
  }

  onInputChange(event) {
    let payload: IInputValue;
    if(event){
      this.value = new Date(event);

    let convertedDate = this.onlyDate ? this.dateFormatterService.formatDateToYmD(this.value) : this.dateFormatterService.formatDateToIsoStringCorrect(this.value);

        payload = {
        name: this.bindName,
        value: convertedDate
      }
    }else{
       payload = {
        name: this.bindName,
        value: ''
      }
    }
    
    this.onChange.emit(payload);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
    this.unsubscribeSession$.complete();
  }


}
