import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from '@interfaces';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-ngselect',
  templateUrl: './ngselect.component.html',
  styleUrls: ['./ngselect.component.scss']
})
export class NgSelectComponent implements OnInit {
  @Input() options: any[] = [];
  @Input() displayProperty: string;
  @Input() returnProperty: string;
  @Input() bindName: string;
  @Input() type: string;
  @Input() label: string;
  @Input() placeHolder: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() value: any;
  @Input() defaultValue: any;
  @Input() error: any;
  @Input() tabindex: string;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();
  
  
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  checkDefaultValue(){
    let defaultValue = this.options.find( x => x[this.returnProperty] === this.defaultValue);
    this.value = defaultValue;
  }

  ngOnInit() {
    if(this.defaultValue){
      this.checkDefaultValue();
    }
  }

  selectChange(event){

    const payload:IInputValue = {
      name: this.bindName,
      value: this.defaultValue
    }
    console.log(payload);
    this.onChange.emit(payload)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
