import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { IInputValue } from 'app/interfaces';
import { PickerColors } from '@colors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';
import { ElementState } from 'app/enums';
import { ColorPickerControl } from '@iplab/ngx-color-picker';
import { WhithoutTokenService } from '@services/withoutToken/withouttoken.service';


@Component({
  selector: 'cah-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit {

  @Input() bindName: string;
  @Input() type: string;
  @Input() label: string;
  @Input() state: string = ElementState.GENERATOR;
  @Input() placeHolder: string;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() value: string;
  @Input() circle: boolean = true;
  @Input() newPallete: boolean = false;
  @Input() error: any;
  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();
  @Output() onRatioValid: EventEmitter<any> = new EventEmitter();


  paletteState: boolean = false;
  colors:any = PickerColors;

    
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  chromeControl: any;
  ratioValid: boolean = true;
  ratio: any;

  constructor(private languageService: LanguageService, private whithoutTokenService : WhithoutTokenService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });

  }

  ngOnInit() {

    console.log('VALUE ',this.bindName, this.value)

    this.chromeControl = new ColorPickerControl()
    .setValueFrom(this.value)
    .setColorPresets(['#F8574F','#F9904C','#F8D452','#7BF18C','#75E6D1','#5E91FF', '#F450A2','#D666F7','#8B66FE','#4EAEF8','#1ABD66','#A2EA2A'])	
    .showPresets()
    .hideAlphaChannel();

    this.checkColorRatioValidity(false);
  }


//@HostBinding('style.background-color')
 //public get 
 checkColorRatioValidity(updateValue: boolean = true): void {  
    this.whithoutTokenService.checkColorRatio((this.chromeControl.value.toHexString()).slice(1))
        .subscribe(res =>{
          this.ratio = res['ratio'];
          this.ratioValid =  parseFloat(res['ratio']) > 3.8;

          if (updateValue) {
            const payload: IInputValue = {
              name: this.bindName,
              value: this.value
            } ;   
            this.onChange.emit(payload);
          }
          
          this.onRatioValid.emit({ ratioValid : this.ratioValid, ratio: res['ratio'], isOpen: this.paletteState});
        })
  }

  showColorPicker(){
    if (this.paletteState === true) {
      this.checkColorRatioValidity();  
      return;
    }
    this.paletteState = !this.paletteState;
  }

  onInputChange(event) {
    this.value = event.key;
    const payload: IInputValue = {
      name: this.bindName,
      value: event.key
    }
    this.onChange.emit(payload);
    this.togglePalette();
  }

  togglePalette(evt = null){
    if(evt){
      evt.stopPropagation();
    }
    if(this.paletteState){
      this.clickout(evt,true);
    }else{
      this.paletteState = !this.paletteState;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

  @HostListener('document:click', ['$event'])
  clickout(event, check = false) {
    if (event.target.getAttribute('class') &&
      !event.target.getAttribute('class').includes('cah__input__palette') && 
      !event.target.getAttribute('class').includes('cah__input__picker') || check ) {

      const payload: IInputValue = {
        name: this.bindName,
        value: this.value
      }      
      if(this.paletteState){
        this.checkColorRatioValidity();
        this.paletteState = !this.paletteState;
        this.onChange.emit(payload);
      }
    }
  }
}
