import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PagesEnum } from '@enums';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss']
})
export class EmptyListComponent implements OnInit {
  @Input() pageName: PagesEnum;
  @Input() buttonName: string;
  @Input() text: string;
  @Input() buttons: string[] = [];
  @Input() type: string = 'session' || 'list';
  @Input() emptySearch: boolean = false;

  @Output() onClick = new EventEmitter<string>();

  localPages = PagesEnum;
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor( public languageService: LanguageService ) { 

    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == 'he';
    });

  }

  ngOnInit() {

  }

  onClickBtn(btnName: string) {
    this.onClick.emit(btnName);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
