import {Directive, ElementRef, Output, EventEmitter, HostListener, OnInit} from '@angular/core';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
    
@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective implements OnInit {
    @Output() clickOutside = new EventEmitter<MouseEvent>();
    captured = false;

    constructor(private _elementRef: ElementRef) {
    }


    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        event.stopPropagation();
        
        if (!this.captured || !targetElement) {
            return;
        }

        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.clickOutside.emit(event);
        }
    }

    ngOnInit() {
        fromEvent(document, 'click', { capture: true }).pipe(take(1))
          .subscribe(() => this.captured = true);
    }
}