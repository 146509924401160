import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanBannerComponent } from './plan-banner.component';
import { SharedPipesModule } from '@pipes';
import { RouterModule } from '@angular/router';
import { SharedDirectivesModule } from 'app/shared-directives';


@NgModule({
  declarations: [PlanBannerComponent],
  imports: [
    CommonModule,
    SharedPipesModule,
    RouterModule,
    SharedDirectivesModule
  ],
  exports: [
    PlanBannerComponent
  ]
})
export class PlanBannerModule { }
