import { MenuItem } from './models/menu.item';
import { UserRole } from './enums';

export const MainMenu: MenuItem[] = [
  {
    title: 'profile',
    url: '/profile',
    icon: 'purple-nav nav-profile',
    activeBg: 'active-bg-profile',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN, UserRole.STAFF, UserRole.SUPERVISOR],
    showInNavigation: [],
    image: {
      width: 37,
      height: 37,
      url: '',
      description: ''
    }
  },
  {
    title: 'home',
    url: '/',
    icon: 'dashboard',
    tooltip: "home",
    activeBg: 'active-bg-dashboard',
    activeBgColor: 'blue',
    badge: null,
    role: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.STAFF],
    image: null,
    showInNavigation: [UserRole.SUPERADMIN],
    newIcon: 'clickto-screen'
  },
  {
    title: 'online-player',
    url: '/online-player',
    icon: 'dashboard',
    tooltip: "online-player",
    activeBg: 'active-bg-dashboard',
    activeBgColor: 'blue',
    badge: null,
    role: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.STAFF],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-screen'
  },
  {
    title: 'schedule',
    url: '/live-schedule',
    icon: 'live_schedule',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    tooltip: "view_live_schedule",
    role: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.STAFF],
    badge: null,
    image: null,
    showInNavigation: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.STAFF],
    newIcon: 'clickto-calendar'
  },
  {
    title: 'live',
    url: '/live-schedule/daily-agenda',
    icon: 'live_schedule',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    tooltip: "view_live_schedule",
    role: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.STAFF],
    badge: null,
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-calendar'
  },
  {
    title: 'live',
    url: '/live-schedule/weekly-agenda',
    icon: 'live_schedule',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    tooltip: "view_live_schedule",
    role: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.STAFF],
    badge: null,
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-calendar'
  },
  {
    title: 'live',
    url: '/live-schedule/daily-by-session',
    icon: 'live_schedule',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    tooltip: "view_live_schedule",
    role: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.STAFF],
    badge: null,
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-calendar'
  },
  {
    title: 'content_board',
    url: '/content-board/:id',
    icon: 'live_schedule',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    tooltip: "content_board",
    role: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.STAFF, UserRole.SUPERADMIN],
    badge: null,
    image: null,
    showInNavigation: []
  },
  {
    title: 'allboards',
    url: '/boards/allboards',
    icon: 'allboards',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    description: ''
  },
  {
    title: 'programs',
    url: '/sessions',
    icon: 'activities',
    tooltip: "programs_sessions",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [UserRole.ADMIN],
    expandTitle: 'manage_sessions',
    newIcon: "clickto-stack"
  },
  {
    title: 'programs',
    url: '/sessions/overview',
    icon: '',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
  },
  {
    title: 'enrollments',
    url: '/programs/enrollments',
    icon: 'enrollments',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    description: 'enrollments_desc'
  },
  {
    title: 'spaces',
    url: '/programs/spaces',
    icon: 'spaces',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    description: 'space_decr'
  },
  {
    title: 'meetings',
    url: '/programs/meetings',
    icon: 'activities',
    tooltip: "meetings",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-paste'
  },
  {
    title: 'groups',
    url: '/programs/groups',
    icon: 'groups',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    description: 'groups_desc'
  },
  {
    title: 'timeslots',
    url: '/programs/timeslots',
    icon: 'timeslots',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    description: 'timeslots_desc'
  },
  {
    title: 'electives',
    url: '/programs/electives',
    icon: 'electives',
    activeBg: '',
    activeBgColor: '',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    description: 'electives_desc'
  },
  {
    title: 'schedule',
    url: '/programs/scheduling',
    icon: 'scheduling-board',
    tooltip: "schedule_your_activities",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-paste'
  },
  {
    title: 'assign',
    url: '/programs/staff-scheduling',
    icon: 'assign',
    tooltip: "assign_staff_members_to_activities",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-person'
  },
  {
    title: 'recordings',
    url: '/settings/recordings',
    icon: 'groups',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    description: 'groups_desc'
  },
  {
    title: 'recordings',
    url: '/programs/recordings',
    icon: 'groups',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    description: 'groups_desc'
  },
  {
    title: 'learners',
    url: '/campers',
    icon: 'campers',
    tooltip: 'Learners',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [UserRole.ADMIN],
    description: 'campers_desc',
    newIcon: 'clickto-group'
  },
  {
    title: 'test',
    url: '/test-modal',
    icon: 'test',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    description: 'campers_desc',
    newIcon: 'clickto-group'
  },
  {
    title: 'staff',
    url: '/members/staff',
    icon: 'activities',
    tooltip: "staff",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [UserRole.ADMIN],
    newIcon: 'clickto-person'
  },
  {
    title: 'webpage',
    url: '/store',
    icon: 'activities',
    tooltip: "manage_your_registration_webpage",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [UserRole.ADMIN],
    expandTitle: 'manage_sessions',
    newIcon: "clickto-shop"
  },
  {
    title: 'webpage',
    url: '/store/:id',
    icon: 'activities',
    tooltip: "programs_sessions",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    expandTitle: 'manage_sessions',
    newIcon: "clickto-stack"
  },
  {
    title: 'reports',
    url: '/reports',
    icon: 'activities',
    tooltip: "reports",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [UserRole.ADMIN],
    newIcon: 'clickto-pie'
  },
  {
    title: 'reports-staff',
    url: '/reports/staff',
    icon: 'activities',
    tooltip: "staff",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-pie'
  },
  {
    title: 'reports-enrollment',
    url: '/reports/enrollment',
    icon: 'activities',
    tooltip: "staff",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-pie'
  },
  {
    title: 'activities',
    url: '/programs/activities',
    icon: 'activities',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    description: 'activities_desc'
  },
  {
    title: 'spaces',
    url: '/spaces/public',
    icon: 'activities',
    tooltip: "staff",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-person'
  },
  {
    title: 'spaces',
    url: '/spaces/program',
    icon: 'activities',
    tooltip: "staff",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-person'
  },
  {
    title: 'spaces',
    url: '/spaces/course',
    icon: 'activities',
    tooltip: "staff",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-person'
  },
  {
    title: 'myboards',
    url: '/boards/myboards',
    icon: 'myboards',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.STAFF],
    image: null,
    showInNavigation: [],
    description: "boards_description"
  },
  {
    title: 'publicboards',
    url: '/boards/publicboards',
    icon: 'publicboards',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.STAFF],
    image: null,
    showInNavigation: [],
    description: 'public_boards_description'
  },
  {
    title: 'allboards',
    url: '/boards/allboards',
    icon: 'allboards',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.STAFF],
    image: null,
    showInNavigation: [],
    description: ''
  },
  {
    title: 'camps',
    url: '/settings/camps',
    icon: 'camps',
    tooltip: "camps",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.SUPERADMIN],
    image: null,
    showInNavigation: [UserRole.SUPERADMIN],
    newIcon: "clickto-group"
  },
  {
    title: 'admins',
    url: '/superadmins',
    icon: 'superadmins',
    tooltip: "superadmins",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.SUPERADMIN],
    image: null,
    showInNavigation: [UserRole.SUPERADMIN],
    newIcon: "clickto-person"
  },
  {
    title: 'purchase',
    url: '/purchase',
    icon: 'Purchase',
    activeBg: '',
    activeBgColor: '',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: []
  },
  {
    title: 'order',
    url: '/purchase/order/:id',
    icon: 'Purchase',
    activeBg: '',
    activeBgColor: '',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: []
  },
  {
    title: 'purchase',
    url: '/purchase-history',
    icon: 'purchase_history',
    tooltip: "purchase_history",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.SUPERADMIN],
    image: null,
    showInNavigation: [UserRole.SUPERADMIN],
    newIcon: 'clickto-coins'
  },
  {
    title: 'translation',
    url: '/translations',
    icon: 'translate',
    tooltip: 'translation',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.SUPERADMIN],
    image: null,
    showInNavigation: [UserRole.SUPERADMIN],
    newIcon: "clickto-text"
  },
  {
    title: 'coupons',
    url: '/coupons',
    icon: 'coupon',
    tooltip: "coupons",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.SUPERADMIN],
    image: null,
    showInNavigation: [UserRole.SUPERADMIN],
    newIcon: "clickto-stack"
  },
  {
    title: 'licenses',
    url: '/licenses',
    icon: 'licenses',
    tooltip: "licenses",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.SUPERADMIN],
    image: null,
    showInNavigation: [UserRole.SUPERADMIN],
    newIcon: "clickto-list"
  },
  {
    title: 'icons',
    url: '/icons',
    icon: 'icons',
    tooltip: "icons",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.SUPERADMIN],
    image: null,
    showInNavigation: [],
    newIcon: "clickto-list"
  },
  {
    title: 'templates',
    url: '/templates',
    icon: 'icons',
    tooltip: "icons",
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.SUPERADMIN],
    image: null,
    showInNavigation: [UserRole.SUPERADMIN],
    newIcon: "clickto-screen"
  },
  {
    title: 'billing',
    url: '/settings/balance',
    icon: 'billing',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: []
  },
  {
    title: 'more',
    url: '/more',
    icon: 'more',
    activeBg: '',
    activeBgColor: '',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [UserRole.ADMIN],
    newIcon: 'clickto-more',
    tooltip: "more",
    expandTitle: 'more',
    childrens: [
      {
        title: 'spaces',
        url: '/spaces',
        icon: 'door',
        tooltip: "spaces",
        activeBg: 'active-bg-inventory',
        activeBgColor: 'purple',
        badge: null,
        role: [UserRole.ADMIN],
        image: null,
        showInNavigation: [UserRole.ADMIN],
        expandTitle: 'boards',
        newIcon: "clickto-door",
        description: "spaces_description"
      },
      {
        title: 'boards',
        url: '/boards/myboards',
        icon: 'boards',
        tooltip: "manage_your_program_boards",
        activeBg: 'active-bg-inventory',
        activeBgColor: 'purple',
        badge: null,
        role: [UserRole.ADMIN,UserRole.SUPERVISOR, UserRole.STAFF],
        image: null,
        showInNavigation: [UserRole.ADMIN,UserRole.SUPERVISOR, UserRole.STAFF],
        expandTitle: 'boards',
        newIcon: "clickto-web_page",
        description: "boards_description"
      },
      {
        title: 'recordings',
        url: '/settings/recordings',
        icon: 'recordings',
        activeBg: '',
        activeBgColor: '',
        badge: null,
        role: [UserRole.ADMIN],
        image: null,
        showInNavigation: [UserRole.ADMIN],
        newIcon: "clickto-camera",
        description: 'recordings_description'
      }
    ]
  },
  {
    title: 'help',
    url: '/help',
    icon: 'help_circle',
    activeBg: '',
    activeBgColor: '',
    badge: null,
    tooltip: "help",
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [UserRole.ADMIN],
    newIcon: 'clickto-help_circle',
    childrens: []
  },
  {
    title: 'settings',
    url: '/settings/camps/camp-settings',
    icon: 'settings',
    activeBg: '',
    activeBgColor: '',
    badge: null,
    tooltip: "settings",
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [UserRole.ADMIN],
    newIcon: 'clickto-cog',
    childrens: []
  },
  {
    title: 'billing',
    url: '/settings/balance',
    icon: 'billing',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.STAFF, UserRole.SUPERVISOR],
    image: null,
    showInNavigation: []
  },
  {
    title: 'program_settings',
    url: '/settings/camps/camp-settings',
    icon: 'program_settings',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-cog',
    description: 'account-settings_description'
  },
  {
    title: 'program_settings',
    url: '/settings/camps/camp-settings/profile_settings',
    icon: 'program_settings',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN, UserRole.STAFF, UserRole.SUPERVISOR],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-cog',
    description: 'account-settings_description'
  },
  {
    title: 'program_settings',
    url: '/settings/camps/camp-settings/payment_billing',
    icon: 'program_settings',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-cog',
    description: 'account-settings_description'
  },
  {
    title: 'participant_fields',
    url: '/settings/camps/camp-settings/participant_fields',
    icon: 'program_settings',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN, UserRole.STAFF, UserRole.SUPERVISOR],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-cog',
    description: 'participant_fields_description'
  },
  {
    title: 'default_templates',
    url: '/settings/camps/camp-settings/default_templates',
    icon: 'program_settings',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN, UserRole.STAFF, UserRole.SUPERVISOR],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-cog',
    description: 'participant_fields_description'
  },
  {
    title: 'integrations',
    url: '/settings/camps/camp-settings/integrations',
    icon: 'integrations',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.ADMIN],
    image: null,
    showInNavigation: [],
    newIcon: 'clickto-stack',
    description: 'integration_description'
  },
  {
    title: 'video_platform',
    url: '/settings/camps/camp-settings/video-platform',
    icon: 'video_platform',
    activeBg: 'active-bg-inventory',
    activeBgColor: 'purple',
    badge: null,
    role: [UserRole.OWNER],
    image: null,
    showInNavigation: []
  },
];
