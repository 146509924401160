import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '@services/user/user.service';
import { MainMenuService } from '@services/main-menu.service';
import { HelperService } from '@services/helper/helper.service';
import { UserRole } from '@enums';
import { MenuItem } from '@models';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivateChild {

    constructor(
        private router: Router,
        private userService: UserService,
        private mainMenuService: MainMenuService
    ) {
    }

    findRoute(menuItems: MenuItem[], url: string): MenuItem {
        for (const menuItem of menuItems) {
            if (menuItem.url === url) { 
                return menuItem; 
            } else {
                if (menuItem.childrens && menuItem.childrens.length) {
                    const findItem = this.findRoute(menuItem.childrens, url);
                    if (findItem) { return findItem; }
                }
            }
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
            return zip(this.userService.userInfo, this.mainMenuService.mainMenu)
                        .pipe(
                            map(([user, navigation]: any) => {
                                if (!user || HelperService.isObjectEmpty(user), !navigation.length) {
                                    this.router.navigate(['/'], {replaceUrl: true});
                                    return false;
                                }

                                if (user.password_expired) {
                                    this.router.navigate(['/password-expired'], {replaceUrl: true});
                                    return false;
                                }
                                
                                const url: string = state.url.split('?')[0].split('/').map(item => /\d/.test(item) ? ':id' : item).join('/');
                                const findRoute: MenuItem = this.findRoute(navigation, url);
                                
                                if (!findRoute) {
                                    this.router.navigate(['/'], {replaceUrl: true});
                                    return false;
                                }
                                if (!findRoute.role.includes(UserRole.OWNER) && !findRoute.role.includes(user.role_id)) {
                                    this.router.navigate(['/'], {replaceUrl: true});
                                    return false;
                                }
                                if (findRoute.role.includes(UserRole.OWNER) && !user.is_owner) {
                                    this.router.navigate(['/'], {replaceUrl: true});
                                    return false;
                                }
                                return true;
                            })
                        );
    }
}
