import { Injectable } from '@angular/core';
import { TimeFormat } from 'app/enums';
import { CampService } from '@services/camp/camp.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {
  days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  camp: any;
  constructor(private campService: CampService) {
    this.campService.campInfo$.subscribe(camp => this.camp = camp);
  }

  getRange(startDate, endDate, weekday?, repeating?){
    let start = new Date(startDate);
    let end = new Date(endDate);
    let repeatEvery = repeating ? repeating : 1;
    let dates = [];

  //to avoid modifying the original date
    const currentDate = new Date(start)
    while (currentDate <= end) {
      let newDate = this.formatDateToIsoStringCorrect(new Date(currentDate).toISOString());
      if(weekday && repeating){
        if(this.getWeekday(newDate) == weekday){
          dates = [...dates, newDate];
          currentDate.setDate(currentDate.getDate() + repeatEvery)
        }else{
          currentDate.setDate(currentDate.getDate() + 1)
        }
      }else{
        dates = [...dates, newDate];
        currentDate.setDate(currentDate.getDate() + 1)
      }
    }
    return dates
  }

  getWeekday(dateString, langCode: string = 'en'){
    dateString = !dateString.includes('T00') ? dateString+'T00:00':dateString;
    let date = new Date(dateString);
    let payload = date.toLocaleDateString(langCode, {weekday: 'long'});
    return payload;
  }

  getMonthAndDay(dateString, langCode: string = 'en'){
    dateString = !dateString.includes('T00') ? dateString+'T00:00':dateString;
    if (langCode === 'he') { return moment(dateString).format('DD/MM/YYYY'); }
    let date = new Date(dateString);
    let payload = date.toLocaleDateString(langCode, {month: 'long', day: 'numeric'});
    return payload;
  }

  getWeekDay(date: any) {
    return this.days[moment(date).day()];
  }

  formatDate(dateString, langCode: string = 'en', time: boolean = false){
    if (langCode === 'he' && !time) { 
      return moment.utc(dateString).format('DD/MM/YYYY'); 
    }else if(langCode === 'he' && time){
      return moment.utc(dateString).format('DD/MM/YYYY hh:mm A'); 
    }
    const payload = !time ?  moment.utc(dateString, "YYYY MM DD").format('ll') : moment.utc(dateString).format("MMMM Do, YYYY, hh:mm A") ;
    return payload;
  }

  getFormattedDateWithTime(date: any, campTimeZone: string, dateFormat: any, lang: string = '', format: string = "dddd, MMMM DD, ") {
    if (!date) { return date; }
    let timeFormat: string = 'hh:mm A'
    if (dateFormat == TimeFormat.HOUR24) {
      timeFormat = 'HH:mm';
    }
    if (lang === 'he') { return moment.tz(date, campTimeZone).format('DD/MM/YYYY HH:mm'); }
    const time = moment.tz(date, campTimeZone).format(format + timeFormat);
    return time;
  }

  getFormattedTime(date: any, campTimeZone: string, dateFormat: any) {
    if (!date) { return date; }
    let timeFormat: string = 'hh:mm A'
    if (dateFormat === TimeFormat.HOUR24) {
      timeFormat = 'HH:mm';
    }
    const time = moment.tz(date, campTimeZone).format(timeFormat);
    return time;
  }

  getWeeklyDay(date: any) {
    return moment(date).format('dddd');
  }

  getDurations(startTime: any, endTime: any, format: string = 'YYYY/MM/DD HH:mm') {
    return moment
      .duration(moment(endTime, format)
      .diff(moment(startTime, format))
      ).asHours();
  }

  getDurationSeconds(startTime: any, endTime: any, format: string = 'YYYY/MM/DD HH:mm') {
    return moment
      .duration(moment(endTime, format)
      .diff(moment(startTime, format))
      ).asSeconds();
  }

  getDurationByMinutes(startTime: any, endTime: any, format: string = 'YYYY/MM/DD HH:mm') {
    return moment
      .duration(moment(endTime, format)
      .diff(moment(startTime, format))
      ).asMinutes();
  }

  getDurationMinutes(time1: any, time2: any, time1type = 'start', byTimezone: boolean = false) {
    if(byTimezone){
      time2 = moment.utc(time2, 'YYYY/MM/DD HH:mm').tz(this.camp.timezone.slug);
    }
    const startTime = time1type == 'start' ? time1 : time2;
    const endTime = time1type == 'start' ? time2 : time1;
    if(moment(endTime, 'YYYY/MM/DD HH:mm').isBefore(moment(startTime, 'YYYY/MM/DD HH:mm'))){
      return 0;
    }else{
      return moment
      .duration(moment(endTime, 'YYYY/MM/DD HH:mm')
      .diff(moment(startTime, 'YYYY/MM/DD HH:mm'))
      ).asMinutes();
    } 
  }

  formatDateWeekView(dateString, langCode: string = 'en', format: any = 'long'){
    dateString = !dateString.includes('T00') ? dateString+'T00:00':dateString;
    if (langCode === 'he') { return moment(dateString).format('DD/MM/YYYY'); }
    let date = new Date(dateString);
    let payload = date.toLocaleDateString(langCode, { weekday: format, month: format, day: 'numeric'});
    return payload;
  }

  formatDateToIsoStringCorrect(date, time?){
    if(time){
      return this.formatDateToYmD(date)+"T"+time+".000Z";
    }else{
      return this.formatDateToYmD(date)+"T00:00:00.000";
    }
  }

  diffTimes(start: any, end: any) {
    const duration = moment.duration(moment(end, "HH:mm:ss").diff(moment(start, "HH:mm:ss")));
    const mins = moment.utc(moment(end, "HH:mm:ss").diff(moment(start, "HH:mm:ss"))).format("mm");
    return Math.floor(duration.asHours()) + ':' + mins;
  }

  diffMinutes(start: any, end: any) {
    const duration = moment.duration(moment(end, "HH:mm:ss").diff(moment(start, "HH:mm:ss")));
    return (duration.hours() * 60) + duration.minutes()
  }

  checkDatePassed(date: any, time: any) {
    const dateWithTime: any = moment(date + ' ' + time);
    return dateWithTime.isBefore()
  }

  formatDateForBack(date){
    date = !date.includes('T00') ? date+'T00:00':date;

    return this.formatDateToYmD(date)+"T00:00:00.000";
  }

  formatDateToYmD(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  
  getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  getDisabledDays(month, year, allowedDays){
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      const newDate = new Date(date)
      if(!allowedDays.includes(this.formatDateToYmD(newDate))){
        days.push(newDate);
      }
      date.setDate(date.getDate() + 1);
    }
    return days;
  }
}
