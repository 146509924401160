import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CampModel } from 'app/models/camp.model';
import { environment } from '../../../environments/environment';
import { map, catchError, take, shareReplay, pluck } from 'rxjs/operators';
import { throwError, BehaviorSubject, of } from 'rxjs';
import { Observable } from 'rxjs';
import { HelperService } from '@services/helper/helper.service';
import { TranslateService as TranslationService } from '@services/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ThemeService } from '@services/theme/theme.service';
import { ScriptService } from '@services/script/script.service';
import { PaymentService } from '@services/payment/payment.service';
import { LanguageService } from '@services/language/language.service';
import { DateFormatService } from '@services/date-format/date-format.service';

@Injectable({
  providedIn: 'root'
})
export class CampService {
  private _domain = new BehaviorSubject<string>('dev.clickto.live');
  domain$ = this._domain.asObservable();

  private _balance = new BehaviorSubject<number>(0);
  balance$ = this._balance.asObservable();

  private _campInfo: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  campInfo$ = this._campInfo.asObservable();

  private _stripeKey: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  stripeKey$ = this._stripeKey.asObservable();

  constructor(private http: HttpClient, 
              private scriptService: ScriptService,
              private paymentService: PaymentService,
              private translateService: TranslationService,
              private languageService: LanguageService,
              private dateFormatService: DateFormatService,
              public translate: TranslateService) { }

  setDomain(domain: string): void {
    this._domain.next(domain);
  }

  getCampInfo(): any {
    return this._campInfo.getValue();
  }

  getDomain(): string {
    return this._domain.getValue();
  }

  updateBalance(): void {
    this.http.get(environment.endpoint + 'camps/balance/camp')
      .pipe(take(1))
      .subscribe((credits: any) => {
        this._balance.next(credits);
      }, err => {
        console.log(err);
      });
  }

  addCamp(payload: CampModel) {
    return this.http.post(environment.endpoint + "camps/camp", payload)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  getCamps(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + 'camps?' + queryString);
  }

  getCampsLicensesCount(): Observable<any> {
    return this.http.get(environment.endpoint + `camps/licenses/count`)
      .pipe(take(1),
        catchError(e => {
          return of({status: false, message: 'error on fetching'});
        })
      );
  }

  getPaymentStatus(): Observable<any> {
    return this.http.get(environment.endpoint + `camps/payment/paid`)
      .pipe(take(1),
        catchError(e => {
          return of({status: false, message: 'error on fetching'});
        })
      );
  }

  getCamp(id: string): Observable<any> {
    return this.http.get<any>(environment.endpoint + 'camps/camp/' + id);
  }

  getMyCamp(): void {
    this.http.get<any>(environment.endpoint + 'camps/mycamp')
      .pipe(take(1))
      .subscribe((camp: any) => {
        this._campInfo.next(camp);
      }, e => {
        this._campInfo.next(null);
      })
  }

  getCampConfigs(): Promise<boolean> {
    
    const promise: Promise<boolean> = new Promise((resolve, reject) => {  
      this.http.get<any>(environment.endpoint + 'camps/mycamp')
        .pipe(
          take(1),
          shareReplay(1),
          map(res => res),
        ).subscribe((camp: any) => {
          if (!camp) return resolve(true);
          this.paymentService.getRate();
          this._campInfo.next(camp);

          if ([406].includes(camp.id)) {
            this.scriptService.load("userback").then(() => {
              console.log('load bug-report script');
            });
          };

          const domains: any = {
            dev: 'dev.clickto.live',
            prod: 'clickto.live'
          };
          const domain: string = window.location.hostname.includes(domains.prod) ? domains.prod : domains.dev;
          this.setDomain(domain);
          this.dateFormatService.changeTimeFormat(camp.time_format);
          this.languageService.changeLanguage(camp.language.code);
          ThemeService.changeFontFamily(camp.language.code);

          // get and set translations         
          if (this.translate.getLangs().includes(camp.language.code)) return resolve(true);
          this.translate.addLangs([camp.language.code]);
          this.translate.use(camp.language.code);  
          this.translateService.getLang(camp.language.code)
            .subscribe(res => {
              this.translate.setTranslation(camp.language.code, res, true);
              resolve(true);
            }, err => {
              reject(false);
            });
          }, err => {
            this.translateService.getLang('en')
              .subscribe(res => {
                this.translate.setTranslation('en', res, true);
                resolve(true);
              }, err => {
                reject(false);
              });
          });
        });

      return promise;
  }

  searchCamps(filter): Observable<any> {
    return this.http.post(environment.endpoint + "camps", filter);
  }

  updateCamp(id: string, data: any): Observable<CampModel> {
    return this.http.patch<CampModel>(environment.endpoint + 'camps/camp/' + id + '/setting', data)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  updateCampCustomised(id: string, data: any): Observable<any> {
    return this.http.patch<any>(environment.endpoint + 'camps/camp/' + id + '/customization', data)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  changeCampTheme(campId: number, data: any): Observable<any> {
    return this.http.patch<any>(environment.endpoint + `camps/camp/${campId}/change-theme`, data)
      .pipe(
        take(1),
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }

  updateUnicko(id: string, data: any) {
    return this.http.patch<CampModel>(environment.endpoint + 'camps/camp/' + id + '/keys', data)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  updateCampPlatform(id: string, data: any) {
    return this.http.patch<CampModel>(environment.endpoint + `camps/camp/${id}/integration`, data)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  updateCampListing(id: string, data: any) {
    return this.http.patch<CampModel>(environment.endpoint + 'camps/camp/' + id, data)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  uploadCampPhoto(urlParams, data: any): Observable<any> {
    return this.http.post(environment.endpoint + 'uploads/upload/image' + urlParams, data);
  }


  uploadCampImage(data: any, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.post(environment.endpoint + `uploads/upload/image?${queryString}`, data)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  suspendCamp(id: string) {
    return this.http.patch(environment.endpoint + "camps/camp/" + id + "/suspend", {})
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }
  unickoIntegrDelete(id: string) {

    return this.http.delete(environment.endpoint + "camps/camp/" + id + "/keys")
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  uploadEmailPreview(payload: any, params: any = {}){
    const queryString = HelperService.generateQueryParams(params);
    return this.http.post(environment.endpoint + `uploads/template/image?${queryString}`, payload)
      .pipe(
        map(res => res),
        catchError(error => {
          return throwError(error.error || "server error")
        })
      )
  }

  getCampNotes(id: number, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `camps/notes/camp/${id}?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  addCampNotes(data: any): Observable<any> {
    return this.http.post(environment.endpoint + `camps/note`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  updateCampNotes(noteId: number, data: any): Observable<any> {
    return this.http.patch(environment.endpoint + `camps/note/${noteId}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  deleteCampNotes(noteId: number): Observable<any> {
    return this.http.delete(environment.endpoint + `camps/note/${noteId}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getStripeStatus(): Observable<any> {
    return this.http.get(environment.endpoint + 'camps/stripe/')
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  connectStripe(): Observable<any> {
    return this.http.post(environment.endpoint + 'camps/stripe/connect', {})
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
  unlinkStripe(): Observable<any> {
    return this.http.delete(environment.endpoint + 'camps/stripe/unlink')
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getCampCoupons(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + 'camps/coupons?'+ queryString)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getCampCoupon(id: number, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `camps/coupons/${id}?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( (res: any) => {
            res = {
              ...res,
              amount: res.type === 'amount' ? res.amount / 100 : res.amount,
              expiration_date: moment(res.expiration_date).add(-1, 'days').format('YYYY-MM-DD[T]00:00:00')
            }
            return res;
          } ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getCampActiveCouponQt(params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + 'camps/coupons/actives?'+ queryString)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  addCampCoupons(data: any = {}): Observable<any> {
    const payload: any = {
      ...data,
      expiration_date: moment(data.expiration_date).add(1, 'days').format('YYYY-MM-DD[T]00:00:00'),
      amount: data.type === 'amount' ? data.amount * 100 : +data.amount,
      participant_limit: data.limit_per_participant ? +data.participant_limit : null,
      code: data.code.toUpperCase()
    };

    return this.http.post(environment.endpoint + 'camps/coupons', payload)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  updateCampCoupons(id: number, data: any = {}): Observable<any> {
    const payload: any = {
      ...data,
      expiration_date: moment(data.expiration_date).add(1, 'days').format('YYYY-MM-DD[T]00:00:00'),
      limit_per_participant: !!data.limit_per_participant,
      participant_limit: data.limit_per_participant ? +data.participant_limit : null,
      code: data.code.toUpperCase(),
      amount: data.type === 'amount' ? data.amount * 100 : +data.amount,
    };

    if (+payload.coupon_used) {
      delete payload.amount;
      delete payload.type;
    }

    return this.http.patch(environment.endpoint + 'camps/coupons/' + id, payload)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
  uploadBannerImage(data: any, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.post(environment.endpoint + `camps/banner?${queryString}`, data)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }
}
