import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '@services/user/user.service';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-board-elective-box',
  templateUrl: './board-elective-box.component.html',
  styleUrls: ['./board-elective-box.component.scss']
})
export class BoardElectiveBoxComponent implements OnInit {
  @Input() position: any = {
    vertical: 'bottom',
    horizontal: 'right'
  };

  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onEdit: EventEmitter<number> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onElectiveAction: EventEmitter<any> = new EventEmitter();

  @Input() electiveData: any[] = [];
  @Input() electiveValue: any;
  @Input() selectedElective: any;
  suggestionState: any;
  notValid: boolean = false;

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;


  constructor(private userService: UserService,
    public languageService: LanguageService
    ) {
      languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == 'he';
    });
   }


  ngOnInit() {
    const div: any = document.getElementById('main-content');
    div.style.overflow = 'hidden';
  }

  onActionElective(e: Event, elective: any, optionName: string): void {
    let payload = {
      name: optionName,
      value: elective
    }
    this.onElectiveAction.emit(payload);
  }

  onBoxClose(): void {
    this.onClose.emit();
  }

  onEditElective(): void {
    this.onEdit.emit(this.selectedElective.id);
  }

  onItemSelect(event: any): any {
    
    if (!event) {
        this.notValid = true; 
        this.selectedElective = null;
        this.electiveValue = null;
      }else{
        this.selectedElective = event;
        this.electiveValue = event.title;
      }

    this.onSelect.emit({ name: 'elective', value: this.selectedElective });

    if( !(this.selectedElective  && this.electiveValue.length)){      
      this.onBoxClose();
    }
  }

  onTextChange(event: any, inputName: string): void {
    let payload = {
      name: inputName,
      value: event.value
    }
    this.onSearch.emit(payload)
  }
  
  ngOnDestroy(): void {
    const div: any = document.getElementById('main-content');
    div.style.overflow = 'auto';
    this.unsubscribe$.complete();
  }
}
