import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, catchError, take, shareReplay } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { HelperService } from '@services/helper/helper.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CampService } from '@services/camp/camp.service';
import { RoomTypeEnum } from 'app/enums';
@Injectable({
  providedIn: 'root'
})
export class CampersService {
  private _groupCampers = new BehaviorSubject<any>({});
  groupCampers$ = this._groupCampers.asObservable();
  camp: any = {};

  constructor(private http: HttpClient, private campService: CampService, private router: Router) {
    this.campService.campInfo$.subscribe(
      (res: any) => {
        if (!res) { return; }
        this.camp = res;
      }
    )
  }

  setGroupCampers(groupCampers: any): void {
    this._groupCampers.next(groupCampers);
  }

  getCampersSessionCount(id): Observable<any> {
    return this.http.get<any>(environment.endpoint + 'campers/count/session/' + id)
      .pipe(
        catchError(error => {
          return throwError(error.error || "server error")
        })
      );
  }


  // getCampers(): Observable<any> {

  //   return this.http.get<any>(environment.endpoint + 'campers')
  //     .pipe(
  //       map( res => res),
  //       catchError( err => throwError(err))
  //     )
  // }
  onSuspendCamper(id: string) {
    return this.http.patch(environment.endpoint + 'campers/camper/' + id + '/suspend', '' )
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
  onActiveCamper(id: string) {
    return this.http.patch(environment.endpoint + 'campers/camper/' + id + '/activate', '' )
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  addCamper(payload: any, params: any = {}) {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.post(environment.endpoint + `campers/camper?${queryString}`, payload)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  updateCamper(id: number, data: any) {
    return this.http.patch(environment.endpoint + 'campers/camper/' + id, data)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  enrollCamper(payload: any, params: any = {}){
    const queryString = HelperService.generateQueryParams(params);
    if (payload.type === 'range') {
      payload.start = moment.tz(payload.start, this.camp.timezone.slug).utc().format('YYYY-MM-DD[T]HH:mm:ss');
      payload.end = moment.tz(payload.end, this.camp.timezone.slug).set({'hour': 23, 'minute': 59, 'seconds': 59}).utc().format('YYYY-MM-DD[T]HH:mm:ss');
    }
    return this.http.patch(environment.endpoint + `campers/enroll?${queryString}`, payload)
      .pipe(
        map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
      )
  }

  onCampersSendInvitationAll(session_id: string, payload: any) {
    return this.http.post(environment.endpoint + 'campers/invite/session/' + session_id, payload)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
  onCampersSendInvitationSelected(payload: any) {
    return this.http.post(environment.endpoint + 'campers/invite', payload)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  sendEmailToCampers(payload, params){
    const queryString = HelperService.generateQueryParams(params);
    return this.http.post(environment.endpoint + `campers/sendemail?${queryString}`, payload)
    .pipe(
      map( res => res ),
      catchError( error => {
        return throwError(error.error || "server error")
      })
    )
  }

  assign(payload: any, params: any = {}) {
    return this.http.post(environment.endpoint + `campers/camper/assign/${params}`, payload)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  deleteCamper(id: string) {
    return this.http.delete(environment.endpoint + "campers/camper/" + id)
      .pipe(
        map( res => res),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }
  deleteCamperList(payload: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ...payload },
    };
    console.log(options)
    return this.http.delete(environment.endpoint + "campers/", options)
      .pipe(
        map( res => res),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  importCampers(payload: any, params: any = {}) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');

    const queryString = HelperService.generateQueryParams(params);

    return this.http.post(environment.endpoint + `campers/import/excel?${queryString}`, payload)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error || "server error")
          })
        )
  }

  exportCampers(params: any = {}) {
    const queryString = HelperService.generateQueryParams(params);

    return this.http.get(environment.endpoint + `campers/export/excel/?${queryString}`, { responseType: 'blob' })
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }
  displayButtonFilter(params: any = {}) {
    const queryString = HelperService.generateQueryParams(params);

    return this.http.get(environment.endpoint + `display/?${queryString}`)
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  exportGlobalCampers(params: any = {}) {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `campers/export/excel?${queryString}`, { responseType: 'blob' })
      .pipe(
        take(1),
        shareReplay(1),
        map( res => res ),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  getCampers(filter: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(filter);
    return this.http.get<any>(environment.endpoint + 'campers?' + queryString)
      .pipe(
        map( res => res ),
        catchError( err => throwError(err))
      );
  }
  getAssignedCampers(filter: any): Observable<any> {
    const queryString = HelperService.generateQueryParams(filter);
    return this.http.get<any>(environment.endpoint + 'campers?' + queryString)
      .pipe(
        map( res => res ),
        catchError( err => throwError(err))
      );
  }

  getCallTime(code: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Clear-Site-Data': 'cache',
        'Cache-Control': 'no-cache'
      }),
    };

    return this.http.get<any>(environment.endpoint + 'campers/camper/live/' + code, options)
    .pipe(take(1), catchError( err => throwError(err)));
  }

  getCamperUpcomingMeetings(code: string, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `campers/camper/live/${code}/upcoming?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getCamperUpcomingSessions(code: string, params: any = {}): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `campers/camper/live/${code}/sessions?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  goToKalturaMeeting(hash: string, scheduleId: number, params: any = {}, space_title: string, camp_id: number, logo: string, roomType: RoomTypeEnum): any {
    const queryString = HelperService.generateQueryParams(params);
    if(roomType === RoomTypeEnum.ZOOM) {
      return this.http.get(`${environment.endpoint}campers/camper/meeting/${hash}/${scheduleId}?${queryString}`)
          .subscribe((res: any) => HelperService.goToLink(res.url, '_self'))
    }
    return this.router.navigate(['/room'],
      { queryParams:
        {
          link: `${environment.endpoint}campers/camper/meeting/${hash}/${scheduleId}?${queryString}`, camp_id, space_title, logo
        }
      })
  }

  updateCamperStatus(id: string, status: string): Observable<any> {
    return this.http.patch(environment.endpoint + `campers/camper/${id}/${status}`, {})
        .pipe(take(1), catchError( err => throwError(err)));
  }

  changeCampersStatus(data: any, params: any = {}, ): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.patch(environment.endpoint + `campers/status?${queryString}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  cancelEnrollSelectedCampers(data: any, params: any = {}, ): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ...data },
    };
    return this.http.delete(environment.endpoint + `campers/enroll?${queryString}`, options)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  cancelCamperEnrolls(payload: any = {}, ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ...payload },
    };
    return this.http.delete(environment.endpoint + `campers/enrollments/`, options)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  camperEnrollsGroupAssign(data: number, params: any = {}, ): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.post(environment.endpoint + `campers/assign/group?${queryString}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  camperEnrollUpdate(id: number, data: any, params: any = {}, ): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    if (data.type === 'range') {
      data.start = moment.tz(data.start, this.camp.timezone.slug).set({'hour': 0, 'minute': 0, 'seconds': 0}).utc().format('YYYY-MM-DD[T]HH:mm:ss');
      data.end = moment.tz(data.end, this.camp.timezone.slug).set({'hour': 23, 'minute': 59, 'seconds': 59}).utc().format('YYYY-MM-DD[T]HH:mm:ss');
    }
    return this.http.patch(environment.endpoint + `campers/enroll/${id}?${queryString}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  camperEnrolledSessions(id: number, params: any = {}, ): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `campers/camper/${id}/sessions?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }


  camperEnrollesByid(id: number, params: any = {}, ): Observable<any> {
    const queryString = HelperService.generateQueryParams(params);
    return this.http.get(environment.endpoint + `campers/camper/${id}/enrolls?${queryString}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  getCamperNotes(id: any, data: any = {}): Observable<any> {
    const options = {
      params: {...data}
    };
    return this.http.get(environment.endpoint + `campers/notes/camper/${id}`, options)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  addCamperNotes(data: any = {}): Observable<any> {
    return this.http.post(environment.endpoint + `campers/note`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  onCamperCancelationRefund(id: number, data: any = {}): Observable<any> {
    return this.http.post(environment.endpoint + `campers/camper/${id}/stripe/refund`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  updateCamperNotes(id: number, data: any = {}): Observable<any> {
    return this.http.patch(environment.endpoint + `campers/note/${id}`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  deleteCamperNotes(id: number): Observable<any> {
    return this.http.delete(environment.endpoint + `campers/note/${id}`)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  cancelCamperEnrollmentAddNote(payload: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { ...payload },
    };
    console.log(options)
    return this.http.delete(environment.endpoint + `campers/enrollment/cancell`, options)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  swapEnrollment(data: any = {}): Observable<any> {
    return this.http.post(environment.endpoint + `campers/swap`, data)
        .pipe(
          take(1),
          shareReplay(1),
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
}
