import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cah-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {

  @Input() title: string = '30 Min';
  @Input() class: string = 'dodger-blue';
  @Input() tooltip: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
