import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'secondToHms', pure: true })
export class SecondToHmsPipe implements PipeTransform {

  transform(d: number | string): string {
    if (!d) return '';
    d = Number(d);
    const h: number = Math.floor(d / 3600);
    const m: number = Math.floor(d / 60);
    const s: number = Math.floor(d % 60);
    const hour: string = (h + ':' + (m < 10 ? '0' + m : m || '00') + ':' + (s || '00') + ' Hour');
    const minute: string = ((m || '00') + ':' + (s < 10 ? '0' + s : s || '00') + ' Min');
    const hDisplay: string = h > 0 ? hour : m > 0 ? minute : (s || '00') + ' Sec';
    return hDisplay; 
}

}
