import { ElementRef, EventEmitter, OnChanges, Output, ViewChild } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from '@models';
import { HelperService } from '@services/helper/helper.service';
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';
import { ModelTypeEnum, UserRole } from '@enums';
import * as moment from 'moment';
import 'moment-timezone';
import { CampService } from '@services/camp/camp.service';
import { IButton } from '@interfaces';

@Component({
  selector: 'cah-navigation-expand',
  templateUrl: './navigation-expand.component.html',
  styleUrls: ['./navigation-expand.component.scss']
})
export class NavigationExpandComponent implements OnInit {
  @Input() menuItem: MenuItem;
  @Input() camp;
  @Input() type: string = 'menu' || 'settings';
  @Input() language: string = 'he';
  @Input() user: any;

  userRoleEnum: any = UserRole;
  @Output() outClickMenuItem: EventEmitter<string> = new EventEmitter<string>();


  primaryIconSettings: IButton = {
    model: ModelTypeEnum.BtnPRIMARY,
    text: "add_credits",
    icon: "add",
    size: "",
    type: "",
    disabled: false,
  };

  textBtnSettings: IButton = {
    model: ModelTypeEnum.BtnTEXT,
    text: "credit_history",
    icon: "",
    size: "sm",
    type: "",
    disabled: false,
  };

  constructor(public dateFormatterService: DateFormatterService,
              public campService: CampService) { }

  ngOnInit() {
  }


  
  onClickItem(url: string): void {
    this.outClickMenuItem.emit(url);
  }

  numberWithCommas(credits: number) {
    return HelperService.numberWithCommas(credits);
  }

  checkOnExpire(date: string) {
    if (date) {
      const days: number = moment(date).diff(moment(), 'days');
      
      let h = moment(date).diff(moment(), 'hours');

      
      if(days == 0 && h > 0){      
        return true;
      }
      
      return days < 15 && days > 0;
    }

    return false;
  }

  alreadyExpired(date: string) {
    if (date) {
      const days: number = moment(date).diff(moment(), 'days');     
      let h = moment(date).diff(moment(), 'hours');
 
      if(days == 0 && h <= 0){
        return true;
      }
      return days < 0;
    }
    return false;
  }

}
