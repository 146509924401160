import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IModalvalue } from '@interfaces';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() question: string = 'are_you_sure_delete';
  @Input() info: string = '';
  @Input() campId: number;
  @Input() isWarning: boolean = true;
  @Input() isAmount: boolean = false;
  @Input() confirmLabel: string = 'yes_delete';
  @Input() cancelLabel: string = 'no';
  @Input() customIcon: string;
  @Input() additionalLabel: string;
  @Input() additionalInf: string;
  @Input() modalName: string;
  @Input() translateDynamicallValues: any = {};
  @Input() confirmBtnstyle: any = {};
  @Input() cancelBtnstyle: any = {};
  
  @Output() outPutValue = new EventEmitter<IModalvalue>();
  
  questionArr: string[] = [];
  value: IModalvalue = {
    cancel: false,
    confirm: false,
    additional: false
  };

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  
  constructor(public languageService: LanguageService
    ) {
      languageService.language$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(isHebrew => {
          this.isHebrew = isHebrew == 'he';
        });
   }

  ngOnInit(): void {
  }

  onClick(btn: string): void {
    this.value = {
      cancel: false,
      confirm: false,
      additional: false,
      modalName: this.modalName
    };
    this.value[btn] = true;
    this.outPutValue.emit(this.value);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
