import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'cah-import-file-modal',
  templateUrl: './import-file-modal.component.html',
  styleUrls: ['./import-file-modal.component.scss']
})
export class ImportFileModalComponent implements OnInit {

  @Input() errors;
  @Input() contentBody = [];
  @Input() exampleFile: any = {};
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onUpload: EventEmitter<any> = new EventEmitter();

  value: any;

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  fileOver: boolean = false;

  constructor(public languageService: LanguageService
  ) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });
  }

  ngOnInit() {
    
  }

  onFileDropped(event) {
    
    if (event[0]) {
      const formData = new FormData();
      formData.append('file', event[0]);
      HelperService.createFormData(event)
      this.onUpload.emit(formData);
     // event.target.value = ''
      this.fileOver = false;
      
    }
  }

  fileOverChange(e){    
    this.fileOver = e;     
   }

  closeModal() {
    this.onClose.emit(false);
  }

}
