import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { IInputValue } from 'app/interfaces';

@Component({
  selector: 'cah-inline-color-picker',
  templateUrl: './inline-color-picker.component.html',
  styleUrls: ['./inline-color-picker.component.scss']
})
export class InlineColorPickerComponent implements OnInit {
  @Input() label: string;
  @Input() discardBtn: string;
  @Input() saveBtn: string;
  @Input() bindName: string;
  @Input() isDisabled: boolean = false;
  @Input() set value(val: any) {
    this.firstValue = val;
    this.editValue = val;
  };
  @Output() onOutPutValue: EventEmitter<IInputValue> = new EventEmitter();
  firstValue: any;
  editValue: any;
  editMode: boolean = false;
  isValidColor: boolean = true;

  constructor(public languageService: LanguageService) { }

  ngOnInit() {
  }

  onValidityCheck(e: any): void {
    this.isValidColor = e.ratioValid;
  }

  onInputChange(e): void {
    this.editValue = e.value;
  }

  onSubmit(): void {
    const payload: any = {
      name: this.bindName,
      value: this.editValue,
      accessibility: this.isValidColor ? 'pass' : 'fail'
    };
    if (!this.isDisabled) {
      this.onOutPutValue.emit(payload);
      this.editMode = false;
    }
  }

  onDiscard(): void {
    this.editValue = this.firstValue;
    this.editMode = false;
  }

}
