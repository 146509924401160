import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'translation', pure: true })
export class Translation implements PipeTransform {

    constructor(public translate: TranslateService) { }
    
    transform(key: string, component: string, dinamicalValues: any = {}, lang: string = '', campId: number = 0): Observable<string> {
        return key ? this.translate.get(key, dinamicalValues) : of('');
    }
}
