import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'cah-modal-credits',
  templateUrl: './modal-credits.component.html',
  styleUrls: ['./modal-credits.component.scss']
})
export class ModalCreditsComponent implements OnInit {
  @Input() errorMessage: string = '';
  @Input() message: string = '';
  @Input() headMessage: string = '';
  @Input() sessionName: string = '';
  @Input() costCredits: number = 0;
  @Input() balanceCredits: number = 0;
  @Input() camperQty: number = 0;
  @Input() dayQty: number = 0;
  @Input() showDay: boolean = false;
  @Input() showCamper: boolean = false;
  @Input() purchase: boolean = false;
  @Input() translateDynamicallValues: any = {};

  @Output() onApprove: EventEmitter<boolean> = new EventEmitter();
  @Output() onPurchase: EventEmitter<boolean> = new EventEmitter();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  constructor(public language: LanguageService) { }

  ngOnInit() {
  }

  onCloseModal(): void {
    this.onClose.emit(false);
  }

  onApproveModal(): void {
    this.onApprove.emit(true);
  }

  onPurchaseModal(): void {
    this.onPurchase.emit(true);
  }

  numberWithCommas(x: any): any {
    if (!x) { return 0; }
    return HelperService.numberWithCommas(x);
  }

}
