import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from '@interfaces';



@Component({
  selector: 'cah-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
 
  @Output() onCheck: EventEmitter<any> = new EventEmitter();
  @Input() bindName:string;
  @Input() value: boolean = false;
  @Input() disabled: boolean = false;
  @Input() returnNumber: boolean = false;
  @Input() checkedCircle: boolean = false;


  constructor() { }

  ngOnInit() {
    if (this.returnNumber) this.value = Boolean(Number(this.value));
  }

  toggleCheck() {
    if (this.disabled) return;
    this.value = !this.value;
    let payload: IInputValue = {
      name: this.bindName,
      value: this.returnNumber ? Number(this.value) : this.value
    };
    this.onCheck.emit(payload);
  }

}
