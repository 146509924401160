import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPipesModule } from 'app/shared-pipes';
import { CustomControlComponent } from "./custom-control/custom-control.component";
import { FormsModule } from '@angular/forms';
import { ImageUploadComponent } from './image-upload';
import { SharedDirectivesModule } from 'app/shared-directives';
import { InfoDropdownComponent } from './info-dropdown/info-dropdown.component';

@NgModule({
  declarations: [
    ButtonComponent, CustomControlComponent, ImageUploadComponent, InfoDropdownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedPipesModule,
    NgbModule,
    SharedDirectivesModule
  ],
  exports: [
    ButtonComponent, CustomControlComponent, ImageUploadComponent, InfoDropdownComponent
  ]
})
export class FormElementModule { }