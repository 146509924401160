import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModelTypeEnum } from "app/enums";

@Component({
  selector: "cah-custom-control",
  templateUrl: "./custom-control.component.html",
  styleUrls: ["./custom-control.component.scss"],
})
export class CustomControlComponent implements OnInit {
  @Input() model: string = ModelTypeEnum.NONE;
  @Input() idName: string = "";
  @Input() label: string = "";
  @Input() size: string = "lg";
  @Input() isActive: boolean = true;
  @Input() disabled: boolean = false;
  @Output() onCheck: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.model === ModelTypeEnum.CbxTOGGLE) {
      this.size = '';
    }
  }

  onClick(evt){
    if(!this.disabled) this.onCheck.emit(!this.isActive);
  }
}
