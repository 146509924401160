import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';

@Component({
  selector: 'cah-color-circle',
  templateUrl: './color-circle.component.html',
  styleUrls: ['./color-circle.component.scss']
})
export class ColorCircleComponent implements OnInit {
  @Input() color: string;
  @Input() group: any = {};
  @Input() index: number;
  @Input() groupDetails: boolean = false;
  @Input() groupDetailsPosition: any = {};
  @Output() outGroupDetailsShow = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onShowGroupDetails(event: any, index: number, option: boolean): void {
    this.outGroupDetailsShow.emit({ event, index, option });
  }

}
