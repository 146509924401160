import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from 'app/interfaces';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-button-form',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonFormComponent implements OnInit {

  @Input() bindName: string;
  @Input() label: string;
  @Input() type: string = 'button';
  @Input() isDisabled: boolean = false;
  @Output() onClickButton: EventEmitter<IInputValue> = new EventEmitter();

     
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  ngOnInit() {
  }

  onClick(){
    const payload: IInputValue = {
      name: this.bindName,
      value: true
    }

    this.onClickButton.emit(payload);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
