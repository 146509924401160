import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '@services/localization/localization.service';
import { map } from 'rxjs/operators';

@Pipe({ name: 'currency', pure: true})
export class CurrencyPipe implements PipeTransform {

  constructor(private localizationService: LocalizationService) { }

  transform( amount: any, isInCents: boolean = true, toFixed: number = 2): any {
    
    if (isInCents){
      amount /= 100;
    }
    
    if (toFixed){
      amount = amount.toFixed(toFixed);
    }

    return this.localizationService.getCurrency().pipe(
      map((currency: any) => {
            if (!currency) { return; }               
            return currency.currencyFull + amount;
            })
      )
  }

} 

