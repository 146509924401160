import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
} from "@angular/forms";
import { HelpQuestionMarkType, ElectiveTypeEnum } from "@enums";
import {
  IElectiveRadioOption,
  IElectiveOptionBoxForm,
  IModalvalue,
} from "@interfaces";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { CampersService } from "@services/campers/campers.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LanguageService } from "@services/language/language.service";
import { SessionService } from '@services/session/session.service';
import { HelperService } from "@services/helper/helper.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "cah-add-elective-modal",
  templateUrl: "./add-elective-modal.component.html",
  styleUrls: ["./add-elective-modal.component.scss"],
})
export class AddEectiveModalComponent implements OnInit {
  form: FormGroup;
  electiveTypeEnum = ElectiveTypeEnum;
  deletedItemId: number;
  deleteModal: any = { isOpen: false, question: "are_you_sure_delete" };
  electiveToEdit: any;
  isPickRadio = true;
  isRegRadio = false;
  elData: any = null;
  campersList: any;
  @Input() set editedElective(elective: any) {
    if (!elective) {
      this.onAddOptionBox();
      return;
    }
    this.electiveToEdit = elective;
    this.form.patchValue(elective);
    this.optionBoxForm = elective.options.sort((a, b) =>
      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    );
  }
  @Input() optionBoxForm: any = [];
  @Input() set electiveData(elData: any) {
    if (elData && elData.value) {
      this.elData = elData;
      this.isPickRadio = elData.value.type == ElectiveTypeEnum.PICKANDGO;
      this.isRegRadio = elData.value.type == ElectiveTypeEnum.REGISTRATIONBASED;
    }
  }

  @Input() selectedSession_id: any = null;
  @Input() label: string = "add_an_elective";
  @Input() discardBtn: string = "discard";
  @Input() saveBtn: string = "add";
  @Input() addNewBtn: string = "add_a_new_option";
  @Input() activityData: any[] = [];
  @Input() spaceData: any[] = [];
  @Input() selectedAssignedCampersListIds: any[] = [];
  @Input() nameError: any = null;
  pickRadioOptions: IElectiveRadioOption = {
    radioValue: "pickandgo",
    checkBoxLabel1: "pickandgoallowtochoose1",
    checkBoxLabel2: "pickandgoallowtochoose2",
    value: ElectiveTypeEnum.PICKANDGO,
    checkBoxName: "selection_change",
    helperType: HelpQuestionMarkType.INFO,
    helperRadioLableText: "pickandgoallowtochooseinf",
    helperCheckboxLableText: "pickandgocheckboxlabel",
    helperleftDirection: false,
  };
  regRadioOptions: IElectiveRadioOption = {
    radioValue: "preregistration",
    checkBoxLabel1: "preregistracionelectiveallowassign1",
    checkBoxLabel2: "preregistracionelectiveallowassign2",
    value: ElectiveTypeEnum.REGISTRATIONBASED,
    checkBoxName: "self_registration",
    helperType: HelpQuestionMarkType.INFO,
    helperRadioLableText: "preregistracionelectiveallowassigninf",
    helperCheckboxLableText: "preregistracioncheckboxlabel",
    helperleftDirection: true,
  };

  @Output() outAdd: EventEmitter<any> = new EventEmitter();
  @Output() outActSpaceSearch: EventEmitter<boolean> = new EventEmitter();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  @Output() onSearchCamper: EventEmitter<boolean> = new EventEmitter();
  @Output() outSelectedCampersChange: EventEmitter<any> = new EventEmitter();
  @Output() onActivityAction: EventEmitter<any> = new EventEmitter();
  @Output() optionBoxFormEmit: EventEmitter<any> = new EventEmitter();
  @Output() electiveFormEmit: EventEmitter<any> = new EventEmitter();
  @Output() onSpaceTabChange: EventEmitter<any> = new EventEmitter();


  alertOption: any = { isError: false, isOpen: false, message: '' };

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  optionTitle: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private campersService: CampersService,
    private languageService: LanguageService,
    private sessionService: SessionService,
    public translate: TranslateService
  ) {
    this.createForm();

    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (isHebrew) => {
        this.isHebrew = isHebrew == "he";
        let translateValue: string = await this.translate.get('option').toPromise();;
        this.optionTitle = translateValue;
      });

      this.campersService.getCampers({"session_id":this.sessionService.selectedSession.session.id, 'all': 1}).subscribe(res => {
        this.campersList = res.data;              
      })
  }

  ngOnInit() {
    switch (
      this.elData && this.elData.value.type
        ? this.elData.value.type
        : this.form.value.type
    ) {
      case this.pickRadioOptions.value:
        this.isPickRadio = true;
        this.isRegRadio = false;
        break;
      case this.regRadioOptions.value:
        this.isPickRadio = false;
        this.isRegRadio = true;
        break;
      default:
        break;
    }
  }

  onActionActivity(event) {
    this.onActivityAction.emit(event);
    this.form.value.options = this.optionBoxForm;
    this.electiveFormEmit.emit(this.form);
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      id: new FormControl(null),
      title: new FormControl(null, [
        Validators.required,
        Validators.maxLength(25),
      ]),
      color: new FormControl("#1e84f0", Validators.required),
      type: new FormControl(ElectiveTypeEnum.PICKANDGO, Validators.required),
      selection_change: new FormControl(false, Validators.required),
      self_registration: new FormControl(false, Validators.required),
      options: new FormControl([]),
    });
  }

  onCloseModal(): void {
    this.onClose.emit(false);
  }

  onAdd(): void {
    //if(this.electiveToEdit && this.electiveToEdit.id){
    // let allowSplashActSelectNew = { 'electiveId': this.electiveToEdit.id,  'canChange': this.form.value.selection_change};
    // if(!localStorage.getItem('allowSplashActSelect')){
    //   localStorage.setItem('allowSplashActSelect', JSON.stringify([allowSplashActSelectNew]));
    // }else{

    //   let selectActStatusItem = JSON.parse(localStorage.getItem('allowSplashActSelect'));

    //   let finded = false;
    //   selectActStatusItem.forEach(el => {
    //       if(el.electiveId == this.electiveToEdit.id){
    //           el.canChange = this.form.value.selection_change;
    //           finded = true;
    //           return;
    //       }
    //     });

    //   if(!finded){
    //     selectActStatusItem.push(allowSplashActSelectNew);
    //   }
    //   localStorage.setItem('allowSplashActSelect', JSON.stringify(selectActStatusItem));

    // }
    //}

    if (this.form && this.form.value && !this.form.value.title) {
      this.nameError = { name: "title", message: "name_is_required" };
    }
    if (this.form.invalid) {
      return;
    }

    for (const optionIndex in this.optionBoxForm) {
      if (this.optionBoxForm.hasOwnProperty(optionIndex)) {
        const option = this.optionBoxForm[optionIndex];

        for (const camperIndex in option.campers) {
          if (option.campers.hasOwnProperty(camperIndex)) {
            const campers = option.campers[camperIndex];
            if (campers.camper) {
              option.campers[camperIndex] = campers.camper.id;
            }
          }
        }
        if (!option.title || !option.activity || !option.activity_id || !option.space_id || !option.space) {
          this.outAdd.emit({
            hasError: true,
            errorMessage: "cant_save_empty_option",
          });
          return;
        }
      }
    }


    this.optionBoxForm.map(op => {
      op.campers = op.campers.filter(cmp => this.campersList.find(el => el.id == cmp))
    });
     

    this.form.patchValue({ options: this.optionBoxForm });

    this.outAdd.emit(
      this.elData && this.elData.value ? this.elData.value : this.form.value
    );
    this.elData = null;
    this.form = null;
  }

  onChangeValue(e: any): void {
    if (e.name === "title" && (!e.value || e.value.length > 25)) {
      this.nameError = { name: "title", message: "max_symbols" };
    } else if (e.name === "electiveType") {
      this.form.value.type = e.value;
      this.form.patchValue({ ["type"]: e.value });
      switch (this.form.value.type) {
        case this.pickRadioOptions.value:
          this.isPickRadio = true;
          this.isRegRadio = false;
          break;
        case this.regRadioOptions.value:
          this.isPickRadio = false;
          this.isRegRadio = true;
          break;
        default:
          break;
      }
    } else {
      this.nameError = null;
      this.form.patchValue({ [e.name]: e.value });
    }
  }

  onActSpaceSelect(e: any, index: number): void {
    if (e.name === "activity") {      
      this.optionBoxForm[index].activity_id = e.value.item && e.value.item.id ? e.value.item.id : null ;
      this.optionBoxForm[index].activity = { title: e.value.item && e.value.item.title ? e.value.item.title : null };
    } else {
      this.optionBoxForm[index].space_id = e.value.item && e.value.item.id ? e.value.item.id : null ;
      this.optionBoxForm[index].space = { title: e.value.item && e.value.item.title ? e.value.item.title : null };
    }
  }

  onAlertError(event){    
    this.alertOption =  event;
  }

  onAssignedCampersChange(payload: any, index: number): void {
    
    this.optionBoxForm[index].campers = payload.selectedAssignedCampers;
    this.selectedAssignedCampersListIds = [];

    this.optionBoxForm.forEach((x) => {
      x.campers.forEach((camperOption) => {
        if (camperOption.camper && camperOption.camper.id) {
          this.selectedAssignedCampersListIds.push(camperOption.camper.id);
        } else {
          this.selectedAssignedCampersListIds.push(camperOption);
        }
      });
    });    
    this.outSelectedCampersChange.emit(this.selectedAssignedCampersListIds);
  }

  onDeleteOptionBox(e: any, index: number): void {
    this.deletedItemId = index;
    this.deleteModal.isOpen = true;
  }

  onDelete(option: IModalvalue): void {
    if (option.confirm) {
      this.optionBoxForm[this.deletedItemId].deleted = true;
      this.onAssignedCampersChange(
        { action: "delete", selectedAssignedCampers: [] },
        this.deletedItemId
      );
    }
    this.deleteModal.isOpen = false;
  }

  onAddOptionBox(): void {
    const newOptionBoxForm: IElectiveOptionBoxForm = {
      id: null,
      title: this.optionTitle,
      order: this.optionBoxForm.length,
      activity_id: null,
      activity: {},
      space_id: null,
      space: {},
      campers: [],
      deleted: false,
    };

    this.optionBoxForm.push(newOptionBoxForm);
  }

  setOrdersToOptionBoxItems(): void {
    let i = 0;
    while (i < this.optionBoxForm.length) {
      this.optionBoxForm[i].order = i;
      i++;
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.optionBoxForm,
      event.previousIndex,
      event.currentIndex
    );
    console.log(event);
    
    this.setOrdersToOptionBoxItems();
  }

  outSearchCampers(e) {
    this.onSearchCamper.emit(e);
  }

  checkBoxChecked(checkBoxName) {
    
    
    return this.elData && this.elData.value[checkBoxName]
      ? this.elData.value[checkBoxName]
      : this.form && this.form.value[checkBoxName]
      ? this.form.value[checkBoxName]
      : false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
  
  // @HostListener("document:click", ["$event"])
  // clickout(event) {
  //   if (
  //     event.target.getAttribute("class") &&
  //     event.target.getAttribute("class").includes("elective-modal close")
  //   ) {
  //     this.onCloseModal();
  //   }
  // }
  
}
