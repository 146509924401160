import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IInputValue } from '@interfaces';
import { LanguageService } from '@services/language/language.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'cah-checkbox-form',
  templateUrl: './checkbox-form.component.html',
  styleUrls: ['./checkbox-form.component.scss']
})
export class CheckboxFormComponent implements OnInit {
  @Input() bindName:string;
  @Input() value: boolean = false;
  @Input() disabled: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() error: any;
  @Input() isRequired: any;
  @Input() label: any;
  @Input() helper: string;
  @Input() helperIcon: string;
  @Input() returnNumber: boolean = false;
   
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor(private languageService: LanguageService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  ngOnInit() {
  }

  toggleCheck() {
    if (this.disabled) return;
    this.value = !this.value;
    let payload: IInputValue = {
      name: this.bindName,
      value: this.returnNumber ? Number(this.value) : this.value
    };
    this.onChange.emit(payload);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
