import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IInputValue } from "@interfaces";
import { LanguageService } from "@services/language/language.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";

@Component({
  selector: "cah-upload-cropped-image",
  templateUrl: "./upload-cropped-image.component.html",
  styleUrls: ["./upload-cropped-image.component.scss"],
})
export class UploadCroppedImageComponent implements OnInit {
  @Input() imageValues: any = "";
  @Input() imageChangedEvent: any = "";

  @Output() onChange: EventEmitter<IInputValue> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  transform: ImageTransform = {};
  scale: number = 1;
  fakeScale: number = 1;

  croppedImage: any = "";
  cropper = {
    x1: 100,
    y1: 100,
    x2: 200,
    y2: 200,
  };

  minScale: number = 1;
  maxScale: number = 100;

  constructor(private languageService: LanguageService) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isHebrew) => {
        this.isHebrew = isHebrew == "he";
      });
  }

  ngOnInit() {}

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(image: string) {
    this.croppedImage = image;
  }
  imageLoaded() {
    // show cropper
    setTimeout(() => {
      this.cropper = {
        x1: 131,
        y1: 51,
        x2: 500,
        y2: 500,
      };
    });
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  zoomIn() {
    if (this.fakeScale >= 100 || this.scale >= 2) {
      this.fakeScale = 100;
      this.scale = 2;
      return;
    }
    this.fakeScale += 5;

    this.scale += 0.05;

    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomOut() {
    if (this.fakeScale <= 1 || this.scale <= 1) {
      this.fakeScale = 1;
      this.scale = 1;
      return;
    }
    this.fakeScale -= 5;
    this.scale -= 0.05;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  slideZoom(fakeScale: number) {
    this.fakeScale = +fakeScale;
    this.scale = this.fakeScale / 100 + 1;

    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  onCloseModal() {
    this.onClose.emit('clicked');
  }


  onAdd(): void {
    const payload: IInputValue = {
      name: '',
      value: this.croppedImage,
    };
    this.onSubmit.emit(payload);
  }


  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
