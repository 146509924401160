import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';
import { ModelTypeEnum } from "app/enums";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'cah-date-list',
  templateUrl: './date-list.component.html',
  styleUrls: ['./date-list.component.scss']
})
export class DateListComponent implements OnInit {
  @Input() bindName : string;
  @Input() day : any;
  @Input() start_date : any;
  @Input() end_date : any;
  @Input() repeating : number;
  @Input() helper : any;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  dates = [];
  selectedDates = [];

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;

  constructor( public languageService: LanguageService, private dateFormatterService: DateFormatterService) { 
    languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == "he";
    });
  }

  ngOnInit() {
  }

  ngOnChanges(){
    if(this.day && this.start_date && this.end_date){
      this.dates = this.dateFormatterService.getRange(this.start_date, this.end_date, this.day, this.repeating);
      this.selectedDates = this.dateFormatterService.getRange(this.start_date, this.end_date, this.day, this.repeating);
      const payload = {
        name: this.bindName,
        value: this.selectedDates
      }
  
      this.onChange.emit(payload);
    }
  }

  selectDate(date){
    if(this.selectedDates.includes(date)){
      this.selectedDates.splice(this.selectedDates.indexOf(date), 1);
    }else{
      this.selectedDates.push(date);
    }

    const payload = {
      name: this.bindName,
      value: this.selectedDates
    }

    this.onChange.emit(payload);
  }

}
