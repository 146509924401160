import { Component, OnInit, Input } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { TimeFormat } from '@enums';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cah-tomorrow-box',
  templateUrl: './tomorrow-box.component.html',
  styleUrls: ['./tomorrow-box.component.scss']
})
export class TomorrowBoxComponent implements OnInit {
  @Input() timeFormat: TimeFormat = TimeFormat.HOUR12;
  @Input() campId: number;
  @Input() background: string;
  @Input() session_start_time: string;
  @Input() session_end_time: string;
  @Input() dayOfWeek: any;
  @Input() activityData: any;
  @Input() activityDate: string;

  timeFormatEnum = TimeFormat;

  isHebrew: boolean = false;

  constructor() {
      this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;

   }

  ngOnInit() {
  }


}
