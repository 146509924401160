import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'numberToArray'})
export class NumberToArray implements PipeTransform {
  transform(value: number, start: number = 1): number[] {
    const res = [];
    for (let i = start; i <= value; i++) {
        res.push(i);
      }
    return res;
  }
}
