import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cah-row-status',
  templateUrl: './row-status.component.html',
  styleUrls: ['./row-status.component.scss']
})
export class RowStatusComponent implements OnInit {
  @Input() status: string;
  @Input() row_id: string;
  @Output() getRowId: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  onActivateId(row_id){
    this.getRowId.emit(row_id);
  }
}
