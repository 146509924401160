import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HelperService } from '@services/helper/helper.service';
import { environment } from 'environments/environment';
import { map, catchError, take, shareReplay } from 'rxjs/operators';
import { TranslateService as Translate} from '@ngx-translate/core';
import { ThemeService } from '@services/theme/theme.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor(private http: HttpClient, public translate: Translate) { }

  getLang(lang: string): Observable<any> {
    return this.http.get(environment.endpoint + 'translate/export/json/' + lang)
      .pipe(
        take(1),
        shareReplay(1),
        map(res => res),
        catchError( error => {
          return throwError(error.error || "server error")
        })
      )
  }

  getTranslations(filter?: any): Observable<any> {
    const queryString = HelperService.generateQueryParams(filter);
    return this.http.get<any>(environment.endpoint + 'translate?' + queryString)
      .pipe(
        map(res => res),
        catchError(err => throwError(err))
      )
  }

  upadteTranslation(id: string, data: any) {
    return this.http.patch(environment.endpoint + 'translate/key/' + id, data)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }
  
  addTranslation(data: any) {
    return this.http.post(environment.endpoint + 'translate/add/' ,data)
        .pipe(
          map( res => res ),
          catchError( error => {
            return throwError(error.error || "server error")
          })
        )
  }

  setLanguage(lang: string): Promise<boolean> {
    const promise: Promise<boolean> = new Promise((resolve, reject) => {  
      this.translate.addLangs([lang]);
      this.translate.use(lang);
      this.getLang(lang)
        .subscribe(res => {
          this.translate.setTranslation(lang, res, true);
          ThemeService.changeFontFamily(lang);
          resolve(true);
        }, err => {
          console.error(err);
          reject(false);
        });
    });
    return promise;
  }

}
