import { Component, OnInit, EventEmitter, HostListener, OnDestroy, Input } from '@angular/core';
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SessionService } from '@services/session/session.service';
import { ProgramService } from '@services/program/program.service';
import { ProgramTypeEnum } from 'app/enums';

@Component({
  selector: 'cah-session-picker',
  templateUrl: './session-picker.component.html',
  styleUrls: ['./session-picker.component.scss']
})
export class SessionPickerComponent implements OnInit, OnDestroy {

  boxState: boolean = false;
  private clickInside: boolean = false;
  language: string = 'en';
  showDropdown: boolean = false;
  sessions: any[] = null;
  programs: any = [];
  selectedSession: any = null;
  onSelect: EventEmitter<any> = new EventEmitter();
  isHebrew: boolean;
  private unsubscribe$ = new Subject<any>();
  private unsubscribeSession$ = new Subject<any>();
  filter: any = {
    search: ''
  }

  constructor(
    private dateFormatterService: DateFormatterService,
    private programService: ProgramService,
    public languageService: LanguageService,
    public sessionService: SessionService
  ) {
    this.getPrograms({ type: ProgramTypeEnum.PROGRAM });
    this.sessionService.selectedSession$
      .pipe(takeUntil(this.unsubscribeSession$))
      .subscribe(selectedSession => {
        if (!selectedSession.isLoaded) { return; }
        this.selectedSession = selectedSession.session;
      });

    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(lang => {
        this.language = lang;
        this.isHebrew = lang == 'he';
      });

  }

  ngOnInit() {
  }

  getPrograms(filter: any = {}) {
    this.programService.getPrograms(filter)
      .subscribe(res => {
        this.programs = res.data;
      })
  }

  @HostListener('click')
  onComponentClick() {
    this.clickInside = true;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (!this.clickInside && this.boxState) {
      this.boxState = false;
    }
    this.clickInside = false;
  }

  onShowDropdown(){
    this.showDropdown = !this.showDropdown;
  }

  toggleBox(): void {
    this.boxState = !this.boxState;
  }

  onSelectChange(session: any) {
    this.boxState = false;
    console.log(session);
    
  }

  getDateLabel(startDate, endDate): string {
    return this.dateFormatterService.formatDate(startDate, this.language) + " - "
      + this.dateFormatterService.formatDate(endDate, this.language);
  }

  ngOnDestroy(): void {
    this.unsubscribeSession$.next();
    this.unsubscribeSession$.complete();
  }

  search(event){
    this.filter.search = event.value;
    this.getPrograms(this.filter);
  }

}
