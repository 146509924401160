import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cah-warning-splash',
  templateUrl: './warning-splash.component.html',
  styleUrls: ['./warning-splash.component.scss']
})
export class WarningSplashComponent implements OnInit {
  @Input() logo: string = '/assets/images/splash/logo.png';
  @Input() text: string = 'we_hope_you_had_wonderful_time';
  @Input() text2: string = 'we_already_miss_you_too';
  @Input() mainText: string = 'see_you_next_time';
  @Input() camper: any ;
  @Input() campId: number;

  isHebrew: boolean = false;

  constructor() {
        this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;
               }

  ngOnInit() {
  }

}
