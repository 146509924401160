import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { IDisplayColumn, ResponseList } from 'app/interfaces';
import { DateFormatterService } from '@util/date-formatter/date-formatter.service';
import { TimeFormat } from 'app/enums';
import { Router } from '@angular/router';
import { HelperService } from '@services/helper/helper.service';
import * as moment from 'moment';
import 'moment-timezone';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { CampService } from '@services/camp/camp.service';
import { DateFormatService } from '@services/date-format/date-format.service';
import { HostListener } from '@angular/core';


@Component({
  selector: 'cah-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() showCheckbox: boolean = true;
  @Input() showIndex: boolean = false;
  @Input() showId: boolean = false;
  @Input() dataSource: ResponseList;
  @Input() displayColumns: IDisplayColumn[];
  @Input() checkedItems: any[] = [];
  @Input() deleteOnInactive: boolean = false;
  @Input() isRecordingPage: boolean = false;
  @Input() isActionsListReversed: boolean = false;
  @Input() actions: any = {
    delete: true,
    edit: true,
    view: true,
    download: false,
    duplicate: false,
    resetTemplate: false,
    resetCamper: false
  };
  actionsView: boolean = true;
  @Input() columnSort: any = {};
  @Input() dotsActionTitle: any = {};

  @Input() isDataLoaded: boolean = false;
  @Input() isScrollable: boolean = false;
  @Input() sessionExists: boolean = false;

  @Output() onActionClick: EventEmitter<any> = new EventEmitter();
  @Output() getSwitchOption: EventEmitter<any> = new EventEmitter();
  @Output() onRowCheck: EventEmitter<any> = new EventEmitter();
  @Output() getPage: EventEmitter<number> = new EventEmitter<number>();
  @Output() getClickedActBtn: EventEmitter<number> = new EventEmitter<number>();
  @Output() getClickedArchiveBtn: EventEmitter<number> = new EventEmitter<number>();
  @Output() switchChange: EventEmitter<any> = new EventEmitter<any>();

  groupDetailsPosition: any = {}
  groupDetails: any = {};
  groupDetailsShow: boolean = false;
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;
  campTimeZone: string = 'Etc/GMT'
  roomType: string = '';
  dropdownStatus: boolean = false;
  dropDownId: number;
  showCopyTooltip: any = {};

  constructor(
    private dateFormatterService: DateFormatterService,
    private router: Router,
    languageService: LanguageService,
    private campService: CampService,
    public dateFormatService: DateFormatService
  ) {
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });

    this.campService.campInfo$
      .subscribe(res => {
        if (!res) { return; }
        this.campTimeZone = res.timezone.slug;
        this.roomType = res.room_type;
      })
  }


  isListLoaded = false;

  ngOnInit() {
    setTimeout(() => {
      this.isListLoaded = this.isDataLoaded;

    }, 800);
    console.log(this.actions);
    
    this.actionsView = this.actions.delete || this.actions.edit || this.actions.view || this.actions.duplicate ? true : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isListLoaded = false;

    if (changes.dataSource && changes.dataSource.previousValue) {
      if (changes.dataSource.currentValue.total === 0) { //&& changes.dataSource.previousValue.total != 0
        setTimeout(() => {
          this.isListLoaded = true;
        }, 900);
      }
    }
  }

  onShowGroupDetails(e: any): void {
    const { event, index, option } = e;
    this.groupDetailsShow = option;
    this.groupDetails[index] = option;
    this.groupDetailsPosition = HelperService.calculatePopupPosition(event.pageX,
      event.pageY,
      400, 300,
      window.innerHeight,
      window.innerWidth,
      window.scrollY,
      window.scrollX);
  }

  onColumnSort(column) {
    if (this.columnSort.hasOwnProperty(column)) {
      if (this.columnSort[column] === "asc") {
        this.columnSort[column] = "desc"
      } else {
        this.columnSort[column] = "asc"
      }
    } else {
      this.columnSort = {};
      this.columnSort[column] = "asc"
    }

    const payload = {
      action: 'sort',
      data: `${Object.keys(this.columnSort)[0]},${this.columnSort[column]}`
    }

    this.onActionClick.emit(payload)
  }

  actionClick(event, index, confirm: boolean = true) {
    let payload = {
      action: event,
      index: index,
      confirm: confirm
    }
    this.dropdownStatus = false;
    this.onActionClick.emit(payload);
  }

  actionClickRowId(rowId) {
    this.getClickedActBtn.emit(rowId);
  }

  getArchiveOption(option) {
    console.log(option);
    this.getClickedArchiveBtn.emit(option);
  }

  onCheck(event, index, option: string = 'single') {
    if (option === 'all') {
      event.value ? this.checkedItems = [...this.dataSource.data] : this.checkedItems = [];
    } else {
      const row = this.dataSource.data[index];
      if (event.value) {
        this.checkedItems.push(row);
      } else {
        this.checkedItems = this.checkedItems.filter(item => item.id !== row.id);
      }
    }
    this.onRowCheck.emit(this.checkedItems);
  }

  onSwitchChange(event, index) {
    this.switchChange.emit({ 'status': event, 'rowId': index })
  }

  openDropdown(i: number = -1) {
    this.dropDownId = i;
    this.dropdownStatus = !this.dropdownStatus;
  }

  getFormattedDate(date, time: boolean = false) {
    return this.dateFormatterService.formatDate(date, this.isHebrew ? 'he' : 'en', time);
  }

  getFormattedDateWithTime(date: any, campTimeZone: string, dateFormat: any) {
    if (!date) { return date; }
    let timeFormat: string = 'hh:mm A'
    if (dateFormat === TimeFormat.HOUR24) {
      timeFormat = 'HH:mm';
    }
    if (this.isHebrew) { return moment.tz(date, campTimeZone).format('DD/MM/YYYY HH:mm'); }
    const time = moment.tz(date, campTimeZone).format('dddd, MMMM DD, ' + timeFormat);
    return time;
  }

  getObjectVal(item: any, val: any): any {
    if (!item || !val) return;
    const nestedObj: string[] = val.split('.');
    for (const objProrperty of nestedObj) {
      if (!item) return;
      item = item[objProrperty];
    }
    return item;
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.getAttribute('class') &&
      !event.target.getAttribute('class').includes('cah-table__action-dots')) {
      this.dropdownStatus = false;
    }
  }

  numberWithCommas(n) {
    return HelperService.numberWithCommas(n);
  }

  goToLink(link: string, target): void {
    HelperService.goToLink(link, target);
  }

  moment(datetime) {
    return moment(datetime).format('llll');
  }

  copyToClipboard(i, str: string) {

    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;

    //e.stopPropagation();
    if (i >= 0) {
      this.showCopyTooltip[i] = true;
      setTimeout(() => {
        this.showCopyTooltip[i] = false;
      },
        350);
    }


    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = baseUrl + '/' + str;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }
}
