import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { ScheduleTypeEnum, AssignmentTypeEnum } from '@enums';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { PickerColors } from '@colors';

@Component({
  selector: 'cah-staff-board',
  templateUrl: './staff-board.component.html',
  styleUrls: ['./staff-board.component.scss']
})
export class StaffBoardComponent implements OnInit {

  scheduleType = ScheduleTypeEnum;
  assignmentTypeEnum = AssignmentTypeEnum;

  @Input() days: any[] = [];
  @Input() selectedDayIndex: number;
  @Input() groups: any = {};
  @Input() timeslots: any[];
  @Input() staff: any[];
  @Input() spaces: any = {};
  @Input() selectedSchedule: any;
  @Input() canTimeslotCopied: boolean = false;
  @Input() timeslotStart: number;
  @Input() timeslotLimit: number;
  @Input() isCopied: any = false;
  @Input() hovered: any = {
    row: null,
    col: null
  }
  @ViewChild('scroll',{static:true}) scroll: any;
  @ViewChild('board', { static: true }) board: any;
  @Input() set scrollBody(left) { 
    this.scroll.nativeElement.scrollLeft = left; 
    this.openModal = {
      row: null,
      col: null
    } 
  };
  @Output() onScheduleSelect: EventEmitter<any> = new EventEmitter();
  @Output() onScheduleDelete: EventEmitter<any> = new EventEmitter();
  @Output() onStaffDelete: EventEmitter<any> = new EventEmitter();
  @Output() onSchedulePaste: EventEmitter<any> = new EventEmitter();
  @Output() onEditBox: EventEmitter<number> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onCopied: EventEmitter<any> = new EventEmitter();
  @Output() onHover: EventEmitter<any> = new EventEmitter();
  @Output() onEditQuick: EventEmitter<any> = new EventEmitter();
  @Output() onScroll: EventEmitter<any> = new EventEmitter();
  @Output() onTimeslotAction: EventEmitter<any> = new EventEmitter();
  @Output() onAddStaff: EventEmitter<boolean> = new EventEmitter();
  @Output() onTabChange: EventEmitter<any> = new EventEmitter();

  selectedDay: any;
  rowSchedule: any = {};
  emptyBoxesQty: number = 0;
  hasHorizontalScrollbar: boolean = false;
  coordinate = { i: -1, j: -1 }
  pickerColors = PickerColors;

  deletePayload = {};
  @Input() openModal = {
    row: null,
    col: null
  }

  showDropDown: any = {
    row: null,
    col: null
  }

  position = {
    vertical: 'bottom',
    horizontal: 'right'
  }

  alertOption = {
    isError: false,
    message: 'Copied!',
    isOpen: false
  };

  confirmModal: any = {
    question: 'are_you_sure_delete',
    show: false
  };

  timeslotDropdown: any = { col: false };

  showCopy: boolean;
  selectedStaff: any;

  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false;


  constructor(public languageService: LanguageService
    ) {
      languageService.language$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(isHebrew => {
      this.isHebrew = isHebrew == 'he';
    });
   }

  ngOnInit() {
    this.emptyBoxesQty = (this.board.nativeElement.offsetHeight) / 70;
  }

  ngAfterViewInit(): void {
    this.onCheckVerticalScroll();
    console.log(this.rowSchedule)
  }

  ngOnChanges() {
    setTimeout(() => {
      this.onCheckVerticalScroll();
    })
    this.selectedDay = this.selectedDayIndex != null && this.days ? this.days[this.selectedDayIndex] : null;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.getAttribute('class') && 
        (!event.target.getAttribute('class').includes('board-right-list-item-box-full-icons-icon-dots') &&
      !event.target.getAttribute('class').includes('icon-svg-dots'))) {
      this.showDropDown = {
        row: null,
        col: null
      }
    }

    if (event.target.getAttribute('class') !== 'board-right-list-item-header-right-icon' &&
      event.target.getAttribute('class') !== 'icon-svg-dots') {
      this.timeslotDropdown = {
        col: null
      }
    }
  }

  onCheckVerticalScroll(): void {
    const div = document.getElementById('board-right-list');
    this.hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
  }

  onShowTimeslotDropDown(i: number): any {
    if (this.timeslotDropdown.col === i) {
      return this.timeslotDropdown.col = false;
    }
    this.timeslotDropdown.col = i;
  }

  onActionTimeslot(name: string, option: any): void {
    this.onTimeslotAction.emit({ name, option });
  }

  findSchedule(schedules: any[], staffId: any, timeslotId: number): any {
    // if (this.rowSchedule[timeslotId + '-'+staffId.staff.id]) { return this.rowSchedule[timeslotId + '-'+staffId.staff.id]; }
    if (!schedules) { return; }
    const findSchedule: any = schedules.find(sched => sched.staff_id == staffId.staff.id);
    if (findSchedule) {
      this.rowSchedule[timeslotId + '-'+staffId.staff.id] = findSchedule;
    }
    return findSchedule;
  }

  mouseOver(row, col) {
    this.hovered = {
      row,
      col
    }

    this.onHover.emit(this.hovered);
  }

  closePopup() {
    this.onTabChange.emit({index: 0});
    this.openModal = {
      row: null,
      col: null
    }
  }

  showPopup(event, row, col, scheduleIndex: any = 0, scheduleBlock?: any) {
    if (this.openModal.row == row && this.openModal.col == col) {
      this.closePopup();
    } else {
      this.openModal = {
        row,
        col
      }
    }
    this.position = HelperService.calculatePopupPosition(event.pageX, event.pageY, 400, 300, window.innerHeight+50, window.innerWidth, window.scrollY, window.scrollX);
    
    let schedule = {
      payload: scheduleBlock ? scheduleBlock : {
        timeslot_id: this.timeslots[col].id,
        staff_id: this.staff[row].staff.id,
        space_id: null,
        space: null,
        group_id: null,
        group: null,
        type: AssignmentTypeEnum.GROUP
      },
      timeslotIndex: col,
      scheduleIndex: scheduleIndex
    }
    this.onScheduleSelect.emit(schedule);
  }

  onCopySchedule(schedule: any, i = -1, j = -1): void {
    this.onCopied.emit(schedule);
    this.showDropDown.row = null;
    this.showDropDown.col = null;

    if (i >= 0 && j >= 0) {
      this.coordinate.i = i;
      this.coordinate.j = j;
      setTimeout(() => {
        this.coordinate.i = -1;
        this.coordinate.j = -1;
      },
        350)
    }

  }

  onPasteSchedule(row, col): void {
    if (!this.isCopied) { return; }
    let schedule = {
      payload: {
        timeslot_id: this.timeslots[col].id,
        staff_id: this.staff[row].staff.id,
        group_id: this.isCopied.group_id,
        space_id: this.isCopied.space_id,
        type: this.isCopied.type,
        space: null,
        group: null,
      },
      timeslotIndex: col,
    }

    this.onSchedulePaste.emit(schedule);
  }

  onShowDropDown(i: number, j: number): void {
    this.showDropDown.row = i;
    this.showDropDown.col = j;
  }

  onBoxSearch(event) {
    this.onSearch.emit(event)
  }

  onBoxSelect(event) {
    this.onSelect.emit(event)
  }

  onEditQuickAssign(schedule, timeslotIndex, scheduleIndex): void {
    this.onEditQuick.emit({schedule, timeslotIndex, scheduleIndex});
  }

  deleteSchedule(scheduleId, timeslotIndex, scheduleIndex) {
    this.deletePayload = {
      scheduleId,
      timeslotIndex,
      scheduleIndex
    }
    // this.confirmModal.show = true;
    // this.confirmModal.name = 'schedule';
    // this.confirmModal.question = 'are_you_sure_delete';
    this.onScheduleDelete.emit(this.deletePayload);
  }

  onConfirm(event) {
    if (event.confirm) {
      if (event.modalName === 'schedule') {
        this.onScheduleDelete.emit(this.deletePayload);
      } else if (event.modalName === 'staff') {
        this.onStaffDelete.emit(this.selectedStaff);
      }
    }
    this.confirmModal.show = false;
  }

  onDeleteStaff(staff: any): void {
    this.selectedStaff = staff;
    this.confirmModal.show = true;
    this.confirmModal.question = 'are_you_sure_remove';
    this.confirmModal.name = 'staff';
  }
  onScrollBox(event){
    this.onScroll.emit(event.target.scrollLeft);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

}
