import { Component, OnInit, HostListener, Input } from '@angular/core';
import { UserService } from '@services/user/user.service';
import { CampService } from '@services/camp/camp.service';
import { UserRole, RouteTypeEnum } from '@enums';
import { HelperService } from '@services/helper/helper.service';
import { LanguageService } from '@services/language/language.service';
import { DateFormatService } from '@services/date-format/date-format.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ThemeService } from '@services/theme/theme.service';
import { Router } from '@angular/router';
import { ConfigService } from '@services/config/config.service';

@Component({
  selector: 'cah-header',
  templateUrl: './header.container.html',
  styleUrls: ['./header.container.scss']
})
export class HeaderContainer implements OnInit {

  showDropDown: boolean = false;
  camp: any;
  campLogo: any;
  defaultLogo;
  usersRole: number;
  userFullName: string;
  userInf: any;
  private unsubscribe$ = new Subject<any>();
  isHebrew: boolean = false
  filter: any = {};
  sessionList: any[] = [];
  userEmail: string = "";

  //for demonstration purpose only
  @Input() breadcrumbs: any[] = [];
  @Input() showSession: boolean = false;
  @Input() dummySessions: any[] = [];

  constructor(private userService: UserService,
    public campService: CampService,
    public languageService: LanguageService,
    public themeService: ThemeService,
    private dateFormatService: DateFormatService,
    public router: Router,
    private configService: ConfigService
  ) {
    this.configService.getConfig({ sections: ['campers'] })
      .subscribe( res => this.configService.extractMergeTags(res));
    languageService.language$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isHebrew => {
        this.isHebrew = isHebrew == 'he';
      });

  }

  ngOnInit() {
    const routesWithSessions = RouteTypeEnum as any;
    this.sessionList = Object.values(routesWithSessions);
    this.userService.userInfo.subscribe(
      res => {
        this.userInf = res;
        if (res && (res.first_name || res.last_name)) {
          this.userFullName = res.first_name + ' ' + res.last_name;
        }
        if (res && res.email) {
          this.userEmail = res.email;
        }
        if (res && res.role_id) {
          this.usersRole = res.role_id;

          if (res.role_id != UserRole.SUPERADMIN) {
            this.campService.updateBalance();
          } 
        }
        if(res && res.image){
          this.defaultLogo = res.image;
        }
      }
    );
  }

  onDropDownClick(event) {
    this.showDropDown = !this.showDropDown;
  }

  // Close the dropdown if the user clicks outside of it
  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (event.target.id != 'dropdown') {
      this.showDropDown = false;
    }
  }

  logout() {
    if (this.userInf.role_id != UserRole.SUPERADMIN && localStorage.getItem('superAdminToken')) {
      localStorage.setItem('token', localStorage.getItem('superAdminToken'));
      localStorage.removeItem('superAdminToken');
      HelperService.goToLink(window.location.origin, '_self');
    } else {
      localStorage.removeItem('superAdminToken');
      this.userService.logout();
    }
  }
  numberWithCommas(n) {
    return HelperService.numberWithCommas(n);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
