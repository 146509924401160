import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef, EventEmitter,
  Input,
  OnChanges,
  OnInit, Output, QueryList,
  SimpleChanges,
  ViewChildren, ViewContainerRef
} from '@angular/core';

@Component({
  selector: 'cah-box-lists',
  templateUrl: './box-lists.component.html',
  styleUrls: ['./box-lists.component.scss']
})
export class BoxListsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input('data') data: any[];

  @Input('component') component: any;
  @ViewChildren('target', {read: ViewContainerRef}) target: QueryList<ViewContainerRef>;

  cmpRef: Array<ComponentRef<any>> = [];

  @Output('listeners') listeners: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.target.forEach(((item, key) => {
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.component);
      this.cmpRef[key] = item.createComponent(factory);

      this.cmpRef[key].instance.item = this.data[key];

      // for (const label in this.cmpRef[key].instance.viewFields) {
      //   if (this.cmpRef[key].instance.viewFields.hasOwnProperty(label)) {
      //     // this.cmpRef[key].instance[label] = this.data[key][label];
      //   }
      // };



      this.cmpRef[key].instance.events.subscribe(event => {
        this.listeners.emit(event);
      });
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  trackById = (index: number, obj: object): string => {
    return this.data[index];
  };

}
