import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CampersService } from '@services/campers/campers.service';

@Component({
  selector: 'cah-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.scss']
})
export class GroupDetailComponent implements OnInit {

  @Input() group: any = {};
  campers: [] = [];
  @Input() showDropdown: boolean = false;
  
  constructor(private campersService: CampersService) { }

  ngOnInit() {       
  }

  getCampers(group_id){
    this.campersService.getCampers({ group_id, all:1 })
    .subscribe(res => {
      this.campers = res.data;
      this.showDropdown =  res.data.length > 0 ? true : false;
      console.log(this.campers);
      
    });
  }

}
