import { Directive, OnInit, OnDestroy, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from '@services/user/user.service';
import { HelperService } from '@services/helper/helper.service';
import { UserRole } from '@enums';

@Directive({
    selector: '[appHasRole]'
  })
export class HasRoleDirective implements OnInit, OnDestroy {
  // the role the user must have
  @Input() appHasRole: string[];

  private unsubscribe$ = new Subject<void>();

  isVisible: boolean = false;

  /**
   * @param {ViewContainerRef} viewContainerRef
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef
   *   -- the templateRef to be potentially rendered
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.userService.userInfo
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        // Check user data and if it clear or null, we clear the viewContainerRef
        if (!user || HelperService.isObjectEmpty(user)) {
          return this.viewContainerRef.clear();
        }
        // map role array with enum
        const hasRoles = [...this.appHasRole.map(role => UserRole[role])];
        // If the user has the role needed to
        // render this component we can add it
        if (!hasRoles.includes(user.role_id) || (hasRoles.includes(UserRole.OWNER) && !user.is_owner)) {
          // If the user does not have the role, 
          // we update the `isVisible` property and clear
          // the contents of the viewContainerRef
          this.isVisible = false;
          this.viewContainerRef.clear();
          
        } else {
          // If it is already visible (which can happen if
          // his roles changed) we do not need to add it a second time
          if (!this.isVisible) {
            // We update the `isVisible` property and add the 
            // templateRef to the view using the 
            // 'createEmbeddedView' method of the viewContainerRef
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}