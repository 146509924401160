import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import * as moment from 'moment';

@Pipe({
  name: 'dateRange',
  pure: true
})
export class DateRangePipe implements PipeTransform {

  transform(dates: any[]): string {
    if(HelperService.dateIsEqual(dates[0], dates[1])){
      return moment(dates[0]).format('MMM D')
    }else{
      return moment(dates[0]).format('MMM D')  + ' - ' + moment(dates[1]).format('MMM D') 
    }
  }

}
