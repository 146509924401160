import { Component, OnInit } from '@angular/core';
import { SplashError, ScheduleTypeEnum, ElectiveTypeEnum, RoomTypeEnum } from '@enums';
import { CampersService } from '@services/campers/campers.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { LanguageService } from '@services/language/language.service';
import { takeUntil } from 'rxjs/operators';
import { CampService } from '@services/camp/camp.service';


@Component({
  selector: 'cah-elective-splash',
  templateUrl: './elective-splash.component.html',
  styleUrls: ['./elective-splash.component.scss']
})
export class ElectiveSplashComponent implements OnInit {

  logo: string = '/assets/images/splash/logo.png';
  hash: any;
  loading: boolean = true;
  error: any;
  warningSplash: boolean = false;
  activityData: any;
  electiveId: number;
  optionList: Array<any> = [];
  splashElective: any;
  selectedOption: any;
  key: string;
  electiveType: ElectiveTypeEnum;
  public get roomTypeResult(): typeof RoomTypeEnum {
    return RoomTypeEnum; 
  }
  optionsHasCamper: boolean = false;
  campInfo: any = null;

  isHebrew: boolean = false;

  constructor(  private route: ActivatedRoute,
                private campersService: CampersService,
                private campService: CampService
                ) {
                  this.isHebrew = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) == 'he' : false;
                 }

  ngOnInit() {
    // check hash
    this.hash = this.route.snapshot.params.hash;
    if (!this.hash) {
      this.loading = false;
      return this.error = SplashError.NOTWORK;
    }

    this.campersService.getCallTime(this.hash).subscribe(
      res => {
        this.logo = res.campLogo && res.campLogo.length ? res.campLogo[0].full_path : '/assets/images/splash/logo.png';
        if (res.schedule.type !== ScheduleTypeEnum.ELECTIVE) {
          this.loading = false;
          return this.error = SplashError.NOTWORK;
        }
        
        this.activityData = res;
        this.electiveType = res.schedule.elective.type;
        this.campInfo = res.schedule.session.camp;

        const optionsArr: any = res.schedule.elective.options;
        for(let i = 0; i < optionsArr.length; i++) {
          
          if (optionsArr[i].activity && optionsArr[i].space) {
            
            this.optionList.push(optionsArr[i]);
            
              if (optionsArr[i].campers && 
                optionsArr[i].campers.length &&
                this.electiveType == ElectiveTypeEnum.REGISTRATIONBASED) {
                optionsArr[i].campers.forEach(camperObj => {
                    if (camperObj.camper.code == this.hash) {
                      this.optionsHasCamper = true;
                      this.selectedOption = camperObj.option_id;
                      this.goToMeeting(this.hash, this.activityData.schedule.id, camperObj.option_id);
                    }
                });
              }
          }

          if (i === optionsArr.length - 1 && !this.optionsHasCamper) {
            this.loading = false;
          }
        };   
        if (this.electiveType == ElectiveTypeEnum.PICKANDGO ){
          this.checkLocal();
        }    
      }, e => {
        this.loading = false;
        if (e.status === 410) {
          this.logo = e.error.campLogo && e.error.campLogo.length ? e.error.campLogo[0].full_path : '/assets/images/splash/logo.png';
          return this.warningSplash = true;
        } else if (e.status === 409 && e.error.message === 'Session is not active') {
          return this.error = SplashError.TRYAGAIN;
        } else if (e.error.message === 'access_denied') {
          return this.error = SplashError.DENIED;
        }
        this.error = SplashError.NOTWORK;
      });
  }

  checkLocal(): void {
    const canChangeSelection = this.activityData.schedule.elective.selection_change;
    this.key = this.activityData.schedule.id + '-' + 
              this.activityData.camper.id + '-' + 
              this.activityData.schedule.elective_id;
    const splacAct = localStorage.getItem('splashAct') && JSON.parse(localStorage.getItem('splashAct'));
    this.selectedOption = (splacAct && splacAct.hasOwnProperty(this.key)) ? splacAct[this.key] : null;

    if (!canChangeSelection && 
        this.selectedOption && 
        this.optionList.find(opt => opt.id === this.selectedOption)) {
      this.goToMeeting(this.hash, this.activityData.schedule.id, this.selectedOption);
    }else{
      this.loading = false;
    }
  }

  setToLocalOrDb(activityId: number): void {
    this.selectedOption = activityId;
    if (this.electiveType == ElectiveTypeEnum.PICKANDGO){
      let choosedActivity: any = { [this.key]: activityId };
      let splacAct = localStorage.getItem('splashAct') && JSON.parse(localStorage.getItem('splashAct'));
      if (splacAct) {
        choosedActivity = {
          ...splacAct,
          [this.key]: activityId
        };
      }
      localStorage.setItem('splashAct', JSON.stringify(choosedActivity));
      this.goToMeeting(this.hash, this.activityData.schedule.id, this.selectedOption);
    }else if (this.electiveType == ElectiveTypeEnum.REGISTRATIONBASED){     

      this.campersService.assign({"option_id": this.selectedOption}, this.hash)
      .subscribe(
        res => {
        console.log(res)
        this.goToMeeting(this.hash, this.activityData.schedule.id, this.selectedOption);
      },
      () => {
        this.loading = false;
        this.error = SplashError.NOTWORK;
      });
    }
  }


  goToMeeting(hash: string, scheduleId: number, electiveId: number): void {
    this.loading = true;
    const params = {
      electiveOption: electiveId
    } 
    return this.campersService.goToKalturaMeeting(hash, scheduleId, params, 
        this.optionList.find(opt => opt.id === this.selectedOption).space.title, this.campInfo.id, this.logo, this.campInfo.room_type);
  }

}
